export const LIST_SYSTEM_USERS = 'LIST_SYSTEM_USERS';
export const LIST_SYSTEM_USERS_SUCCESS = 'LIST_SYSTEM_USERS_SUCCESS';
export const LIST_SYSTEM_USERS_FAILED = 'LIST_SYSTEM_USERS_FAILED';

export const CREATE_SYSTEM_USER = 'CREATE_SYSTEM_USER';
export const CREATE_SYSTEM_USER_SUCCESS = 'CREATE_SYSTEM_USER_SUCCESS';
export const CREATE_SYSTEM_USER_FAIL = 'CREATE_SYSTEM_USER_FAIL';

export const EDIT_SYSTEM_USER = 'EDIT_SYSTEM_USER';
export const EDIT_SYSTEM_USER_SUCCESS = 'EDIT_SYSTEM_USER_SUCCESS';
export const EDIT_SYSTEM_USER_FAIL = 'EDIT_SYSTEM_USER_FAIL';

export const VIEW_SYSTEM_USER = 'VIEW_SYSTEM_USER';
export const VIEW_SYSTEM_USER_SUCCESS = 'VIEW_SYSTEM_USER_SUCCESS';
export const VIEW_SYSTEM_USER_FAIL = 'VIEW_SYSTEM_USER_FAIL';

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FAIL = 'CHANGE_USER_STATUS_FAIL';

export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAIL = 'RESET_USER_PASSWORD_FAIL';
