import React, { useState, useEffect } from "react";
import SectionTitle from "../../components/dashboard/section-title";
import Breadcrumb from "../../components/breadcrumbs";
import { Form, Input, Button, Select, DatePicker, Image, Modal } from "antd";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "../../utils/generic";
import { useTranslation } from "react-i18next";
import {
  BiUser,
  BiTimeFive,
  BiErrorCircle
} from "react-icons/bi";
import { useRef } from "react";
import moment from "moment";
import { editIGWDetailsAction, getIGWDetailsAction } from "../../store/actions/igwServiceActions";
import { IgwSkeleton } from "../../components/skeleton/WidgetSkeleton";
import { imageUploadHandlerIdentifier } from '../../utils/upload';
import { nationality_choices, ethnicity_choices, martial_choices, religion_choices, gender_choices, hobbies_choices, experience_choices, education_choices, language_choices, upskilling_interest_choices, overseas_working_experience, local_working_experience, driving_licence_choices, job_interest_choices, yes_or_no } from "../../utils/get-igw-select-options-list"
import FormItem from "antd/es/form/FormItem";
import history from "../../utils/history";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { utcToLocalDateTime } from "../../utils/dateFormat";
const { TextArea } = Input;
const { Option } = Select;

function IGWServicesDetailsReview() {
  const { subscriber_id, form_uuid } = useParams();
  const { t } = useTranslation();

  const initialTableParams = {
    subscriber_id: subscriber_id,
    form_uuid: form_uuid,
  };

  let title = "Review IGW Details";
  let breadcrumbsItem = [
    {
      home: true,
      title: "Customer Panel",
      url: "/",
      last: false,
    },
    {
      home: false,
      title: "IGW",
      url: "/customers/igw/",
      last: false,
    },
    { home: false, title, url: "/", last: true },
  ];

  const { currentIGWService, currentIGWServiceLoading } = useSelector(
    (state) => state.igwService
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIGWDetailsAction(initialTableParams));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const { upload } = useSelector((state) => ({ upload: state.upload }));
  const { uploadImageIdentifier } = upload;


  const reviewIGWInitialValue = {
    legal_name: currentIGWService?.input_data?.legal_name,
    mobile_number: currentIGWService?.input_data?.mobile_number,
    email: currentIGWService?.input_data?.email,
    residential_address: currentIGWService?.input_data?.residential_address,
    agent_code: currentIGWService?.input_data?.agent_code,
    date_of_birth: moment(currentIGWService?.input_data?.date_of_birth, "YYYY-MM-DD"),
    place_of_birth: currentIGWService?.input_data?.place_of_birth,
    nationality: currentIGWService?.input_data?.nationality,
    ethnicity: currentIGWService?.input_data?.ethnicity,
    martial_status: currentIGWService?.input_data?.martial_status,
    religion: currentIGWService?.input_data?.religion,
    gender: currentIGWService?.input_data?.gender,
    hobbies: currentIGWService?.input_data?.hobbies.split(', '),
    ktp_id_card: currentIGWService?.input_data?.ktp_id_card.match(/.{1,4}/g).join(" "),
    birth_certificate_number: currentIGWService?.input_data?.birth_certificate_number,
    kk_family_card: currentIGWService?.input_data?.kk_family_card.match(/.{1,4}/g).join(" "),
    passport_status: currentIGWService?.input_data?.passport_status,
    passport_number: currentIGWService?.input_data?.passport_number,
    passport_date_of_expiry: currentIGWService?.input_data?.passport_date_of_expiry !== "" ? moment(currentIGWService?.input_data?.passport_date_of_expiry, "YYYY-MM-DD") : moment("2020-12-12"),
    experience_sector: currentIGWService?.input_data?.experience_sector.split(', '),
    additional_skills: currentIGWService?.input_data?.additional_skills,
    language_ability: currentIGWService?.input_data?.language_ability.split(', '),
    highest_level_of_eduaction: currentIGWService?.input_data?.highest_level_of_eduaction,
    school_name: currentIGWService?.input_data?.school_name,
    upskilling_interest: currentIGWService?.input_data?.upskilling_interest.split(', '),
    local_working_experience: currentIGWService?.input_data?.local_working_experience,
    worked_overseas_before: currentIGWService?.input_data?.worked_overseas_before,
    overseas_working_experience: currentIGWService?.input_data?.overseas_working_experience,
    overseas_working_description: currentIGWService?.input_data?.overseas_working_description,
    driving_license: currentIGWService?.input_data?.driving_license,
    driving_license_class: currentIGWService?.input_data?.driving_license_class,
    driving_license_country: currentIGWService?.input_data?.driving_license_country,
    job_interest: currentIGWService?.input_data?.job_interest,
    can_sweep_and_mop_floor: currentIGWService?.input_data?.can_sweep_and_mop_floor,
    can_iron_clothes: currentIGWService?.input_data?.can_iron_clothes,
    can_cook: currentIGWService?.input_data?.can_cook,
    cooking_description: currentIGWService?.input_data?.cooking_description,
    can_wash_car_and_clothes_by_hand: currentIGWService?.input_data?.can_wash_car_and_clothes_by_hand,
    can_feed_and_bath_baby: currentIGWService?.input_data?.can_feed_and_bath_baby,
    can_babysit: currentIGWService?.input_data?.can_babysit,
    can_fetch_children_to_and_from_school: currentIGWService?.input_data?.can_fetch_children_to_and_from_school,
    can_look_after_ill_or_old_or_disabled: currentIGWService?.input_data?.can_look_after_ill_or_old_or_disabled,
    self_description: currentIGWService?.input_data?.self_description,
    have_family_consent: currentIGWService?.input_data?.have_family_consent,
    is_willing_to_be_responsible: currentIGWService?.input_data?.is_willing_to_be_responsible,
    can_take_advice_and_obey_instructions: currentIGWService?.input_data?.can_take_advice_and_obey_instructions,
    can_work_overtime: currentIGWService?.input_data?.can_work_overtime,
    can_work_2_years_on_contract: currentIGWService?.input_data?.can_work_2_years_on_contract,
    can_be_left_alone_in_house: currentIGWService?.input_data?.can_be_left_alone_in_house,
    can_work_with_non_muslim_employer: currentIGWService?.input_data?.can_work_with_non_muslim_employer,
  };


  const reviewIGWFormHandler = ({
    legal_name,
    mobile_number,
    email,
    residential_address,
    agent_code,
    date_of_birth,
    place_of_birth,
    nationality,
    ethnicity,
    martial_status,
    religion,
    gender,
    hobbies,
    ktp_id_card,
    birth_certificate_number,
    kk_family_card,
    passport_status,
    passport_number,
    passport_date_of_expiry,
    experience_sector,
    additional_skills,
    language_ability,
    highest_level_of_eduaction,
    school_name,
    upskilling_interest,
    local_working_experience,
    worked_overseas_before,
    overseas_working_experience,
    overseas_working_description,
    driving_license,
    driving_license_class,
    driving_license_country,
    job_interest,
    can_sweep_and_mop_floor,
    can_iron_clothes,
    can_cook,
    cooking_description,
    can_wash_car_and_clothes_by_hand,
    can_feed_and_bath_baby,
    can_babysit,
    can_fetch_children_to_and_from_school,
    can_look_after_ill_or_old_or_disabled,
    self_description,
    have_family_consent,
    is_willing_to_be_responsible,
    can_take_advice_and_obey_instructions,
    can_work_overtime,
    can_work_2_years_on_contract,
    can_be_left_alone_in_house,
    can_work_with_non_muslim_employer,
    comments,
  }) => {
    const updatedFormValue = {
      legal_name: legal_name || '',
      mobile_number: mobile_number || '',
      email: email || '',
      residential_address: residential_address || '',
      agent_code: agent_code || '',
      date_of_birth: date_of_birth.format("YYYY-MM-DD") || '',
      place_of_birth: place_of_birth || '',
      nationality: nationality || '',
      ethnicity: ethnicity || '',
      martial_status: martial_status || '',
      religion: religion || '',
      gender: gender || '',
      hobbies: hobbies.join(", ") || '',
      ktp_id_card: ktp_id_card.split(" ").join("") || '',
      birth_certificate_number: birth_certificate_number || '',
      kk_family_card: kk_family_card.split(" ").join("") || '',
      passport_status: passport_status || '',
      passport_number: passport_number || '',
      passport_date_of_expiry: passport_date_of_expiry?.format("YYYY-MM-DD") || '',
      experience_sector: experience_sector.join(", ") || '',
      additional_skills: additional_skills || '',
      language_ability: language_ability.join(", ") || '',
      highest_level_of_eduaction: highest_level_of_eduaction || '',
      school_name: school_name || '',
      upskilling_interest: upskilling_interest.join(", ") || '',
      local_working_experience: local_working_experience || '',
      worked_overseas_before: worked_overseas_before || '',
      overseas_working_experience: overseas_working_experience || '',
      overseas_working_description: overseas_working_description || '',
      driving_license: driving_license || '',
      driving_license_class: driving_license_class || '',
      driving_license_country: driving_license_country || '',
      job_interest: job_interest || '',
      can_sweep_and_mop_floor: can_sweep_and_mop_floor || '',
      can_iron_clothes: can_iron_clothes || '',
      can_cook: can_cook || '',
      cooking_description: cooking_description || '',
      can_wash_car_and_clothes_by_hand: can_wash_car_and_clothes_by_hand || '',
      can_feed_and_bath_baby: can_feed_and_bath_baby || '',
      can_babysit: can_babysit || '',
      can_fetch_children_to_and_from_school: can_fetch_children_to_and_from_school || '',
      can_look_after_ill_or_old_or_disabled: can_look_after_ill_or_old_or_disabled || '',
      self_description: self_description || '',
      have_family_consent: have_family_consent || '',
      is_willing_to_be_responsible: is_willing_to_be_responsible || '',
      can_take_advice_and_obey_instructions: can_take_advice_and_obey_instructions || '',
      can_work_overtime: can_work_overtime || '',
      can_work_2_years_on_contract: can_work_2_years_on_contract || '',
      can_be_left_alone_in_house: can_be_left_alone_in_house || '',
      can_work_with_non_muslim_employer: can_work_with_non_muslim_employer || '',
      ktp_id_card_image: replacedKtpImage,
      birth_certificate_image: replacedBirthImage,
      kk_family_card_image: replacedKKImage,
      photo: replacedYourPhoto,
      comments,
    };
    dispatch(editIGWDetailsAction(initialTableParams, updatedFormValue));
  };

  const inputRef = useRef(null);

  const [passportStatus, setPassportStatus] = useState("");
  const [workedOverseas, setworkedOverseas] = useState("")
  const [drivingLicence, setDrivingLicence] = useState("")
  const [jobInterest, setJobInterest] = useState("")
  const [drivingLicenceClass, setDrivingLicenceClass] = useState("")
  const [canCook, setCanCook] = useState("")
  const [replacedKtpImage, setReplacedKtpImage] = useState("")
  const [replacedBirthImage, setReplacedBirthImage] = useState("")
  const [replacedKKImage, setReplacedKKImage] = useState("")
  const [replacedYourPhoto, setReplacedYourPhoto] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false);


  useEffect(() => {
    const input_data = currentIGWService?.input_data ?? {}
    setPassportStatus(input_data?.passport_status)
    setworkedOverseas(input_data?.worked_overseas_before)
    setDrivingLicence(input_data?.driving_license)
    setJobInterest(input_data?.job_interest)
    setDrivingLicenceClass(input_data?.driving_license_class)
    setCanCook(input_data?.can_cook)
    setReplacedKtpImage(input_data?.ktp_id_card_image)
    setReplacedBirthImage(input_data?.birth_certificate_image)
    setReplacedKKImage(input_data.kk_family_card_image)
    setReplacedYourPhoto(input_data.photo)
    setIsModalVisible(currentIGWService?.status === "FIRST_REVIEW" || currentIGWService?.status === "UPDATE_REVIEW")
  }, [currentIGWService]);


  useEffect(() => {
    const { identifier } = upload?.identifier ?? {}
    if (identifier === "ktp_id_card_image") {
      setReplacedKtpImage(uploadImageIdentifier)
    }
    else if (identifier === "birth_certificate_image") {
      setReplacedBirthImage(uploadImageIdentifier)
    }
    else if (identifier === "kk_family_card_image") {
      setReplacedKKImage(uploadImageIdentifier)
    }
    else if (identifier === "photo") {
      setReplacedYourPhoto(uploadImageIdentifier)
    }
  }, [upload.identifier, uploadImageIdentifier])

  const ReviewWarningModal = () => {
    return (
      <Modal
        maskStyle={{ backgroundColor: "rgb(37 37 36)" }}
        closable={false}
        keyboard={false}
        visible={isModalVisible}
        footer={null}
      >
        <div className="flex flex-col text-center p-4">
          <div className="flex flex-col items-center">
            <BiErrorCircle size={25} className="text-red-500 mb-2" />
            <h5 className='mb-2 font-bold'>Please note before editing profile.</h5>
            <p className='mb-4'>To continue, please ensure that you are in contact with the user. Otherwise, please cancel this action.</p>
          </div>
          <div className="flex flex-row justify-center">
            <Link to={`/customers/igw/`}>
              <Button type='default' className="mx-2" size='large'  >
                Cancel
              </Button>
            </Link>
            <Button type='success' className='success mx-2' size='large' onClick={() => { setIsModalVisible(!isModalVisible) }}>
              Proceed to edit
            </Button>
          </div>
        </div>
      </Modal>
    )
  }

  const FirstReviewBlock = () => {
    return (
      <div className="grid grid-cols-12 mb-5 row-auto w-full rounded-lg bg-white p-5">
        <div className="col-span-12 sm:col-span-8 pt-0 rounded-lg flex md:flex-row flex-col md:items-center items-start">
          <div className="px-5 py-1 text-lg font-bold rounded-full bg-orange-100 text-orange-500 border border-orange-500 mr-4 bg-opacity-25">
            First Review
          </div>
          <p className="text-md font-bold">
            Please review the first submission by the user.
          </p>
        </div>
        <div className="col-span-12 sm:col-span-4 rounded-lg flex flex-col lg:flex-row items-center justify-end">
          <div className="flex flex-row">
            <p className="mr-2">Submitted On:</p>
            <p className="font-bold">{utcToLocalDateTime(currentIGWService?.created_at)}</p>
          </div>
        </div>
      </div>
    )
  }

  const UpdateReviewBlock = () => {
    return (
      <>
        <div className="grid grid-cols-12 mb-5 row-auto w-full rounded-lg bg-white p-5">
          <div className="col-span-12 sm:col-span-8 pt-0 rounded-lg flex md:flex-row flex-col md:items-center items-start">
            <div className="px-5 py-1 text-lg font-bold rounded-full bg-orange-100 text-orange-500 border border-orange-500 mr-4 bg-opacity-25">
              Update Review
            </div>
            <p className="text-md font-bold">
              Please review the submission for update by the user.
            </p>
          </div>
          <div className="col-span-12 sm:col-span-4 rounded-lg flex flex-col lg:flex-row items-center justify-end">
            <div className="flex flex-row">
              <p className="mr-2">Submitted On:</p>
              <p className="font-bold">{utcToLocalDateTime(currentIGWService?.created_at)}</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 mb-5 row-auto w-full rounded-lg bg-white p-5">
          <div className="col-span-12 sm:col-span-4 pt-0 rounded-lg">
            <div className="flex w-full mb-2">
              <div className="w-1/2">Last Updated On:</div>
              <div className="w-1/2 font-bold">{utcToLocalDateTime(currentIGWService?.reviewed_at) || '-'}</div>
            </div>
            <div className="flex w-full">
              <div className="w-1/2">Updated By:</div>
              <div className="w-1/2 font-bold">{currentIGWService?.reviewed_by || '-'}</div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-8 rounded-lg flex flex-col md:flex-row items-center justify-end">
            <div className="flex flex-row justify-between">
              <Link to={`/customers/igw/subscriber/${subscriber_id}`}>
                <Button
                  className="ml-2 flex items-center text-green-500 border border-green-500"
                  type="default"
                  size="default"
                  icon={<BiTimeFive className="mr-2" size={16} />}
                >
                  View History
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }

  const CompletedReviewBlock = () => {
    return (
      <div className="grid grid-cols-12 mb-5 row-auto w-full rounded-lg bg-white p-5">
        <div className="col-span-12 sm:col-span-8 pt-0 rounded-lg flex md:flex-row flex-col md:items-center items-start">
          <div className="px-5 py-1 text-lg font-bold rounded-full bg-green-100 text-green-500 border border-green-500 mr-4 bg-opacity-25">
            Admin Update
          </div>
          <p className="text-md font-bold">
            Please make sure you are in contact with the user. If not, please exit and return to Profile.
          </p>
        </div>
        <div className="col-span-12 sm:col-span-4 rounded-lg flex flex-col lg:flex-row items-center justify-end">
          <div className="flex flex-row">
            <Button
              className="ml-1 flex items-center"
              type="default"
              size="default"
              onClick={history.goBack}
              icon={<BiUser className="mr-2" size={16} />}
            >
              Back to Profile
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <SectionTitle
        title={
          <div className="flex items-center">
            {title}
          </div>
        }
        right={<Breadcrumb items={breadcrumbsItem} />}
      />

      <div className="" ref={inputRef}>
        {currentIGWServiceLoading ? (
          <IgwSkeleton />
        ) : (
          <>
            {(currentIGWService?.status === "FIRST_REVIEW" || currentIGWService?.status === "UPDATE_REVIEW") ?
              <ReviewWarningModal /> :
              <></>
            }
            <div className="flex flex-col">
              {currentIGWService?.status === "FIRST_REVIEW" ?
                <FirstReviewBlock /> :
                currentIGWService?.status === "UPDATE_REVIEW" ?
                  <UpdateReviewBlock /> :
                  currentIGWService?.status === "COMPLETED" ?
                    <CompletedReviewBlock /> :
                    <></>
              }

              <div className="grid grid-cols-12 gap-4 mb-4 row-auto w-full">
                <div className="col-span-12 md:col-span-8 pt-0 rounded-lg">
                  <Form
                    name="createOrganisation"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={reviewIGWFormHandler}
                    scrollToFirstError
                    initialValues={reviewIGWInitialValue}
                  >
                    <div className="bg-white border p-4 rounded-t-md mb-5">
                      <div className="text-xl font-bold bg-white-200 pb-5">
                        Step 1 - Personal Particulars
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="legal_name"
                            label={t("igw_review.legal_name")}
                            rules={[
                              { required: true, message: "Please input valid name!" },
                              {
                                type: "string",
                                message: "Please input valid name",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z0-9]*[^=+@<>-]*$/),
                                message: "This field does not accept =, +, -, @, <, >"
                              }
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Name"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="mobile_number"
                            label={t("igw_review.mobile_number")}
                            rules={[
                              { required: true, message: "Please input mobile number" },
                            ]}
                          >
                            <Input
                              disabled
                              size="large"
                              placeholder="Enter Mobile Number"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="email"
                            label={t("igw_review.email")}
                            rules={[
                              {
                                required: false,
                                message: "Please input email!",
                              }
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Email"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-full">
                          <Form.Item
                            className="mb-6"
                            name="residential_address"
                            label={t("igw_review.residential_address")}
                            rules={[
                              { required: true, message: "Please input residential address!" },
                              {
                                type: "string",
                                message: "Please input valid residential address",
                              },
                            ]}
                          >
                            <TextArea
                              size="large"
                              placeholder="Please input residential address"
                              rows={4}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="agent_code"
                            label={t("igw_review.agent_code")}
                            rules={[
                              { required: false, message: "Please input agent code!" },
                              {
                                type: "string",
                                message: "Please input agent code",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Agent Code"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="date_of_birth"
                            label={t("igw_review.date_of_birth")}
                          // rules={[
                          //   { required: false, message: "Please select date of birth" },
                          //   {
                          //     message: "Please select",
                          //   }
                          // ]}
                          >
                            <DatePicker
                              className="w-full"
                              size="large"
                              format="YYYY-MM-DD"
                              allowClear={false}
                              onChange={d => {
                                console.log(d.format().split("T")[0])
                              }}
                              disabledDate={(current) => {
                                let last18Years = (new Date(new Date().getTime() - (6570 * 24 * 60 * 60 * 1000)))
                                return current && current > moment(last18Years, "YYYY-MM-DD")
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="place_of_birth"
                            label={t("igw_review.place_of_birth")}
                            rules={[
                              {
                                required: true,
                                message: "Please input place of birth!",
                              }
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter place of birth"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="nationality"
                            label={t('igw_review.nationality_choices')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input nationality!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              placeholder={t('igw_review.nationality_placeholder')}
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {nationality_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="ethnicity"
                            label={t('igw_review.ethnicity_choices')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input ethnicity_choices!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              placeholder="Please select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {ethnicity_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="martial_status"
                            label={t('igw_review.martial_choices')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input martial_choices!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              placeholder="Please select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {martial_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="religion"
                            label={t('igw_review.religion_choices')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input religion_choices!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              showSearch
                              placeholder="Please select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {religion_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="gender"
                            label={t('igw_review.gender_choices')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input gender_choices!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              showSearch
                              placeholder="Please select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {gender_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="hobbies"
                            htmlFor="hobbies"
                            label={t('igw_review.hobbies_choices')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input hobbies_choices!',
                              },
                            ]}
                          >
                            <Select
                              id="hobbies"
                              size="large"
                              mode="multiple"
                              // labelInValue
                              showSearch
                              placeholder="Please select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {hobbies_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="ktp_id_card"
                            label={t("igw_review.ktp_id_card")}
                            rules={[
                              { required: true, message: "Please input identity card" },
                              {
                                type: "string",
                                message: "Please input identity card",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Identity card no."
                            />
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="birth_certificate_number"
                            label={t("igw_review.birth_certificate_number")}
                            rules={[
                              { required: true, message: "Please input birth_certificate_number!" },
                              {
                                type: "string",
                                message: "Please input birth_certificate_number",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter birth certificate number"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="kk_family_card"
                            label={t("igw_review.kk_family_card")}
                            rules={[
                              { required: true, message: "Please input family card" },
                              {
                                type: "string",
                                message: "Please input identity card no.",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter KK family card no."
                            />
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="passport_status"
                            label={t('igw_review.passport_status')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input passport status!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              showSearch
                              placeholder="Select passport status"
                              onChange={(value) => {
                                console.log(value)
                                setPassportStatus(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      {
                        passportStatus === "Yes" ?
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4" >
                            <div className="w-full lg:w-1/2">
                              <Form.Item
                                className="mb-6"
                                name="passport_number"
                                label={t("igw_review.passport_number")}
                                rules={[
                                  {
                                    required: passportStatus === "Yes",
                                    message: "Please input passport number",
                                  }
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="enter passport number"
                                />
                              </Form.Item>
                            </div>
                            <div className="w-full lg:w-1/2">
                              <Form.Item
                                className="mb-6"
                                name="passport_date_of_expiry"
                                label={t("igw_review.passport_date_of_expiry")}
                                rules={[
                                  {
                                    required: passportStatus === "Yes",
                                    message: "Please input passport date of expiry"
                                  },
                                ]}
                              >
                                <DatePicker
                                  allowClear={false}
                                  className="w-full"
                                  size="large"
                                  format="YYYY-MM-DD"
                                  // disabledDate={(current) =>
                                  //   current && current < moment().startOf('day')
                                  // }
                                  onChange={on => {
                                    console.log(on)
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div> :
                          <></>}
                    </div>

                    <div className="bg-white border p-4 rounded-t-md mb-5">
                      <div className="text-xl font-bold bg-white-200 pb-5">
                        Step 2 - Working Experience
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="experience_sector"
                            label={t('igw_review.experience_sector')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input experience_sector!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              mode="multiple"
                              // labelInValue
                              showSearch
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {experience_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="additional_skills"
                            label={t("igw_review.additional_skills")}
                            rules={[
                              { required: false, message: "Please input additional skills" },
                              {
                                type: "string",
                                message: "Please input additional skills",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Select here"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="language_ability"
                            label={t('igw_review.language_ability')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input language_ability!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              mode="multiple"
                              // labelInValue
                              showSearch
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {language_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="highest_level_of_eduaction"
                            label={t('igw_review.highest_level_of_eduaction')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input highest_level_of_eduaction!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              showSearch
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {education_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="school_name"
                            label={t("igw_review.school_name")}
                            rules={[
                              { required: true, message: "Please select school name" },
                              {
                                type: "string",
                                message: "Please select school name",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Select here"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="upskilling_interest"
                            label={t('igw_review.upskilling_interest')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input upskilling_interest!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              mode="multiple"
                              showSearch
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {upskilling_interest_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="local_working_experience"
                            label={t('igw_review.local_working_experience')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input local_working_experience!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              showSearch
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {local_working_experience?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="worked_overseas_before"
                            label={t('igw_review.worked_overseas_before')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input worked_overseas_before!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                                setworkedOverseas(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      {
                        workedOverseas === "Yes" ?
                          <>
                            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                              <div className="w-full lg:w-1/2">
                                <Form.Item
                                  className="mb-6"
                                  name="overseas_working_experience"
                                  label={t('igw_review.overseas_working_experience')}
                                  rules={[
                                    {
                                      required: workedOverseas === "Yes",
                                      message: 'Please input overseas working experience!',
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    // labelInValue
                                    showSearch
                                    placeholder="Select here"
                                    onChange={(value) => {
                                      console.log(value)
                                    }}
                                  >
                                    {overseas_working_experience?.map((item) => (
                                      <Option key={item} value={item}>
                                        {item}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                              <div className="w-full lg:w-full">
                                <Form.Item
                                  className="mb-6"
                                  name="overseas_working_description"
                                  label={t("igw_review.overseas_working_description")}
                                  rules={[
                                    {
                                      required: workedOverseas === "Yes",
                                      message: "Please input overseas working description!"
                                    },
                                    {
                                      type: "string",
                                      message: "Please input valid overseas working description",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    size="large"
                                    placeholder="Enter overseas working description"
                                    rows={4}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </>
                          :
                          <></>}
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="driving_license"
                            label={t('igw_review.driving_license')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input driving_license!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              showSearch
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                                setDrivingLicence(value);
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        {
                          drivingLicence === "Yes" ?
                            <div className="w-full lg:w-1/2">
                              <Form.Item
                                className="mb-6"
                                name="driving_license_class"
                                label={t('igw_review.driving_license_class')}
                                rules={[
                                  {
                                    required: drivingLicence === "Yes",
                                    message: 'Please input driving license class!',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  // labelInValue
                                  showSearch
                                  placeholder="Select here"
                                  onChange={(value) => {
                                    setDrivingLicenceClass(value)
                                  }}
                                >
                                  {driving_licence_choices?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div> :
                            <></>
                        }

                      </div>
                      {drivingLicenceClass === "Others" ?
                        <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                          <div className="w-full lg:w-full">
                            <Form.Item
                              className="mb-6"
                              name="driving_license_country"
                              label={t("igw_review.driving_license_country")}
                              rules={[
                                {
                                  required: drivingLicenceClass === "Others",
                                  message: "Please input driving license country!"
                                },
                                {
                                  type: "string",
                                  message: "Please input valid driving license country",
                                },
                              ]}
                            >
                              <TextArea
                                size="large"
                                placeholder="Enter driving license country"
                                rows={4}
                              />
                            </Form.Item>
                          </div>
                        </div> :
                        <></>
                      }

                    </div>

                    <div className="bg-white border p-4 rounded-t-md mb-5">
                      <div className="text-xl font-bold bg-white-200 pb-5">
                        Step 3 - Apply for Job Interest
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <Form.Item
                            className="mb-6"
                            name="job_interest"
                            label={t('igw_review.job_interest')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input job_interest!',
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              // labelInValue
                              placeholder="Select here"
                              onChange={(value) => {
                                console.log(value)
                                setJobInterest(value);
                              }}
                            >
                              {job_interest_choices?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      {jobInterest === "Domestic Helper" ?
                        <>
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4">
                              <p className="text-lg">Can you sweep and mop the floor?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_sweep_and_mop_floor"
                                onChange={(value) => {
                                  console.log(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4 flex">
                              <p className="text-lg">Can you Iron Cloths?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_iron_clothes"
                                placeholder="Select here"
                                onChange={(value) => {
                                  console.log(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4	">
                              <p className="text-lg">Can you cook?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_cook"
                                placeholder="Select here"
                                onChange={(value) => {
                                  console.log(value)
                                  setCanCook(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="Select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                    setCanCook(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          {canCook === "Yes" ?
                            <>
                              <hr className="mb-5" />
                              <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                                <div className="w-full lg:w-full">
                                  <Form.Item
                                    className="mb-6"
                                    name="cooking_description"
                                    label="Please elaborate further."
                                    rules={[
                                      {
                                        required: canCook === "Yes",
                                        message: "Please input!"
                                      },
                                      {
                                        type: "string",
                                        message: "Please input valid data",
                                      },
                                    ]}
                                  >
                                    <TextArea
                                      size="large"
                                      placeholder="Please elaborate further."
                                      rows={4}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </> :
                            <></>
                          }

                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4">
                              <p className="text-lg">Are you willing to wash car and wash clothes by hand?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_wash_car_and_clothes_by_hand"
                                placeholder="Select here"
                                onChange={(value) => {
                                  console.log(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="Select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4">
                              <p className="text-lg">Do you know how to feed and bath the baby?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_feed_and_bath_baby"
                                placeholder="Select here"
                                onChange={(value) => {
                                  console.log(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="Select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4">
                              <p className="text-lg">Can you babysit and have experience in looking after babies?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_babysit"
                                placeholder="Select here"
                                onChange={(value) => {
                                  console.log(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="Select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4">
                              <p className="text-lg">Are you willing to fetch children to and from school?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_fetch_children_to_and_from_school"
                                placeholder="Select here"
                                onChange={(value) => {
                                  console.log(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="Select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-3/4">
                              <p className="text-lg">Are you willing to look after the ill, partially disabled and/or elderly persons?</p>
                            </div>
                            <div className="w-full lg:w-1/4">
                              <FormItem
                                size="large"
                                name="can_look_after_ill_or_old_or_disabled"
                                placeholder="Select here"
                                onChange={(value) => {
                                  console.log(value)
                                }}
                                rules={[
                                  {
                                    required: jobInterest === "Domestic Helper",
                                    message: 'Please select',
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  className="w-full"
                                  placeholder="Select here"
                                  // labelInValue
                                  onChange={(value) => {
                                    console.log(value)
                                  }}
                                >
                                  {yes_or_no?.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                            <div className="w-full lg:w-full">
                              <Form.Item
                                className="mb-6"
                                name="self_description"
                                label={t("igw_review.any_other_information_to_add")}
                                rules={[
                                  {
                                    required: false,
                                    message: "Please input data!"
                                  },
                                  {
                                    type: "string",
                                    message: "Please input data",
                                  },
                                ]}
                              >
                                <TextArea
                                  size="large"
                                  placeholder={t(
                                    "Please enter here"
                                  )}
                                  rows={4}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </> :
                        <></>}
                    </div>

                    <div className="bg-white border p-4 rounded-t-md mb-5">
                      <div className="text-xl font-bold bg-white-200 pb-5">
                        Step 4 - Declaration
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-3/4">
                          <p className="text-lg">Do you have your family’s consent?</p>
                        </div>
                        <div className="w-full lg:w-1/4">
                          <FormItem
                            size="large"
                            name="have_family_consent"
                            placeholder="Select here"
                            onChange={(value) => {
                              console.log(value)
                            }}
                          >
                            <Select
                              size="large"
                              className="w-full"
                              // labelInValue
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-3/4">
                          <p className="text-lg">Are you willing to be responsible for your work?</p>
                        </div>
                        <div className="w-full lg:w-1/4">
                          <FormItem
                            size="large"
                            name="is_willing_to_be_responsible"
                            placeholder="Select here"
                            onChange={(value) => {
                              console.log(value)
                            }}
                          >
                            <Select
                              size="large"
                              className="w-full"
                              // labelInValue
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-3/4">
                          <p className="text-lg">Are you willing to take advice and obey instructions?</p>
                        </div>
                        <div className="w-full lg:w-1/4">
                          <FormItem
                            size="large"
                            name="can_take_advice_and_obey_instructions"
                            placeholder="Select here"
                            onChange={(value) => {
                              console.log(value)
                            }}
                          >
                            <Select
                              size="large"
                              className="w-full"
                              // labelInValue
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-3/4">
                          <p className="text-lg">Are you willing to work overtime?</p>
                        </div>
                        <div className="w-full lg:w-1/4">
                          <FormItem
                            size="large"
                            name="can_work_overtime"
                            placeholder="Select here"
                            onChange={(value) => {
                              console.log(value)
                            }}
                          >
                            <Select
                              size="large"
                              className="w-full"
                              // labelInValue
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-3/4">
                          <p className="text-lg">Are you willing to work for 2 years on contract?</p>
                        </div>
                        <div className="w-full lg:w-1/4">
                          <FormItem
                            size="large"
                            name="can_work_2_years_on_contract"
                            placeholder="Select here"
                            onChange={(value) => {
                              console.log(value)
                            }}
                          >
                            <Select
                              size="large"
                              className="w-full"
                              // labelInValue
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-3/4">
                          <p className="text-lg">Are you comfortable to be left alone in the house?</p>
                        </div>
                        <div className="w-full lg:w-1/4">
                          <FormItem
                            size="large"
                            name="can_be_left_alone_in_house"
                            placeholder="Select here"
                            onChange={(value) => {
                              console.log(value)
                            }}
                          >
                            <Select
                              size="large"
                              className="w-full"
                              // labelInValue
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                      <hr className="mb-5" />
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-3/4">
                          <p className="text-lg">Are you prepared to work with Non-Muslim employer?</p>
                        </div>
                        <div className="w-full lg:w-1/4">
                          <FormItem
                            size="large"
                            name="can_work_with_non_muslim_employer"
                            placeholder="Select here"
                            onChange={(value) => {
                              console.log(value)
                            }}
                          >
                            <Select
                              size="large"
                              className="w-full"
                              // labelInValue
                              onChange={(value) => {
                                console.log(value)
                              }}
                            >
                              {yes_or_no?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white border p-4 rounded-t-md mb-5">
                      <div className="text-xl font-bold bg-white-200 pb-5">
                        Step 5 - Uploaded Documents
                      </div>
                      <div className="flex flex-col mb-5">
                        <p className="text-lg font-bold mb-1">Your Identity card (KTP)</p>
                        <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                          <Image
                            width={300}
                            src={replacedKtpImage}
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <input
                            className="custom-file-input"
                            size="large"
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={(e) => { imageUploadHandlerIdentifier(e, "ktp_id_card_image") }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mb-5">
                        <p className="text-lg font-bold mb-1">Your Birth Certificate</p>
                        <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                          <Image
                            width={300}
                            src={replacedBirthImage}
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <input
                            className="custom-file-input"
                            size="large"
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={(e) => { imageUploadHandlerIdentifier(e, "birth_certificate_image") }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mb-5">
                        <p className="text-lg font-bold mb-1">Your Family Card (KK)</p>
                        <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                          <Image
                            width={300}
                            src={replacedKKImage}
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <input
                            className="custom-file-input"

                            size="large"
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={(e) => { imageUploadHandlerIdentifier(e, "kk_family_card_image") }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mb-5">
                        <p className="text-lg font-bold mb-1">Your Photo</p>
                        <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                          <Image
                            width={300}
                            src={replacedYourPhoto}
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <input
                            className="custom-file-input"
                            size="large"
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={(e) => { imageUploadHandlerIdentifier(e, "photo") }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="bg-green-50 border border-green-200 p-4 rounded-t-md mb-5">
                      <div className="text-xl font-bold bg-white-200 pb-5">
                        Internal Comments
                      </div>
                      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                        <div className="w-full lg:w-full">
                          <Form.Item
                            className="mb-6"
                            name="comments"
                            label="Please add further information that may be helpful. This will not be seen by the user."
                            rules={[
                              { required: false, message: "Please input comments!" },
                              {
                                type: "string",
                                message: "Please input valid data",
                              },
                            ]}
                          >
                            <TextArea
                              size="large"
                              placeholder={t(
                                "Please Enter here"
                              )}
                              rows={4}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div className="bg-green-50 border border-green-200 rounded-t-md mb-5">
                      {currentIGWService?.comments?.length === 0 || currentIGWService?.comments === null ?
                        <div className="col-span-12 bg-white mb-5">
                          <div className="flex justify center items-center font-bold bg-green-50 border-b border-grey-300 p-5">No Comments Found</div>
                        </div> :
                        <>
                          <div className="text-xl font-bold bg-green-200 p-3 px-4 rounded-t-md">
                            Internal Comments ({currentIGWService?.comments?.length})
                          </div>
                          <div className="col-span-12 bg-white" id="expand">
                            {currentIGWService?.comments?.map((comment, index) => {
                              return (
                                <div key={index} className="flex justify items-center font-bold bg-green-50 border-b border-grey-300 p-5">
                                  <img style={{ height: "50px", objectFit: "cover" }} className="rounded-full mr-4 border border-grey-300 " src={comment.photo} width="50px" alt="" />
                                  <div className="flex flex-col">
                                    <div className="text-md font-medium mb-1 flex sm:flex-row flex-col">
                                      <p className="mr-5">{comment.name}</p>
                                      <p className="text-xs text-grey-500">{utcToLocalDateTime(comment.reviewed_at)}</p>
                                    </div>
                                    {comment.comment || "-"}
                                  </div>
                                </div>
                              )
                            })
                            }
                          </div>
                        </>}
                    </div>

                    <div className="flex justify-center">
                      <Button
                        type="success"
                        size="large"
                        className="mr-4 w-full lg:w-64 mb-4 lg:mb-0"
                        htmlType="submit"
                      >
                        Confirm and Update
                      </Button>
                    </div>
                  </Form>
                </div>
                <div className="col-span-12 md:col-span-4 text-lg font-bold row-span-full p-2 bg-white rounded-lg height-fit">
                  {!isEmpty(currentIGWService?.input_data?.photo) && (
                    <img src={currentIGWService?.input_data.photo} alt="img-igw" className="img-igw" />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default IGWServicesDetailsReview;
