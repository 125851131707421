import { put, all, takeLatest } from 'redux-saga/effects';
import * as types from '../store/actionTypes/downloadActionTypes';
import { downloadFailAction, downloadSuccessAction } from '../store/actions/downloadActions';
import { downloadService } from '../services/downloadService';
import { notification } from 'antd';
import { capitalizeFirstLetter } from '../utils/string-helpers';


export function* downloadServiceRequest({ payload }) {
	try {
		const list = yield downloadService(payload);
		yield put(downloadSuccessAction(list.data));
		notification.success({
			message: 'Download Successful',
			description: 'The download link has been emailed to your registered e-mail address.',
		});
	} catch (error) {
		yield put(downloadFailAction(error));
		notification.error({
			message: 'Download Failed',
			description: capitalizeFirstLetter(error.response.data.detail)
		});
	}
}

//Watcher
export function* downloadRequest() {
	yield takeLatest(types.DOWNLOAD_REQUEST, downloadServiceRequest);
}

export function* downloadSaga() {
	yield all([downloadRequest()]);
}
