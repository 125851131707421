import React, { useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import TransactionDetails from './transaction-details';
import CustomerWalletDetails from './wallet-details';
import CustomerProfileHeader from '../../components/profile-widgets/customer-profile-header';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { viewCustomerAction } from '../../store/actions/customerActions';
import { Skeleton, Empty } from 'antd';
import WidgetTitle from '../../components/profile-widgets/widget-title';

function CustomerDetails() {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  let title = t('customer_details.section_title');
  let customerBreadcrumbsItem = [
    {
      home: true,
      title: 'Customer Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('customer_list.section_title'),
      url: '/customers/list',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];
  let walletBreadcrumbsItem = [
    {
      home: true,
      title: 'Wallet',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'Wallet Details',
      url: '/wallet-details',
      last: false,
    },
    { home: false, title: 'Customer', url: '/', last: true },
  ];
  const { currentCustomerLoading, currentCustomer } = useSelector(
    (state) => state.customers
  );
  const { customerId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewCustomerAction(customerId));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };

  return (
    <>
      <SectionTitle
        title={title}
        right={
          <Breadcrumb
            items={
              url.includes('/wallet-details')
                ? walletBreadcrumbsItem
                : customerBreadcrumbsItem
            }
          />
        }
      />
      <div className="w-full mb-4">
        <div className="user-cover w-full h-24 lg:h-40"></div>
        {currentCustomerLoading ? (
          <div className="px-6 pb-6 flex space-x-4">
            <div>
              <Skeleton.Avatar
                active
                style={{ width: 200, height: 200 }}
                className="-mt-16 block"
              />
            </div>
            <div className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b pb-6">
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b pb-6">
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
              </div>

              <div className="flex pt-4 space-x-4">
                <Skeleton.Button active style={{ width: '200px' }} />
                <Skeleton.Button active style={{ width: '200px' }} />
              </div>
            </div>
          </div>
        ) : (
          <CustomerProfileHeader userDetails={currentCustomer} />
        )}
        <div className="flex flex-col lg:flex-row items-stretch mt-8 lg:space-x-6">
          <div className="lg:w-1/3 mb-4 lg:mb-0 bg-white border-grey-10 border">
            {currentCustomer && (
              <CustomerWalletDetails walletDetails={currentCustomer?.wallet} />
            )}
          </div>
          <div className="bg-white rounded-md border border-grey-200 p-4 lg:w-2/3">
            <div className="flex items-center">
              <WidgetTitle className="text-lg" title="Recent Transactions" />
              {currentCustomer?.transaction?.length >= 1 && (
                <Link
                  to={`${url}/transactions`}
                  className="text-blue-link font-bold cursor-pointer mt-2 ml-auto"
                >
                  {t('customer_details.wallet.recent_transactions_cta')}
                </Link>
              )}
            </div>
            {currentCustomerLoading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : currentCustomer?.transaction?.length >= 1 ? (
              currentCustomer?.transaction
                .slice(0, 4)
                .map((item, i) => (
                  <TransactionDetails
                    key={i}
                    transactionId={item.id}
                    transactionDetails={item}
                  />
                ))
            ) : (
              <Empty className="mt-8" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerDetails;
