import * as types from '../actionTypes/customer-cases';

const initialState = {
  listCases: null,
  listCasesLoading: false,
  listCasesError: null,
  currentCase: null,
  currentCaseLoading: false,
  currentCaseError: null,
  casesSummary: null,
  casesSummaryLoading: false,
  casesSummaryError: null,
  updateCaseLoading: false,
  updateCaseError: null,
  currentCaseHistory: null,
  currentCaseHistoryLoading: false,
  currentCaseHistoryError: null,
};

const customerCasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_CUSTOMER_CASES:
      return {
        ...state,
        listCasesLoading: true,
      };
    case types.LIST_CUSTOMER_CASES_SUCCESS:
      return {
        ...state,
        listCases: action.payload,
        listCasesLoading: false,
      };
    case types.LIST_CUSTOMER_CASES_FAILED:
      return {
        ...state,
        listCasesLoading: false,
        listCasesError: action.payload,
      };
    case types.GET_CUSTOMER_CASE:
      return {
        ...state,
        currentCaseLoading: true,
        updateCaseLoading: true,
      };
    case types.GET_CUSTOMER_CASE_SUCCESS:
      return {
        ...state,
        currentCase: action.payload,
        currentCaseLoading: false,
        updateCaseLoading: false,
      };
    case types.GET_CUSTOMER_CASE_FAILED:
      return {
        ...state,
        currentCaseLoading: false,
        currentCaseError: action.payload,
        updateCaseLoading: false,
      };
    case types.CUSTOMER_CASES_SUMMARY:
      return {
        ...state,
        casesSummaryLoading: true,
      };
    case types.CUSTOMER_CASES_SUMMARY_SUCCESS:
      return {
        ...state,
        casesSummary: action.payload,
        casesSummaryLoading: false,
      };
    case types.CUSTOMER_CASES_SUMMARY_FAILED:
      return {
        ...state,
        casesSummaryLoading: false,
        casesSummaryError: action.payload,
      };
    case types.UPDATE_CUSTOMER_CASE:
      return {
        ...state,
        updateCaseLoading: true,
      };
    case types.UPDATE_CUSTOMER_CASE_SUCCESS:
      return {
        ...state,
        updateCaseLoading: false,
        currentCaseLoading: true,
        currentCase: action.payload,
      };
    case types.UPDATE_CUSTOMER_CASE_FAILED:
      return {
        ...state,
        updateCaseLoading: false,
        updateCaseError: action.payload,
      };
    case types.GET_CUSTOMER_CASE_HISTORY:
      return {
        ...state,
        currentCaseHistoryLoading: true,
      };
    case types.GET_CUSTOMER_CASE_HISTORY_SUCCESS:
      return {
        ...state,
        currentCaseHistory: action.payload,
        currentCaseHistoryLoading: false,
      };
    case types.GET_CUSTOMER_CASE_HISTORY_FAILED:
      return {
        ...state,
        currentCaseHistoryLoading: false,
        currentCaseHistoryError: action.payload,
      };
    case types.RESET_CUSTOMER_CASE_HISTORY:
      return {
        ...state,
        currentCaseHistory: null,
        currentCaseHistoryLoading: false,
      };
    default:
      return state;
  }
};

export default customerCasesReducer;
