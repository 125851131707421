import axiosClient from './config';

export const getKycOverviewService = async () => {
  return await axiosClient.get('/customer-kyc/summary/');
};

export const listKYCService = async ({
  page,
  kyc_status,
  search,
  ordering,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/customer-kyc/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&kyc_status=${kyc_status}`
  );
};

export const getKYCService = async (customer_id) => {
  return await axiosClient.get(`/customer-kyc/${customer_id}/`);
};

export const getKYCDocumentService = async ({
  customer_id,
  kyc_attempt_id,
}) => {
  return await axiosClient.get(
    `/customer-kyc/${customer_id}/kyc/${kyc_attempt_id}/`
  );
};
