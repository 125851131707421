import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listTransactions } from '../../store/actions/transactionActions';
import { getTransactionStatus } from '../../utils/get-transaction-status';
import TopUpTransactionDetails from './topup/top-up-details';
import RemittanceTransactionDetails from './remittance/remittance-details';
import P2PTransactionDetails from './p2p/p2p-details';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { getTransactionCategory } from '../../utils/get-transaction-category';

function AllTransactionsTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    category: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [];
  const csvExportHeader = [
    { label: 'ID', key: 'id' },
    { label: 'Sender', key: 'sender_user.name' },
    { label: 'Receiver', key: 'recipient_user.name' },
    { label: 'Balance', key: 'balance' },
    { label: 'Amount', key: 'amount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Transaction Type', key: 'category' },
    { label: 'Date & Time', key: 'created_at' },
    { label: 'Status', key: 'status' },
  ];
  const { transactions, transactionsLoading, transactionsError } = useSelector(
    (state) => state.transaction
  );
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="category"
      dispatchAction={listTransactions}
      data={transactions}
      loading={transactionsLoading}
      error={transactionsError}
      csvExportHeader={csvExportHeader}
      downloadUrl="transaction"
    >
      <Column
        title="Sender"
        dataIndex="sender_user"
        key="sender_user"
        align="left"
        render={(text, record) => record?.sender_user?.name || 'From Bank'}
      />
      <Column
        title="Receiver"
        dataIndex="recipient_user"
        key="recipient_user"
        align="left"
        render={(text, record) => record?.recipient_user?.name || 'To Bank'}
      />
      <Column
        title="Amount"
        dataIndex="amount"
        key="amount"
        sorter={true}
        align="left"
        render={(text, record) => `${record?.amount} ${record?.currency}`}
      />
      <Column
        title="Transaction Type"
        dataIndex="category"
        key="category"
        render={(text, record) => getTransactionCategory(record?.category)}
      />
      <Column
        title="Date & Time"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getTransactionStatus(record?.status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) =>
          record?.category === 1 ? (
            <TopUpTransactionDetails transactionId={record?.id} />
          ) : record?.category === 2 ? (
            <P2PTransactionDetails transactionId={record?.id} />
          ) : record?.category === 3 ? (
            <RemittanceTransactionDetails transactionId={record?.id} />
          ) : null
        }
        width={120}
      />
    </TableUniversal>
  );
}
export default AllTransactionsTable;
