export const LIST_ORGANISATIONS = 'LIST_ORGANISATIONS';
export const LIST_ORGANISATIONS_SUCCESS = 'LIST_ORGANISATIONS_SUCCESS';
export const LIST_ORGANISATIONS_FAILED = 'LIST_ORGANISATIONS_FAILED';

export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export const CREATE_ORGANISATION_FAIL = 'CREATE_ORGANISATION_FAIL';

export const EDIT_ORGANISATION = 'EDIT_ORGANISATION';
export const EDIT_ORGANISATION_SUCCESS = 'EDIT_ORGANISATION_SUCCESS';
export const EDIT_ORGANISATION_FAIL = 'EDIT_ORGANISATION_FAIL';

export const VIEW_ORGANISATION = 'VIEW_ORGANISATION';
export const VIEW_ORGANISATION_SUCCESS = 'VIEW_ORGANISATION_SUCCESS';
export const VIEW_ORGANISATION_FAIL = 'VIEW_ORGANISATION_FAIL';

export const VIEW_ORGANISATION_SUBSCRIBER = 'VIEW_ORGANISATION_SUBSCRIBER';
export const VIEW_ORGANISATION_SUBSCRIBER_SUCCESS =
  'VIEW_ORGANISATION_SUBSCRIBER_SUCCESS';
export const VIEW_ORGANISATION_SUBSCRIBER_FAIL =
  'VIEW_ORGANISATION_SUBSCRIBER_FAIL';

export const VIEW_ORGANISATION_USERS = 'VIEW_ORGANISATION_USERS';
export const VIEW_ORGANISATION_USERS_SUCCESS =
  'VIEW_ORGANISATION_USERS_SUCCESS';
export const VIEW_ORGANISATION_USERS_FAIL = 'VIEW_ORGANISATION_USERS_FAIL';

export const DELETE_ORGANISATION = 'DELETE_ORGANISATION';
export const DELETE_ORGANISATION_SUCCESS = 'DELETE_ORGANISATION_SUCCESS';
export const DELETE_ORGANISATION_FAIL = 'DELETE_ORGANISATION_FAIL';
