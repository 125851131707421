export const LIST_TRANSACTIONS = 'LIST_TRANSACTIONS';
export const LIST_TRANSACTIONS_SUCCESS = 'LIST_TRANSACTIONS_SUCCESS';
export const LIST_TRANSACTIONS_FAILURE = 'LIST_TRANSACTIONS_FAILURE';

export const LIST_P2P_TRANSACTIONS = 'LIST_P2P_TRANSACTIONS';
export const LIST_P2P_TRANSACTIONS_SUCCESS = 'LIST_P2P_TRANSACTIONS_SUCCESS';
export const LIST_P2P_TRANSACTIONS_FAILURE = 'LIST_P2P_TRANSACTIONS_FAILURE';

export const LIST_REMITTANCE_TRANSACTIONS = 'LIST_REMITTANCE_TRANSACTIONS';
export const LIST_REMITTANCE_TRANSACTIONS_SUCCESS =
  'LIST_REMITTANCE_TRANSACTIONS_SUCCESS';
export const LIST_REMITTANCE_TRANSACTIONS_FAILURE =
  'LIST_REMITTANCE_TRANSACTIONS_FAILURE';

export const LIST_TOP_UP_TRANSACTIONS = 'LIST_TOP_UP_TRANSACTIONS';
export const LIST_TOP_UP_TRANSACTIONS_SUCCESS =
  'LIST_TOP_UP_TRANSACTIONS_SUCCESS';
export const LIST_TOP_UP_TRANSACTIONS_FAILURE =
  'LIST_TOP_UP_TRANSACTIONS_FAILURE';

export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAILURE = 'GET_TRANSACTION_FAILURE';

export const TRANSACTION_OVERVIEW = 'TRANSACTION_OVERVIEW';
export const TRANSACTION_OVERVIEW_SUCCESS = 'TRANSACTION_OVERVIEW_SUCCESS';
export const TRANSACTION_OVERVIEW_FAILURE = 'TRANSACTION_OVERVIEW_FAILURE';
