import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { Link } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { listCustomerCasesAction } from '../../store/actions/customerCasesAction';
import { getKycStatus } from '../../utils/get-kyc-status';
import { getCaseStatus } from '../../utils/get-case-status';

function CustomerCasesTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '-updated_at',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [
    {
      key: 'case_open',
      value: 'Case Open',
    },
    {
      key: 'case_closed',
      value: 'Case Closed',
    },
  ];
  const csvExportHeader = [
    { label: 'Case ID', key: 'case_id' },
    { label: 'Name', key: 'name' },
    { label: 'Case Status', key: 'status' },
    { label: 'Case Created', key: 'created_at' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Case Updated', key: 'updated_at' },
    { label: 'KYC Status', key: 'external_kyc_status' },
  ];
  const { listCases, listCasesLoading, listCasesError } = useSelector(
    (state) => state.customerCases
  );
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listCustomerCasesAction}
      data={listCases}
      loading={listCasesLoading}
      error={listCasesError}
      csvExportHeader={csvExportHeader}
      downloadUrl="customer-case"
    >
      <Column
        title="Case ID"
        dataIndex="case_id"
        key="case_id"
        align="left"
        render={(text, record) => record?.case_id}
      />
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Case Status"
        dataIndex="status"
        key="status"
        sorter={true}
        align="left"
        render={(text, record) => getCaseStatus(record?.status)}
      />
      <Column
        title="Case Created"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="Case Updated"
        dataIndex="updated_at"
        key="updated_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.updated_at)}
        defaultSortOrder="descend"
      />
      <Column
        title="KYC Status"
        dataIndex="external_kyc_status"
        key="external_kyc_status"
        render={(text, record) => getKycStatus(record?.external_kyc_status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/customers/cases/${record?.user}/${record?.uuid}`}>
            View Details
          </Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default CustomerCasesTable;
