export const LIST_CUSTOMERS = 'LIST_CUSTOMERS';
export const LIST_CUSTOMERS_SUCCESS = 'LIST_CUSTOMERS_SUCCESS';
export const LIST_CUSTOMERS_FAILED = 'LIST_CUSTOMERS_FAILED';

export const VIEW_CUSTOMER = 'VIEW_CUSTOMER';
export const VIEW_CUSTOMER_SUCCESS = 'VIEW_CUSTOMER_SUCCESS';
export const VIEW_CUSTOMER_FAILED = 'VIEW_CUSTOMER_FAILED';

export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILED = 'EDIT_CUSTOMER_FAILED';

export const UPDATE_CUSTOMER_PIN = 'UPDATE_CUSTOMER_PIN';
export const UPDATE_CUSTOMER_PIN_SUCCESS = 'UPDATE_CUSTOMER_PIN_SUCCESS';
export const UPDATE_CUSTOMER_PIN_FAILED = 'UPDATE_CUSTOMER_PIN_FAILED';

export const LIST_CUSTOMER_TRANSACTION = 'LIST_CUSTOMER_TRANSACTION';
export const LIST_CUSTOMER_TRANSACTION_SUCCESS =
  'LIST_CUSTOMER_TRANSACTION_SUCCESS';
export const LIST_CUSTOMER_TRANSACTION_FAILED =
  'LIST_CUSTOMER_TRANSACTION_FAILED';
