// Root Reducer
import { combineReducers } from 'redux';
import config from './config';
import dashboard from './dashboard';
import rolesPermission from './rolesPermissions';
import leftSidebar from './left-sidebar';
import palettes from './palettes';
import navigation from './navigation';
import auth from './authReducer';
import systemUsers from './systemUsersReducer';
import orgCategory from './orgCategoryReducer';
import organisation from './organisationReducer';
import orgMessages from './orgMessageReducer';
import upload from './uploadReducer';
import orgUsers from './orgUsersReducer';
import customers from './customerReducer';
import userProfile from './userProfileReducer';
import kyc from './kycReducer';
import transaction from './transactionReducer';
import wallet from './walletReducer';
import customerCases from './customerCasesReducer';
import igwService from './igwServiceReducer';
import download from './downloadReducer';

const rootReducer = combineReducers({
  navigation,
  config,
  dashboard,
  rolesPermission,
  leftSidebar,
  palettes,
  auth,
  systemUsers,
  userProfile,
  orgCategory,
  organisation,
  orgMessages,
  upload,
  orgUsers,
  customers,
  kyc,
  transaction,
  wallet,
  customerCases,
  igwService,
  download,
});

export default rootReducer;
