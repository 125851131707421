import { formatCurrency } from '../../utils/numbers';
import React from 'react';
import '../../utils/numbers';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { BiInfoCircle } from 'react-icons/bi';

export function TransactionsOverviewWidget1({ transaction_overview }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
      <div className="flex flex-col">
        <div className="flex justify-center items-center font-bold pb-3">
          <div className="flex flex-col">
            <div className="text-md font-medium mb-1">
              Total number of Transactions
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-4 space-y-4 lg:space-y-0">
        <div className="flex flex-col justify-center items-center sm:w-1/2 w-full">
          {/* <BiWallet size={24} /> */}
          <img src="/icons/ic_top_up.svg" width={25} alt="top-up" />
          <div className="text-md font-normal my-3">
            {t('dashboard.transactions_overview.top_ups')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            <span className="">
              {' '}
              {formatCurrency(transaction_overview?.topup_count || 0)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="flex flex-col justify-center items-center sm:w-1/2 w-full">
          {/* <AiFillBank size={24} /> */}
          <img src="/icons/ic_remmittance.svg" width={25} alt="remmittance" />
          <div className="text-md font-normal my-3">
            {t('dashboard.transactions_overview.remittance')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            <span className="">
              {' '}
              {formatCurrency(
                transaction_overview?.remittance_count || 0
              )}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TransactionsOverviewWidget2({ transaction_overview }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-3 rounded-full bg-blue-100 text-blue-500 border border-blue-100 mr-4 bg-opacity-25">
            {/* <BiWallet size={24} /> */}
            <img src="/icons/ic_top_up_blue.svg" width={22} alt="wallet" />
          </div>
          <div className="flex flex-col">
            <div className="text-md font-normal mb-1 flex items-center">
              Total Top-ups Amount
              <Tooltip
                title="Showing only successful Top-ups transactions"
                className="ml-2"
                overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}
                placement="top"
                arrowPointAtCenter
              >
                <BiInfoCircle size={16} />
              </Tooltip>
            </div>
            <div className="text-2xl order-last font-extrabold leading-none">
              SGD{' '}
              <span className="">
                {' '}
                {formatCurrency(transaction_overview?.total_topup_amount || 0)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="font-medium">
          <div className="text-md font-normal mb-1">
            {t('dashboard.transactions_overview.last_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(transaction_overview?.last_month_topup_amount || 0)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md font-normal mb-1">
            {t('dashboard.transactions_overview.this_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(
                transaction_overview?.this_month_topup_amount || 0
              )}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TransactionsOverviewWidget3({ transaction_overview }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-3 rounded-full bg-blue-100 text-blue-500 border border-blue-100 mr-4 bg-opacity-25">
            {/* <AiFillBank size={24} /> */}
            <img src="/icons/ic_remittance_blue.svg" width={22} alt="wallet" />

          </div>
          <div className="flex flex-col">
            <div className="text-md font-normal mb-1 flex items-center">
              Total Remittance Amount
              <Tooltip
                title="Showing only successful Remittance transactions"
                className="ml-2"
                overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}
                placement="top"
                arrowPointAtCenter
              >
                <BiInfoCircle size={16} />
              </Tooltip>
            </div>
            <div className="text-2xl order-last font-extrabold leading-none">
              SGD{' '}
              <span className="">
                {' '}
                {formatCurrency(transaction_overview?.total_remittance_amount || 0)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="font-medium">
          <div className="text-md font-normal mb-1">
            {t('dashboard.transactions_overview.last_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(transaction_overview?.last_month_remittance_amount || 0)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md font-normal mb-1">
            {t('dashboard.transactions_overview.this_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(
                transaction_overview?.this_month_remittance_amount || 0
              )}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}