import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { HiMenuAlt1 } from 'react-icons/hi';
import Dropdown5 from './dropdown-5';
import '../../css/components/navbar.css';
import { getUserProfileAction } from '../../store/actions/userProfileActions';
// import LanguageSelect from './languageSelect';

const Navbar = () => {
  const { config, userProfile } = useSelector(
    (state) => ({
      config: state.config,
      userProfile: state.userProfile,
    }),
    shallowEqual
  );
  let { collapsed } = { ...config };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, []); // eslint-disable-line

  return (
    <div className="navbar navbar-1 border-b sticky top-0 z-10">
      <div className="navbar-inner w-full flex items-center justify-start">
        <button
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed,
            })
          }
          className="mx-4"
        >
          <HiMenuAlt1 size={20} />
        </button>
        <span className="ml-auto"></span>
        {/* <LanguageSelect /> */}
        {/* Notifications */}
        {/* <Dropdown3 /> */}
        <Dropdown5 userProfile={userProfile?.user} />
        <span className="ml-2"></span>
      </div>
    </div>
  );
};

export default Navbar;
