import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { BiPlusCircle } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Drawer, Button, Spin } from 'antd';
import OrgUsersTable from './org-user-table';
import { createOrgUserAction } from '../../store/actions/orgUsersAction';
import { useTranslation } from 'react-i18next';
import { listOrganisationAction } from '../../store/actions/organisationActions';
import { CheckPermission } from '../../components/check-permission';
import { isEmpty } from '../../utils/generic';
import useDebounce from '../../hooks/useDebounce';
import { listRoles } from '../../store/actions/rolesPermissionActions';

function OrgUsers() {
  const dispatch = useDispatch();
  const { orgUsers, organisation, rolesPermission } = useSelector(
    (state) => ({
      orgUsers: state.orgUsers,
      organisation: state.organisation,
      rolesPermission: state.rolesPermission,
    }),
    shallowEqual
  );
  const { createUserLoading } = orgUsers;
  const { orgList, orgListLoading } = organisation;
  const { roles, rolesLoading } = rolesPermission;
  const [searchOrg, setSearchOrg] = useState('');
  const debouncedOrgSearchValue = useDebounce(searchOrg, 500);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentOrgUUID, setCurrentOrgUUID] = useState('');
  const { t } = useTranslation();
  const [addOrgUserForm] = Form.useForm();
  let title = t('org_user.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    { home: false, title: 'Org User', url: '/', last: true },
  ];
  const { Option } = Select;
  const listOrgSearchHandler = (value) => {
    setSearchOrg(value);
  };
  const addOrgUserHandler = (values) => {
    dispatch(
      createOrgUserAction({
        ...values,
        organization: currentOrgUUID,
      })
    );
    setIsDrawerOpen(false);
    addOrgUserForm.resetFields();
  };
  let listOrgInitialValue = {
    page: 1,
    search: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    is_active: '',
  };
  useEffect(() => {
    if (debouncedOrgSearchValue !== '') {
      const timer = setTimeout(() => {
        dispatch(
          listOrganisationAction({
            ...listOrgInitialValue,
            search: debouncedOrgSearchValue,
          })
        );
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else {
      dispatch(listOrganisationAction(listOrgInitialValue));
    }
  }, [debouncedOrgSearchValue]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <CheckPermission permission="organization_management">
        <div className="flex lg:flex-row-reverse mb-6">
          <Button
            type="success"
            size="large"
            className="flex items-center"
            onClick={() => setIsDrawerOpen(true)}
            disabled={createUserLoading}
            icon={
              createUserLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      size={22}
                      className="mr-2 mb-1 text-blue-anak"
                      spin
                    />
                  }
                />
              ) : (
                <BiPlusCircle size={24} className="mr-2" />
              )
            }
          >
            Add New Org User
          </Button>
          <Drawer
            title={t('org_user.add_modal.title')}
            placement="right"
            onClose={() => {
              addOrgUserForm.resetFields();
              setIsDrawerOpen(false);
            }}
            visible={isDrawerOpen}
            headerStyle={{
              fontWeight: 'bold',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Form
              layout="vertical"
              onFinish={addOrgUserHandler}
              autoComplete="off"
              form={addOrgUserForm}
            >
              <Form.Item
                name="name"
                label="Full Name"
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: "Please input user's full name!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="Enter Name" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email Address"
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: 'Enter email address!',
                  },
                ]}
              >
                <Input
                  type="email"
                  size="large"
                  placeholder="Enter email address!"
                />
              </Form.Item>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: 'Select role!',
                  },
                ]}
                className="mb-6"
                onClick={() => dispatch(listRoles('org_admin'))}
              >
                <Select size="large" placeholder="Select user role">
                  {rolesLoading ? (
                    <Option value="loading">Loading...</Option>
                  ) : !isEmpty(roles?.results) ? (
                    roles?.results?.map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))
                  ) : (
                    <Option disabled value="not-found">
                      No roles found
                    </Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                className="mb-6"
                name="organization"
                label="Organization"
                rules={[
                  {
                    required: true,
                    message: 'Please input organisation!',
                  },
                ]}
              >
                <Select
                  size="large"
                  labelInValue
                  filterOption={false}
                  showSearch
                  onSearch={listOrgSearchHandler}
                  placeholder="Search for an organization"
                  onChange={({ value }) => {
                    setCurrentOrgUUID(value);
                    dispatch(listOrganisationAction(listOrgInitialValue));
                  }}
                >
                  {orgListLoading ? (
                    <Option disabled value="spin">
                      <Spin size="small" />
                    </Option>
                  ) : (
                    orgList?.results?.map((item) => (
                      <Option key={item?.uuid} value={item?.uuid}>
                        {item?.name}
                      </Option>
                    ))
                  )}
                  {isEmpty(orgList?.results) && (
                    <Option disabled value="none">
                      No organisation found
                    </Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item className="mb-6">
                <Button
                  type="success"
                  htmlType="submit"
                  className="w-full"
                  size="large"
                  block
                >
                  Add New Org User
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        </div>
      </CheckPermission>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <OrgUsersTable />
      </Card>
    </>
  );
}

export default OrgUsers;
