import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listKYCAction } from '../../store/actions/kycActions';
import { utcToLocalDateTime } from '../../utils/table-helper';
import { Link } from 'react-router-dom';
import { getKycStatus } from '../../utils/table-helper';

function KYCDetailsTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    kyc_status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [
    {
      key: 'verified',
      value: 'Verified',
    },
    {
      key: 'pending',
      value: 'Not Started',
    },
    {
      key: 'rejected',
      value: 'Rejected',
    },
    {
      key: 'submitted',
      value: 'In Progress',
    },
  ];
  const csvExportHeader = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Mobile Number', key: 'mobile' },
    { label: 'Registered On', key: 'created_at' },
    { label: 'KYC Reviewed At', key: 'kyc_verified_at' },
    { label: 'KYC Status', key: 'kyc_status' },
  ];
  const { kycList, kycListLoading, kycListError } = useSelector(
    (state) => state.kyc
  );
  const { Column } = Table;

  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="kyc_status"
      dispatchAction={listKYCAction}
      data={kycList}
      loading={kycListLoading}
      error={kycListError}
      csvExportHeader={csvExportHeader}
      downloadUrl="customer-kyc"
    >
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Mobile Number"
        dataIndex="mobile"
        key="mobile"
        align="left"
        render={(text, record) => (`+${record?.mobile_country} ${record?.mobile}`)}
      />
      <Column
        title="Created At"
        dataIndex="created_at"
        key="created_at"
        // defaultSortOrder="ascend"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="KYC Reviewed At"
        dataIndex="kyc_verified_at"
        key="kyc_verified_at"
        // defaultSortOrder="ascend"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.kyc_verified_at)}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getKycStatus(record?.kyc_status)}
      />

      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/customers/kyc/${record.id}`}>View Details</Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default KYCDetailsTable;
