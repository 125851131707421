import React, { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { Select, Tooltip, Empty } from 'antd';
import Widget1 from '../../components/dashboard/widget-1';
import Section from '../../components/dashboard/section';
import SectionTitle from '../../components/dashboard/section-title';
// import { FiMail } from 'react-icons/fi';
import WidgetTitle from '../../components/dashboard/widget-title';
import KYCList from '../../components/dashboard/kyc-list';
// import WalletDetailsWidget from '../../components/dashboard/wallet-details';
// import UserDetails from '../../components/dashboard/user-details';
import Breadcrumb from '../../components/breadcrumbs';
import { Column } from '@ant-design/charts';
import { Pie } from '@ant-design/plots';
import { formatNumber } from '../../utils/numbers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStats } from '../../store/actions/dashboardActions';
import moment from 'moment';
import { isEmpty } from '../../utils/generic';
import BarChartSkeleton from '../../components/skeleton/BarChartSkeleton';
import { WidgetSkeleton, WalletWidgetSkeleton, EarningsBreakdownWidgetSkeleton } from '../../components/skeleton/WidgetSkeleton';
import DonutChartSkeleton from '../../components/skeleton/DonutChartSkeleton';
import { TransactionsOverviewWidget1, TransactionsOverviewWidget2, TransactionsOverviewWidget3 } from '../../components/dashboard/transactions-overview';
import { EarningsOverviewWidget1, EarningsOverviewWidget2, EarningsOverviewWidget3 } from '../../components/dashboard/earnings-overview';
import { AiFillDollarCircle, AiOutlineGlobal } from 'react-icons/ai';

const Index = () => {
  const { Option } = Select;
  const { t } = useTranslation();
  let title = 'Dashboard';
  const dispatch = useDispatch();

  const [kycDonutData, setKycDonutData] = useState('kyc_this_month');
  const [selectedEarningsMenuData, setSelectedEarningsMenuData] = useState(null);
  // const [orgChartData, setOrgChartData] = useState('Organisation');
  // const [chartTitle, setChartTitle] = useState('dashboard.organisation_details.organization_chart_title');

  let breadcrumbsItem = [
    {
      home: true,
      title: 'Administration',
      url: '/',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];

  const { stats, statsLoading } = useSelector((state) => state.dashboard);

  const active_customers_by_month = stats?.customers_by_month?.map((item) => {
    return {
      name: 'Active Customers',
      month: moment(item.date).format('MMM YYYY'),
      count: item.active_customer_count,
    };
  });

  const inactive_customers_by_month = stats?.customers_by_month?.map((item) => {
    return {
      name: 'Inactive Customers',
      month: moment(item.date).format('MMM YYYY'),
      count: item.inactive_customer_count,
    };
  });

  const handleKycDonutSelect = (value) => {
    setKycDonutData(value);
  };
  const handleEarningBreakdownSelect = (value) => {
    setSelectedEarningsMenuData(earningsBreakdown[value])
  };

  // const inflow_transaction_by_month = stats?.transaction_by_month.map(
  //   (item) => {
  //     return {
  //       name: 'Amount Inflow',
  //       month: moment(item.date).format('MMM YYYY'),
  //       number: item.inflow,
  //     };
  //   }
  // );

  // const outflow_transaction_by_month = stats?.transaction_by_month.map(
  //   (item) => {
  //     return {
  //       name: 'Amount Outflow',
  //       month: moment(item.date).format('MMM YYYY'),
  //       number: item.outflow,
  //     };
  //   }
  // );

  // const organizations_by_month = stats?.organizations_by_month.map((item) => {
  //   return {
  //     month: moment(item.date).format('MMM YYYY'),
  //     total: item.org_count,
  //   };
  // });

  // const org_messages_by_month = stats?.org_messages_by_month.map((item) => {
  //   return {
  //     month: moment(item.date).format('MMM YYYY'),
  //     total: item.org_message_count,
  //   };
  // });

  // const handleOrgChart = (value) => {
  //   if (value === 'Message') {
  //     setOrgChartData('Message');
  //     setChartTitle('dashboard.organisation_details.message_chart_title');
  //   } else {
  //     setOrgChartData('Organisation');
  //     setChartTitle('dashboard.organisation_details.organization_chart_title');
  //   }
  // };

  // const getOrgMessageCount = (status) => {
  //   const statusArr = stats?.organization_message_overview?.filter(
  //     (item) => item.status === status
  //   )[0];
  //   if (!isEmpty(statusArr)) {
  //     return statusArr?.message_count;
  //   } else return 0;
  // };

  // const org_messages_sent = getOrgMessageCount(1);
  // const org_messages_draft = getOrgMessageCount(2);
  // const org_messages_scheduled = getOrgMessageCount(3);
  // const total_message_count = org_messages_sent + org_messages_draft + org_messages_scheduled;

  const kycDonutDataFiltered = [
    {
      type: 'Verified',
      value: stats?.kyc_overview[kycDonutData]?.verified || 0,
    },
    {
      type: 'In Progress',
      value: stats?.kyc_overview[kycDonutData]?.submitted || 0,
    },
    // {
    //   type: 'Not Started',
    //   value: stats?.kyc_overview[kycDonutData]?.pending || 0,
    // },
    {
      type: 'Rejected',
      value: stats?.kyc_overview[kycDonutData]?.rejected || 0,
    },
  ];

  const customerChartConfig = {
    data: active_customers_by_month?.concat(inactive_customers_by_month) || [],
    isGroup: true,
    xField: 'month',
    yField: 'count',
    seriesField: 'name',
    color: ['#007AFF', '#BFDDFF'],
    legend: false,
    height: 284,
  };

  // const transactionChartConfig = {
  //   data:
  //     inflow_transaction_by_month?.concat(outflow_transaction_by_month) || [],
  //   isGroup: true,
  //   xField: 'month',
  //   yField: 'number',
  //   seriesField: 'name',
  //   color: ['#22C55E', '#D3F3DF'],
  //   legend: false,
  //   height: 300,
  // };

  // const orgChartConfig = {
  //   data:
  //     orgChartData === 'Organisation'
  //       ? organizations_by_month || []
  //       : org_messages_by_month || [],
  //   xField: 'month',
  //   yField: 'total',
  //   color: '#007AFF',
  //   legend: false,
  //   height: 250,
  // };

  const kycDonutConfig = {
    appendPadding: 10,
    pieStyle: 'fill',
    data: kycDonutDataFiltered,
    angleField: 'value',
    colorField: 'type',
    color: ['#22C55E', '#FF7600', '#EF4444'],
    radius: 1,
    innerRadius: 0.7,
    legend: false,
    label: false,
    height: 220,
    statistic: false,
  };

  const KYCSelectMenu = [
    {
      key: 'kyc_today',
      text: 'Today',
    },
    {
      key: 'kyc_this_week',
      text: 'This Week',
    },
    {
      key: 'kyc_this_month',
      text: 'This Month',
    },
    {
      key: 'kyc_this_year',
      text: 'This Year',
    },
    {
      key: 'kyc_lifetime',
      text: 'Lifetime',
    },
  ];

  const earnings_overview_chart_data = stats?.earnings_breakdown?.map((item) => {
    return {
      month: moment(item.date).format('MMM YYYY'),
      SGD: parseFloat(item.earnings.toFixed(2)),
    };
  });

  const earningsOverviewConfig = {
    data: earnings_overview_chart_data?.reverse() || [],
    xField: 'month',
    yField: 'SGD',
    color: '#22C55E',
    height: 285,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  let earningsBreakdownMenu = []
  const earningsBreakdown = stats?.earnings_breakdown?.reduce((acc, curr) => {
    acc[curr.date] = curr;
    earningsBreakdownMenu.push({
      key: curr.date,
      text:moment(curr.date).format('MMMM YYYY')
    })
    return acc;
  }, {});

  // const getStatsArrow = (value) => {
  //   if (value > 0) {
  //     return (
  //       <div className="bg-green-100 bg-opacity-25 border border-green-100 block rounded-full p-1">
  //         <BiUpArrowAlt size={24} className="stroke-current text-green-anak" />
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="bg-red-100 bg-opacity-25 border border-red-100 block rounded-full p-1">
  //         <BiDownArrowAlt size={24} className="stroke-current text-red-anak" />
  //       </div>
  //     );
  //   }
  // };

  useEffect(() => {
    dispatch(getDashboardStats());
  }, []); // eslint-disable-line

  useEffect(() => {
    let defaultSelectedMonth = earningsBreakdownMenu[0]?.key
    earningsBreakdown &&
    setSelectedEarningsMenuData(earningsBreakdown[defaultSelectedMonth])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[stats])

  return (
    <>
      <SectionTitle
        title={t('dashboard.section_title')}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="mb-6 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
        <div className="w-full lg:w-2/3 mb-6 lg:mb-0">
          {/* APP USERS OVERVIEW START*/}
          <WidgetTitle title={t('dashboard.app_users_overview.widget_title')}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4 mb-4">
              {statsLoading ? (
                <>
                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>

                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>

                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>

                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>
                </>
              ) : (
                <>
                  <Widget1
                    className=""
                    title={t('dashboard.app_users_overview.total_app_users')}
                    description={formatNumber(
                      stats?.customer_overview?.total_customers
                    )}
                  />
                  <Widget1
                    title={t('dashboard.app_users_overview.registered_today')}
                    description={formatNumber(
                      stats?.customer_overview?.registered_today
                    )}
                  // right={getStatsArrow(
                  //   stats?.customer_overview?.registered_today -
                  //   stats?.customer_overview?.registered_yesterday
                  // )}
                  />
                  <Widget1
                    title={t(
                      'dashboard.app_users_overview.registered_this_month'
                    )}
                    description={formatNumber(
                      stats?.customer_overview?.registered_this_month
                    )}
                  // right={getStatsArrow(
                  //   stats?.customer_overview?.registered_this_month -
                  //   stats?.customer_overview?.registered_last_month
                  // )}
                  />
                  <Widget1
                    title={t(
                      'dashboard.app_users_overview.registered_last_month'
                    )}
                    description={formatNumber(
                      stats?.customer_overview?.registered_last_month
                    )}
                  // right={getStatsArrow(
                  //   stats?.customer_overview?.registered_this_month -
                  //   stats?.customer_overview?.registered_last_month
                  // )}
                  />
                </>
              )}
            </div>
            {/* APP USERS OVERVIEW END*/}
          </WidgetTitle>
          {/* CUSTOMER OVERVIEW START*/}
          <WidgetTitle title={t('dashboard.customer_overview.widget_title')}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4 mb-4">
              {statsLoading ? (
                <>
                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>

                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>

                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>

                  <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
                    <WidgetSkeleton />
                  </div>
                </>
              ) : (
                <>
                  <Widget1
                    className=""
                    title={t('dashboard.customer_overview.total_customers')}
                    description={formatNumber(
                      stats?.wallet_details?.total_wallet_users
                    )}
                  />
                  <Widget1
                    title={t('dashboard.customer_overview.onboarded_today')}
                    description={formatNumber(
                      stats?.wallet_details?.onboarded_today
                    )}
                  // right={getStatsArrow(
                  //   stats?.customer_overview?.registered_today -
                  //   stats?.customer_overview?.registered_yesterday
                  // )}
                  />
                  <Widget1
                    title={t(
                      'dashboard.customer_overview.onboarded_this_month'
                    )}
                    description={formatNumber(
                      stats?.wallet_details?.onboarded_this_month
                    )}
                  // right={getStatsArrow(
                  //   stats?.customer_overview?.registered_yesterday -
                  //   stats?.customer_overview
                  //     ?.registered_day_before_yesterday
                  // )}
                  />
                  <Widget1
                    title={t(
                      'dashboard.customer_overview.onboarded_last_month'
                    )}
                    description={formatNumber(
                      stats?.wallet_details?.onboarded_last_month
                    )}
                  // right={getStatsArrow(
                  //   stats?.customer_overview?.registered_this_month -
                  //   stats?.customer_overview?.registered_last_month
                  // )}
                  />
                </>
              )}
            </div>
            {/* REGISTERED CUSTOMER GRAPH */}
            {statsLoading ? (
              <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center pb-6">
                <BarChartSkeleton />
              </div>
            ) : (
              <Section
                title={t('dashboard.customer_graph.widget_title')}
                middle="blue"
                label={{
                  one: 'Active',
                  two: 'Inactive',
                }}
                right={
                  <Tooltip
                    title={t('dashboard.customer_graph.tooltip')}
                    overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}
                    placement="topRight"
                    arrowPointAtCenter
                  >
                    <BiInfoCircle size={16} />
                  </Tooltip>
                }
                className="hidden md:inline-block"
              >
                {isEmpty(customerChartConfig.data) ? (
                  <Empty className="mt-4 lg:mt-6" />
                ) : (
                  <Column {...customerChartConfig} />
                )}
              </Section>
            )}
          </WidgetTitle>
          {/* CUSTOMER OVERVIEW END*/}
        </div>

        {/* KYC DETAILS START*/}
        <div className="w-full lg:w-1/3 ">
          {statsLoading ? (
            <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center pb-10 pt-10 px-4 mt-10">
              <DonutChartSkeleton />
            </div>
          ) : (
            <>
              <WidgetTitle title={t('dashboard.kyc_details.section_title')}>
                <div className="relative w-full p-4 rounded-lg bg-white border border-grey-100">
                  <div className="flex">
                    <Select
                      defaultValue="kyc_this_month"
                      style={{ width: 140 }}
                      onChange={handleKycDonutSelect}
                      className="ml-auto"
                    >
                      {KYCSelectMenu.map((item) => (
                        <Option value={item.key} key={item.key}>
                          {item.text}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  {/* KYC DONUT */}
                  <div className="my-4 sm:my-10 sm:mb-8">
                    <Pie {...kycDonutConfig} />
                  </div>
                  <KYCList kyc={stats?.kyc_overview[kycDonutData]} />
                </div>
              </WidgetTitle>
            </>
          )}

          {/* KYC DETAILS END*/}
        </div>
      </div>
      {/* WALLET DETAILS SECTION */}
      <div className="flex flex-col w-full mb-6">
        {/* TRANSACTIONS OVERVIEW */}
        <div className="mb-6">
          <WidgetTitle title={t('dashboard.transactions_overview.section_title')}>
            <div className="flex flex-col lg:flex-row lg:space-x-4">
              {/*wallet widget*/}
              <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <>
                    <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4">
                      <WalletWidgetSkeleton />
                    </div>
                  </>
                ) : (
                  <>
                    <TransactionsOverviewWidget1 transaction_overview={stats?.transaction_overview} />
                  </>
                )}
              </div>
              <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <>
                    <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4">
                      <WalletWidgetSkeleton />
                    </div>
                  </>
                ) : (
                  <>
                    <TransactionsOverviewWidget2 transaction_overview={stats?.transaction_overview} />
                  </>
                )}
              </div>
              <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <>
                    <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4">
                      <WalletWidgetSkeleton />
                    </div>
                  </>
                ) : (
                  <>
                    <TransactionsOverviewWidget3 transaction_overview={stats?.transaction_overview} />
                  </>
                )}
              </div>
            </div>
          </WidgetTitle>
        </div>
        {/* TRANSACTIONS OVERVIEW END */}

        {/* EARNINGS OVERVIEW */}
        <div className="">
          <WidgetTitle title={t('dashboard.earnings_overview.section_title')}>
            <div className="flex flex-col lg:flex-row lg:space-x-4">
              {/*wallet widget*/}
              <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <>
                    <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4">
                      <WalletWidgetSkeleton />
                    </div>
                  </>
                ) : (
                  <>
                    <EarningsOverviewWidget1 earnings_overview={stats?.earnings_overview} />
                  </>
                )}
              </div>
              <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <>
                    <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4">
                      <WalletWidgetSkeleton />
                    </div>
                  </>
                ) : (
                  <>
                    <EarningsOverviewWidget2 earnings_overview={stats?.earnings_overview} />
                  </>
                )}
              </div>
              <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <>
                    <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4">
                      <WalletWidgetSkeleton />
                    </div>
                  </>
                ) : (
                  <>
                    <EarningsOverviewWidget3 earnings_overview={stats?.earnings_overview} />
                  </>
                )}
              </div>
            </div>
          </WidgetTitle>
        </div>
        <div className="mt-5">
          <WidgetTitle>
            <div className="flex flex-col lg:flex-row lg:space-x-4">
              <div className="w-full lg:w-2/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center pb-6">
                    <BarChartSkeleton />
                  </div>
                ) : (
                  <Section
                    title="Earnings by Month"
                    middle="green"
                    right={
                      <Tooltip
                        title="Earnings per month in SGD"
                        overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}
                        placement="topRight"
                        arrowPointAtCenter
                      >
                        <BiInfoCircle size={16} />
                      </Tooltip>
                    }
                    className="hidden md:inline-block"
                  >
                    {isEmpty(earnings_overview_chart_data) ? (
                      <Empty className="mt-4 lg:mt-6" />
                    ) : (
                      <Column {...earningsOverviewConfig} />
                    )}
                  </Section>
                )}
              </div>
              <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                {statsLoading ? (
                  <>
                    <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4">
                      <EarningsBreakdownWidgetSkeleton />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
                      <div className='flex text-md font-bold mb-5'>Earnings Breakdown</div>
                      <div className="flex flex-row items-center">
                        <div className="font-medium">Search Month</div>

                        <Select
                          defaultValue={earningsBreakdownMenu[0]?.key}
                          style={{ width: 200 }}
                          onChange={handleEarningBreakdownSelect}
                          className="ml-auto"
                        >
                          {earningsBreakdownMenu.map((item) => (
                            <Option value={item.key} key={item.key}>
                              {item.text}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className="flex items-center justify-between py-2 pb-3 mt-8">
                        <div className="flex items-center">
                          <div className="p-2 rounded-full border mr-4 bg-opacity-25 bg-green-100 border-green-100 text-green-anak">
                            <img src="/icons/ic-remmittance-green.svg" width={20} alt="remmittance" />
                          </div>
                          <div className="text-md font-bold text-grey-anak">Transfer Fee</div>
                        </div>
                        <div className="text-xl font-bold order-last">
                        ${" "}{selectedEarningsMenuData?.fee.toFixed(2)}
                        </div>
                      </div>
                      <div className="flex items-center justify-between py-2 pb-3">
                        <div className="flex items-center">
                          <div className="p-2 rounded-full border mr-4 bg-opacity-25 bg-green-100 border-green-100 text-green-anak">
                            <AiOutlineGlobal size={20} />
                          </div>
                          <div className="text-md font-bold text-grey-anak">FX Fee</div>
                        </div>
                        <div className="text-xl font-bold order-last">
                        ${" "}{selectedEarningsMenuData?.fx.toFixed(2)}
                        </div>
                      </div>
                      <div className="flex items-center justify-between py-4 pb-3 border-t border-grey-300 mt-8">
                        <div className="flex items-center">
                          <div className="p-2 rounded-full border mr-4 bg-opacity-25 bg-green-100 border-green-100 text-green-anak">
                            <AiFillDollarCircle size={28} />
                          </div>
                          <div className="text-md font-bold text-grey-anak">Earnings</div>
                        </div>
                        <div className="text-xl font-bold order-last">
                          SGD{" "}
                          <span className="text-green-anak">{selectedEarningsMenuData?.earnings.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </WidgetTitle>
        </div>
        {/* EARNINGS OVERVIEW END */}
      </div>

      {/* ORGANIZATIONS DETAILS*/}
      {/*<div className="flex flex-col w-full">
         <WidgetTitle title={t('dashboard.organisation_details.section_title')}>
          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
            <div className="w-full lg:w-2/3 mb-6">
              {statsLoading ? (
                <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-1 px-4">
                  <BarChartSkeleton />
                </div>
              ) : (
                <Section
                  title={t(chartTitle)}
                  right={
                    <Select
                      defaultValue="Organisation"
                      style={{ width: 140 }}
                      onChange={handleOrgChart}
                    >
                      <Option value="Organisation">
                        {t(
                          'dashboard.organisation_details.organization_chart_label'
                        )}
                      </Option>
                      <Option value="Message">
                        {t(
                          'dashboard.organisation_details.message_chart_label'
                        )}
                      </Option>
                    </Select>
                  }
                >
                  <div className="flex flex-row w-full">
                    <div style={{ width: '100%', minHeight: 230 }}>
                      //  <OrgChart data={orgChartData} /> 
                      {isEmpty(orgChartConfig.data) ? (
                        <Empty className="mt-4 lg:mt-6" />
                      ) : (
                        <Column {...orgChartConfig} />
                      )}
                    </div>
                  </div>
                </Section>
              )}
            </div>
            <div className="w-full lg:w-1/3 mb-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 lg:gap-4">
                {statsLoading ? (
                  <>
                    <div className="mb-2">
                      <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-start lg:items-center py-1 px-4">
                        <WidgetSkeleton />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-start lg:items-center py-1 px-4">
                        <WidgetSkeleton />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-start lg:items-center py-1 px-4">
                        <WidgetSkeleton />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-start lg:items-center py-1 px-4">
                        <WidgetSkeleton />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-start lg:items-center py-1 px-4">
                        <WidgetSkeleton />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-start lg:items-center py-1 px-4">
                        <WidgetSkeleton />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-2">
                      <Widget1
                        title={t(
                          'dashboard.organisation_details.total_organization'
                        )}
                        description={formatNumber(
                          stats?.organization_overview?.total_organizations
                        )}
                        className="text-blue-anak"
                      />
                    </div>
                    <div className="mb-2">
                      <Widget1
                        title={t(
                          'dashboard.organisation_details.onboard_this_month'
                        )}
                        description={formatNumber(
                          stats?.organization_overview?.this_month_organizations
                        )}
                        right={getStatsArrow(
                          stats?.organization_overview
                            ?.this_month_organizations -
                          stats?.organization_overview
                            ?.last_month_organizations
                        )}
                      />
                    </div>
                    <div className="mb-2">
                      <Widget1
                        title={t(
                          'dashboard.organisation_details.org_all_messages'
                        )}
                        description={formatNumber(total_message_count)}
                        right={
                          <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-2">
                            <FiMail
                              size={24}
                              className="stroke-current text-blue-anak "
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <Widget1
                        title={t(
                          'dashboard.organisation_details.draft_messages'
                        )}
                        description={formatNumber(org_messages_draft)}
                        right={
                          <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-2">
                            <BiNote
                              size={24}
                              className="stroke-current text-blue-anak"
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <Widget1
                        title={t(
                          'dashboard.organisation_details.scheduled_message'
                        )}
                        description={formatNumber(org_messages_scheduled)}
                        right={
                          <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-2">
                            <BiTime
                              size={24}
                              className="stroke-current text-blue-anak"
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <Widget1
                        title={t(
                          'dashboard.organisation_details.published_message'
                        )}
                        description={formatNumber(org_messages_sent)}
                        right={
                          <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-2">
                            <BiPaperPlane
                              size={24}
                              className="stroke-current text-blue-anak"
                            />
                          </div>
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </WidgetTitle> 
      </div>*/}
    </>
  );
};
export default Index;
