import * as types from "../actionTypes/organisations";

const initialState = {
  orgList: null,
  orgListLoading: false,
  orgListError: null,
  currentOrg: null,
  currentOrgLoading: false,
  currentOrgError: null,
  createOrgLoading: false,
  createOrgError: null,
  orgSubscribers: null,
  orgSubscribersLoading: false,
  orgSubscribersError: null,
  orgUsers: null,
  orgUsersLoading: false,
  orgUsersError: null,
};

const organisationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ORGANISATIONS:
      return {
        ...state,
        orgListLoading: true,
      };
    case types.LIST_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        orgList: action.payload,
        orgListLoading: false,
      };
    case types.LIST_ORGANISATIONS_FAILED:
      return {
        ...state,
        orgListLoading: false,
        orgListError: action.payload,
      };
    case types.VIEW_ORGANISATION:
      return {
        ...state,
        currentOrgLoading: true,
      };
    case types.VIEW_ORGANISATION_SUCCESS:
      return {
        ...state,
        currentOrg: action.payload,
        currentOrgLoading: false,
      };
    case types.VIEW_ORGANISATION_FAIL:
      return {
        ...state,
        currentOrgLoading: false,
        currentOrgError: action.payload,
      };
    case types.VIEW_ORGANISATION_SUBSCRIBER:
      return {
        ...state,
        orgSubscribersLoading: true,
      };
    case types.VIEW_ORGANISATION_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        orgSubscribers: action.payload,
        orgSubscribersLoading: false,
      };
    case types.VIEW_ORGANISATION_SUBSCRIBER_FAIL:
      return {
        ...state,
        orgSubscribersLoading: false,
        orgSubscribersError: action.payload,
      };
    case types.VIEW_ORGANISATION_USERS:
      return {
        ...state,
        orgUsersLoading: true,
      };
    case types.VIEW_ORGANISATION_USERS_SUCCESS:
      return {
        ...state,
        orgUsers: action.payload,
        orgUsersLoading: false,
      };
    case types.VIEW_ORGANISATION_USERS_FAIL:
      return {
        ...state,
        orgUsersLoading: false,
        orgUsersError: action.payload,
      };
    case types.CREATE_ORGANISATION:
      return {
        ...state,
        createOrgLoading: true,
      };
    case types.CREATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        createOrgLoading: false,
      };

    case types.CREATE_ORGANISATION_FAIL:
      return {
        createOrgError: action.payload,
      };
    default:
      return state;
  }
};

export default organisationReducer;
