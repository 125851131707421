import { put, all, takeLatest } from 'redux-saga/effects';
import * as types from '../store/actionTypes/customers';

import {
  listCustomersSuccessAction,
  listCustomersFailedAction,
  viewCustomerSuccessAction,
  viewCustomerFailAction,
  editCustomerSuccessAction,
  editCustomerFailAction,
  updateCustomerPinSuccessAction,
  updateCustomerPinFailAction,
  listCustomerTransactionSuccessAction,
  listCustomerTransactionFailedAction,
} from '../store/actions/customerActions';

import {
  listCustomersService,
  viewCustomerService,
  editCustomerService,
  updateCustomerPinService,
  listCustomerTransactionService,
} from '../services/customerService';
import { notification } from 'antd';
import history from '../utils/history';

export function* listCustomers({ payload }) {
  try {
    const list = yield listCustomersService(payload);
    yield put(listCustomersSuccessAction(list.data.data));
  } catch (error) {
    yield put(listCustomersFailedAction(error));
  }
}
export function* viewCustomer({ payload }) {
  try {
    const view = yield viewCustomerService(payload);
    yield put(viewCustomerSuccessAction(view.data.data));
  } catch (error) {
    yield put(viewCustomerFailAction(error));
  }
}
export function* editCustomer({ payload: { customerId, updatedFormValue } }) {
  try {
    const edit = yield editCustomerService(customerId, updatedFormValue);
    yield put(editCustomerSuccessAction(edit.data));
    notification['success']({
      message: 'Customer edited successfully.',
      // description: 'Customer edited successfully.',
    });
    history.push('/customers/list');
  } catch (error) {
    yield put(editCustomerFailAction(error));
    notification['error']({
      message: 'Failed to edit customer!',
      // description: error.response.data.detail,
    });
  }
}
export function* updateCustomerPin({ payload: { customerId, pin } }) {
  try {
    const update = yield updateCustomerPinService(customerId, pin);
    yield put(updateCustomerPinSuccessAction(update.data));
    notification['success']({
      message: 'Customer pin updated successfully.',
      description: 'Customer pin updated successfully.',
    });
  } catch (error) {
    yield put(updateCustomerPinFailAction(error));
    notification['error']({
      message: 'Failed to update customer pin!',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* listCustomerTransaction({ payload }) {
  try {
    const list = yield listCustomerTransactionService(payload);
    yield put(listCustomerTransactionSuccessAction(list.data.data));
  } catch (error) {
    yield put(listCustomerTransactionFailedAction(error));
  }
}

export function* onListRequest() {
  yield takeLatest(types.LIST_CUSTOMERS, listCustomers);
}

export function* onViewRequest() {
  yield takeLatest(types.VIEW_CUSTOMER, viewCustomer);
}
export function* onEditRequest() {
  yield takeLatest(types.EDIT_CUSTOMER, editCustomer);
}
export function* onUpdatePinRequest() {
  yield takeLatest(types.UPDATE_CUSTOMER_PIN, updateCustomerPin);
}
export function* onListCustomerTransaction() {
  yield takeLatest(types.LIST_CUSTOMER_TRANSACTION, listCustomerTransaction);
}
export function* customerSaga() {
  yield all([
    onListRequest(),
    onViewRequest(),
    onEditRequest(),
    onUpdatePinRequest(),
    onListCustomerTransaction(),
  ]);
}
