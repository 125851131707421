import React from 'react';
import { BiInfoCircle, BiUser } from 'react-icons/bi';
import { Tooltip } from 'antd';
import { formatNumber } from '../../utils/numbers';
import { useTranslation } from 'react-i18next';

export default function UserDetails({ user_details }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100 mt-4">
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-3 rounded-full bg-blue-100 text-blue-500 border border-blue-100 mr-4 bg-opacity-25">
            <BiUser size={24} />
          </div>
          <div className="flex flex-col">
            <div className="text-md font-medium mb-1">
              {t('dashboard.wallet_details.total_users')}
            </div>
            <div className="text-2xl order-last font-bold leading-none">
              {formatNumber(user_details?.total_wallet_users || 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="">
          <div className="text-md flex items-center font-medium mb-1">
            {t('dashboard.wallet_details.users_active')}
            <span className="ml-2">
              <Tooltip
                title="Active = At least 1 transaction per month"
                placement="top"
              >
                <BiInfoCircle size={16} />
              </Tooltip>
            </span>
          </div>
          <div className="text-xl order-last font-bold leading-none">
            {formatNumber(user_details?.active_wallet_user || 0)}
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md flex items-center font-medium mb-1 tracking-normal">
            {t('dashboard.wallet_details.users_inactive')}
            <span className="ml-2">
              <Tooltip
                title="Active = At least 1 transaction per month"
                placement="top"
              >
                <BiInfoCircle size={16} />
              </Tooltip>
            </span>
          </div>
          <div className="text-xl order-last font-bold leading-none">
            {formatNumber(
              user_details?.total_wallet_users -
                user_details?.active_wallet_user || 0
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
