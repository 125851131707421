import * as types from '../actionTypes/customers';

const initialState = {
  customersList: null,
  customersListLoading: false,
  customersListError: null,
  currentCustomer: null,
  currentCustomerLoading: false,
  currentCustomerError: null,
  editCustomerLoading: false,
  editCustomerSuccess: false,
  editCustomerError: null,
  resetCustomerPinLoading: false,
  resetCustomerPinError: null,
  resetCustomerPinSuccess: null,
  transactionList: null,
  transactionListLoading: false,
  transactionListError: null,
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_CUSTOMERS:
      return {
        ...state,
        customersListLoading: true,
      };
    case types.LIST_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customersList: action.payload,
        customersListLoading: false,
      };
    case types.LIST_CUSTOMERS_FAILED:
      return {
        ...state,
        customersListLoading: false,
        customersListError: action.payload,
      };
    case types.VIEW_CUSTOMER:
      return {
        ...state,
        currentCustomerLoading: true,
      };
    case types.VIEW_CUSTOMER_SUCCESS:
      return {
        ...state,
        currentCustomer: action.payload,
        currentCustomerLoading: false,
      };
    case types.VIEW_CUSTOMER_FAILED:
      return {
        ...state,
        currentCustomerLoading: false,
        currentCustomerError: action.payload,
      };
    case types.EDIT_CUSTOMER:
      return {
        ...state,
        editCustomerLoading: true,
        editCustomerSuccess: false,
        editCustomerError: null,
      };
    case types.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        editCustomerLoading: false,
        editCustomerSuccess: true,
        editCustomerError: null,
        currentCustomer: action.payload,
      };
    case types.EDIT_CUSTOMER_FAILED:
      return {
        ...state,
        editCustomerLoading: false,
        editCustomerSuccess: false,
        editCustomerError: action.payload,
      };
    case types.UPDATE_CUSTOMER_PIN:
      return {
        ...state,
        resetCustomerPinLoading: true,
        resetCustomerPinError: null,
        resetCustomerPinSuccess: null,
      };
    case types.UPDATE_CUSTOMER_PIN_SUCCESS:
      return {
        ...state,
        resetCustomerPinLoading: false,
        resetCustomerPinSuccess: action.payload,
      };
    case types.UPDATE_CUSTOMER_PIN_FAILED:
      return {
        ...state,
        resetCustomerPinLoading: false,
        resetCustomerPinError: action.payload,
      };
    case types.LIST_CUSTOMER_TRANSACTION:
      return {
        ...state,
        transactionListLoading: true,
        transactionListError: null,
      };
    case types.LIST_CUSTOMER_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionList: action.payload,
        transactionListLoading: false,
        transactionListError: null,
      };
    case types.LIST_CUSTOMER_TRANSACTION_FAILED:
      return {
        ...state,
        transactionListLoading: false,
        transactionListError: action.payload,
      };
    default:
      return state;
  }
};

export default customersReducer;
