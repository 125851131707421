import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as types from '../store/actionTypes/system-users';
import {
  listSystemUsersSuccessAction,
  listSystemUsersFailedAction,
  createSystemUserSuccessAction,
  createSystemUserFailAction,
  editSystemUserSuccessAction,
  editSystemUserFailAction,
  viewSystemUserSuccessAction,
  viewSystemUserFailAction,
  changeSystemUserStatusSuccessAction,
  changeSystemUserStatusFailAction,
  resetSystemUserPasswordSuccessAction,
  resetSystemUserPasswordFailAction,
} from '../store/actions/systemUsersActions';
import {
  listSystemUsersService,
  createSystemUserService,
  editSystemUserService,
  viewSystemUserService,
  changeSystemUserStatusService,
  resetSystemUserPasswordService,
} from '../services/systemUserService';
import { isEmpty } from '../utils/generic';
import { notification } from 'antd';

//Worker
export function* listSystemUsers({ payload }) {
  try {
    const list = yield listSystemUsersService(payload);
    yield put(listSystemUsersSuccessAction(list.data.data));
  } catch (error) {
    yield put(listSystemUsersFailedAction(error));
  }
}

export function* createSystemUser({ payload: { name, email, role } }) {
  try {
    const create = yield createSystemUserService(name, email, role);
    if (isEmpty(create)) {
      yield put(createSystemUserFailAction(create.data));
      notification['error']({
        message: 'Something went wrong!',
        description: 'Please retry or contact administration.',
      });
    } else if (create.status_code === 400) {
      yield put(createSystemUserFailAction(create.data));
      notification['error']({
        message: 'Email already exists!',
        description:
          'This email already exists. Please enter unique email address.',
      });
    } else if (create.status === 200) {
      yield put(createSystemUserSuccessAction(create.data.data.user));
      notification['success']({
        message: 'System user added successfully.',
        description:
          'System user added successfully and an invite email has sent.',
      });
      yield call(listSystemUsers, {
        payload: {
          page: 1,
          search: '',
          role: '',
          ordering: '-created_at',
          page_size: 10,
          created_at_after: '',
          created_at_before: '',
        },
      });
    } else {
      yield put(createSystemUserFailAction(create.status));
    }
  } catch (error) {
    yield put(createSystemUserFailAction(error));
    notification['error']({
      message: 'Something went wrong!',
      description: 'Please retry or contact administration.',
    });
  }
}

export function* editSystemUser({ payload: { updatedFormValue, userId } }) {
  try {
    const edit = yield editSystemUserService(updatedFormValue, userId);
    yield put(editSystemUserSuccessAction(edit.data.data));
    notification['success']({
      message: 'User profile updated successfully.',
      description: 'User profile updated successfully',
    });
  } catch (error) {
    yield put(editSystemUserFailAction(error));
  }
}

export function* viewSystemUser({ payload: userId }) {
  try {
    const list = yield viewSystemUserService(userId);
    yield put(viewSystemUserSuccessAction(list.data.data));
  } catch (error) {
    yield put(viewSystemUserFailAction(error));
  }
}

export function* changeSystemUserStatus({
  payload: { userStatus: status, uuid },
}) {
  try {
    const change = yield changeSystemUserStatusService(status, uuid);
    yield put(changeSystemUserStatusSuccessAction(change.data.data));
    notification['success']({
      message: 'User status updated successfully.',
      description: 'User status updated successfully',
    });
  } catch (error) {
    yield put(changeSystemUserStatusFailAction(error));
    notification['error']({
      message: 'Failed to update user status!',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* resetPassword({ payload }) {
  try {
    yield resetSystemUserPasswordService(payload);
    yield put(resetSystemUserPasswordSuccessAction());
    notification['success']({
      message: 'Password link sent successfully.',
      description: 'Password link sent successfully',
    });
  } catch (error) {
    yield put(resetSystemUserPasswordFailAction(error));
    notification['error']({
      message: 'Failed to reset password!',
      description: 'Please retry or contact administration.',
    });
  }
}
//Watcher
export function* onListRequest() {
  yield takeLatest(types.LIST_SYSTEM_USERS, listSystemUsers);
}

export function* onCreateSystemUser() {
  yield takeLatest(types.CREATE_SYSTEM_USER, createSystemUser);
}

export function* onEditSystemUser() {
  yield takeLatest(types.EDIT_SYSTEM_USER, editSystemUser);
}

export function* onViewSystemUser() {
  yield takeLatest(types.VIEW_SYSTEM_USER, viewSystemUser);
}
export function* onChangeSystemUserStatus() {
  yield takeLatest(types.CHANGE_USER_STATUS, changeSystemUserStatus);
}
export function* onResetPassword() {
  yield takeLatest(types.RESET_USER_PASSWORD, resetPassword);
}

export function* systemUsersSaga() {
  yield all([
    onListRequest(),
    onCreateSystemUser(),
    onEditSystemUser(),
    onViewSystemUser(),
    onChangeSystemUserStatus(),
    onResetPassword(),
  ]);
}
