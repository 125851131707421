import React, { useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import Widget from '../../components/dashboard/widget-1';
import { formatNumber } from '../../utils/numbers';
import {
  BiUser,
  BiUserCheck,
  BiUserMinus,
  BiUserPin,
  BiUserX,
} from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import KYCDetailsTable from './table';
import { WidgetSkeleton } from '../../components/skeleton/WidgetSkeleton';
import { getKycOverviewAction } from '../../store/actions/kycActions';

function KYCDetails() {
  const { t } = useTranslation();
  let title = t('kyc_details.section_title');
  const dispatch = useDispatch();
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Customer Panel',
      url: '/',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];
  // const { kycList, kycListLoading } = useSelector((state) => state.kyc);
  // const { kycOverview } = useSelector((state) => state.kyc);

  const { kycOverview, kycOverviewLoading } = useSelector(
    (state) => state.kyc
  );

  // kycOverviewLoading

  useEffect(() => {
    dispatch(getKycOverviewAction());
  }, []); // eslint-disable-line

  // console.log(kycOverview, kycOverviewLoading);

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-4 mb-4">
        {kycOverviewLoading ? (
          Array(5)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100"
                >
                  <WidgetSkeleton />
                </div>
              );
            })
        ) : (
          <>
            <Widget
              // className="text-black-anak"
              title="Total KYC"
              // description={formatNumber(kycList?.summary?.total)}
              description={kycOverview?.total}
              right={
                <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-1">
                  <BiUser size={24} className="stroke-current text-blue-anak" />
                </div>
              }
            />
            <Widget
              title="Total Verified KYC"
              description={formatNumber(kycOverview?.verified)}
              rightBorderColor="green"
              right={
                <div className="bg-green-100 bg-opacity-25 border border-green-100 block rounded-full p-1">
                  <BiUserCheck
                    size={24}
                    className="stroke-current text-green-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total In Progress KYC"
              description={formatNumber(kycOverview?.submitted)}
              rightBorderColor="blue"
              right={
                <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-1">
                  <BiUserMinus
                    size={24}
                    className="stroke-current text-blue-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Not Started KYC"
              description={formatNumber(kycOverview?.pending)}
              rightBorderColor="orange"
              right={
                <div className="bg-orange-100 bg-opacity-25 border border-orange-100 block rounded-full p-1">
                  <BiUserPin
                    size={24}
                    className="stroke-current text-orange-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Rejected KYC"
              description={formatNumber(kycOverview?.rejected)}
              rightBorderColor="red"
              right={
                <div className="bg-red-100 bg-opacity-25 border border-red-100 block rounded-full p-1">
                  <BiUserX size={24} className="stroke-current text-red-anak" />
                </div>
              }
            />
          </>
        )}
      </div>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <KYCDetailsTable />
      </Card>
    </>
  );
}

export default KYCDetails;
