import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { Avatar, Table, Skeleton, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getKYCAction } from '../../store/actions/kycActions';
import { BiChevronRight, BiFile, BiUser } from 'react-icons/bi';
import { RiHistoryFill } from "react-icons/ri";
import { utcToLocalDate, utcToLocalDateTime } from '../../utils/dateFormat';
import { replaceUnderscore } from '../../utils/string-helpers';
import { getKycStatus } from '../../utils/get-kyc-status';
import CasesInfoWidget from './cases-info-widget';
import { getCustomerCaseAction, getCustomerCaseHistoryAction, resetCustomerCaseHistoryAction } from '../../store/actions/customerCasesAction';
import DocumentDetails from '../kyc-details/document-details';
import CaseHistoryDetails from './cases-history-details';

export function CustomerCasesKYCAttemptDetails({ caseID }) {
  const [openDetails, setOpenDetails] = useState(false);
  const showDetails = () => {
    setOpenDetails(true);
  };
  return (
    <>
      <div
        className="text-blue-link font-bold cursor-pointer"
        onClick={showDetails}
      >
        View Details
      </div>
      <Modal
        title="Document Details"
        visible={openDetails}
        footer={false}
        width={'80%'}
        onOk={() => {
          setOpenDetails(false);
        }}
        onCancel={() => {
          setOpenDetails(false);
        }}
      >
        <DocumentDetails caseID={caseID} />
      </Modal>
    </>
  );
}

export function CasesHistoryModal() {
  const [openHistoryDetails, setOpenHistoryDetails] = useState(false);
  const showCaseHistoryDetails = () => {
    setOpenHistoryDetails(true);
  };
  const { case_uuid } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    openHistoryDetails && dispatch(getCustomerCaseHistoryAction(case_uuid))
    !openHistoryDetails && dispatch(resetCustomerCaseHistoryAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openHistoryDetails])

  return (
    <>
      <div style={{ "width": "fit-content" }} className="flex flex-row justify-between items-center w-min bg-blue-anaklite text-blue-anaklite rounded mb-8 p-2 text-md font-bold cursor-pointer" onClick={showCaseHistoryDetails}>
        <div className="p-1 rounded-full bg-indigo-400 mr-2 text-white"><RiHistoryFill size={16} /></div>
        <div>View Submitted Case History</div>
        <BiChevronRight size={25} />
      </div>
      <Modal
        title="Submitted Case History"
        visible={openHistoryDetails}
        footer={false}
        width={'80%'}
        onOk={() => {
          setOpenHistoryDetails(false);
        }}
        onCancel={() => {
          setOpenHistoryDetails(false);
        }}
      >
        <CaseHistoryDetails />
      </Modal>
    </>
  );
}

function CustomerCasesDetails() {
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  let title = 'Customer Cases Details';
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Compliance',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'Customer Cases',
      url: '/customers/cases',
      last: false,
    },
    { home: false, title: 'Cases Details', url: '/', last: true },
  ];
  const { currentKyc, currentKycLoading } = useSelector((state) => state.kyc);
  const { customer_id, case_uuid } = useParams();
  useEffect(() => {
    dispatch(getKYCAction(customer_id));
    dispatch(getCustomerCaseAction(case_uuid));
  }, []); // eslint-disable-line
  const columns = [
    {
      title: 'Date & Time',
      dataIndex: 'completed_at',
      render: (text, record) => utcToLocalDateTime(record?.completed_at),
      sorter: (a, b) => a.completed_at?.localeCompare(b.completed_at),
      defaultSortOrder: 'descend',
      width: '15%',
      align: 'left',
    },
    {
      title: 'Document Type',
      dataIndex: 'docType',
      render: (text, record) => replaceUnderscore(record?.document[0]?.type),
      width: '20%',
    },
    {
      title: 'Document Sub Type',
      dataIndex: 'docType',
      render: (text, record) =>
        replaceUnderscore(record?.document[0]?.sub_type),
      width: '20%',
    },
    {
      title: 'KYC Status',
      dataIndex: 'status',
      render: (text, record) => getKycStatus(record?.status),
      width: '20%',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      sorter: false,
      render: (text, record) => (
        // <Link to={`/customers/cases/${customer_id}/${case_uuid}/${record?.id}`}>
        <CustomerCasesKYCAttemptDetails caseID={record?.id} />
        // </Link>
      ),
      width: '15%',
    },
  ];
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col sm:flex-row relative bg-white rounded-md shadow-lg border border-grey-200 mb-8">
        <div className="w-2/6 relative py-4 sm:py-8 flex items-center mx-auto">
          <div className="user-cover lg:w-1/2 absolute left-0 top-0 h-full"></div>
          <Avatar
            size={{ xs: 80, sm: 80, md: 80, lg: 80, xl: 160 }}
            style={{
              verticalAlign: 'middle',
            }}
            className="w-32 h-32 rounded-full flex self-center items-center justify-center mx-auto shadow-xl border-2 border-white "
            icon={<BiUser />}
          />
        </div>
        <div className="w-4/6 sm:py-8 flex flex-col items-start justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 w-full p-4">
            <div className="text-sm">
              Legal Name
              <div className="text-base font-bold mt-1">
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  currentKyc?.customer?.name || '-'
                )}
              </div>
            </div>
            <div className="text-sm">
              Date of Birth
              <div className="text-base font-bold">
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  utcToLocalDate(currentKyc?.customer?.dob)
                )}
              </div>
            </div>
            <div className="text-sm">
              Gender
              <div className="text-base font-bold">
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  currentKyc?.customer?.gender || '-'
                )}
              </div>
            </div>
            <div className="text-sm">
              Nationality
              <div className="text-base font-bold">
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  currentKyc?.customer?.nationality || '-'
                )}
              </div>
            </div>
            <div className="text-sm">
              Mobile Number
              <div className="text-base font-bold">
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  `+${currentKyc?.customer?.mobile_country} ${currentKyc?.customer?.mobile} ` ||
                  '-'
                )}
              </div>
            </div>
            <div className="text-sm">
              Email
              <div
                className="text-base font-bold break-words w-100 white-space-normal leading-tight"
                style={{ paddingTop: 1 }}
              >
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  currentKyc?.customer?.email || '-'
                )}
              </div>
            </div>
            <div className="text-sm">
              KYC Reviewed At
              <div className="text-base font-bold">
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  utcToLocalDate(currentKyc?.customer?.kyc_verified_at)
                )}
              </div>
            </div>
            <div className="text-sm">
              Customer Case Status
              <div className="text-base font-bold">
                {currentKycLoading ? (
                  <Skeleton.Input
                    style={{ width: 120 }}
                    active={currentKycLoading}
                  />
                ) : (
                  getKycStatus(currentKyc?.customer?.kyc_status) || '-'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-lg bg-white rounded-md mb-8">
        {currentKycLoading ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : currentKyc?.kyc_attempts.length === 0 ? (
          <div className="text-grey-500 mb-6 col-span-4 flex w-full flex-col items-center ant-card-body bg-white shadow rounded py-10">
            <div className="pt-6 pb-4">
              <BiFile className="text-8xl text-gray-100" />
            </div>
            <div className="text-lg font-bold text-blue-anak">
              No KYC Attempts!
            </div>
            <div className="w-full md:w-1/3 text-center py-2 mb-2">
              Sorry! There are no KYC attempt with this user.
            </div>
          </div>
        ) : (
          <Table
            dataSource={currentKyc?.kyc_attempts}
            bordered={true}
            pagination={false}
            columns={columns}
            showSorterTooltip={false}
            rowKey={(record) => record.id}
            scroll={{ x: true }}
          />
        )}
      </div>
      {currentKycLoading ? <Skeleton.Input  style={{ width: 250 }} className="mb-2" /> : <CasesHistoryModal /> }
      <CasesInfoWidget />
    </>
  );
}

export default CustomerCasesDetails;
