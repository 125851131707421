import * as types from '../actionTypes/customer-cases';

export const listCustomerCasesAction = (tableParams) => {
  return {
    type: types.LIST_CUSTOMER_CASES,
    payload: tableParams,
  };
};
export const listCustomerCasesSuccessAction = (customerCases) => {
  return {
    type: types.LIST_CUSTOMER_CASES_SUCCESS,
    payload: customerCases,
  };
};
export const listCustomerCasesFailedAction = (error) => {
  return {
    type: types.LIST_CUSTOMER_CASES_FAILED,
    payload: error,
  };
};

export const getCustomerCaseAction = (caseId) => {
  return {
    type: types.GET_CUSTOMER_CASE,
    payload: caseId,
  };
};
export const getCustomerCaseSuccessAction = (customerCase) => {
  return {
    type: types.GET_CUSTOMER_CASE_SUCCESS,
    payload: customerCase,
  };
};
export const getCustomerCaseFailedAction = (error) => {
  return {
    type: types.GET_CUSTOMER_CASE_FAILED,
    payload: error,
  };
};
export const getCasesSummaryAction = () => {
  return {
    type: types.CUSTOMER_CASES_SUMMARY,
  };
};
export const getCasesSummarySuccessAction = (casesSummary) => {
  return {
    type: types.CUSTOMER_CASES_SUMMARY_SUCCESS,
    payload: casesSummary,
  };
};
export const getCasesSummaryFailedAction = (error) => {
  return {
    type: types.CUSTOMER_CASES_SUMMARY_FAILED,
    payload: error,
  };
};
export const updateCustomerCaseAction = (submitUrl, caseData, case_uuid) => {
  return {
    type: types.UPDATE_CUSTOMER_CASE,
    payload: { submitUrl, caseData, case_uuid },
  };
};
export const updateCustomerCaseSuccessAction = (customerCase) => {
  return {
    type: types.UPDATE_CUSTOMER_CASE_SUCCESS,
    payload: customerCase,
  };
};
export const updateCustomerCaseFailedAction = (error) => {
  return {
    type: types.UPDATE_CUSTOMER_CASE_FAILED,
    payload: error,
  };
};
export const getCustomerCaseHistoryAction = (caseId) => {
  return {
    type: types.GET_CUSTOMER_CASE_HISTORY,
    payload: caseId,
  };
};
export const getCustomerCaseHistorySuccessAction = (customerCaseHistory) => {
  return {
    type: types.GET_CUSTOMER_CASE_HISTORY_SUCCESS,
    payload: customerCaseHistory,
  };
};
export const getCustomerCaseHistoryFailedAction = (error) => {
  return {
    type: types.GET_CUSTOMER_CASE_HISTORY_FAILED,
    payload: error,
  };
};
export const resetCustomerCaseHistoryAction = () => {
  return {
    type: types.RESET_CUSTOMER_CASE_HISTORY,
    payload: null,
  };
};