import React, { useState } from 'react';
import { Drawer, Divider, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { getTransactionDetailStatus } from '../../../utils/get-transaction-status';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionAction } from '../../../store/actions/transactionActions';
import { utcToLocalDateTime } from '../../../utils/dateFormat';
import { TransactionSkeleton } from '../../../components/skeleton/WidgetSkeleton'

const P2PTransactionDetails = ({ transactionId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = useState(false);
  const showDetails = () => {
    setOpenDetails(true);
    dispatch(getTransactionAction(transactionId));
  };
  const { getTransaction, getTransactionLoading, getTransactionError } =
    useSelector((state) => state.transaction);
  const hideDetails = () => {
    setOpenDetails(false);
  };

  return (
    <>
      <div
        className="text-blue-link font-bold cursor-pointer"
        onClick={showDetails}
      >
        View Details
      </div>

      <Drawer
        title="P2P Transaction Details"
        onClose={hideDetails}
        visible={openDetails}
      >
        {getTransactionError ? (
          <div>Failed to get transaction</div>
        ) : getTransactionLoading ? (
          <div className="grid grid-cols-1 justify-items-center text-center">
            <TransactionSkeleton />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-y-3 justify-items-center text-center">
              <Avatar>{getTransaction?.sender_user?.name?.[0]}</Avatar>
              <div className="">
                <div className="text-gray-600">Sender</div>
                <div className="text-base font-bold">
                  {getTransaction?.sender_user?.name}
                </div>
                <div>
                  +{getTransaction?.sender_user?.mobile_country} {getTransaction?.sender_user?.mobile}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600">
                  {t('p2p_transactions.details_modal.amount')}
                </div>
                <div className="text-3xl font-bold flex">
                  SGD
                  <span
                    className={`text-${getTransaction?.status === 1
                      ? 'green'
                      : getTransaction?.status === 2
                        ? 'orange'
                        : 'red'
                      }-anak flex items-center mx-2`}
                  >
                    {getTransaction?.amount}
                  </span>
                </div>
              </div>
              <div className="">
                <div className="text-gray-600">
                  {t('p2p_transactions.details_modal.date_time')}
                </div>
                <div className="text-base font-bold">
                  {utcToLocalDateTime(getTransaction?.created_at)}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.status')}
                </div>
                {getTransactionDetailStatus(getTransaction?.status)}
              </div>
            </div>

            {getTransaction?.status === 1 && (
              <>
                <div className="pt-4"></div>
                <Divider orientation="center" className="font-bold">
                  {t('p2p_transactions.details_modal.credit_title')}
                </Divider>
              </>
            )}

            <div className="pt-6"></div>

            <div className="grid grid-cols-1 gap-y-2 justify-items-center text-center">
              <div className="">
                <div className="text-gray-600">
                  {t('p2p_transactions.details_modal.to')}
                </div>
                <div className="text-base font-bold">
                  {getTransaction?.recipient_user?.name}
                </div>
                <div className="text-sm font-bold">
                  VBN - {getTransaction?.recipient_user?.wallet_id}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.from')}
                </div>
                <div className="text-base font-bold">
                  {getTransaction?.sender_user?.name}
                </div>
                <div className="text-sm font-bold">
                  VBN - {getTransaction?.sender_user?.wallet_id}
                </div>
              </div>

              {getTransaction?.transfer?.comments && (
                <>
                  <div className="pt-3">
                    <div className="text-gray-600 mb-1">
                      Remarks
                    </div>
                    <div className="text-base font-bold">
                      {getTransaction?.transfer?.comments}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default P2PTransactionDetails;
