import axiosClient from './config';

const TOP_UP_TRANSACTION = 1;
const P2P_TRANSACTION = 2;
const REMITTANCE_TRANSACTION = 3;

export const listAllTransactionService = async ({
  page,
  search,
  ordering,
  category,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/transaction/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&category=${category}`
  );
};
export const listP2PTransactionService = async ({
  page,
  search,
  ordering,
  status,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/transaction/?category=${P2P_TRANSACTION}&page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&status=${status}`
  );
};
export const listRemittanceService = async ({
  page,
  search,
  ordering,
  status,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/transaction/?category=${REMITTANCE_TRANSACTION}&page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&status=${status}`
  );
};
export const listTopUpService = async ({
  page,
  search,
  ordering,
  status,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/transaction/?category=${TOP_UP_TRANSACTION}&page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&status=${status}`
  );
};
export const getTransactionOverviewService = async ({
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/transaction-summary/?created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};
export const getTransactionService = async (transaction_id) => {
  return await axiosClient.get(`/transaction/${transaction_id}/`);
};
