import * as types from '../actionTypes/kyc-details';

const initialState = {
  kycOverview: null,
  kycOverviewLoading: false,
  kycOverviewError: null,
  kycList: null,
  kycListLoading: false,
  kycListError: null,
  currentKyc: null,
  currentKycLoading: false,
  currentKycError: null,
  currentDocument: null,
  currentDocumentLoading: false,
  currentDocumentError: null,
};

const kycReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_KYC_OVERVIEW:
      return {
        ...state,
        kycOverviewLoading: true,
      }

    case types.GET_KYC_OVERVIEW_SUCCESS:
      return {
        ...state,
        kycOverview: action.payload,
        kycOverviewLoading: false
      }

    case types.GET_KYC_OVERVIEW_FAILURE:
      return {
        ...state,
        kycOverviewLoading: false,
        kycOverviewError: action.payload,
      }

    case types.LIST_KYC:
      return {
        ...state,
        kycListLoading: true,
      };
    case types.LIST_KYC_SUCCESS:
      return {
        ...state,
        kycList: action.payload,
        kycListLoading: false,
      };
    case types.LIST_KYC_FAILURE:
      return {
        ...state,
        kycListLoading: false,
        kycListError: action.payload,
      };
    case types.GET_KYC:
      return {
        ...state,
        currentKycLoading: true,
      };
    case types.GET_KYC_SUCCESS:
      return {
        ...state,
        currentKyc: action.payload,
        currentKycLoading: false,
      };
    case types.GET_KYC_FAILURE:
      return {
        ...state,
        currentKycLoading: false,
        currentKycError: action.payload,
      };
    case types.GET_KYC_DOCUMENT:
      return {
        ...state,
        currentDocumentLoading: true,
      };
    case types.GET_KYC_DOCUMENT_SUCCESS:
      return {
        ...state,
        currentDocument: action.payload,
        currentDocumentLoading: false,
      };
    case types.GET_KYC_DOCUMENT_FAILURE:
      return {
        ...state,
        currentDocumentLoading: false,
        currentDocumentError: action.payload,
      };
    default:
      return state;
  }
};

export default kycReducer;
