import { put, all, takeLatest } from 'redux-saga/effects';
import {
  listAllTransactionService,
  listP2PTransactionService,
  listRemittanceService,
  listTopUpService,
  getTransactionOverviewService,
  getTransactionService,
} from '../services/transactionService';
import {
  listTransactionsSuccess,
  listTransactionsFailure,
  listP2PTransactionsSuccessAction,
  listP2PTransactionsFailedAction,
  listRemittanceSuccessAction,
  listRemittanceFailedAction,
  listTopUpTransactionsSuccess,
  listTopUpTransactionsFailure,
  getTransactionSuccessAction,
  getTransactionFailedAction,
  getTransactionOverviewSuccessAction,
  getTransactionOverviewFailedAction,
} from '../store/actions/transactionActions';
import * as types from '../store/actionTypes/transactions';

//Worker
export function* listTransactions({ payload }) {
  try {
    const list = yield listAllTransactionService(payload);
    yield put(listTransactionsSuccess(list.data.data));
  } catch (error) {
    yield put(listTransactionsFailure(error));
  }
}
export function* listP2PTransaction({ payload }) {
  try {
    const list = yield listP2PTransactionService(payload);
    yield put(listP2PTransactionsSuccessAction(list.data.data));
  } catch (error) {
    yield put(listP2PTransactionsFailedAction(error));
  }
}
export function* listRemittance({ payload }) {
  try {
    const list = yield listRemittanceService(payload);
    yield put(listRemittanceSuccessAction(list.data.data));
  } catch (error) {
    yield put(listRemittanceFailedAction(error));
  }
}
export function* listTopUp({ payload }) {
  try {
    const list = yield listTopUpService(payload);
    yield put(listTopUpTransactionsSuccess(list.data.data));
  } catch (error) {
    yield put(listTopUpTransactionsFailure(error));
  }
}
export function* getTransaction({ payload }) {
  try {
    const res = yield getTransactionService(payload);
    yield put(getTransactionSuccessAction(res.data.data));
  } catch (error) {
    yield put(getTransactionFailedAction(error));
  }
}
export function* getOverview({ payload }) {
  try {
    const res = yield getTransactionOverviewService(payload);
    yield put(getTransactionOverviewSuccessAction(res.data.data));
  } catch (error) {
    yield put(getTransactionOverviewFailedAction(error));
  }
}
//Watcher
export function* onListTransactionsRequest() {
  yield takeLatest(types.LIST_TRANSACTIONS, listTransactions);
}
export function* onListP2PTransactionRequest() {
  yield takeLatest(types.LIST_P2P_TRANSACTIONS, listP2PTransaction);
}
export function* onListRemittanceRequest() {
  yield takeLatest(types.LIST_REMITTANCE_TRANSACTIONS, listRemittance);
}
export function* onListTopUpRequest() {
  yield takeLatest(types.LIST_TOP_UP_TRANSACTIONS, listTopUp);
}
export function* onGetTransaction() {
  yield takeLatest(types.GET_TRANSACTION, getTransaction);
}
export function* onTransactionOverview() {
  yield takeLatest(types.TRANSACTION_OVERVIEW, getOverview);
}

export function* transactionsSaga() {
  yield all([
    onListTransactionsRequest(),
    onListP2PTransactionRequest(),
    onGetTransaction(),
    onListRemittanceRequest(),
    onListTopUpRequest(),
    onTransactionOverview(),
  ]);
}
