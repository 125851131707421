import { Button, Drawer, Form, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { getCaseStatus } from '../../utils/get-case-status';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import getFormFields from '../../utils/get-form-fields';
import { useDispatch, useSelector } from 'react-redux';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import {
  camelCaseString,
  capitalizeEachWord,
  capitalizeFirstLetter,
} from '../../utils/string-helpers';
import { updateCustomerCaseAction } from '../../store/actions/customerCasesAction';
import { useParams } from 'react-router-dom';
import { isEmpty } from '../../utils/generic';

function CasesInfoWidget() {
  const { kyc, customerCases, auth } = useSelector((state) => ({
    kyc: state.kyc,
    customerCases: state.customerCases,
    auth: state.auth,
  }));
  const { currentKyc } = kyc;
  const { currentCase, currentCaseLoading, updateCaseLoading } = customerCases;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isIDModalOpen, setIsIDModalOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const [closeCaseForm] = Form.useForm();
  const [caseInfoForm] = Form.useForm();
  const { case_uuid } = useParams();
  const dispatch = useDispatch();
  const closeCaseFormHandler = ({ comments }) => {
    const submit_url = `customer-case/${case_uuid}/submit/close-case/`;
    dispatch(updateCustomerCaseAction(submit_url, { comments }, case_uuid));
    // console.log('close case form', submit_url, comments);
    setIsDrawerOpen(false);
    closeCaseForm.resetFields();
  };
  const dynamicFormHandler = (values) => {
    const url =
      buttonClicked === 'SUBMIT'
        ? currentCase?.submit_button?.submit_url
        : currentCase?.cancel_button?.submit_url;
    dispatch(updateCustomerCaseAction(url, values, case_uuid));
  };
  const contentStyle = {
    height: '200px',
    color: '#fff',
    textAlign: 'center',
    background: '#ebebeb',
    objectFit: 'contain',
  };
  const contentStyleModal = {
    ...contentStyle,
    height: '400px',
  };
  return (
    <Spin
      tip="Getting Case Status..."
      spinning={updateCaseLoading || currentCaseLoading}
    >
      <div className="flex justify-between items-center bg-grey-200 p-4 rounded-t-md">
        <div className="flex justify-between items-center">
          <div className="text-sm font-bold pr-4 border-r border-grey-300">
            Case ID - {currentCase?.case_id}
          </div>
          <div className="text-sm font-bold mx-4">
            Date - {utcToLocalDateTime(currentCase?.created_at)}
          </div>
          <div className="flex text-sm font-bold pl-4 border-l border-grey-300">
            <span className="mr-2">Case Status - </span>
            {getCaseStatus(currentCase?.status)}
          </div>
        </div>
        {currentCase?.status !== 'case_closed' && (
          <div
            onClick={() => setIsDrawerOpen(true)}
            className="text-sm font-bold text-blue-link cursor-pointer underline"
          >
            Close the case
          </div>
        )}
        {/* CLOSE THE CASE DRAWER */}
        <Drawer
          title="Close the Case"
          placement="right"
          onClose={() => {
            setIsDrawerOpen(false);
            closeCaseForm.resetFields();
          }}
          visible={isDrawerOpen}
          headerStyle={{
            fontWeight: 'bold',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div className="text-sm">Case ID</div>
          <div className="text-md font-bold mb-4">
            {currentCase?.case_id || '-'}
          </div>
          <div className="text-sm">Customer Name</div>
          <div className="text-md font-bold mb-4">
            {currentKyc?.customer?.name || '-'}
          </div>
          <div className="text-sm">Case Handled by</div>
          <div className="text-md font-bold mb-4">
            {auth?.currentUser?.name || '-'}
          </div>
          <Form
            layout="vertical"
            onFinish={closeCaseFormHandler}
            autoComplete="off"
            form={closeCaseForm}
          >
            <Form.Item
              name="comments"
              label="Comment"
              className="mb-8"
              rules={[
                {
                  required: true,
                  message: 'Please provide comment!',
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                maxLength={500}
                allowClear
                placeholder="Type here..."
              />
            </Form.Item>
            <Form.Item className="mb-6">
              <div className="flex justify-between items-center space-x-2">
                <Button
                  type="success"
                  htmlType="submit"
                  className="w-full"
                  size="large"
                  block
                >
                  Submit
                </Button>
                <Button
                  onClick={() => {
                    setIsDrawerOpen(false);
                    closeCaseForm.resetFields();
                  }}
                  className="w-full"
                  size="large"
                  block
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
      <div className="grid grid-cols-12 gap-4 bg-white p-4 rounded-b-md">
        <div className="col-span-12 md:col-span-5 rounded-lg bg-white border border-grey-100 p-4">
          {!isEmpty(currentCase?.previous_response_data) && (
            <div className="text-md font-bold mb-2">Previous Response</div>
          )}
          {!isEmpty(currentCase?.previous_response_data) &&
            currentCase?.previous_response_data.map(
              (previous_response_data, index) => (
                <div key={index} className="text-md mb-4 flex ">
                  <div className="font-bold">
                    {camelCaseString(previous_response_data?.question)} :
                  </div>
                  <div className="ml-4">{previous_response_data?.answer}</div>
                </div>
              )
            )}
          {isEmpty(currentCase?.title) ? (
            <div className="text-lg font-bold mb-4">No Case Info Found</div>
          ) : (
            <div className="text-lg font-bold mb-4">
              {capitalizeEachWord(currentCase?.title)}
            </div>
          )}
          {!isEmpty(currentCase?.description) && (
            <div className="text-sm mb-4">
              {capitalizeFirstLetter(currentCase?.description)}
            </div>
          )}
          <Form
            layout="vertical"
            onFinish={dynamicFormHandler}
            autoComplete="off"
            form={caseInfoForm}
          >
            {!isEmpty(currentCase?.form_fields) &&
              currentCase?.form_fields.map((question, index) => (
                <Form.Item
                  key={index}
                  name={question?.field_name}
                  label={question?.label}
                  className="mb-6 w-full"
                  initialValue={question?.value}
                  rules={[
                    {
                      required: true,
                      message: `This field is compulsory!`,
                    },
                  ]}
                >
                  {getFormFields(question)}
                </Form.Item>
              ))}
            {!isEmpty(currentCase?.submit_button?.label) && (
              <Form.Item className="mb-6">
                <div className="flex justify-between items-center space-x-2 w-2/3">
                  <Button
                    type="success"
                    htmlType="submit"
                    size="large"
                    onClick={() => {
                      setButtonClicked('SUBMIT');
                    }}
                    block
                  >
                    {currentCase?.submit_button?.label}
                  </Button>
                  {!isEmpty(currentCase?.cancel_button?.label) && (
                    <Button
                      htmlType="submit"
                      size="large"
                      onClick={() => {
                        setButtonClicked('CANCEL');
                      }}
                      block
                    >
                      {currentCase?.cancel_button?.label}
                    </Button>
                  )}
                </div>
              </Form.Item>
            )}
          </Form>
          {!isEmpty(currentCase?.note) && (
            <>
              <div className="text-lg font-bold">Note</div>
              <div className="text-sm mb-4">
                {capitalizeFirstLetter(currentCase?.note)}
              </div>
            </>
          )}
          {currentCase?.status === 'wallet_creation_failed' && !isEmpty(currentCase?.comments) && (
            <>
              <div className="text-sm mb-4">
                {capitalizeFirstLetter(currentCase?.comments)}
              </div>
            </>
          )}
        </div>
        <div className="col-span-12 md:col-span-3">
          {currentCase?.status === 'address_detail' && (
            <div
              className={`rounded-lg border ${
                isEmpty(currentCase?.user_address?.address)
                  ? 'bg-grey-50 border-grey-300 cursor-not-allowed text-grey-500'
                  : 'border-grey-100'
              } p-4`}
            >
              <div className="flex justify-between mb-4 items-center">
                <div className="text-lg font-bold">User Submission</div>
                {isEmpty(currentCase?.user_address?.address) && (
                  <div className="text-white bg-grey-400 px-4 py-1 text-sm font-bold rounded-full">
                    Pending
                  </div>
                )}
              </div>
              <div className="pb-3 mb-3 border-b border-grey-300">
                <div className="text-md font-bold">Address</div>
                <div className="text-sm">
                  {!isEmpty(currentCase?.user_address?.address)
                    ? `${currentCase?.user_address?.address}`
                    : 'Not Available'}
                </div>
              </div>
              <div className="pb-3 mb-3 border-b border-grey-300">
                <div className="text-md font-bold">City</div>
                <div className="text-sm">
                  {!isEmpty(currentCase?.user_address?.city)
                    ? currentCase?.user_address?.city
                    : 'Not Available'}
                </div>
              </div>
              <div className="pb-3 mb-3 border-b border-grey-300">
                <div className="text-md font-bold">Zip Code</div>
                <div className="text-sm">
                  {!isEmpty(currentCase?.user_address?.zipcode)
                    ? currentCase?.user_address?.zipcode
                    : 'Not Available'}
                </div>
              </div>
              <div className="text-md font-bold">Country</div>
              <div className="text-sm">
                {!isEmpty(currentCase?.user_address?.country)
                  ? currentCase?.user_address?.country
                  : 'Not Available'}
              </div>
            </div>
          )}
        </div>
        <div className="col-span-12 md:col-span-4 rounded-lg border border-grey-100 p-4">
          {!isEmpty(currentCase?.documents) && (
            <Carousel showArrows={true}>
              {currentCase?.documents.map((document, index) => (
                <div
                  key={index}
                  onClick={() => {
                    // setIsIDModalOpen(true);
                  window.open(`${document.url}`)
                  }}
                  className="cursor-pointer"
                >
                  <p className="text-lg font-bold text-left mb-4">{`${document.category}- ${document.classifier}`}</p>
                  <img
                    style={contentStyle}
                    alt={`${document.category}- ${document.classifier}`}
                    src={`${document.url}`}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <Modal
          title="Document Details"
          visible={isIDModalOpen}
          footer={false}
          width={'60%'}
          onOk={() => {
            setIsIDModalOpen(false);
          }}
          onCancel={() => {
            setIsIDModalOpen(false);
          }}
        >
          {!isEmpty(currentCase?.documents) && (
            <Carousel showArrows={true}>
              {currentCase?.documents.map((document, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setIsIDModalOpen(true);
                  }}
                >
                  <p className="text-lg font-bold text-left mb-4">{`${document.category}- ${document.classifier}`}</p>
                  <img
                    style={contentStyleModal}
                    alt={`${document.category}- ${document.classifier}`}
                    src={`${document.url}`}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </Modal>
      </div>
    </Spin>
  );
}

export default CasesInfoWidget;
