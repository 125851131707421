import axiosClient from './config';

export const listOrgUsersService = async ({
  page,
  search,
  status,
  ordering,
  page_size,
  created_at_after,
  created_at_before,
  org_uuid,
}) => {
  return await axiosClient.get(
    `/organisation-users/?${page ? `page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${status ? `&status=${status}` : ''}${search ? `&search=${search}` : ''}${
      ordering ? `&ordering=${ordering}` : ''
    }${org_uuid ? `&org_uuid=${org_uuid}` : ''}${
      created_at_after ? `&created_at_after=${created_at_after}` : ''
    }${created_at_before ? `&created_at_before=${created_at_before}` : ''}`
  );
};

export const createOrgUserService = async (payload) => {
  return await axiosClient.post('/organisation-users/', payload);
};

export const editOrgUserService = async (name, role, uuid) => {
  return await axiosClient.patch(`/organisation-users/${uuid}/`, {
    name,
    role,
  });
};

export const viewOrgUserService = async (userId) => {
  return await axiosClient.get(`/organisation-users/${userId}/`);
};

export const changeOrgUserStatusService = async (status, uuid) => {
  return await axiosClient.patch(`/organisation-users/${uuid}/status/`, {
    status,
  });
};

export const updateOrgUserPasswordService = async (profile_uuid, password) => {
  return await axiosClient.patch(
    `/organisation-users/${profile_uuid}/update-password/`,
    {
      password,
    }
  );
};
