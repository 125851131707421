import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listOrgCategoryAction } from '../../store/actions/orgCategoryActions';
import { Link } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';

function OrgCategoryTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [];
  const {
    orgCategoriesList,
    orgCategoriesListLoading,
    orgCategoriesListError,
  } = useSelector((state) => state.orgCategory);
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listOrgCategoryAction}
      data={orgCategoriesList}
      loading={orgCategoriesListLoading}
      error={orgCategoriesListError}
    >
      <Column
        title="Category Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Created At"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
        defaultSortOrder="descend"
      />
      <Column
        title="No. of Organisation"
        dataIndex="organization_count"
        key="organization_count"
        // sorter={true}
        render={(text, record) => record?.organization_count}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/organisations/categories/${record.uuid}`}>
            View Details
          </Link>
        )}
        width={140}
      />
    </TableUniversal>
  );
}
export default OrgCategoryTable;
