const translationBahasaIndonesia = {
  left_sidebar: {
    administration_panel: {
      label: 'ADMINISTRASI',
      dashboard: 'Dasbor',
      system_users: 'Pengguna Sistem',
      roles_and_permission: 'Peran dan Izin',
    },
    organisation_panel: {
      label: 'PANEL ORGANISASI',
      category: 'Kategori',
      organisations: 'Organisasi',
      add_organisations: 'Tambahkan Organisasi',
      org_message: 'Pesan Org',
      org_user: 'Pengguna Org',
    },
    customer_panel: {
      label: 'PANEL PELANGGAN',
      customer_list: 'Daftar pelanggan',
      kyc_details: 'Detail KYC',
    },
    wallet_panel: {
      label: 'DOMPET',
      wallet_details: 'Detail Dompet',
      transactions: {
        label: 'Transaksi',
        p2p_transactions_list: 'Daftar Transaksi P2P',
        remittance_list: 'Daftar Pengiriman Uang',
      },
    },
  },
  navbar: {
    user_dropdown: {
      logout: 'Keluar',
      editProfile: 'Sunting profil',
    },
  },
  profileEdit: {
    section_title: 'Informasi pribadi',
  },
  dashboard: {
    section_title: 'Dasbor',
    customer_overview: {
      widget_title: 'Ikhtisar Pelanggan',
      total_customers: 'Jumlah pelanggan',
      registered_today: 'Terdaftar Hari Ini',
      registered_yesterday: 'Terdaftar Kemarin',
      registered_this_month: 'Terdaftar Bulan ini',
    },
    customer_graph: {
      widget_title: 'Pelanggan Terdaftar berdasarkan Bulan',
      tooltip: 'Lorem ipsum dolor sit amet itatibus!',
    },
    kyc_details: {
      section_title: 'Detail KYC',
      approved: 'KYC Disetujui Bulan ini',
      pending: 'KYC Ditunda Bulan ini',
      rejected: 'KYC Ditolak Bulan Ini',
      total: 'Total KYC Bulan ini',
    },
    wallet_details: {
      section_title: 'Detail Dompet',
      total_amount: 'Jumlah total dompet',
      transaction_today: 'Transaksi hari ini',
      transaction_month: 'Transaksi Bulan Ini',
      total_users: 'Jumlah pengguna dompet',
      users_active: 'Pengguna Dompet Aktif',
      users_inactive: 'Pengguna Wallet tidak aktif',
      chart_title: 'Transaksi per Bulan',
      chart_tooltip: 'Lorem ipsum dolor sit amet itatibus!',
    },
    organisation_details: {
      section_title: 'Detail Organisasi',
      total_organization: 'Total organisasi',
      onboard_this_month: 'Masuk Bulan Ini',
      org_all_messages: 'Org Semua Pesan',
      draft_messages: 'Draf Pesan',
      scheduled_message: 'Pesan Terjadwal',
      published_message: 'Pesan yang Dipublikasikan',
      message_chart_title: 'Total pesan per Bulan',
      message_chart_label: 'Pesan',
      organization_chart_title: 'Organisasi Terdaftar berdasarkan Bulan',
      organization_chart_label: 'Organisasi',
    },
  },
  system_users: {
    section_title: 'Pengguna Sistem',
    add_modal: {
      button_text: 'Tambahkan Pengguna Sistem Baru',
      title: 'Tambahkan Pengguna Sistem Baru',
      name_label: 'Nama lengkap',
      name_placeholder: 'Masukkan nama',
      name_error: 'Silakan masukkan nama pengguna',
      email_label: 'Alamat email',
      email_placeholder: 'Masukkan alamat email',
      email_error: 'Harap masukkan email yang valid.',
      role_label: 'Peran pengguna',
      role_placeholder: 'Pilih peran pengguna',
      role_error: 'Pilih peran pengguna',
      confirm_text: 'Tambahkan Pengguna Sistem Baru',
    },
  },
  system_user_details: {
    section_title: 'Detail Pengguna Sistem',
    profile_header: {
      name: 'Nama',
      email: 'ID email',
      role: 'Peran',
      status: {
        label: 'Status',
        drawer: {
          title: 'Ubah Status Pengguna',
          confirm: 'Menyimpan',
          cancel: 'Membatalkan',
        },
        active: 'Aktif',
        suspend: 'Menskors',
        delete: {
          text: 'Menghapus',
          popup_title: 'Hapus pengguna',
          description:
            'Apakah Anda yakin ingin menghapus pengguna ini dari sistem?',
          form_email: {
            label: 'Alamat email',
            placeholder: 'Masukkan alamat email',
            error: 'Harap masukkan email yang valid.',
          },
          confirm_text: 'Hapus pengguna',
          cancel_text: 'Membatalkan',
        },
        confirm_text: 'Menyimpan',
        cancel_text: 'Membatalkan',
      },
      created_at: 'Dibuat di',
      updated_at: 'Diperbarui Pada',
      created_by: 'Dibuat oleh',
      last_login: 'Login terakhir',
      edit_system_user_drawer: {
        button_text: 'Sunting profil',
        drawer_title: 'Edit Pengguna',
        form_name_label: 'Nama lengkap',
        form_email_label: 'Alamat email',
        form_role_label: 'Peran',
        drawer_submit_text: 'Perbarui Pengguna Sistem',
      },
      reset_password_popup: {
        button_text: 'Setel Ulang Kata Sandi',
        title: 'Setel Ulang Kata Sandi',
        description:
          'Apakah Anda yakin ingin menyetel ulang sandi pengguna ini?',
        form_email_label: 'Alamat email',
        form_email_placeholder: 'Masukkan alamat email',
        email_required_error: 'Silakan masukkan email Anda!',
        email_invalid_error: 'Silakan masukkan id email yang valid!',
        confirm_text: 'Setel Ulang Kata Sandi',
        cancel_text: 'Membatalkan',
      },
    },
    permission_1: 'Nama izin 1',
    permission_1_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_2: 'Nama izin 2',
    permission_2_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_3: 'Nama izin 3',
    permission_3_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_4: 'Nama izin 4',
    permission_4_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  roles_permission: {
    section_title: 'Peran dan Izin',
    role_title: 'Peran',
    dropdown_label: 'Pilih Peran',
    user: 'Pengguna',
    moderator: 'Moderator',
    admin: 'Admin',
    super_admin: 'Admin super',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    permissions_title: 'Izin',
    permission_1: 'Nama izin 1',
    permission_1_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_2: 'Nama izin 2',
    permission_2_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_3: 'Nama izin 3',
    permission_3_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_4: 'Nama izin 4',
    permission_4_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    permission_save: 'Menyimpan',
  },
  org_category: {
    section_title: 'Kategori Organisasi',
    create_modal: {
      button_text: 'Buat Kategori Baru',
      title: 'Tambahkan kategori baru',
      form_label: 'Nama Kategori',
      form_placeholder: 'Masukkan nama kategori',
      confirm_text: 'Menyimpan',
    },
    details: {
      category_name: 'Nama Kategori',
      created_on: 'Dibuat pada',
      total: 'Jumlah Organisasi',
    },
    edit_modal: {
      button_text: 'Sunting Kategori',
      title: 'Sunting Kategori',
      form_label: 'Nama Kategori',
      form_placeholder: 'Masukkan nama kategori',
      confirm_text: 'Menyimpan',
    },
    delete_modal: {
      button_text: 'Menghapus',
      title: 'Hapus Kategori',
      description: 'Apakah Anda yakin ingin menghapus kategori ini?',
      confirm_text: 'Menghapus',
      cancel_text: 'Membatalkan',
    },
  },
  org_details: {
    section_title: 'Detail Organisasi',
    status: 'Status',
    category: 'Kategori',
    tab_description: 'Keterangan',
    tab_Users: 'Pengguna',
    tab_message: 'Pesan',
    tab_contact: 'Detail Kontak',
    edit_modal: {
      button_text: 'Edit Organisasi',
      title: 'Edit Organisasi',
      // ADD MORE FROM add_organisation
    },
    delete_modal: {
      button_text: 'Hapus Organisasi',
      title: 'Hapus Organisasi',
      description: 'Apakah Anda yakin ingin menghapus organisasi ini?',
      confirm_text: 'Menghapus',
      cancel_text: 'Membatalkan',
    },
  },
  add_organisation: {
    button_text: 'Tambahkan Organisasi Baru',
    title: 'Tambahkan Organisasi Baru',
    name_label: 'nama organisasi',
    name_placeholder: 'Masukkan Nama Organisasi',
    category_label: 'Kategori Organisasi',
    category_placeholder: 'Pilih Kategori Organisasi',
    logo_label: 'Logo Organisasi',
    logo_placeholder: 'Tidak ada file yang dipilih',
    description_label: 'Keterangan',
    description_placeholder: 'Ketik isi pesan',
    add_language: 'Tambahkan Bahasa Baru',
    email_label: 'Kontak Email',
    email_placeholder: 'Masukkan email kontak',
    phone_label: 'Hubungi Telepon',
    phone_placeholder: 'Masukkan telepon kontak',
    url_label: 'alamat situs web',
    url_placeholder: 'Masukkan url situs web',
    confirm_text: 'Menyimpan',
  },
  org_message: {
    section_title: 'Pesan Organisasi',
    create: {
      button_text: 'Buat Pesan Baru',
      title: 'Buat Pesan Baru',
      org_label: 'Organisasi',
      org_placeholder: 'Pilih Organisasi',
      recipients_label: 'Penerima',
      recipients_placeholder: 'Tambahkan Penerima',
      msg_title_label: 'Judul Pesan',
      msg_title_placeholder: 'Masukkan Judul Pesan',
      msg_content_label: 'Isi pesan',
      msg_content_placeholder: 'Ketik Isi Pesan',
      image_label: 'Lampirkan Gambar',
      image_placeholder: 'Tidak ada file yang dipilih',
      image_cta: 'Pilih File',
      video_label: 'Lampirkan Video',
      video_placeholder: 'Masukkan tautan video',
      file_label: 'Lampirkan file',
      file_placeholder: 'Tidak ada file yang dipilih',
      file_cta: 'Pilih File',
      send_text: 'Mengirim',
      draft_text: 'Simpan di Draf',
      schedule_text: 'Jadwal',
    },
    details: {
      name_label: 'Nama Organisasi',
      date_label: 'Tanggal & Waktu Terkirim',
      language_label: 'Bahasa',
      status_label: 'Status',
      views_label: 'Tampilan Pengguna',
      heading_label: 'Judul Pesan',
      content_label: 'Isi pesan',
      video_label: 'Video Pesan',
      cta_label: 'Panggilan untuk bertindak',
    },
  },
  org_user: {
    section_title: 'Pengguna Organisasi',
    add_modal: {
      button_text: 'Tambahkan pengguna baru',
      title: 'Tambahkan pengguna baru',
      name_label: 'Full Name',
      name_placeholder: 'Masukkan nama',
      name_error: 'Silakan masukkan nama',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Harap masukkan email yang valid.',
      role_label: 'Peran pengguna',
      role_placeholder: 'Pilih peran pengguna',
      role_error: 'Pilih peran pengguna',
      confirm_text: 'Tambahkan pengguna baru',
    },
  },
  org_user_detail: {
    section_title: 'Detail Pengguna Organisasi',
    name_label: 'Nama',
    email_label: 'Surel',
    role_label: 'Peran',
    status_label: 'Status',
    created_label: 'Dibuat di',
    updated_label: 'Diperbarui Pada',
    created_by_label: 'Dibuat oleh',
    last_login_label: 'Login terakhir',
    org_name_label: 'nama organisasi',
    edit_modal: {
      button_text: 'Sunting profil',
      title: 'Edit Pengguna',
      name_label: 'Nama lengkap',
      name_placeholder: 'Masukkan nama',
      name_error: 'Silakan masukkan nama',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Please input valid email.',
      org_label: 'Organisasi',
      org_placeholder: 'Masuk organisasi',
      org_error: 'Harap berikan organisasi.',
      role_label: 'Peran pengguna',
      role_placeholder: 'Pilih peran pengguna',
      role_error: 'Pilih peran pengguna',
      confirm_text: 'Perbarui Pengguna Sistem',
    },
    password_modal: {
      button_text: 'Setel Ulang Kata Sandi',
      title: 'Setel Ulang Kata Sandi',
      description: 'Apakah Anda yakin ingin menyetel ulang sandi pengguna ini?',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Harap masukkan email yang valid.',
      confirm_text: 'Menghapus',
      cancel_text: 'Membatalkan',
    },
  },
  customer_list: {
    section_title: 'Daftar pelanggan',
  },
  customer_details: {
    section_title: 'detil pelanggan',
    name_label: 'Nama Resmi',
    dob_label: 'Tanggal lahir',
    gender_label: 'Jenis kelamin',
    nationality_label: 'Kebangsaan',
    mobile_label: 'Nomor handphone',
    email_label: 'email identitas',
    kyc_status: {
      approved: 'KYC Disetujui',
    },
    edit_modal: {
      button_text: 'Sunting profil',
      title: 'Edit Pengguna',
      name_label: 'Nama lengkap',
      name_placeholder: 'Masukkan nama',
      name_error: 'Silakan masukkan nama',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Harap masukkan email yang valid.',
      mobile_label: 'Nomor handphone',
      mobile_placeholder: 'Masukkan nomor ponsel',
      mobile_error: 'Harap masukkan nomor yang valid.',
      nationality_label: 'Kebangsaan',
      confirm_text: 'Kebangsaan',
    },
    password_modal: {
      button_text: 'Setel Ulang Kata Sandi',
      title: 'Setel Ulang Kata Sandi',
      description: 'Apakah Anda yakin ingin menyetel ulang sandi pengguna ini?',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Harap masukkan email yang valid.',
      confirm_text: 'Menghapus',
      cancel_text: 'Membatalkan',
    },
    wallet: {
      title: 'Detail Dompet',
      balance: 'Saldo Dompet',
      recent_transactions: 'Transaksi Terbaru',
      recent_transactions_cta: 'Lihat semua',
    },
    work_permit: {
      title: 'Izin Kerja (Singapura)',
      cta: 'Lihat Dokumen',
      permit_label: 'Izin Kerja No',
      issue_label: 'Tanggal pengeluaran',
      fin: 'FIN',
      address: 'Alamat Kerja',
    },
    passport: {
      title: 'Detail Paspor',
      cta: 'Lihat Dokumen',
    },
    national_identity: 'Detail Identitas Nasional',
  },
  kyc_details: {
    section_title: 'Detail KYC',
    name_label: 'Nama Resmi',
    dob_label: 'Tanggal lahir',
    gender_label: 'Jenis kelamin',
    nationality_label: 'Kebangsaan',
    mobile_label: 'Nomor handphone',
    email_label: 'ID email',
    kyc_status: {
      approved: 'KYC Disetujui',
    },
    work_permit: {
      title: 'Izin Kerja (Singapura)',
      cta: 'Lihat Dokumen',
      permit_label: 'Izin Kerja No',
      issue_label: 'Tanggal pengeluaran',
      fin: 'FIN',
      address: 'Alamat Kerja',
    },
    passport: {
      title: 'Detail Paspor',
      cta: 'Lihat Dokumen',
    },
    national_identity: 'Detail Identitas Nasional',
  },
  wallet_details: {
    section_title: 'Detail Dompet',
    total_amount: 'Jumlah total dompet',
    today_transaction: 'Transaksi hari ini',
    month_transactions: 'Transaksi Bulan Ini',
    total_users: 'Jumlah pengguna dompet',
    active_users: 'Pengguna Dompet Aktif',
    inactive_users: 'Pengguna Dompet Tidak Aktif',
  },
  p2p_transactions: {
    section_title: 'Transaksi P2P',
    details_modal: {
      title: 'Detil transaksi',
      received_from: 'Diterima dari',
      amount: 'Jumlah',
      date_time: 'Tanggal Waktu',
      status: 'Status',
      credit_title: 'Dikreditkan ke dompet',
      transaction_id: 'ID transaksi',
      to: 'Ke',
      from: 'Dari',
    },
  },
  remittance_list: {
    section_title: 'Daftar Pengiriman Uang',
    details_modal: {
      title: 'Detail Pengiriman Uang',
      paid_to: 'Dibayarkan kepada',
      mobile: 'Nomor handphone',
      account: 'Nomor akun',
      bank: 'Bank',
      bank_code: 'Kode Bank',
      transferred: 'Jumlah yang Ditransfer',
      received: 'Jumlah yang Diterima',
      fees: 'Biaya Transfer',
      total_debited: 'Total Jumlah Debit dari Wallet',
      remark: 'Catatan',
      debited_title: 'Didebit dari Dompet Anda',
      from: 'Dari',
      transaction_id: 'ID transaksi',
    },
  },
};

export default translationBahasaIndonesia;