import React, { useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { Table, Card } from 'antd';
import { getTransactionStatus } from '../../utils/get-transaction-status';
import TableUniversal from '../../components/table';
import {
  listCustomerTransactionAction,
  viewCustomerAction,
} from '../../store/actions/customerActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { useTranslation } from 'react-i18next';
import { getTransactionCategory } from '../../utils/get-transaction-category';
import { isEmpty } from '../../utils/generic';
import TopUpTransactionDetails from '../transactions/topup/top-up-details';
import P2PTransactionDetails from '../transactions/p2p/p2p-details';
import RemittanceTransactionDetails from '../transactions/remittance/remittance-details';

function CustomerTransactionsTable() {
  const { t } = useTranslation();
  const { customerId } = useParams();
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Customer Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('customer_list.section_title'),
      url: '/customers/list',
      last: false,
    },
    {
      home: false,
      title: t('customer_details.section_title'),
      url: `/customers/list/${customerId}`,
      last: false,
    },
    { home: false, title: 'Transaction History', url: '/', last: true },
  ];
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    user_id: customerId,
  };
  const menuFilter = [];
  const {
    currentCustomer,
    currentCustomerLoading,
    transactionList,
    transactionListLoading,
    transactionListError,
  } = useSelector((state) => state.customers);
  const { Column } = Table;
  const dispatch = useDispatch();
  useEffect(() => {
    isEmpty(currentCustomer) && dispatch(viewCustomerAction(customerId));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <SectionTitle
        title={`${
          currentCustomerLoading ? 'Customer' : `${currentCustomer?.name}'s`
        } Transaction History`}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <TableUniversal
          initialTableParams={initialTableParams}
          menuFilter={menuFilter}
          menuFilterBy="status"
          dispatchAction={listCustomerTransactionAction}
          data={transactionList}
          loading={transactionListLoading}
          error={transactionListError}
        >
          <Column
            title="Sender"
            dataIndex="sender_user"
            key="sender_user"
            sorter={true}
            align="left"
            render={(text, record) => record?.sender_user?.name || 'From Bank'}
          />
          <Column
            title="Receiver"
            dataIndex="recipient_user"
            key="recipient_user"
            sorter={true}
            align="left"
            render={(text, record) => record?.recipient_user?.name || 'To Bank'}
          />
          <Column
            title="Amount"
            dataIndex="amount"
            key="amount"
            sorter={true}
            align="left"
            render={(text, record) => record?.amount}
          />
          <Column
            title="Transaction Type"
            dataIndex="category"
            key="category"
            render={(text, record) => getTransactionCategory(record?.category)}
          />
          <Column
            title="Date & Time"
            dataIndex="created_at"
            key="created_at"
            sorter={true}
            render={(text, record) => utcToLocalDateTime(record?.created_at)}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(text, record) => getTransactionStatus(record?.status)}
          />
          <Column
            className="text-sm px-2 py-3"
            title="Actions"
            dataIndex="action"
            key="action"
            fixed="right"
            render={(text, record) =>
              record?.category === 1 ? (
                <TopUpTransactionDetails transactionId={record?.id} />
              ) : record?.category === 2 ? (
                <P2PTransactionDetails transactionId={record?.id} />
              ) : record?.category === 3 ? (
                <RemittanceTransactionDetails transactionId={record?.id} />
              ) : null
            }
            width={120}
          />
        </TableUniversal>
      </Card>
    </>
  );
}

export default CustomerTransactionsTable;
