import { put, all, takeLatest } from 'redux-saga/effects';
import {
  getKYCDocumentService,
  getKYCService,
  listKYCService,
  getKycOverviewService
} from '../services/kycService';
import {
  getKycOverviewSuccessAction,
  getKycOverviewFailedAction,
  listKYCFailedAction,
  listKYCSuccessAction,
  getKYCSuccessAction,
  getKYCFailedAction,
  getKYCDocumentSuccessAction,
  getKYCDocumentFailedAction,
} from '../store/actions/kycActions';
import * as types from '../store/actionTypes/kyc-details';

//Worker
export function* getKycOverviewStats(payload) {
  try {
    const list = yield getKycOverviewService(payload);
    yield put(getKycOverviewSuccessAction(list.data.data));
  } catch (error) {
    yield put(getKycOverviewFailedAction(error));
  }
}

export function* listKYCDetails({ payload }) {
  try {
    const list = yield listKYCService(payload);
    yield put(listKYCSuccessAction(list.data.data));
  } catch (error) {
    yield put(listKYCFailedAction(error));
  }
}

export function* getKYCDetails({ payload }) {
  try {
    const kyc = yield getKYCService(payload);
    yield put(getKYCSuccessAction(kyc.data.data));
  } catch (error) {
    yield put(getKYCFailedAction(error));
  }
}

export function* getKYCDocuments({ payload }) {
  try {
    const kyc = yield getKYCDocumentService(payload);
    yield put(getKYCDocumentSuccessAction(kyc.data.data));
  } catch (error) {
    yield put(getKYCDocumentFailedAction(error));
  }
}

//Watcher
export function* kycOverviewRequest() {
  yield takeLatest(types.GET_KYC_OVERVIEW, getKycOverviewStats);
}

export function* onListRequest() {
  yield takeLatest(types.LIST_KYC, listKYCDetails);
}
export function* onGetKYC() {
  yield takeLatest(types.GET_KYC, getKYCDetails);
}
export function* onGetDocument() {
  yield takeLatest(types.GET_KYC_DOCUMENT, getKYCDocuments);
}

export function* kycDetailsSaga() {
  yield all([kycOverviewRequest(), onListRequest(), onGetKYC(), onGetDocument()]);
}
