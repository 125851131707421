import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listOrgMessagesAction, viewOrgMessageSuccessAction } from '../../store/actions/orgMessageActions';
import { Link } from 'react-router-dom';
import getMessageStatus from '../../components/status/message';
import { utcToLocalDateTime } from '../../utils/dateFormat';

function OrgMessageTable({ org_uuid }) {
  const initialTableParams = {
    page: 1,
    search: '',
    ordering: '',
    status: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    org_uuid,
  };
  const menuFilter = [
    {
      key: 1,
      value: 'Sent Messages',
    },
    {
      key: 2,
      value: 'Draft Messages',
    },
    {
      key: 3,
      value: 'Scheduled Messages',
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewOrgMessageSuccessAction(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { orgMessagesList, orgMessagesListLoading, orgMessagesListError } =
    useSelector((state) => state.orgMessages);
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listOrgMessagesAction}
      data={orgMessagesList}
      loading={orgMessagesListLoading}
      error={orgMessagesListError}
    >
      <Column
        title="Message Heading"
        dataIndex="title"
        key="title"
        align="left"
        render={(text, record) => record?.title}
      />
      <Column
        title="Organisation Name"
        dataIndex="organization"
        key="organization"
        render={(text, record) => record?.organization}
      />
      <Column
        title="Date &amp; Time"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        defaultSortOrder="descend"
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="User Views"
        dataIndex="views"
        key="views"
        sorter={true}
        render={(text, record) => record?.views}
      />
      {/* <Column
        title="Language"
        dataIndex="language"
        key="language"
        render={(text, record) => record?.language || '-'}
      /> */}
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getMessageStatus(record?.status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link
            to={
              record?.status === 1 || record?.status === 3
                ? `/organisations/messages/${record.uuid}`
                : `/organisations/messages/edit/${record?.uuid}`
            }
          >
            {record?.status === 1 || record?.status === 3 ? (
              'View Details'
            ) : (
              <span className="text-orange-anak">Edit Message</span>
            )}
          </Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default OrgMessageTable;
