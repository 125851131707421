import axiosClient from './config';

export const listCustomersService = async ({
  page,
  search,
  ordering,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/customers/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};

export const viewCustomerService = async (uuid) => {
  return await axiosClient.get(`/customers/${uuid}/`);
};

export const editCustomerService = async (customerId, updatedFormValue) => {
  return await axiosClient.patch(`/customers/${customerId}/`, updatedFormValue);
};

export const updateCustomerPinService = async (customerId, pin) => {
  return await axiosClient.patch(`/customers/${customerId}/update-pin/`, {
    pin,
  });
};

export const listCustomerTransactionService = async ({
  created_at_after,
  created_at_before,
  ordering,
  page,
  page_size,
  search,
  status,
  user_id,
}) => {
  return await axiosClient.get(
    `/customers/${user_id}/wallet/transactions/?page=${page}&page_size=${page_size}&search=${search}&ordering=${ordering}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&status=${status}`
  );
};
