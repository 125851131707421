export const getHTMLToPDFString = (currentIGWService, imgBase64) => {
  return(
    `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
body{
  font-family: 'DM Sans';
  color: #423e40;
}
.title{
  font-size:46px;
  font-weight:600;
  margin-bottom:2.5rem;
}
.list-box{
  width:1800px;
  margin-bottom:5rem;
}
.list{
  padding: 0.7rem 1rem 1rem 1rem;
  padding-bottom:1.5rem;
  display:flex;
  justify-content:start;
  align-items:center;
  width:1800px;
  border-bottom: 2px solid #cbd5e1;
}
.list-half{
  width:50%;
  font-size:28px;
}
.list-75{
  width:67%;
  font-size:28px;
}
.list-quarter{
  width:33%;
  font-size:28px;
}
.list1{
  width: 95% !important;
}
.list:nth-child(odd){
  background:#f8fafc;
}
.list-box:nth-child(1){
  border-top: 2px solid #cbd5e1;
}
.passport-box{
  display:flex;
  flex-direction:column;
  align-items: center;
  width:31%;
  border-radius:20px;
  overflow:hidden;
}
.passport-top{
  padding:10px 40px; 
  font-size:30px;
  margin-bottom:15px;
  font-weight:600;
}
.passport-bottom{
  width: 85.5%; 
  padding:10px 40px 10px 40px; 
  text-align:center; 
  font-size:30px;
  padding-bottom:25px;
  font-weight:600;
}
.box-valid{
  border: 3px solid #85f0ab;
}
.box-expired{
  border: 3px solid #fcabab;
}
.box-no-passport{
  border: 3px solid #ccd6e2;
}
.valid{
  color: #31c865;
  background: #dcfce6;
}
.expired{
  color: #ef4444;
  background: #fde2e1;
}
.no-passport{
  color:grey;
  background:#eef3f4;
}
</style>
    </head>
    
    <body style="margin: 0; padding: 0;">
    
    <div style="display:flex; justify-content:space-between;width:1800px;flex-wrap: nowrap; margin-bottom:40px; margin-top:40px">
    <div style="width:67%;font-size:60px; font-weight:700;margin-top:-30px">
        ${currentIGWService?.input_data?.legal_name}
    </div>
    ${currentIGWService?.input_data?.passport_status === "No" ?
    `<div class="passport-box box-no-passport">
      <div class="passport-top" >Passport:</div>
      <div class="passport-bottom no-passport" >Need to Apply</div>
    </div>`:
    currentIGWService?.input_data?.passport_date_of_expiry.replace(/-/g,'/') >= new Date().toISOString().slice(0, 10).replace(/-/g,'/')?
    `<div class="passport-box box-valid">
       <div class="passport-top" >Passport Valid Till:</div>
       <div class="passport-bottom valid" >${currentIGWService?.input_data?.passport_date_of_expiry}</div>
    </div>`:
    `<div class="passport-box box-expired">
       <div class="passport-top" >Passport Expired On:</div>
       <div class="passport-bottom expired" >${currentIGWService?.input_data?.passport_date_of_expiry}</div>
    </div>`
}
  </div>

  <div style="display:flex; justify-content:space-between;width:1800px; margin-bottom:5rem">
  <div style="width:67%">
  <div class="title">Personal Particulars</div>
  <div class="list list1" style="border-top: 2px solid #cbd5e1;">
  <div class="list-half">Legal Name</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.legal_name || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Mobile Number</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.mobile_number || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Email (Optional)</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.email || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Residential Address</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.residential_address || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Agent Code (Optional)</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.agent_code || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Date of Birth</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.date_of_birth || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Place of Birth</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.place_of_birth || '-'}
  </div>
</div>
<div class="list list1 ">
  <div class="list-half">Nationality</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.nationality || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Ethnicity</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.ethnicity || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Martial Status</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.martial_status || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Religion</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.religion || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Gender</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.gender || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Hobbies</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.hobbies || '-'}
  </div>
</div>
<div class="list list1 ">
  <div class="list-half">Identity card (KTP) No.</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.ktp_id_card}
  </div>
</div>
<div class="list list1 ">
  <div class="list-half">Birth Certificate No.</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.birth_certificate_number ||
      '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Family Card (KK) No.</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.kk_family_card|| '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Do you have a passport?</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.passport_status || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Passport Number</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.passport_number || '-'}
  </div>
</div>
<div class="list list1">
  <div class="list-half">Date of expiry</div>
  <div class="list-half">
    ${currentIGWService?.input_data?.passport_date_of_expiry || '-'}
  </div>
</div>
  </div>
  <div style="display:flex;width:31%; flex-direction:column; align-items: start; border-radius:10px; margin-top:85px">
  <img width="100%" src="${imgBase64}" alt="photo" />
  </div>
</div>

<div class="list-box">
<div class="title">Work Experience</div>
<div class="list" style="border-top: 2px solid #cbd5e1;">
<div class="list-half">Experience Sector</div>
<div class="list-half">
  ${currentIGWService?.input_data?.experience_sector || '-'}
</div>
</div>
<div class="list">
<div class="list-half">Additional Skills (Optional)</div>
<div class="list-half">
  ${currentIGWService?.input_data?.additional_skills || '-'}
</div>
</div>

<div class="list">
<div class="list-half">Language ability</div>
<div class="list-half">
  ${currentIGWService?.input_data?.language_ability || '-'}
</div>
</div>
<div class="list">
<div class="list-half">Highest level of education</div>
<div class="list-half">
  ${currentIGWService?.input_data?.highest_level_of_eduaction ||
    '-'}
</div>
</div>
<div class="list">
<div class="list-half">School Name</div>
<div class="list-half">
  ${currentIGWService?.input_data?.school_name || '-'}
</div>
</div>
<div class="list">
<div class="list-half">Upskilling Interest</div>
<div class="list-half">
  ${currentIGWService?.input_data?.upskilling_interest || '-'}
</div>
</div>
${currentIGWService?.input_data?.hobbies.length >= 70 ?
  `<div style="height:250px"></div>`:
  `<div></div>`
  }
<div class="list">
<div class="list-half">Local Working Experience</div>
<div class="list-half">
  ${currentIGWService?.input_data?.local_working_experience ||
    '-'}
</div>
</div>
<div class="list">
<div class="list-half">Have you worked overseas before?</div>
<div class="list-half">
  ${currentIGWService?.input_data?.worked_overseas_before || '-'}
</div>
</div>
${currentIGWService?.input_data?.hobbies.length < 70 ?
`<div style="height:250px"></div>`:
`<div></div>`
}

<div class="list">
<div class="list-half">Overseas Working Experience</div>
<div class="list-half">
  ${currentIGWService?.input_data?.overseas_working_experience ||
    '-'}
</div>
</div>
<div class="list">
<div class="list-half">Please elaborate further</div>
<div class="list-half">
  ${currentIGWService?.input_data?.overseas_working_description ||
    '-'}
</div>
</div>
<div class="list">
<div class="list-half">Do you have a Driver's License?</div>
<div class="list-half">
  ${currentIGWService?.input_data?.driving_license || '-'}
</div>
</div>
<div class="list">
<div class="list-half">Driver License Class</div>
<div class="list-half">
  ${currentIGWService?.input_data?.driving_license_class ||
    '-'}
</div>
</div>
<div class="list">
<div class="list-half">Driver License Country</div>
<div class="list-half">
  ${currentIGWService?.input_data?.driving_license_country ||
    '-'}
</div>
</div>
</div>
<div class="list-box">
<div class="title">Job Interest</div>
<div class="list" style="border-top: 2px solid #cbd5e1;">
<div class="list-75">Job Interest:</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.job_interest || '-'}
</div>
</div>
<div class="list">
<div class="list-75">
  Can you sweep and mop the floor?
</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_sweep_and_mop_floor ||
    '-'}
</div>
</div>

<div class="list">
<div class="list-75">Can you iron clothes?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_iron_clothes || '-'}
</div>
</div>
<div class="list">
<div class="list-75">Can you cook?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_cook || '-'}
</div>
</div>
<div class="list">
<div class="list-75">Please elaborate further.</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.cooking_description || '-'}
</div>
</div>
<div class="list">
<div class="list-75">
  Are you willing to wash car and wash clothes by hand?
</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_wash_car_and_clothes_by_hand || '-'}
</div>
</div>
<div class="list">
<div class="list-75">
  Do you know how to feed and bath the baby?
</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_feed_and_bath_baby || '-'}
</div>
</div>
<div class="list">
<div class="list-75">
  Can you babysit and have experience in looking after
  babies?
</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_babysit || '-'}
</div>
</div>
<div class="list">
<div class="list-75">
  Are you willing to fetch children to and from school?
</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_fetch_children_to_and_from_school || '-'}
</div>
</div>
<div class="list">
<div class="list-75">
  Are you willing to look after the ill, partially disabled
  and/or elderly persons?
</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_look_after_ill_or_old_or_disabled || '-'}
</div>
</div>
<div class="list">
<div class="list-75">
  Any other information to add (Optional)
</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.self_description || '-'}
</div>
</div>
</div>

<div class="list-box">
<div class="title">Declaration</div>
<div class="list" style="border-top: 2px solid #cbd5e1;">
<div class="list-75">Do you have your family’s consent?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.have_family_consent || '-'}
</div>
</div>
<div class="list">
<div class="list-75">Are you willing to be responsible for your work?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.is_willing_to_be_responsible ||
    '-'}
</div>
</div>
<div class="list">
<div class="list-75">Are you willing to take advice and obey instructions?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_iron_clothes || '-'}
</div>
</div>
<div class="list">
<div class="list-75">Are you willing to work overtime?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_work_overtime || '-'}
</div>
</div>
<div class="list">
<div class="list-75">Are you willing to work for 2 years on contract?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_work_2_years_on_contract || '-'}
</div>
</div>
<div class="list">
<div class="list-75">Are you comfortable to be left alone in the house?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_be_left_alone_in_house || '-'}
</div>
</div>
<div class="list">
<div class="list-75">Are you prepared to work with Non-Muslim employer?</div>
<div class="list-quarter">
  ${currentIGWService?.input_data?.can_work_with_non_muslim_employer || '-'}
</div>
</div>
</div>

    </body>
    </html>
    `
  )
}