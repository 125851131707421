import React, { useEffect } from 'react';
import Permissions from '../../components/profile-widgets/permissions';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { viewSystemUserAction } from '../../store/actions/systemUsersActions';
import { Skeleton } from 'antd';
import SystemUserProfileHeader from '../../components/profile-widgets/system-user-profile-header';
import { useTranslation } from 'react-i18next';
import { getRoles } from '../../store/actions/rolesPermissionActions';

function SystemUserDetails() {
  const { currentUserLoading, currentUser } = useSelector(
    (state) => state.systemUsers
  );
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const getRoleId = () => {
    let role = currentUser?.user_detail?.role[0];
    switch (role) {
      case 'superuser':
        return 1;
      case 'moderator':
        return 2;
      case 'compliance_admin':
        return 3;
      case 'org_user':
        return 4;
      case 'org_admin':
        return 5;
      default:
        break;
    }
  };
  const roleId = getRoleId();

  useEffect(() => {
    dispatch(viewSystemUserAction(uuid));
    roleId && dispatch(getRoles(roleId));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  const { t } = useTranslation();
  let title = t('system_user_details.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Administration',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('system_users.section_title'),
      url: '/system-users',
      last: false,
    },
    { home: false, title: 'Details', url: '/', last: true },
  ];

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="w-full mb-4">
        <div className="user-cover w-full h-24 lg:h-40"></div>
        {currentUserLoading ? (
          <div className="px-6 pb-6 flex space-x-4">
            <div>
              <Skeleton.Avatar
                active
                style={{ width: 200, height: 200 }}
                className="-mt-16 block"
              />
            </div>
            <div className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b pb-6">
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b pb-6">
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
              </div>

              <div className="flex pt-4 space-x-4">
                <Skeleton.Button active style={{ width: '200px' }} />
                <Skeleton.Button active style={{ width: '200px' }} />
              </div>
            </div>
          </div>
        ) : (
          <>
            <SystemUserProfileHeader userDetails={currentUser} />
            <Permissions permissions={currentUser?.user_detail?.permissions} />
          </>
        )}
      </div>
    </>
  );
}

export default SystemUserDetails;
