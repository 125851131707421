import React from 'react';
import { HiOutlineDocumentText } from "react-icons/hi";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { formatNumber } from '../../utils/numbers';

function KYCList({ kyc }) {
  const kyc_total = kyc?.verified + kyc?.rejected + kyc?.submitted;
  return (
    <>
      <div className="flex items-center justify-between py-2 pb-3 border-b border-grey-300 border-dashed">
        <div className="flex items-center">
          <div className="p-2 bg-grey-500 text-gray-500 rounded-full mr-2 bg-opacity-25">
            <HiOutlineDocumentText size={20} />
          </div>
          <div className="text-md font-bold text-grey-anak">Not Started</div>
        </div>
        <div className="text-xl font-bold order-last">
          {formatNumber(kyc?.pending)}
        </div>
      </div>
      <div className="flex items-center justify-between py-2 pt-3">
        <div className="flex items-center">
          <div className="p-2 bg-orange-anak  text-orange-400 rounded-full mr-2 bg-opacity-25">
            <HiOutlineDocumentText size={20} />
          </div>
          <div className="text-md font-bold text-grey-anak">
            In Progress
          </div>
        </div>
        <div className="text-xl font-bold order-last">
          {formatNumber(kyc?.submitted)}
        </div>
      </div>
      <div className="flex items-center justify-between py-2">
        <div className="flex items-center">
          <div className="p-2 bg-green-anak text-green-anak rounded-full mr-2 bg-opacity-25">
            <HiOutlineDocumentText size={20} />
          </div>
          <div className="text-md font-bold text-grey-anak">Verified</div>
        </div>
        <div className="text-xl font-bold order-last">
          {formatNumber(kyc?.verified)}
        </div>
      </div>
      <div className="flex items-center justify-between py-2 pb-3">
        <div className="flex items-center">
          <div className="p-2 bg-red-anak text-red-400 rounded-full mr-2 bg-opacity-25">
            <HiOutlineDocumentText size={20} />
          </div>
          <div className="text-md font-bold text-grey-anak">Rejected</div>
        </div>
        <div className="text-xl font-bold order-last">
          {formatNumber(kyc?.rejected)}
        </div>
      </div>
      <div className="flex items-center justify-between pt-3 border-t border-grey-300">
        <div className="flex items-center">
          <div className="p-2 bg-grey-900 text-white rounded-full mr-2">
            <BsFillArrowRightCircleFill size={20} />
          </div>
          <div className="text-md font-bold text-grey-anak">Total Attempts</div>
        </div>
        <div className="text-xl font-bold order-last">
          {formatNumber(kyc_total)}
        </div>
      </div>
    </>
  );
}

export default KYCList;
