import React from 'react';

function WidgetTitle({ title, children }) {
  return (
    <>
      {title && <div className="text-lg font-bold mb-4">{title}</div>}
      {children}
    </>
  );
}

export default WidgetTitle;
