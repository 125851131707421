import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { viewOrganisationSubscriberAction } from '../../store/actions/organisationActions';
import { useParams } from 'react-router-dom';

function OrgSubscribersTable() {
  const { organization_uuid } = useParams();
  const initialTableParams = {
    page: 1,
    search: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    organization_uuid,
  };
  const menuFilter = [];
  const { orgSubscribers, orgSubscribersLoading, orgSubscribersError } =
    useSelector((state) => state.organisation);
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      dispatchAction={viewOrganisationSubscriberAction}
      data={orgSubscribers}
      loading={orgSubscribersLoading}
      error={orgSubscribersError}
    >
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Mobile Number"
        dataIndex="mobile"
        key="mobile"
        render={(text, record) => record?.mobile}
      />
    </TableUniversal>
  );
}
export default OrgSubscribersTable;
