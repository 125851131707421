import React, { useState } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import OrgCategoryTable from './org-category-table';
import { Card, Drawer, Form, Input, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createOrgCategoryAction } from '../../store/actions/orgCategoryActions';

function OrgCategory() {
  const { t } = useTranslation();
  const [createOrgDrawer, setCreateOrgDrawer] = useState(false);
  const { createCategoryLoading } = useSelector((state) => state.orgCategory);
  const dispatch = useDispatch();
  const [addCategoryForm] = Form.useForm();
  let title = t('org_category.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    { home: false, title: 'Category', url: '', last: true },
  ];
  const createOrgCategoryHandler = ({ category_name }) => {
    dispatch(createOrgCategoryAction(category_name));
    setCreateOrgDrawer(!createOrgDrawer);
    addCategoryForm.resetFields();
  };
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex lg:flex-row-reverse mb-6">
        <Button
          type="success"
          size="large"
          className="flex items-center"
          onClick={() => setCreateOrgDrawer(!createOrgDrawer)}
          icon={
            createCategoryLoading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    size={22}
                    className="mr-2 mb-1 text-blue-anak"
                    spin
                  />
                }
              />
            ) : (
              <BiPlusCircle size={24} className="mr-2" />
            )
          }
        >
          {t('org_category.create_modal.button_text')}
        </Button>
      </div>
      <Card bodyStyle={{ padding: 0, overflow: 'hidden', borderRadius: 10 }}>
        <div className="pt-4">
          <OrgCategoryTable />
        </div>
      </Card>

      <Drawer
        title={t('org_category.create_modal.title')}
        placement="right"
        onClose={() => {
          addCategoryForm.resetFields();
          setCreateOrgDrawer(!createOrgDrawer);
        }}
        visible={createOrgDrawer}
        headerStyle={{ fontWeight: 'bold', paddingTop: 20, paddingBottom: 20 }}
      >
        <Form
          layout="vertical"
          className=""
          onFinish={createOrgCategoryHandler}
          form={addCategoryForm}
        >
          <Form.Item
            name="category_name"
            label={t('org_category.create_modal.form_label')}
            className="mb-6"
            required
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
              {
                pattern: new RegExp(/^[a-zA-Z0-9]*[^=+@<>-]*$/),
                message: "This field does not accept =, +, -, @, <, >"
              }
            ]}
          >
            <Input
              type="text"
              size="large"
              placeholder={t('org_category.create_modal.title')}
            />
          </Form.Item>
          <Button size="large" type="success" block htmlType="submit">
            {t('org_category.create_modal.title')}
          </Button>
        </Form>
      </Drawer>
    </>
  );
}

export default OrgCategory;
