import React, { useEffect, useState } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
// import { useTranslation } from 'react-i18next';
import Widget from '../../components/dashboard/widget-1';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumber, formatCurrency } from '../../utils/numbers';
import { BiCheck, BiInfoCircle, BiTimeFive, BiWallet, BiX } from 'react-icons/bi';
import DateRangePicker from '../../components/table/table-date-range';
import { getTransactionOverviewAction } from '../../store/actions/transactionActions';
import { WidgetSkeleton } from '../../components/skeleton/WidgetSkeleton';
import { Tooltip } from 'antd';

function TransactionOverview() {
  // const { t } = useTranslation();
  const [overviewParams, setOverviewParams] = useState({
    created_at_after: '',
    created_at_before: '',
  });
  const dispatch = useDispatch();
  let title = 'Transaction Overview';
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Wallet',
      url: '/',
      last: false,
    },
    {
      home: true,
      title: 'Transactions',
      url: '/',
      last: false,
    },
    { home: false, title: 'Overview', url: '/', last: true },
  ];
  const { transactionOverview, transactionOverviewLoading } = useSelector(
    (state) => state.transaction
  );

  const datePickerHandler = (date) => {
    setOverviewParams({
      created_at_after: date.startDate,
      created_at_before: date.endDate,
    });
  };

  const getTitleAndInfoTooltip = (title, info) => {
    return (
      <div className="flex items-center">{title}
        <Tooltip
          title={info}
          className="ml-1"
          overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}
          placement="top"
          arrowPointAtCenter
        >
          <BiInfoCircle size={16} />
        </Tooltip>
      </div>
    )
  }

  useEffect(() => {
    dispatch(getTransactionOverviewAction(overviewParams));
  }, [overviewParams]); // eslint-disable-line

  return (
    <>
      <SectionTitle
        title={
          <div className="flex">
            <div className="mr-4">{title}</div>
            <DateRangePicker datePickerHandler={datePickerHandler} />
          </div>
        }
        right={<Breadcrumb items={breadcrumbsItem} />}
      />

      <div className="text-base font-bold mb-3">P2P Transactions</div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4 mb-4">
        {transactionOverviewLoading ? (
          Array(4)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100"
                >
                  <WidgetSkeleton />
                </div>
              );
            })
        ) : (
          <>
            <Widget
              // className="text-black-anak"
              title={
                getTitleAndInfoTooltip("Total P2P Transaction Amount", "Showing only successful P2P transactions")
              }
              description={"SGD " + formatCurrency(
                transactionOverview?.transfer?.total_transactions
              )}
              right={
                <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-1">
                  <BiWallet
                    size={24}
                    className="stroke-current text-blue-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Success"
              description={formatNumber(transactionOverview?.transfer?.success)}
              rightBorderColor="green"
              right={
                <div className="bg-green-100 bg-opacity-25 border border-green-100 block rounded-full p-1">
                  <BiCheck
                    size={24}
                    className="stroke-current text-green-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Pending"
              description={formatNumber(transactionOverview?.transfer?.pending)}
              rightBorderColor="orange"
              right={
                <div className="bg-orange-100 bg-opacity-25 border border-orange-100 block rounded-full p-1">
                  <BiTimeFive
                    size={24}
                    className="stroke-current text-orange-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Failed"
              description={formatNumber(transactionOverview?.transfer?.failed)}
              rightBorderColor="red"
              right={
                <div className="bg-red-100 bg-opacity-25 border border-red-100 block rounded-full p-1">
                  <BiX size={24} className="stroke-current text-red-anak" />
                </div>
              }
            />
          </>
        )}
      </div>

      <div className="text-base font-bold mb-3">Remittance</div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4 mb-4">
        {transactionOverviewLoading ? (
          Array(4)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100"
                >
                  <WidgetSkeleton />
                </div>
              );
            })
        ) : (
          <>
            <Widget
              // className="text-black-anak"
              title={
                getTitleAndInfoTooltip("Total Remittance Transaction Amount", "Showing only successful Remittance transactions")
              }
              description={"SGD " + formatCurrency(
                transactionOverview?.remittance?.total_transactions
              )}
              right={
                <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-1">
                  <BiWallet
                    size={24}
                    className="stroke-current text-blue-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Success"
              description={formatNumber(
                transactionOverview?.remittance?.success
              )}
              rightBorderColor="green"
              right={
                <div className="bg-green-100 bg-opacity-25 border border-green-100 block rounded-full p-1">
                  <BiCheck
                    size={24}
                    className="stroke-current text-green-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Pending"
              description={formatNumber(
                transactionOverview?.remittance?.pending
              )}
              rightBorderColor="orange"
              right={
                <div className="bg-orange-100 bg-opacity-25 border border-orange-100 block rounded-full p-1">
                  <BiTimeFive
                    size={24}
                    className="stroke-current text-orange-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Failed"
              description={formatNumber(
                transactionOverview?.remittance?.failed
              )}
              rightBorderColor="red"
              right={
                <div className="bg-red-100 bg-opacity-25 border border-red-100 block rounded-full p-1">
                  <BiX size={24} className="stroke-current text-red-anak" />
                </div>
              }
            />
          </>
        )}
      </div>

      <div className="text-base font-bold mb-3">Top Up</div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4 mb-4">
        {transactionOverviewLoading ? (
          Array(4)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100"
                >
                  <WidgetSkeleton />
                </div>
              );
            })
        ) : (
          <>
            <Widget
              // className="text-black-anak"
              title={
                getTitleAndInfoTooltip("Total Top Up Transaction Amount", "Showing only successful Top-ups transactions")
              }
              description={"SGD " + formatCurrency(
                transactionOverview?.topup?.total_transactions
              )}
              right={
                <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-1">
                  <BiWallet
                    size={24}
                    className="stroke-current text-blue-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Success"
              description={formatNumber(transactionOverview?.topup?.success)}
              rightBorderColor="green"
              right={
                <div className="bg-green-100 bg-opacity-25 border border-green-100 block rounded-full p-1">
                  <BiCheck
                    size={24}
                    className="stroke-current text-green-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Pending"
              description={formatNumber(transactionOverview?.topup?.pending)}
              rightBorderColor="orange"
              right={
                <div className="bg-orange-100 bg-opacity-25 border border-orange-100 block rounded-full p-1">
                  <BiTimeFive
                    size={24}
                    className="stroke-current text-orange-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Payment Failed"
              description={formatNumber(transactionOverview?.topup?.failed)}
              rightBorderColor="red"
              right={
                <div className="bg-red-100 bg-opacity-25 border border-red-100 block rounded-full p-1">
                  <BiX size={24} className="stroke-current text-red-anak" />
                </div>
              }
            />
          </>
        )}
      </div>
    </>
  );
}

export default TransactionOverview;
