import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import {
  Card,
  Modal,
  Button,
  Drawer,
  Form,
  Input,
  // Skeleton,
  // Space,
} from 'antd';
import { BsBuilding } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  deleteOrgCategoryAction,
  editOrgCategoryAction,
  viewOrgCategoryAction,
} from '../../store/actions/orgCategoryActions';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from '../../utils/dateFormat';
import { isEmpty } from '../../utils/generic';
import { BiPlusCircle } from 'react-icons/bi';
import { OrgDetailSkeleton, CardSkeleton } from '../../components/skeleton/WidgetSkeleton';

function OrgCategoryDetails() {
  const [toggleEditDrawer, setToggleEditDrawer] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const { category_uuid } = useParams();
  const [editOrgForm] = Form.useForm();
  let { url } = useRouteMatch();
  const { t } = useTranslation();
  const { currentCategory, currentCategoryLoading } = useSelector(
    (state) => state.orgCategory
  );
  let title = currentCategory?.name ?? t('org_category.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'Category',
      url: '/organisations/categories',
      last: false,
    },
    { home: false, title, url: '', last: true },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewOrgCategoryAction(category_uuid));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const editCategoryDrawerHandler = ({ category_name }) => {
    setToggleEditDrawer(!toggleEditDrawer);
    dispatch(editOrgCategoryAction({ category_uuid, category_name }));
  };
  const deleteCategoryModalHandler = () => {
    dispatch(deleteOrgCategoryAction(category_uuid));
    setToggleDeleteModal(!toggleDeleteModal);
  };

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <Card className="my-8">
        {currentCategoryLoading ? (
          <div className="p-4">
            <OrgDetailSkeleton />
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="px-4 py-2">
              <div className="text-md mb-2">Category Name</div>
              <div className="text-lg font-bold">{currentCategory?.name}</div>
            </div>
            <div className="px-4 py-2">
              <div className="text-md mb-2">Created On</div>
              <div className="text-lg font-bold">
                {dateFormat(currentCategory?.created_at)}
              </div>
            </div>
            <div className="px-4 py-2">
              <div className="text-md mb-2">No. of Organisation</div>
              <div className="text-lg font-bold">
                {isEmpty(currentCategory?.organizations)
                  ? 0
                  : currentCategory.organizations.length}
              </div>
            </div>
            <div className="px-4 py-2">
              <Button
                type="success"
                size="large"
                className="mr-4 w-32 lg:w-40"
                onClick={() => setToggleEditDrawer(!toggleEditDrawer)}
              >
                {t('org_category.edit_modal.button_text')}
              </Button>
              <Button
                type="danger"
                size="large"
                className="w-32 lg:w-40"
                onClick={() => setToggleDeleteModal(!toggleDeleteModal)}
                disabled={
                  !isEmpty(currentCategory?.organizations) ? true : false
                }
              >
                {t('org_category.delete_modal.button_text')}
              </Button>
            </div>
          </div>
        )}
      </Card>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 items-stretch mb-6 lg:mb-0">
        {currentCategoryLoading ? (
          <div className="w-full flex-col items-center col-span-4">
            <CardSkeleton />
          </div>
        ) : isEmpty(currentCategory?.organizations) ? (
          <div className="text-grey-500 mb-6 col-span-4 flex w-full flex-col items-center ant-card-body bg-white shadow rounded py-10">
            <div className="pt-10 pb-4">
              <BsBuilding className="text-8xl text-gray-100" />
            </div>
            <div className="text-lg font-bold text-blue-anak">
              No organisations found!
            </div>
            <div className="w-full md:w-1/3 text-center py-2">
              Sorry! There are no organisations associated with this category,
              click the below button to create one
            </div>
            <Link to="/organisations/create" className="lg:mx-auto mt-4 mb-10">
              <Button
                icon={<BiPlusCircle className="mr-2 w-6 h-6" />}
                type="success"
                size="large"
                className="flex items-center ml-auto"
              >
                {t('add_organisation.button_text')}
              </Button>
            </Link>
          </div>
        ) : (
          currentCategory?.organizations?.map((item) => (
            <Card
              className="flex flex-col items-center org-cat-details"
              key={item.uuid}
            >
              <Link to={`${url}/${item.uuid}`}>
                <img src={item.logo} alt={item.name} />
                <div className="mt-4 font-bold text-lg">{item.name}</div>
              </Link>
            </Card>
          ))
        )}
      </div>
      {/* DELETE MODAL */}
      <Modal
        visible={toggleDeleteModal}
        onOk={deleteCategoryModalHandler}
        onCancel={() => setToggleDeleteModal(!toggleDeleteModal)}
        centered
        footer={null}
      >
        <div className="px-8 py-4 text-center">
          <div className="text-center mb-6">
            <div className="text-xl font-bold">
              {t('org_category.delete_modal.title')}
            </div>
            <div className="text-md">
              {t('org_category.delete_modal.description')}
            </div>
          </div>
          <div className="flex justify-between">
            <Button
              size="large"
              onClick={deleteCategoryModalHandler}
              type="danger"
              className="w-64 mr-4"
            >
              {t('org_category.delete_modal.confirm_text')}
            </Button>
            <Button
              size="large"
              className="w-64"
              onClick={() => setToggleDeleteModal(!toggleDeleteModal)}
            >
              {t('org_category.delete_modal.cancel_text')}
            </Button>
          </div>
        </div>
      </Modal>
      {/* EDIT DRAWER */}
      <Drawer
        title={t('org_category.edit_modal.title')}
        placement="right"
        onClose={() => {
          editOrgForm.resetFields();
          setToggleEditDrawer(!toggleEditDrawer);
        }}
        visible={toggleEditDrawer}
        headerStyle={{ fontWeight: 'bold', paddingTop: 20, paddingBottom: 20 }}
      >
        <Form
          form={editOrgForm}
          layout="vertical"
          onFinish={editCategoryDrawerHandler}
          initialValues={{ category_name: currentCategory?.name }}
        >
          <Form.Item
            name="category_name"
            label={t('org_category.edit_modal.form_label')}
            className="mb-6"
            rules={[
              {
                required: true,
                message: 'Please input category name!',
              },
              {
                pattern: new RegExp(/^[a-zA-Z0-9]*[^=+@<>-]*$/),
                message: "This field does not accept =, +, -, @, <, >"
              }
            ]}
          >
            <Input
              type="text"
              size="large"
              placeholder={t('org_category.edit_modal.form_placeholder')}
            />
          </Form.Item>
          <Button size="large" type="success" block htmlType="submit">
            {t('org_category.edit_modal.confirm_text')}
          </Button>
        </Form>
      </Drawer>
    </>
  );
}

export default OrgCategoryDetails;
