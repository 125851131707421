import * as types from '../actionTypes/services-igw';

export const listIGWServicesAction = (tableParams) => {
  return {
    type: types.LIST_IGW_SERVICES,
    payload: tableParams,
  };
};
export const listIGWServicesSuccessAction = (igwServices) => {
  return {
    type: types.LIST_IGW_SERVICES_SUCCESS,
    payload: igwServices,
  };
};
export const listIGWServicesFailedAction = (error) => {
  return {
    type: types.LIST_IGW_SERVICES_FAILED,
    payload: error,
  };
};

export const getIGWDetailsListAction = (subscriber_id) => {
  return {
    type: types.GET_IGW_DETAILS_LIST,
    payload: subscriber_id,
  };
}

export const getIGWDetailsListSuccessAction = (igwDetailsList) => {
  return {
    type: types.GET_IGW_DETAILS_LIST_SUCCESS,
    payload: igwDetailsList,
  };
}

export const getIGWDetailsListFailedAction = (error) => {
  return {
    type: types.GET_IGW_DETAILS_LIST_FAILED,
    payload: error,
  };
}

export const getIGWDetailsAction = (requestParams) => {
  return {
    type: types.GET_IGW_DETAILS,
    payload: requestParams,
  };
};

export const getIGWDetailsSuccessAction = (igwDetails) => {
  return {
    type: types.GET_IGW_DETAILS_SUCCESS,
    payload: igwDetails,
  };
};

export const getIGWDetailsFailedAction = (error) => {
  return {
    type: types.GET_IGW_DETAILS_FAILED,
    payload: error,
  };
};

// REVIEW/EDIT IGW DETAILS
export const editIGWDetailsAction = (initialTableParams, formValue) => {
  return {
    type: types.EDIT_IGW_DETAILS,
    payload: { formValue, initialTableParams },
  };
};

export const editIGWDetailsSuccessAction = (values) => {
  return {
    type: types.EDIT_IGW_DETAILS_SUCCESS,
    payload: values,
  };
};

export const editIGWDetailsFailAction = (error) => {
  return {
    type: types.EDIT_IGW_DETAILS_FAIL,
    payload: error,
  };
};