import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { Link } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { getIGWStatus } from '../../utils/get-igw-status';
import { listIGWServicesAction } from '../../store/actions/igwServiceActions';

function IMWServicesTable() {

  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '-created_at',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [];
  const csvExportHeader = [
    { label: 'Name', key: 'name' },
    { label: 'Phone No.', key: 'mobile' },
    { label: 'User Submitted On', key: 'created_at' },
    { label: 'Admin Updated On', key: 'updated_at' },
    { label: 'Status', key: 'status' },
  ];
  const { listIGWServices, listIGWServicesLoading, listIGWServicesError } =
    useSelector((state) => state.igwService);

  const tableData = {
    results: listIGWServices,
  };

  const { Column } = Table;

  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listIGWServicesAction}
      data={tableData.results}
      loading={listIGWServicesLoading}
      error={listIGWServicesError}
      csvExportHeader={csvExportHeader}
      downloadUrl="/organisations/43220d84-c0ee-47c5-8dbc-7fb828ec326f/services/17eee0eb-18b9-4fe0-bdd3-fcc63fc5b9b9/"
    >
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Phone No."
        dataIndex="mobile"
        key="mobile"
        align="left"
        sorter={true}
        render={(text, record) => (`+${record.mobile_country} ${record?.mobile}`)}
      />
      <Column
        title="User Submitted On"
        dataIndex="created_at"
        key="created_at"
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
        sorter={true}
        defaultSortOrder='descend'
      />
      <Column
        title="Admin Updated On"
        dataIndex="reviewed_at"
        key="reviewed_at"
        render={(text, record) => utcToLocalDateTime(record?.reviewed_at)}
        // sorter={true}
        defaultSortOrder='descend'
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getIGWStatus(record?.status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => {
          if (record.status === "FIRST_REVIEW" || record.status === "UPDATE_REVIEW")
            return <Link className="text-blue-500" to={`/customers/igw/subscriber/${record.subscriber_id}/form/${record.uuid}/review`}>
              View Details
            </Link>
          else
            return (<Link className="text-blue-500" to={`/customers/igw/subscriber/${record.subscriber_id}/form/${record.uuid}/`}>
              View Details
            </Link>)
        }}
        width={120}
      />
    </TableUniversal>
  );
}
export default IMWServicesTable;
