import axiosClient from './config';

export const listWalletService = async ({
  page,
  search,
  ordering,
  status,
  page_size,
  created_at_after,
  created_at_before,
  active_wallet_status
}) => {
  return await axiosClient.get(
    `/wallet/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&status=${status}&active_wallet_status=${active_wallet_status}`
  );
};
export const getWalletSummaryService = async () => {
  return await axiosClient.get(`/wallet-summary/`);
};
export const getWalletService = async (wallet_id) => {
  return await axiosClient.get(`/wallet/${wallet_id}/`);
};
