const translationEnglish = {
  left_sidebar: {
    administration_panel: {
      label: 'Administration',
      dashboard: 'Dashboard',
      system_users: 'System Users',
      roles_and_permission: 'Roles and Permission',
    },
    organisation_panel: {
      label: 'ORGANISATION PANEL',
      category: 'Category',
      organisations: 'Organisations',
      add_organisations: 'Add Organisations',
      org_message: 'Org Message',
      org_user: 'Org User',
    },
    customer_panel: {
      label: 'Users Panel',
      customer_list: 'App Users',
      kyc_details: 'KYC Details',
    },
    wallet_panel: {
      label: 'Wallet',
      wallet_details: 'Wallet Details',
      transactions: {
        label: 'Transactions',
        p2p_transactions_list: 'P2P Transactions List',
        remittance_list: 'Remittance List',
      },
    },
  },
  navbar: {
    user_dropdown: {
      logout: 'Logout',
      editProfile: 'Edit Profile',
    },
  },
  profileEdit: {
    section_title: 'Personal Info',
  },
  dashboard: {
    section_title: 'Dashboard',
    app_users_overview: {
      widget_title: 'App Users Overview',
      total_app_users: 'Total App Users',
      registered_today: 'Registered Today',
      registered_this_month: 'Registered This Month',
      registered_last_month: 'Registered Last Month',
    },
    customer_overview: {
      widget_title: 'Customer Overview (Wallet Opening)',
      total_customers: 'Total Customers',
      onboarded_today: 'Onboarded Today',
      onboarded_yesterday: 'Onboarded Yesterday',
      onboarded_this_month: 'Onboarded This Month',
      onboarded_last_month: 'Onboarded Last Month',
    },
    customer_graph: {
      widget_title: 'Customers by Month',
      tooltip: 'Active = At least 1 transaction for that month',
    },
    kyc_details: {
      section_title: 'KYC Details',
      approved: 'KYC Approved this Month',
      pending: 'KYC Pending this Month',
      rejected: 'KYC Rejected this Month',
      total: 'Total KYC this Month',
    },
    transactions_overview: {
      section_title: 'Transactions Overview',
      top_ups: 'Top-ups',
      remittance: 'Remittance',
      last_month: 'Last Month',
      this_month: 'This Month',
    },
    earnings_overview: {
      section_title: 'Earnings Overview',
      from_fx: 'From FX',
      from_fee: 'From Fee',
      from_fx_this_month: 'From FX This Month',
      from_fee_this_month: 'From Fee This Month',
      from_fx_last_month: 'From FX Last Month',
      from_fee_last_month: 'From Fee Last Month'
    },
    wallet_details: {
      section_title: 'Wallet Details',
      total_amount: 'Total wallet Amount',
      transaction_today: "Today's Transaction",
      transaction_month: 'This Month Transaction',
      total_users: 'Total wallet Users',
      users_active: 'Active Wallet Users',
      users_inactive: 'Inactive Wallet users',
      chart_title: 'Transaction by Month',
      chart_tooltip: 'Lorem ipsum dolor sit amet itatibus!',
    },
    organisation_details: {
      section_title: 'Organisation Details',
      total_organization: 'Total organisation',
      onboard_this_month: 'Onboarded This Month',
      org_all_messages: 'Org All Messages',
      draft_messages: 'Draft Messages',
      scheduled_message: 'Scheduled Messages',
      published_message: 'Published Messages',
      message_chart_title: 'Total messages by Month',
      message_chart_label: 'Message',
      organization_chart_title: 'Registered Organizations by Month',
      organization_chart_label: 'Organization',
    },
  },
  system_users: {
    section_title: 'System Users',
    add_modal: {
      button_text: 'Add New System User',
      title: 'Add New System User',
      name_label: 'Full Name',
      name_placeholder: 'Enter name',
      name_error: "Please input user's name",
      email_label: 'Email Address',
      email_placeholder: 'Enter email address',
      email_error: 'Please input valid email.',
      role_label: 'User Role',
      role_placeholder: 'Select user role',
      role_error: 'Select user role',
      confirm_text: 'Add New System User',
    },
  },
  system_user_details: {
    section_title: 'System User Details',
    profile_header: {
      name: 'Name',
      email: 'Email ID',
      role: 'Role',
      status: {
        label: 'Status',
        drawer: {
          title: 'Change User Status',
          confirm: 'Save',
          cancel: 'Cancel',
        },
        active: 'Active',
        suspend: 'Suspend',
        delete: {
          text: 'Delete',
          popup_title: 'Delete User',
          description:
            'Are you sure you want to delete this user from the system?',
          form_email: {
            label: 'Email Address',
            placeholder: 'Enter email address',
            error: 'Please input valid email.',
          },
          confirm_text: 'Delete User',
          cancel_text: 'Cancel',
        },
        confirm_text: 'Save',
        cancel_text: 'Cancel',
      },
      created_at: 'Created At',
      updated_at: 'Updated At',
      created_by: 'Created By',
      last_login: 'Last Login',
      edit_system_user_drawer: {
        button_text: 'Edit Profile',
        drawer_title: 'Edit User',
        form_name_label: 'Full Name',
        form_email_label: 'Email Address',
        form_role_label: 'Role',
        drawer_submit_text: 'Update System User',
      },
      reset_password_popup: {
        button_text: 'Reset Password',
        title: 'Reset Password',
        description:
          'Are you sure you want to reset the password of this user?',
        form_email_label: 'Email Address',
        form_email_placeholder: 'Enter email address',
        email_required_error: 'Please input your email!',
        email_invalid_error: 'Please input valid email id!',
        confirm_text: 'Reset Password',
        cancel_text: 'Cancel',
      },
    },
    permission_1: 'Permission name 1',
    permission_1_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_2: 'Permission name 2',
    permission_2_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_3: 'Permission name 3',
    permission_3_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_4: 'Permission name 4',
    permission_4_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  roles_permission: {
    section_title: 'Roles and Permission',
    role_title: 'Role',
    dropdown_label: 'Select Role',
    user: 'User',
    moderator: 'Moderator',
    admin: 'Admin',
    super_admin: 'Super Admin',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    permissions_title: 'Permissions',
    permission_1: 'Permission name 1',
    permission_1_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_2: 'Permission name 2',
    permission_2_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_3: 'Permission name 3',
    permission_3_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

    permission_4: 'Permission name 4',
    permission_4_detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    permission_save: 'Save',
  },
  org_category: {
    section_title: 'Organisation Category',
    create_modal: {
      button_text: 'Create New Category',
      title: 'Add New Category',
      form_label: 'Category Name',
      form_placeholder: 'Enter category name',
      confirm_text: 'Save',
    },
    details: {
      category_name: 'Category Name',
      created_on: 'Created On',
      total: 'No. of Organisation',
    },
    edit_modal: {
      button_text: 'Edit Category',
      title: 'Edit Category',
      form_label: 'Category Name',
      form_placeholder: 'Enter category name',
      confirm_text: 'Save',
    },
    delete_modal: {
      button_text: 'Delete',
      title: 'Delete Category',
      description: 'Are you sure you want to delete this category',
      confirm_text: 'Delete',
      cancel_text: 'Cancel',
    },
  },
  org_details: {
    section_title: 'Organisation Details',
    status: 'Status',
    category: 'Category',
    tab_description: 'Description',
    tab_Users: 'Users',
    tab_message: 'Messages',
    tab_contact: 'Contact Details',
    edit_modal: {
      button_text: 'Edit Organisation',
      title: 'Edit Organisation',
      // ADD MORE FROM add_organisation
    },
    delete_modal: {
      button_text: 'Delete Organisation',
      title: 'Delete Organisation',
      description: 'Are you sure you want to delete this organisation',
      confirm_text: 'Delete',
      cancel_text: 'Cancel',
    },
  },
  organisation: {
    section_title: 'Organisations',
  },

  add_organisation: {
    button_text: 'Add New Organisation',
    title_add: 'Add New Organisation',
    title_edit: 'Edit Organisation',
    name_label: 'Organisation Name',
    name_placeholder: 'Enter Organisation Name',
    category_label: 'Organisation Category',
    category_placeholder: 'Select Organisation Category',
    logo_label: 'Organisation Logo',
    logo_placeholder: 'No file selected',
    logo_cta: 'Choose File',
    description_label: 'Description',
    description_placeholder: 'Type message contents',
    add_language: 'Add New Language',
    remove_language: 'Remove Language',
    email_label: 'Contact Email',
    email_placeholder: 'Enter contact email',
    phone_label: 'Contact Phone',
    phone_placeholder: 'Enter contact phone',
    url_label: 'Website URL',
    url_placeholder: 'Enter website url',
    confirm_text: 'Save',
  },
  org_message: {
    section_title: 'Organisation Message',
    create: {
      button_text: 'Create New Message',
      title: 'Create New Message',
      org_label: 'Organisation',
      org_placeholder: 'Select Organisation',
      recipients_label: 'Recipients',
      recipients_placeholder: 'Add Recipients by searching name or mobile',
      msg_title_label: 'Message Title',
      msg_title_placeholder: 'Enter Message Title',
      msg_content_label: 'Message Content',
      msg_content_placeholder: 'Type Message Content',
      image_label: 'Attach Image',
      image_placeholder: 'No file selected',
      image_cta: 'Choose File',
      video_label: 'Attach Video',
      video_placeholder: 'Enter video link',
      file_label: 'Attach File',
      file_placeholder: 'Paste file link/url',
      file_cta: 'Choose File',
      send_text: 'Send',
      draft_text: 'Save in Draft',
      schedule_text: 'Schedule',
    },
    details: {
      title: 'Organisation Message Details',
      name_label: 'Organisation Name',
      date_label: 'Sent Date & Time',
      language_label: 'Language',
      status_label: 'Status',
      views_label: 'User Views',
      heading_label: 'Message Heading',
      content_label: 'Message Content',
      video_label: 'Message Video',
      cta_label: 'Call to Action',
    },
  },
  org_user: {
    section_title: 'Organisation User',
    add_modal: {
      button_text: 'Add New User',
      title: 'Add New User',
      name_label: 'Full Name',
      name_placeholder: 'Enter name',
      name_error: 'Please input name',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      role_label: 'User Role',
      role_placeholder: 'Select user role',
      role_error: 'Select user role',
      confirm_text: 'Add New User',
    },
  },
  org_user_detail: {
    section_title: 'Organisation User Details',
    name_label: 'Name',
    email_label: 'Email',
    role_label: 'Role',
    status_label: 'Status',
    created_label: 'Created At',
    updated_label: 'Updated At',
    created_by_label: 'Created By',
    last_login_label: 'Last Login',
    org_name_label: 'Organisation Name',
    edit_modal: {
      button_text: 'Edit Profile',
      title: 'Edit User',
      name_label: 'Full Name',
      name_placeholder: 'Enter name',
      name_error: 'Please input name',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      org_label: 'Organisation',
      org_placeholder: 'Enter organisation',
      org_error: 'Please provide organisation.',
      role_label: 'User Role',
      role_placeholder: 'Select user role',
      role_error: 'Select user role',
      confirm_text: 'Update System User',
    },
    password_modal: {
      button_text: 'Reset Password',
      title: 'Reset Password',
      description: 'Are you sure you want to reset the password of this user?',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      confirm_text: 'Reset Password',
      cancel_text: 'Cancel',
    },
  },
  customer_list: {
    section_title: 'Customer List',
  },
  customer_details: {
    section_title: 'Customer Details',
    name_label: 'Legal Name',
    dob_label: 'Date of Birth',
    gender_label: 'Gender',
    nationality_label: 'Nationality',
    mobile_label: 'Mobile Number',
    email_label: 'Email ID',
    kyc_status: {
      approved: 'KYC Approved',
    },
    edit_modal: {
      button_text: 'Edit Profile',
      title: 'Edit User',
      name_label: 'Full Name',
      name_placeholder: 'Enter name',
      name_error: 'Please input name',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      mobile_label: 'Mobile Number',
      mobile_placeholder: 'Enter mobile number',
      mobile_error: 'Please input valid number.',
      nationality_label: 'Nationality',
      nationality_placeholder: 'Select Nationality',
      confirm_text: 'Update Customer',
    },
    password_modal: {
      button_text: 'Reset Password',
      title: 'Reset Password',
      description: 'Are you sure you want to reset the password of this user?',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      confirm_text: 'Delete',
      cancel_text: 'Cancel',
    },
    wallet: {
      title: 'Wallet Details',
      balance: 'Current Wallet Balance',
      recent_transactions: 'Recent Transaction',
      recent_transactions_cta: 'View All',
    },
    work_permit: {
      title: 'Work Permit (Singapore)',
      cta: 'View Documents',
      permit_label: 'Work Permit No',
      issue_label: 'Date of Issue',
      fin: 'FIN',
      address: 'Work Address',
    },
    passport: {
      title: 'Passport Details',
      cta: 'View Documents',
    },
    national_identity: 'National Identity Details',
  },
  kyc_details: {
    section_title: 'KYC Details',
    user_detail_title: 'User KYC Details',
    document_title: 'KYC Documents',
    name_label: 'Legal Name',
    dob_label: 'Date of Birth',
    gender_label: 'Gender',
    nationality_label: 'Nationality',
    mobile_label: 'Mobile Number',
    email_label: 'Email ID',
    kyc_status: {
      approved: 'KYC Approved',
    },
    work_permit: {
      title: 'Work Permit (Singapore)',
      cta: 'View Documents',
      permit_label: 'Work Permit No',
      issue_label: 'Date of Issue',
      fin: 'FIN',
      address: 'Work Address',
    },
    passport: {
      title: 'Passport Details',
      cta: 'View Documents',
    },
    national_identity: 'National Identity Details',
  },
  wallet_details: {
    section_title: 'Wallet Details',
    total_amount: 'Total Wallet Transaction',
    today_transaction: "Today's Transaction",
    month_transactions: 'This Month Transaction',
    total_users: 'Total wallet Users',
    active_users: 'Active Wallet Users',
    inactive_users: 'Active Wallet Users',
  },
  p2p_transactions: {
    section_title: 'P2P Transactions',
    details_modal: {
      title: 'Transaction Details',
      received_from: 'Received from',
      amount: 'Amount',
      date_time: 'Date & Time',
      status: 'Status',
      credit_title: 'Credited to wallet',
      transaction_id: 'Transaction ID',
      to: 'To',
      from: 'From',
    },
  },
  remittance_list: {
    section_title: 'Remittance List',
    details_modal: {
      title: 'Remittance Details',
      paid_to: 'Paid To',
      mobile: 'Mobile Number',
      account_holder_name: 'Account Holder Name',
      account: 'Account Number',
      bank: 'Bank',
      bank_code: 'Bank Code',
      transferred: 'Transferred Amount',
      received: 'Received Amount',
      fees: 'Transfer Fees',
      total_debited: 'Total Debited Amount from Wallet',
      remark: 'Remarks',
      debited_title: 'Debited from your Wallet',
      from: 'From',
      transaction_id: 'Payment ID',
    },
  },
  igw_review: {
    legal_name: "Legal Name",
    mobile_number: "Mobile Number",
    email: "Email (Optional)",
    residential_address: "Residential Address",
    agent_code: "Agent Code",
    date_of_birth: "Date of Birth",
    place_of_birth: "Place of Birth",
    nationality_choices: "Nationality",
    ethnicity_choices: "Ethnicity",
    martial_choices: "Martial Status",
    religion_choices: "Religion",
    gender_choices: "Gender",
    hobbies_choices: "Hobbies",
    ktp_id_card: "Identity card (KTP) No.",
    birth_certificate_number: "Birth Certificate No.",
    kk_family_card: "Family Card (KK) No.",
    passport_status: "Do you have a passport?",
    passport_number: "Passport No.",
    passport_date_of_expiry: "Date of Expiry",
    experience_sector: "Experience Sector",
    additional_skills: "Additional Skills (Optional)",
    language_ability: "Language ability",
    highest_level_of_eduaction: "Highest level of education",
    school_name: "School Name",
    upskilling_interest: "Upskilling interest",
    local_working_experience: "Local Working Experience",
    worked_overseas_before: "Have you worked overseas before?",
    overseas_working_experience: "Overseas Working Experience",
    overseas_working_description: "Please elaborate further.",
    driving_license: "Do you have a Driver’s License?",
    driving_license_class: "Driver License Class",
    driving_license_country: "Please specify driving licence country",
    job_interest: "Job Interest",
    any_other_information_to_add: "Any other information to add (Optional)",
    name_placeholder: 'Enter Name',
  }
};

export default translationEnglish;
