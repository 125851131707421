export const LIST_ROLES = 'LIST_ROLES';
export const LIST_ROLES_SUCCESS = 'LIST_ROLES_SUCCESS';
export const LIST_ROLES_FAILED = 'LIST_ROLES_FAILED';

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILED = 'GET_ROLE_FAILED';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

export const RESET_ROLE_PERMISSION = 'RESET_ROLE_PERMISSION';
