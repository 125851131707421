import React from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumbs';
import { BiPlusCircle } from 'react-icons/bi';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import OrgnisationsTable from './organisation-table';
import { CheckPermission } from '../../components/check-permission';

function Organisations() {
  const { t } = useTranslation();
  let title = t('organisation.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'Organisations',
      url: '/organisations/list',
      last: true,
    },
  ];
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <CheckPermission permission="organization_management">
        <div className="mb-6 flex w-full">
          <Link to="/organisations/create" className="lg:ml-auto">
            <Button
              icon={<BiPlusCircle className="mr-2 w-6 h-6" />}
              type="success"
              size="large"
              className="flex items-center ml-auto"
            >
              {t('add_organisation.button_text')}
            </Button>
          </Link>
        </div>
      </CheckPermission>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <OrgnisationsTable />
      </Card>
    </>
  );
}

export default Organisations;
