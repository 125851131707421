import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { Card, Modal, Form, Input, Button, Select, DatePicker, TimePicker, Skeleton, Space, message } from 'antd';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';
import { viewOrganisationSubscriberAction } from '../../store/actions/organisationActions';
import { viewOrgMessageAction, editOrgMessageAction } from '../../store/actions/orgMessageActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useDebounce from '../../hooks/useDebounce';
import { useParams } from 'react-router-dom';
import { fileUploadHandler, imageUploadHandler } from '../../utils/upload';
import { BiFile } from 'react-icons/bi';
import { resetFileUploadAction, resetImageUploadAction } from '../../store/actions/uploadAction';
import { isEmpty } from '../../utils/generic';
import QuillEditor from '../../components/quill-editor';
import { listCustomersAction } from '../../store/actions/customerActions';

function EditOrgMessage() {
  const { t } = useTranslation();
  let title = 'Edit Message';
  const { message_uuid } = useParams();
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = moment.tz(zone).format('Z');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('org_message.section_title'),
      url: '/organisations/messages',
      last: false,
    },
    { home: false, title: 'Edit', url: '/', last: true },
  ];
  const { Option } = Select;
  const [searchSubscribers, setSearchSubscribers] = useState('');
  const debouncedSubsSearchValue = useDebounce(searchSubscribers, 500);
  let orgSubscribersInitialValue = {
    organization_uuid: '',
    page: 1,
    search: '',
    ordering: '',
    page_size: 100,
    created_at_after: '',
    created_at_before: '',
  };
  let usersInitialValue = {
    page: 1,
    search: "",
    ordering: "",
    page_size: 50,
    created_at_after: "",
    created_at_before: ""
  }
  const [scheduleMessageModal, setScheduleMessageModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const [defaultOrgSubscribers, setDefaultOrgSubscribers] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedDefaultUsers, setSelectedDefaultUsers] = useState([])
  let filtered_recipients = [];

  const dispatch = useDispatch();
  const { upload, organisation, orgMessages, customers } = useSelector(
    (state) => ({
      upload: state.upload,
      organisation: state.organisation,
      orgMessages: state.orgMessages,
      customers: state.customers
    }),
    shallowEqual
  );
  const { uploadFile, uploadFileLoading, uploadImage, uploadImageLoading } = upload;
  const { orgSubscribers, orgSubscribersLoading } = organisation;
  const { currentMessage, currentMessageLoading } = orgMessages;
  const { customersList, customersListLoading } = customers
  const [currentVideo, setCurrentVideo] = useState('');
  const [createMessageFormData, setCreateMessageFormData] = useState({
    title: currentMessage?.title,
    images: [],
    videos: [],
    files: [],
    recipient: []
  });

  let subscribedUsers = [];
  useEffect(() => {
    dispatch(viewOrgMessageAction(message_uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCreateMessageFormData({
      ...createMessageFormData,
      title: currentMessage?.title,
      body: currentMessage?.body,
      organization: currentMessage?.org_uuid,
      images: !isEmpty(currentMessage?.images) ? currentMessage?.images : [],
      files: !isEmpty(currentMessage?.files) ? currentMessage?.files : [],
      videos: !isEmpty(currentMessage?.videos) ? currentMessage?.videos : [],
      cta_text: currentMessage?.cta_text,
      cta_link: currentMessage?.cta_link
    })

    currentMessage?.recipient &&
      currentMessage.recipient.forEach(item => {
        subscribedUsers.push(item.id)
      })
    setDefaultOrgSubscribers(subscribedUsers)

    currentMessage?.org_uuid &&
      dispatch(
        viewOrganisationSubscriberAction({
          ...orgSubscribersInitialValue,
          organization_uuid: currentMessage?.org_uuid,
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessage])

  useEffect(() => {
    if (!isEmpty(currentMessage?.recipient) && !isEmpty(orgSubscribers)) {
      let updatedUsers = currentMessage?.recipient.filter(item =>
        !orgSubscribers?.results.some(subItem =>
            subItem.subscriber_id === item.id
        )
      );
      updatedUsers = updatedUsers.filter(item => (item.id !== "all_users"))
      updatedUsers = updatedUsers.filter(item => (item.id !== "all_subscribers"))
      setSelectedDefaultUsers(updatedUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSubscribers])

  useEffect(() => {
    dispatch(resetImageUploadAction());
    dispatch(resetFileUploadAction());
    if (debouncedSubsSearchValue !== '') {
      const timer = setTimeout(() => {
        dispatch(listCustomersAction({
          ...usersInitialValue,
          search: debouncedSubsSearchValue,
        }))
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else {
      currentMessage?.org_uuid &&
        dispatch(listCustomersAction({
          ...usersInitialValue,
          search: debouncedSubsSearchValue
        }))
    }
  }, [debouncedSubsSearchValue]); // eslint-disable-line


  const currentMessageInitialFormValue = {
    organization: currentMessage?.organization,
    title: currentMessage?.title,
    recipient: defaultOrgSubscribers,
    body: currentMessage?.body,
    videos: currentVideo || currentMessage?.videos?.[0],
    cta_text: currentMessage?.cta_text,
    cta_link: currentMessage?.cta_link,
  };

  let recipients = createMessageFormData?.recipient;

  const validateMessageForm = () => {
    let title = createMessageFormData.title;
    let body = createMessageFormData.body;
    let organisation = createMessageFormData.organisation;
    let recipient = filtered_recipients;
    if (
      title === '' ||
      body === '' ||
      organisation === '' ||
      recipient.length === 0
    ) {
      message.error('Please provide all required fields');
      setScheduleMessageModal(false);
      return false;
    } else {
      return true;
    }
  };

  const validateRecipients = () => {
    if (isEmpty(selectedRecipients)) {
      filtered_recipients = defaultOrgSubscribers.map(item => item)
    }
    selectedRecipients && selectedRecipients.map(item => {
      return filtered_recipients.push(item)
    })
    if (filtered_recipients.includes('all_users'))
      filtered_recipients = ['all_users']
    else if (filtered_recipients.includes('all_subscribers'))
      filtered_recipients = ['all_subscribers']
    else {
      filtered_recipients.filter(item => {
        return item !== 'all_users' || item !== 'all_subscribers'
      })
    }
  }

  const sendMessageFormHandler = ({ body }) => {
    validateRecipients()
    validateMessageForm();
    const messageFormData = {
      ...createMessageFormData,
      body,
      images: !isEmpty(uploadImage) ? [uploadImage] : createMessageFormData.images,
      files: !isEmpty(uploadFile) ? [uploadFile] : createMessageFormData.files,
      recipient: filtered_recipients,
      videos: !isEmpty(currentVideo) ? [currentVideo] : createMessageFormData.videos
    };

    if (buttonClicked === 'send') {
      dispatch(
        editOrgMessageAction(message_uuid, {
          ...messageFormData,
          status: 1,
        })
      );
    }
    if (buttonClicked === 'draft') {
      dispatch(
        editOrgMessageAction(message_uuid, {
          ...messageFormData,
          status: 2,
        })
      );
    }
  };
  const scheduleMessageFormHandler = ({ date, time }) => {
    validateRecipients()
    const validated = validateMessageForm();
    const schedule_datetime = `${moment(date._d).format('YYYY-MM-DD')} ${moment(
      time._d
    ).format('HH:mm:ss')}.00${timezone}`;
    if (validated) {
      console.log("click")
      setScheduleMessageModal(false);
      dispatch(
        editOrgMessageAction(message_uuid, {
          ...createMessageFormData,
          status: 3,
          images: !isEmpty(uploadImage) ? [uploadImage] : createMessageFormData.images,
          files: !isEmpty(uploadFile) ? [uploadFile] : createMessageFormData.files,
          recipient: filtered_recipients,
          videos: !isEmpty(currentVideo) ? [currentVideo] : createMessageFormData.videos,
          schedule_datetime,
        })
      );
    }
  };
  const listSubscriberSearchHandler = (value) => {
    setSearchSubscribers(value);
  };

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
        <div className="w-full lg:w-2/3">
          {currentMessageLoading || orgSubscribersLoading ? (
            <div className="px-6 pb-6">
              <Skeleton active paragraph={{ rows: 12 }} />
            </div>
          ) : (
            <Card>
              <Form
                name="editMessage"
                autoComplete="off"
                layout="vertical"
                onFinish={sendMessageFormHandler}
                initialValues={currentMessageInitialFormValue}
              >
                <Form.Item
                  className="mb-6"
                  name="organization"
                  label={t('org_message.create.org_label')}
                >
                  <Input size="large" disabled />
                </Form.Item>

                <Form.Item
                  className="mb-6"
                  name="recipient"
                  label={t('org_message.create.recipients_label')}
                  rules={[
                    {
                      required: isEmpty(defaultOrgSubscribers),
                      message: 'Please select recipient!',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    filterOption={false}
                    showSearch
                    placeholder={t('org_message.create.recipients_placeholder')}
                    onSearch={listSubscriberSearchHandler}
                    onChange={(values, items) => {
                      setSelectedRecipients(values)
                    }}
                  >

                    {orgSubscribersLoading || customersListLoading ? (
                      <Option>Loading...</Option>
                    ) : (
                      <>
                        <Option
                          disabled={selectedRecipients.length > 2 &&
                            selectedRecipients.includes('all_subscribers')
                          }
                          value="all_users"
                          key="all_users"
                          id="all_users"
                        >
                          {recipients?.length > 0 &&
                            !recipients?.includes('all_users') ? (
                            <del>All Users</del>
                          ) : (
                            'All Users'
                          )}
                        </Option>
                        <Option
                          disabled={
                            selectedRecipients.length > 2 &&
                            selectedRecipients.includes('all_users')
                          }
                          value="all_subscribers"
                          key="all_subscribers"
                          id="all_subscribers"
                        >
                          {recipients?.length > 0 &&
                            !recipients?.includes('all_subscribers') ? (
                            <del>All Subscribers</del>
                          ) : (
                            'All Subscribers'
                          )}
                        </Option>


                        {(isEmpty(orgSubscribers?.results) && isEmpty(debouncedSubsSearchValue)) ? (
                          <Option disabled value="none">
                            No subscriber found
                          </Option>
                        ) :
                          isEmpty(debouncedSubsSearchValue) ?
                            orgSubscribers?.results?.map((item) => (
                              <Option
                                disabled={
                                  (selectedRecipients?.includes('all_subscribers')) || selectedRecipients?.includes('all_users')
                                }
                                key={item?.subscriber_id}
                                value={item?.subscriber_id}
                              >
                                {item?.name} (+{item?.mobile_country}{" "}{item?.mobile})
                              </Option>
                            )) :
                            customersList?.results?.map((item) => (
                              <Option
                                disabled={
                                  (selectedRecipients?.includes('all_subscribers')) || selectedRecipients?.includes('all_users')
                                }
                                key={item?.id}
                                value={item?.id}
                              >
                                {item?.name} (+{item?.mobile_country}{" "}{item?.mobile})
                              </Option>
                            ))
                        }
                        {
                          selectedDefaultUsers &&
                          selectedDefaultUsers.map(item => (
                            <Option
                              disabled={
                                (selectedRecipients?.includes('all_subscribers')) || selectedRecipients?.includes('all_users')
                              }
                              key={item?.id}
                              value={item?.id}
                            >
                              {item?.name}
                            </Option>
                          ))
                        }
                      </>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  className="mb-6"
                  name="title"
                  label={t('org_message.create.msg_title_label')}
                  rules={[
                    {
                      required: true,
                      message: 'Please provide message title!',
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9]*[^=+@<>-]*$/),
                      message: "This field does not accept =, +, -, @, <, >"
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t('org_message.create.msg_title_placeholder')}
                    onChange={(e) => {
                      setCreateMessageFormData({
                        ...createMessageFormData,
                        title: e.target.value
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="mb-6"
                  name="body"
                  label={t('org_message.create.msg_content_label')}
                  tooltip="Max 1000 characters"
                  rules={[
                    {
                      required: true,
                      message: 'Please write message body',
                    },
                  ]}
                >
                  <QuillEditor value={currentMessage?.body} onChange={(body) => {
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      body: body
                    });
                  }} />
                </Form.Item>
                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6 file-uploader"
                      name="images"
                      label="Edit Images"
                      tooltip="Image Resolution has to be less than 800*450px. Image file size has to be less than 1MB"
                    >
                      <Input
                        size="large"
                        placeholder={t('org_message.create.image_placeholder')}
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={imageUploadHandler}
                      />
                      {uploadImageLoading && 'Uploading...'}
                      {uploadImage ? (
                        <img
                          src={uploadImage}
                          alt=""
                          className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
                        />
                      ) : !isEmpty(currentMessage?.images?.[0]) ? (
                        <img
                          src={currentMessage?.images?.[0]}
                          alt=""
                          className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
                        />
                      ) : null}
                    </Form.Item>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6 file-uploader"
                      name="files"
                      label="Edit File"
                      tooltip="File size has to be less than 20MB"
                    >
                      <Input
                        size="large"
                        placeholder={t('org_message.create.image_placeholder')}
                        type="file"
                        accept="application/pdf"
                        onChange={fileUploadHandler}
                      />
                      {uploadFileLoading && 'Uploading...'}
                      {uploadFile ? (
                        <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                          <BiFile className="text-blue-link text-lg" />
                          <a
                            className="text-sm font-bold text-blue-link pl-2 truncate"
                            href={uploadFile}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            File Attachment
                          </a>
                        </div>
                      ) : !isEmpty(currentMessage?.files?.[0]) ? (
                        <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                          <BiFile className="text-blue-link text-lg" />
                          <a
                            className="text-sm font-bold text-blue-link pl-2 truncate"
                            href={`${currentMessage?.files?.[0]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            File Attachment
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6"
                      name="videos"
                      label="Edit Video URL"
                      rules={[
                        {
                          type: 'url',
                          message: 'This field must be a valid url.',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t('org_message.create.video_placeholder')}
                        onChange={(e) => setCurrentVideo(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                        className="mb-6"
                        name="cta_text"
                        label="CTA Text"
                    >
                      <Input
                          size="large"
                          placeholder="CTA Text"
                          type="text"
                          onChange={(e) =>
                              setCreateMessageFormData({
                                ...createMessageFormData,
                                cta_text: e.target.value,
                              })
                          }
                      />
                    </Form.Item>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                        className="mb-6"
                        name="cta_link"
                        label="CTA Link"
                    >
                      <Input
                          size="large"
                          placeholder="CTA Link"
                          type="text"
                          onChange={(e) =>
                              setCreateMessageFormData({
                                ...createMessageFormData,
                                cta_link: e.target.value,
                              })
                          }
                      />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item className="mb-3">
                  <div className="flex flex-col lg:flex-row lg:justify-between lg:space-x-8 text-center mt-4">
                    <Button
                      type="success"
                      className="w-full lg:w-1/3 mb-4 lg:mb-0"
                      size="large"
                      onClick={() => setButtonClicked('send')}
                      htmlType="submit"
                    >
                      {t('org_message.create.send_text')}
                    </Button>
                    <Button
                      type="warning"
                      className="w-full lg:w-1/3 mb-4 lg:mb-0"
                      size="large"
                      htmlType="submit"
                      onClick={() => setButtonClicked('draft')}
                    >
                      {t('org_message.create.draft_text')}
                    </Button>
                    <Button
                      type="primary"
                      className="w-full lg:w-1/3 mb-4 lg:mb-0"
                      size="large"
                      htmlType="submit"
                      onClick={() => setScheduleMessageModal(true)}
                    >
                      {t('org_message.create.schedule_text')}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          )}
        </div>
        <div className="flex justify-center w-full lg:w-1/3">
          <div className="mockup-phone">
            <div className="message">
              {currentMessageLoading ? (
                <Space>
                  <Skeleton.Image className="mr-2 mb-1" />
                  <Skeleton.Input
                    style={{ width: 200 }}
                    active={currentMessageLoading}
                  />
                </Space>
              ) : (
                <div className="message-header">
                  <div className="flex items-center mb-2">
                    {currentMessage ? (
                      <div className="logo">
                        <img src={currentMessage?.org_logo} alt="logo" />
                      </div>
                    ) : (
                      <Skeleton.Image className="mr-2 mb-1" />
                    )}

                    <div className="message-sender">
                      <div className="font-bold text-base mb-2 leading-none">
                        {currentMessageLoading
                          ? 'Organisation Name'
                          : currentMessage?.organization}
                      </div>
                      <div className="text-xs text-grey-600">
                        {createMessageFormData?.organization
                          ? new Date().toLocaleString() + ''
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="message-header-title text-base font-bold leading-5 border-b border-grey-300 pb-4 mb-4">

                    {createMessageFormData?.title}
                  </div>
                </div>
              )}
              <div className="message-content">
                <div
                  className="pb-4"
                  dangerouslySetInnerHTML={{
                    __html: createMessageFormData?.body
                  }}
                ></div>
                {uploadImage ? (
                  <img
                    src={uploadImage}
                    alt=""
                    className="w-full h-auto p-2 mt-3 border border-grey-300 mx-auto rounded"
                  />
                ) : !isEmpty(currentMessage?.images?.[0]) ? (
                  <img
                    src={currentMessage?.images?.[0]}
                    alt=""
                    className="w-full h-auto p-2 mt-3 border border-grey-300 mx-auto rounded"
                  />
                ) : null}
              </div>
              <div className="message-footer px-4 pb-5">
                {currentMessage?.videos?.[0] || currentVideo ? (
                  <ReactPlayer
                    className="youtube-embed"
                    width="100%"
                    height="auto"
                    url={
                      currentVideo ? currentVideo : currentMessage?.videos?.[0]
                    }
                    config={{ youtube: { playerVars: { showinfo: 1 } } }}
                  />
                ) : (
                  ''
                )}
                {uploadFile ? (
                  <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                    <BiFile className="text-blue-link text-lg" />
                    <a
                      className="text-sm font-bold text-blue-link pl-2 truncate"
                      href={uploadFile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File Attachment
                    </a>
                  </div>
                ) : !isEmpty(currentMessage?.files?.[0]) ? (
                  <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                    <BiFile className="text-blue-link text-lg" />
                    <a
                      className="text-sm font-bold text-blue-link pl-2 truncate"
                      href={`${currentMessage?.files?.[0]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File Attachment
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={scheduleMessageModal}
        onOk={() => setScheduleMessageModal(false)}
        onCancel={() => setScheduleMessageModal(!scheduleMessageModal)}
        footer={false}
        centered
      >
        <div className="py-4">
          <div className="text-xl font-bold mb-4 text-center">
            Schedule Message
          </div>
          <Form
            name="createMessage"
            autoComplete="off"
            layout="vertical"
            onFinish={scheduleMessageFormHandler}
          >
            <Form.Item
              className="mb-6"
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}
            >
              <DatePicker
                className="w-full"
                size="large"
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  current && current < moment().startOf('day')
                }
              />
            </Form.Item>
            <Form.Item
              className="mb-8"
              name="time"
              label={`Time (Timezone: ${zone}/${timezone})`}
              format="HH:mm:ss"
              rules={[
                {
                  required: true,
                  message: 'Please select time!',
                },
              ]}
            >
              <TimePicker
                className="w-full"
                size="large"
                disabledTime={(current) =>
                  current && current < moment().startOf('minute')
                }
              />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-between">
                <Button
                  size="large"
                  type="success"
                  className="w-64 mr-4"
                  htmlType="submit"
                  onClick={() => setButtonClicked('schedule')}
                >
                  Schedule Message
                </Button>
                <Button
                  size="large"
                  onClick={() => setScheduleMessageModal(false)}
                  className="w-64"
                >
                  Close
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default EditOrgMessage;
