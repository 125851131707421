import React, { useState } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { Drawer, Divider, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { dateFormat, utcToLocalDateTime } from '../../utils/dateFormat';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionAction } from '../../store/actions/transactionActions';
import { getTransactionDetailStatus } from '../../utils/get-transaction-status';
import { isEmpty } from '../../utils/generic';
import { TransactionSkeleton } from '../../components/skeleton/WidgetSkeleton'

function TransactionDetails({ transactionId, transactionDetails }) {
  const { t } = useTranslation();
  function renderIcon(method) {
    switch (method) {
      case 1:
        return <img alt="" src="/icons/ic_topup.svg" />;
      case 2:
        return <img alt="" src="/icons/ic_transfer.svg" />;
      case 3:
        return <img alt="" src="/icons/ic_remittance.svg" />;
      default:
        return;
    }
  }
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  const transactionDetailsHandler = () => {
    setDrawerVisible(true);
    dispatch(getTransactionAction(transactionId));
  };
  const { getTransaction, getTransactionLoading, getTransactionError } =
    useSelector((state) => state.transaction);


  function renderReceiverDetails(transaction){

    if(2 === transaction?.category){

      return(
          <div>
            <div className="font-bold text-base">
              {transactionDetails?.recipient_user?.name}
            </div>
            <div>{transactionDetails?.recipient_user?.mobile}</div>
          </div>
      )

    }

    if(3 === transaction?.category){
      return(
          <div>
            <div className="font-bold text-base">
              {transactionDetails?.recipient_user?.name}
            </div>
            <div>{transactionDetails?.recipient_user?.account_number}</div>
          </div>
      )
    }

    return(
        <div>
          <div className="font-bold text-base">
            {transactionDetails?.recipient_user?.name}
          </div>
          <div>{transactionDetails?.recipient_user?.mobile}</div>
        </div>
    )
  }

  return (
    <>
      {isEmpty(transactionDetails) ? (
        <div
          className="text-blue-link font-bold cursor-pointer"
          onClick={transactionDetailsHandler}
        >
          View Details
        </div>
      ) : (
        <div
          key={getTransaction?.id}
          className="recent-trans flex justify-between items-center py-3 border-b cursor-pointer"
          onClick={transactionDetailsHandler}
        >
          <div className="flex items-center">
            <div className="rounded-full mr-4">
              {renderIcon(transactionDetails?.category)}
            </div>
            {renderReceiverDetails(transactionDetails)}
          </div>
          <div className="flex items-center">
            <div className="mr-3">
              <div className="font-bold text-base">
                SGD {transactionDetails?.amount}
              </div>
              <div>{dateFormat(transactionDetails?.created_at)}</div>
            </div>
            <BiChevronRight className="w-5 h-5" />
          </div>
        </div>
      )}
      <Drawer
        title="Transaction Details"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        {getTransactionError ? (
          <div>Failed to get transaction</div>
        ) : getTransactionLoading ? (
          <div className="grid grid-cols-1 justify-items-center text-center">
            <TransactionSkeleton />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-y-2 justify-items-center text-center">
              <Avatar>
                {getTransaction?.sender_user?.name?.trim().slice(0, 1) || 'U'}
              </Avatar>
              <div className="text-gray-600">Sender</div>
              <div className="text-base font-bold">
                {getTransaction?.sender_user?.name || 'From Bank'}{' '}
              </div>
              <div className="text-sm font-bold">
                {getTransaction?.sender_user?.mobile}
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.amount')}
                </div>
                <div className="text-3xl font-bold flex">
                  SGD
                  <span
                    className={`text-${getTransaction?.status === 1
                      ? 'green'
                      : getTransaction?.status === 2
                        ? 'orange'
                        : 'red'
                      }-anak flex items-center mx-2`}
                  >
                    {getTransaction?.amount}
                  </span>
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.date_time')}
                </div>
                <div className="text-base font-bold">
                  {utcToLocalDateTime(getTransaction?.created_at)}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.status')}
                </div>
                {getTransactionDetailStatus(getTransaction?.status)}
              </div>
            </div>
            {getTransaction?.status === 1 && (
              <>
                <Divider orientation="center" className="font-bold">
                  {t('p2p_transactions.details_modal.credit_title')}
                </Divider>
              </>
            )}

            <div className="pb-4 grid grid-cols-1 gap-y-2 justify-items-center text-center">
              <div className="pt-4">
                <div className="text-gray-600">
                  {t('p2p_transactions.details_modal.to')}
                </div>
                <div className="text-base font-bold">
                  {getTransaction?.recipient_user?.name}
                </div>
                <div className="text-sm font-bold">
                  {[1,2].includes(getTransaction?.category) && `VBN - ${getTransaction?.recipient_user?.wallet_id}`}
                  {3===getTransaction?.category && `${getTransaction?.recipient_user?.account_number}`}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600">
                  {t('p2p_transactions.details_modal.from')}
                </div>
                <div className="text-base font-bold">
                  {getTransaction?.sender_user?.name}
                </div>
                <div className="text-sm font-bold">
                    {1 === getTransaction?.category && `${getTransaction?.sender_user?.accountNumber}`}
                    {[2,3].includes(getTransaction?.category) && `VBN - ${getTransaction?.sender_user?.wallet_id}`}
                </div>
              </div>
              {getTransaction?.transfer?.comments && (
                <>
                  <div className="pt-3">
                    <div className="text-gray-600 mb-1">
                      Remarks
                    </div>
                    <div className="text-base font-bold">
                      {getTransaction?.transfer?.comments}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </Drawer>
    </>
  );
}

export default TransactionDetails;
