import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { BiPlusCircle } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Drawer, Button, Spin, Table } from 'antd';
import TableUniversal from '../../components/table';
import {
  createSystemUserAction,
  listSystemUsersAction,
} from '../../store/actions/systemUsersActions';
import { useTranslation } from 'react-i18next';
import {
  utcToLocalDate,
  utcToLocalDateTime,
  camelCaseString,
} from '../../utils/table-helper';
import { Link } from 'react-router-dom';
import { CheckPermission } from '../../components/check-permission';
import { getSystemUserStatus } from '../../utils/get-system-user-status';
import { isEmpty } from '../../utils/generic';
import { listRoles } from '../../store/actions/rolesPermissionActions';

function SystemUsers() {
  const initialTableParams = {
    page: 1,
    search: '',
    role: '',
    ordering: '-created_at',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [
    {
      key: 1,
      value: 'Super Admin',
    },
    {
      key: 2,
      value: 'Moderator',
    },
    {
      key: 3,
      value: 'Compliance Admin',
    },
  ];
  const csvExportHeader = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'user_detail.email' },
    { label: 'Role', key: 'user_detail.role[0]' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Created By', key: 'created_by' },
    { label: 'Last Login', key: 'user_detail.last_login' },
    { label: 'Status', key: 'status' },
    { label: 'User Id', key: 'uuid' },
  ];

  // const downloadUrl = 'user';

  const dispatch = useDispatch();
  const { systemUsers, rolesPermission } = useSelector(
    (state) => ({
      systemUsers: state.systemUsers,
      rolesPermission: state.rolesPermission,
    }),
    shallowEqual
  );
  const { usersList, userListLoading, userListError, createUserLoading } =
    systemUsers;
  const { roles, rolesLoading } = rolesPermission;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const [addUserForm] = Form.useForm();
  let title = t('system_users.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Administration',
      url: '/',
      last: false,
    },
    { home: false, url: '/', last: true, title },
  ];
  const { Option } = Select;
  const { Column } = Table;
  const addSystemUserHandler = (values) => {
    dispatch(createSystemUserAction(values));
    setIsDrawerOpen(false);
    addUserForm.resetFields();
  };
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <CheckPermission permission="system_user_management">
        <div className="flex lg:flex-row-reverse mb-6">
          <Button
            type="success"
            size="large"
            className="flex items-center"
            onClick={() => {
              addUserForm.resetFields();
              setIsDrawerOpen(true);
            }}
            disabled={createUserLoading}
            icon={
              createUserLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      size={22}
                      className="mr-2 mb-1 text-blue-anak"
                      spin
                    />
                  }
                />
              ) : (
                <BiPlusCircle size={24} className="mr-2" />
              )
            }
          >
            {t('system_users.add_modal.button_text')}
          </Button>
          <Drawer
            title={t('system_users.add_modal.title')}
            placement="right"
            onClose={() => setIsDrawerOpen(false)}
            visible={isDrawerOpen}
            headerStyle={{
              fontWeight: 'bold',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Form
              layout="vertical"
              onFinish={addSystemUserHandler}
              autoComplete="off"
              form={addUserForm}
            >
              <Form.Item
                name="name"
                label={t('system_users.add_modal.name_label')}
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: t('system_users.add_modal.name_error'),
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]*[^=+@<>-]*$/),
                    message: "This field does not accept =, +, -, @, <, >"
                  }
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder={t('system_users.add_modal.name_placeholder')}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={t('system_users.add_modal.email_label')}
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: t('system_users.add_modal.email_error'),
                  },
                ]}
              >
                <Input
                  type="email"
                  size="large"
                  placeholder={t('system_users.add_modal.email_placeholder')}
                />
              </Form.Item>
              <Form.Item
                name="role"
                label="Role"
                className="mb-6"
                onClick={() => dispatch(listRoles('admin'))}
                rules={[
                  {
                    required: true,
                    message: 'Please select a role',
                  },
                ]}
              >
                <Select size="large" placeholder="Select user role">
                  {rolesLoading ? (
                    <Option value="loading">Loading...</Option>
                  ) : !isEmpty(roles?.results) ? (
                    roles?.results?.map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))
                  ) : (
                    <Option value="not-found">No roles found</Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item className="mb-6">
                <Button
                  type="success"
                  htmlType="submit"
                  className="w-full"
                  size="large"
                  block
                >
                  {t('system_users.add_modal.confirm_text')}
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        </div>
      </CheckPermission>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <TableUniversal
          initialTableParams={initialTableParams}
          menuFilter={menuFilter}
          menuFilterBy="role"
          dispatchAction={listSystemUsersAction}
          data={usersList}
          loading={userListLoading}
          error={userListError}
          csvExportHeader={csvExportHeader}
          downloadUrl="users"
        >
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            sorter={true}
            align="left"
            render={(text, record) => record?.name}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            render={(text, record) => record?.user_detail?.email}
          />
          <Column
            title="Role"
            dataIndex="role"
            key="role"
            render={(text, record) =>
              camelCaseString(record?.user_detail?.role[0])
            }
          />
          <Column
            title="Created At"
            dataIndex="created_at"
            key="created_at"
            sorter={true}
            render={(text, record) => utcToLocalDate(record?.created_at)}
            defaultSortOrder="descend"
          />
          {/* <Column
            title="Updated At"
            dataIndex="modified_at"
            key="modified_at"
            sorter={true}
            render={(text, record) => utcToLocalDate(record?.modified_at)}
          /> */}
          <Column
            title="Created By"
            dataIndex="created_by"
            key="created_by"
            render={(text, record) => record?.created_by}
          />
          <Column
            title="Last Login"
            dataIndex="last_login"
            key="last_login"
            sorter={true}
            render={(text, record) =>
              utcToLocalDateTime(record?.user_detail?.last_login)
            }
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(text, record) => getSystemUserStatus(record?.status)}
          />
          <Column
            className="text-sm px-2 py-3"
            title="Actions"
            dataIndex="action"
            key="action"
            fixed="right"
            render={(text, record) => (
              <Link to={`/system-users/${record.uuid}`}>View Details</Link>
            )}
            width={120}
          />
        </TableUniversal>
      </Card>
    </>
  );
}
export default SystemUsers;
