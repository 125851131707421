import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../../components/table';
import { listTopUpTransactions } from '../../../store/actions/transactionActions';
import { getTransactionStatus } from '../../../utils/get-transaction-status';
import TopUpTransactionDetails from './top-up-details';
import { utcToLocalDateTime } from '../../../utils/dateFormat';
import { getTransactionCategory } from '../../../utils/get-transaction-category';

function TopUpTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [
    {
      key: 1,
      value: 'Success',
    },
    {
      key: 2,
      value: 'Pending',
    },
    {
      key: 3,
      value: 'Failed',
    },
  ];
  const csvExportHeader = [
    { label: 'ID', key: 'id' },
    { label: 'Sender', key: 'sender_user.name' },
    { label: 'Receiver', key: 'recipient_user.name' },
    { label: 'Amount', key: 'amount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Transaction Type', key: 'category' },
    { label: 'Date & Time', key: 'created_at' },
    { label: 'Status', key: 'status' },
  ];
  const requestParams = {
    category: 1
  }
  const { topUpList, topUpListLoading, topUpListError } = useSelector(
    (state) => state.transaction
  );
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listTopUpTransactions}
      data={topUpList}
      loading={topUpListLoading}
      error={topUpListError}
      csvExportHeader={csvExportHeader}
      downloadUrl="transaction"
      requestParams= {requestParams}
    >
      <Column
        title="Sender"
        dataIndex="sender_user"
        key="sender_user"
        align="left"
        // render={(text, record) => record?.sender_user?.name}
        render={(text, record) => 'From Bank'}
      />
      <Column
        title="Receiver"
        dataIndex="recipient_user"
        key="recipient_user"
        align="left"
        render={(text, record) => record?.recipient_user?.name}
      />
      <Column
        title="Amount"
        dataIndex="amount"
        key="amount"
        align="left"
        sorter={true}
        render={(text, record) => `${record?.amount} ${record?.currency}`}
      />
      <Column
        title="Transaction Type"
        dataIndex="category"
        key="category"
        render={(text, record) => getTransactionCategory(record?.category)}
      />
      <Column
        title="Date & Time"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getTransactionStatus(record?.status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <TopUpTransactionDetails transactionId={record?.id} />
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default TopUpTable;
