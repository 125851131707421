import * as types from '../actionTypes/organisations';

// ORGANISATION LISTING
export const listOrganisationAction = (tableParams) => {
  return {
    type: types.LIST_ORGANISATIONS,
    payload: tableParams,
  };
};
export const listOrganisationSuccessAction = (orgs) => {
  return {
    type: types.LIST_ORGANISATIONS_SUCCESS,
    payload: orgs,
  };
};
export const listOrganisationFailAction = (error) => {
  return {
    type: types.LIST_ORGANISATIONS_FAILED,
    payload: error,
  };
};

// CREATE ORGANISATION
export const createOrganisationAction = (values) => {
  return {
    type: types.CREATE_ORGANISATION,
    payload: values,
  };
};
export const createOrganisationSuccessAction = (org) => {
  return {
    type: types.CREATE_ORGANISATION_SUCCESS,
    payload: org,
  };
};
export const createOrganisationFailAction = (error) => {
  return {
    type: types.CREATE_ORGANISATION_FAIL,
    payload: error,
  };
};

// VIEW ORGANISATION
export const viewOrganisationAction = (organisations_uuid) => {
  return {
    type: types.VIEW_ORGANISATION,
    payload: organisations_uuid,
  };
};
export const viewOrganisationSuccessAction = (org) => {
  return {
    type: types.VIEW_ORGANISATION_SUCCESS,
    payload: org,
  };
};
export const viewOrganisationFailAction = (error) => {
  return {
    type: types.VIEW_ORGANISATION_SUCCESS,
    payload: error,
  };
};

//VIEW ORGANISATION SUBSCRIBER
export const viewOrganisationSubscriberAction = (payload) => {
  return {
    type: types.VIEW_ORGANISATION_SUBSCRIBER,
    payload,
  };
};
export const viewOrganisationSubscriberSuccessAction = (subscribers) => {
  return {
    type: types.VIEW_ORGANISATION_SUBSCRIBER_SUCCESS,
    payload: subscribers,
  };
};
export const viewOrganisationSubscriberFailAction = (error) => {
  return {
    type: types.VIEW_ORGANISATION_SUBSCRIBER_FAIL,
    payload: error,
  };
};

//VIEW ORGANISATION USERS
export const viewOrganisationUsersAction = (organisations_uuid) => {
  return {
    type: types.VIEW_ORGANISATION_USERS,
    payload: organisations_uuid,
  };
};
export const viewOrganisationUsersSuccessAction = (users) => {
  return {
    type: types.VIEW_ORGANISATION_USERS_SUCCESS,
    payload: users,
  };
};
export const viewOrganisationUsersFailAction = (error) => {
  return {
    type: types.VIEW_ORGANISATION_USERS_FAIL,
    payload: error,
  };
};

// EDIT ORGANISATION
export const editOrganisationAction = (organization_uuid, formValue) => {
  return {
    type: types.EDIT_ORGANISATION,
    payload: { formValue, organization_uuid },
  };
};
export const editOrganisationSuccessAction = (values) => {
  return {
    type: types.EDIT_ORGANISATION_SUCCESS,
    payload: values,
  };
};
export const editOrganisationFailAction = (error) => {
  return {
    type: types.EDIT_ORGANISATION_FAIL,
    payload: error,
  };
};

// DELETE ORGANISATION
export const deleteOrganisationAction = (organisations_uuid) => {
  return {
    type: types.DELETE_ORGANISATION,
    payload: organisations_uuid,
  };
};
export const deleteOrganisationSuccessAction = () => {
  return {
    type: types.DELETE_ORGANISATION_SUCCESS,
  };
};
export const deleteOrganisationFailAction = (error) => {
  return {
    type: types.DELETE_ORGANISATION_FAIL,
    payload: error,
  };
};
