import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as types from '../store/actionTypes/organisations';
import { message, notification } from 'antd';
import {
  listOrganisationsService,
  getOrganisationService,
  getOrganisationSubscriberService,
  getOrganisationUsersService,
  createOrganisationService,
  editOrganisationService,
  deleteOrganisationService,
} from '../services/organisationService';
import {
  listOrganisationSuccessAction,
  listOrganisationFailAction,
  viewOrganisationSuccessAction,
  viewOrganisationFailAction,
  createOrganisationSuccessAction,
  createOrganisationFailAction,
  editOrganisationSuccessAction,
  editOrganisationFailAction,
  deleteOrganisationSuccessAction,
  deleteOrganisationFailAction,
  viewOrganisationSubscriberSuccessAction,
  viewOrganisationSubscriberFailAction,
  viewOrganisationUsersSuccessAction,
  viewOrganisationUsersFailAction,
} from '../store/actions/organisationActions';
import history from '../utils/history';
import { resetImageUploadAction } from '../store/actions/uploadAction';

//Worker
export function* listOrganisations({ payload }) {
  try {
    const list = yield listOrganisationsService(payload);
    yield put(listOrganisationSuccessAction(list.data.data));
  } catch (error) {
    yield put(listOrganisationFailAction(error));
  }
}
export function* getOrganisationDetails({ payload: OrgCategoryUuid }) {
  try {
    const details = yield getOrganisationService(OrgCategoryUuid);
    yield put(viewOrganisationSuccessAction(details.data.data));
  } catch (error) {
    yield put(viewOrganisationFailAction(error));
  }
}
export function* getOrganisationSubscribers({ payload }) {
  try {
    const details = yield getOrganisationSubscriberService(payload);
    yield put(viewOrganisationSubscriberSuccessAction(details.data.data));
  } catch (error) {
    yield put(viewOrganisationSubscriberFailAction(error));
  }
}
export function* getOrganisationUsers({ payload }) {
  try {
    const users = yield getOrganisationUsersService(payload);
    yield put(viewOrganisationUsersSuccessAction(users.data.data));
  } catch (error) {
    yield put(viewOrganisationUsersFailAction(error));
  }
}
export function* createOrganisation({ payload: category_name }) {
  try {
    const create = yield createOrganisationService(category_name);
    yield put(createOrganisationSuccessAction(create));
    notification['success']({
      message: `Organisation created successfully.`,
      // description: 'Success description',
    });
    yield put(resetImageUploadAction());
    history.push('/organisations/list');
  } catch (error) {
    yield put(createOrganisationFailAction(error));
    message.error(error.response.data.detail);
  }
}
export function* editOrganisation({
  payload: { formValue, organization_uuid },
}) {
  try {
    const edit = yield editOrganisationService(organization_uuid, formValue);
    yield put(editOrganisationSuccessAction(edit));
    notification['success']({
      message: `Organisation updated successfully.`,
      description: 'Success description',
    });
    yield call(getOrganisationDetails, { payload: organization_uuid });
    history.push(`/organisations/list/${organization_uuid}`);
  } catch (error) {
    yield put(editOrganisationFailAction(error));
    notification['error']({
      message: 'Failed to update organisation.',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* deleteOrganisation({ payload: category_uuid }) {
  try {
    yield deleteOrganisationService(category_uuid);
    yield put(deleteOrganisationSuccessAction());
    notification['success']({
      message: `Organisation removed successfully.`,
      // description: 'Success description',
    });
    history.push('/organisations/list');
  } catch (error) {
    yield put(deleteOrganisationFailAction(error));
    notification['error']({
      message: 'Failed to delete organisation.',
      description: 'Please retry or contact administration.',
    });
  }
}

//Watcher
export function* onListOrganisationsRequest() {
  yield takeLatest(types.LIST_ORGANISATIONS, listOrganisations);
}
export function* onGetOrganisationDetails() {
  yield takeLatest(types.VIEW_ORGANISATION, getOrganisationDetails);
}
export function* onGetOrganisationSubscribers() {
  yield takeLatest(
    types.VIEW_ORGANISATION_SUBSCRIBER,
    getOrganisationSubscribers
  );
}
export function* onGetOrganisationUsers() {
  yield takeLatest(types.VIEW_ORGANISATION_USERS, getOrganisationUsers);
}
export function* onCreateOrganisation() {
  yield takeLatest(types.CREATE_ORGANISATION, createOrganisation);
}
export function* onEditOrganisation() {
  yield takeLatest(types.EDIT_ORGANISATION, editOrganisation);
}
export function* onDeleteOrganisation() {
  yield takeLatest(types.DELETE_ORGANISATION, deleteOrganisation);
}

export function* organisationSaga() {
  yield all([
    onListOrganisationsRequest(),
    onGetOrganisationDetails(),
    onGetOrganisationSubscribers(),
    onGetOrganisationUsers(),
    onCreateOrganisation(),
    onEditOrganisation(),
    onDeleteOrganisation(),
  ]);
}
