export const CUSTOMER_CASES_SUMMARY = 'CUSTOMER_CASES_SUMMARY';
export const CUSTOMER_CASES_SUMMARY_SUCCESS = 'CUSTOMER_CASES_SUMMARY_SUCCESS';
export const CUSTOMER_CASES_SUMMARY_FAILED = 'CUSTOMER_CASES_SUMMARY_FAILED';

export const LIST_CUSTOMER_CASES = 'LIST_CUSTOMER_CASES';
export const LIST_CUSTOMER_CASES_SUCCESS = 'LIST_CUSTOMER_CASES_SUCCESS';
export const LIST_CUSTOMER_CASES_FAILED = 'LIST_CUSTOMER_CASES_FAILED';

export const GET_CUSTOMER_CASE = 'GET_CUSTOMER_CASE';
export const GET_CUSTOMER_CASE_SUCCESS = 'GET_CUSTOMER_CASE_SUCCESS';
export const GET_CUSTOMER_CASE_FAILED = 'GET_CUSTOMER_CASE_FAILED';

export const UPDATE_CUSTOMER_CASE = 'UPDATE_CUSTOMER_CASE';
export const UPDATE_CUSTOMER_CASE_SUCCESS = 'UPDATE_CUSTOMER_CASE_SUCCESS';
export const UPDATE_CUSTOMER_CASE_FAILED = 'UPDATE_CUSTOMER_CASE_FAILED';

export const GET_CUSTOMER_CASE_HISTORY = 'GET_CUSTOMER_CASE_HISTORY';
export const GET_CUSTOMER_CASE_HISTORY_SUCCESS = 'GET_CUSTOMER_CASE_HISTORY_SUCCESS';
export const GET_CUSTOMER_CASE_HISTORY_FAILED = 'GET_CUSTOMER_CASE_HISTORY_FAILED';
export const RESET_CUSTOMER_CASE_HISTORY = 'RESET_CUSTOMER_CASE_HISTORY';