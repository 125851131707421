export const LIST_IGW_SERVICES = 'LIST_IGW_SERVICES';
export const LIST_IGW_SERVICES_SUCCESS = 'LIST_IGW_SERVICES_SUCCESS';
export const LIST_IGW_SERVICES_FAILED = 'LIST_IGW_SERVICES_FAILED';

export const GET_IGW_DETAILS_LIST = 'GET_IGW_DETAILS_LIST';
export const GET_IGW_DETAILS_LIST_SUCCESS = 'GET_IGW_DETAILS_LIST_SUCCESS';
export const GET_IGW_DETAILS_LIST_FAILED = 'GET_IGW_DETAILS_LIST_FAILED';

export const GET_IGW_DETAILS = 'GET_IGW_DETAILS';
export const GET_IGW_DETAILS_SUCCESS = 'GET_IGW_DETAILS_SUCCESS';
export const GET_IGW_DETAILS_FAILED = 'GET_IGW_DETAILS_FAILED';

export const EDIT_IGW_DETAILS = 'EDIT_IGW_DETAILS';
export const EDIT_IGW_DETAILS_SUCCESS = 'EDIT_IGW_DETAILS_SUCCESS';
export const EDIT_IGW_DETAILS_FAIL = 'EDIT_IGW_DETAILS_FAIL';