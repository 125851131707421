export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAIL = 'IMAGE_UPLOAD_FAIL';
export const RESET_IMAGE_UPLOAD = 'RESET_IMAGE_UPLOAD';

export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';
export const RESET_FILE_UPLOAD = 'RESET_FILE_UPLOAD';

export const IDENTIFIER_IMAGE_UPLOAD = 'IDENTIFIER_IMAGE_UPLOAD';
export const IDENTIFIER_IMAGE_UPLOAD_SUCCESS = 'IDENTIFIER_IMAGE_UPLOAD_SUCCESS';
export const IDENTIFIER_IMAGE_UPLOAD_FAIL = 'IDENTIFIER_IMAGE_UPLOAD_FAIL';