import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listOrgUsersAction } from '../../store/actions/orgUsersAction';
import { Link } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { getOrgUserStatus } from '../../utils/get-org-user-status';
import { camelCaseString } from '../../utils/string-helpers';

function OrgUserTable({ org_uuid }) {
  const initialTableParams = {
    page: 1,
    search: '',
    role: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    org_uuid,
  };
  const menuFilter = [
    {
      key: 1,
      value: 'Active',
    },
    {
      key: 2,
      value: 'Suspended',
    },
    {
      key: 3,
      value: 'Deleted',
    },
  ];
  const csvExportHeader = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'user_detail.email' },
    { label: 'Role', key: 'user_detail.role[0]' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Created By', key: 'created_by' },
    { label: 'Last Login', key: 'user_detail.last_login' },
    { label: 'Status', key: 'status' },
    { label: 'User Id', key: 'uuid' },
  ];
  const { usersList, userListLoading, userListError } = useSelector(
    (state) => state.orgUsers
  );
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listOrgUsersAction}
      data={usersList}
      loading={userListLoading}
      error={userListError}
      csvExportHeader={csvExportHeader}
      downloadUrl="organisation-users"
    >
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Email"
        dataIndex="email"
        key="email"
        render={(text, record) => record?.user_detail?.email}
      />
      <Column
        title="Role"
        dataIndex="role"
        key="role"
        render={(text, record) => camelCaseString(record?.user_detail?.role[0])}
      />
      <Column
        title="Created At"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      {/* <Column
        title="Updated At"
        dataIndex="modified_at"
        key="modified_at"
        sorter={true}
        render={(text, record) => dateFormat(record?.modified_at)}
      /> */}
      {/* <Column
        title="Created By"
        dataIndex="created_by"
        key="created_by"
        render={(text, record) => createdByFormat(record?.created_by)}
      /> */}
      <Column
        title="Organization"
        dataIndex="organization"
        key="organization"
        // sorter={true}
        align="left"
        render={(text, record) => record?.organization}
      />

      <Column
        title="Last Login"
        dataIndex="last_login"
        key="last_login"
        render={(text, record) =>
          utcToLocalDateTime(record?.user_detail?.last_login)
        }
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getOrgUserStatus(record?.status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/organisations/users/${record.uuid}`}>View Details</Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default OrgUserTable;
