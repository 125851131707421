import React, { useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { BiCheck, BiInfoCircle, BiTimeFive, BiUser } from 'react-icons/bi';
import { Card } from 'antd';
import Widget from '../../components/dashboard/widget-1';
// import { useTranslation } from 'react-i18next';
import CustomerCasesTable from './cases-table';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetSkeleton } from '../../components/skeleton/WidgetSkeleton';
import { formatNumber } from '../../utils/numbers';
import { getCasesSummaryAction, getCustomerCaseSuccessAction } from '../../store/actions/customerCasesAction';

function CustomerCases() {
  //   const { t } = useTranslation();
  const dispatch = useDispatch();
  let title = 'Customer Cases';
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Compliance',
      url: '/',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];
  const { casesSummary, casesSummaryLoading } = useSelector(
    (state) => state.customerCases
  );
  useEffect(() => {
    dispatch(getCasesSummaryAction());
    //set customer case data as null to avoid from address detail form showing incorrect values
    dispatch(getCustomerCaseSuccessAction(null));
  }, []); // eslint-disable-line
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4 mb-4">
        {casesSummaryLoading ? (
          Array(4)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100"
                >
                  <WidgetSkeleton />
                </div>
              );
            })
        ) : (
          <>
            <Widget
              title="Total Customer Cases"
              description={formatNumber(casesSummary?.total)}
              rightBorderColor="blue"
              right={
                <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-1">
                  <BiUser size={24} className="stroke-current text-blue-anak" />
                </div>
              }
            />
            <Widget
              title="Total Closed Cases"
              description={formatNumber(casesSummary?.closed)}
              rightBorderColor="green"
              right={
                <div className="bg-green-100 bg-opacity-25 border border-green-100 block rounded-full p-1">
                  <BiCheck
                    size={24}
                    className="stroke-current text-green-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Open Cases"
              description={formatNumber(casesSummary?.open)}
              rightBorderColor="orange"
              right={
                <div className="bg-orange-100 bg-opacity-25 border border-orange-100 block rounded-full p-1">
                  <BiTimeFive
                    size={24}
                    className="stroke-current text-orange-anak"
                  />
                </div>
              }
            />
            <Widget
              title="Total Cases in Progress"
              description={formatNumber(casesSummary?.in_progress)}
              rightBorderColor="blue"
              right={
                <div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-1">
                  <BiInfoCircle
                    size={24}
                    className="stroke-current text-blue-anak"
                  />
                </div>
              }
            />
          </>
        )}
      </div>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <CustomerCasesTable />
      </Card>
    </>
  );
}

export default CustomerCases;
