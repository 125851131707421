import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isEmpty } from '../utils/generic';
import ErrorPage from '../pages/error-page';
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import SystemUsers from '../pages/system-users';
import SystemUserDetails from '../pages/system-users/user-details';
import RolesAndPermission from '../pages/roles-and-permissions';
import OrgCategory from '../pages/org-category';
import OrgCategoryDetails from '../pages/org-category/org-category-details';
import OrgDetails from '../pages/org-category/org-details';
import Organisations from '../pages/organisation';
import OrgMessage from '../pages/org-message';
import MessageDetails from '../pages/org-message/message-details';
import CreateNewMessage from '../pages/org-message/create-new-message';
import CustomersList from '../pages/customers';
import CustomerDetails from '../pages/customers/customer-details';
import CustomerTransactionsTable from '../pages/customers/customer-transactions-table';
import KYCDetails from '../pages/kyc-details';
import UserKYCDetails from '../pages/kyc-details/user-kyc-details';
import OrgUsers from '../pages/org-users';
import OrgUserDetails from '../pages/org-users/user-details';
import WalletDetails from '../pages/wallet-details';
import EditOrganisation from '../pages/organisation/edit-org';
import CreateOrganisation from '../pages/organisation/create-org';
import RecentWalletTransactionsTable from '../pages/wallet-details/recent-transactions';
import EditProfile from '../pages/edit-profile';
import DocumentDetails from '../pages/kyc-details/document-details';
import EditOrgMessage from '../pages/org-message/edit-message';
import CustomerCases from '../pages/customer-cases';
import CustomerCasesDetails from '../pages/customer-cases/cases-details';
import TransactionOverview from '../pages/transactions/transactionOverview';
import TransactionList from '../pages/transactions/transactions-list';
import ResetPassword from '../pages/reset-password';
import IMWServices from '../pages/customer-services';
import IGWServicesDetails from '../pages/customer-services/igw-details';
import IGWServicesDetailsReview from '../pages/customer-services/igw-details-review';
import IMWServicesDetailsTable from '../pages/customer-services/igw-details-list';

export const PrivateRoutes = ({ auth }) => {
  const userPermissions = auth?.currentUser?.permissions;
  const routeConfig = [
    {
      path: '/dashboard',
      component: Dashboard,
      exact: true,
      permissions: ['admin_dashboard_access'],
    },
    {
      path: '/system-users',
      component: SystemUsers,
      exact: true,
      permissions: ['system_user_management', 'system_user_view'],
    },
    {
      path: '/system-users/:uuid',
      component: SystemUserDetails,
      exact: true,
      permissions: ['system_user_management', 'system_user_view'],
    },
    {
      path: '/roles-and-permission',
      component: RolesAndPermission,
      exact: true,
      permissions: [
        'roles_and_permissions_management',
        'roles_and_permissions_view',
      ],
    },
    {
      path: '/organisations/categories',
      component: OrgCategory,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/categories/:category_uuid',
      component: OrgCategoryDetails,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/categories/:category_uuid/:organization_uuid',
      component: OrgDetails,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/list',
      component: Organisations,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/list/:organization_uuid',
      component: OrgDetails,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/create',
      component: CreateOrganisation,
      exact: true,
      permissions: ['organization_management'],
    },
    {
      path: '/organisations/list/:organization_uuid/edit',
      component: EditOrganisation,
      exact: true,
      permissions: ['organization_management'],
    },
    {
      path: '/organisations/messages',
      component: OrgMessage,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/messages/new',
      component: CreateNewMessage,
      exact: true,
      permissions: ['organization_management'],
    },
    {
      path: '/organisations/messages/edit/:message_uuid',
      component: EditOrgMessage,
      exact: true,
      permissions: ['organization_management'],
    },
    {
      path: '/organisations/messages/:message_uuid',
      component: MessageDetails,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/users',
      component: OrgUsers,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/organisations/users/:profile_uuid',
      component: OrgUserDetails,
      exact: true,
      permissions: ['organization_management', 'organization_view'],
    },
    {
      path: '/customers/list',
      component: CustomersList,
      exact: true,
      permissions: ['customer_management', 'customer_view'],
    },
    {
      path: '/customers/list/:customerId',
      component: CustomerDetails,
      exact: true,
      permissions: ['customer_management', 'customer_view'],
    },
    {
      path: '/customers/list/:customerId/transactions',
      component: CustomerTransactionsTable,
      exact: true,
      permissions: ['customer_management', 'customer_view'],
    },
    {
      path: '/customers/kyc',
      component: KYCDetails,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/kyc/:customer_id',
      component: UserKYCDetails,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/kyc/:customer_id/document-details/:kyc_attempt_id',
      component: DocumentDetails,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/igw',
      component: IMWServices,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/igw/subscriber/:subscriber_id/form/:form_uuid/',
      component: IGWServicesDetails,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/igw/subscriber/:subscriber_id/form/:form_uuid/review',
      component: IGWServicesDetailsReview,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/igw/subscriber/:subscriber_id',
      component: IMWServicesDetailsTable,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/cases',
      component: CustomerCases,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/customers/cases/:customer_id/:case_uuid',
      component: CustomerCasesDetails,
      exact: true,
      permissions: ['customer_management', 'customer_kyc_view'],
    },
    {
      path: '/wallet-details',
      component: WalletDetails,
      exact: true,
      permissions: ['wallet_management'],
    },
    {
      path: '/wallet-details/:customerId',
      component: CustomerDetails,
      exact: true,
      permissions: ['wallet_management'],
    },
    {
      path: '/wallet-details/:userId/transactions',
      component: RecentWalletTransactionsTable,
      exact: true,
      permissions: ['wallet_management'],
    },
    {
      path: '/transactions/overview',
      component: TransactionOverview,
      exact: true,
      permissions: ['transaction_management'],
    },
    {
      path: '/transactions/list',
      component: TransactionList,
      exact: true,
      permissions: ['transaction_management'],
    },
  ];
  let roleBasedRoutes = [];
  userPermissions.map((item) =>
    routeConfig.map(
      (route) =>
        route.permissions.includes(item.codename) && roleBasedRoutes.push(route)
    )
  );

  return (
    <Switch>
      {!isEmpty(roleBasedRoutes) &&
        roleBasedRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}

      <Route
        exact
        path="/"
        render={() =>
          !isEmpty(auth.currentUser) ? (
            <Redirect to={roleBasedRoutes[0].path} />
          ) : (
            <Login />
          )
        }
      />

      <Route exact path="/edit-profile/:uuid" component={EditProfile} />

      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/**" component={ErrorPage} />
    </Switch>
  );
};
