import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { Card, Form, Select, Button, Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRoles,
  listRoles,
  updateRole,
} from '../../store/actions/rolesPermissionActions';
import { isEmpty } from '../../utils/generic';
import { CheckPermission } from '../../components/check-permission';
import { PermissionsSkeleton } from '../../components/skeleton/WidgetSkeleton';
import { camelCaseString } from '../../utils/string-helpers';

function RolesAndPermission() {
  const { t } = useTranslation();
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let title = t('roles_permission.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Administration',
      url: '/',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];
  const { roles, rolesLoading, currentRole, currentRoleLoading } = useSelector(
    (state) => state.rolesPermission
  );
  const [currentRoleId, setCurrentRoleId] = useState(null);
  const handleSubmit = () => {
    dispatch(updateRole(currentRoleId, currentRole));
  };
  const changeRoleHandler = (value) => {
    setCurrentRoleId(value);
    dispatch(getRoles(value));
  };
  useEffect(() => {
    isEmpty(roles?.results) && dispatch(listRoles('admin'));
  }, []); // eslint-disable-line

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <Card>
        <div className="text-xl font-bold mb-2">
          {t('roles_permission.role_title')}
        </div>
        <div className="max-w-xs font-bold mb-4">
          <Form layout="vertical">
            <Select
              placeholder={t('roles_permission.dropdown_label')}
              className="rounded-full w-64"
              size="large"
              onChange={changeRoleHandler}
            >
              {rolesLoading ? (
                <Option disabled value="">
                  <Spin />
                </Option>
              ) : (
                roles?.results.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {camelCaseString(role.name)}
                  </Option>
                ))
              )}
            </Select>
          </Form>
        </div>
        <div className="text-xl font-bold my-6 pb-4 border-b">
          {t('roles_permission.permissions_title')}
        </div>
        {currentRoleLoading ? (
          <div className="flex justify-center">
            <PermissionsSkeleton />
          </div>
        ) : isEmpty(currentRoleId) ? (
          <div className="text-grey-darker">
            Select role to display permissions.
          </div>
        ) : (
          <Form onFinish={handleSubmit} form={form}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-8">
              {currentRole?.map((item, i) => (
                <Form.Item key={i} name={item.codename} valuePropName="checked">
                  <div className="pb-4 border-b">
                    <div className="mb-2 flex items-center">
                      <Switch
                        // disabled={true}
                        defaultChecked={item.is_active}
                        onChange={(value) => {
                          const permissions = currentRole?.filter(
                            (elem) => item.codename === elem.codename
                          );
                          permissions[0].is_active = value;
                        }}
                      />
                      <div className="text-base font-bold ml-4">{item.name}</div>
                    </div>
                  </div>
                </Form.Item>
              ))}
              <CheckPermission permission="roles_and_permissions_management">
                <div className="col-span-2 justify-self-center">
                  <Form.Item>
                    <Button
                      type="success"
                      size="large"
                      className="w-48 md:w-64"
                      htmlType="submit"
                    >
                      {t('roles_permission.permission_save')}
                    </Button>
                  </Form.Item>
                </div>
              </CheckPermission>
            </div>
          </Form>
        )}
      </Card>
    </>
  );
}

export default RolesAndPermission;
