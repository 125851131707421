import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as types from '../store/actionTypes/customer-cases';
import { notification } from 'antd';
// import history from '../utils/history';
import {
  getCasesSummaryFailedAction,
  getCasesSummarySuccessAction,
  getCustomerCaseFailedAction,
  getCustomerCaseSuccessAction,
  listCustomerCasesFailedAction,
  listCustomerCasesSuccessAction,
  updateCustomerCaseFailedAction,
  updateCustomerCaseSuccessAction,
  getCustomerCaseHistorySuccessAction,
  getCustomerCaseHistoryFailedAction
} from '../store/actions/customerCasesAction';
import {
  getCustomerCaseService,
  listCustomerCasesService,
  updateCustomerCaseService,
  getCasesSummaryService,
  getCustomerCaseHistoryService
} from '../services/customerCasesService';

export function* listCustomerCases({ payload }) {
  try {
    const list = yield listCustomerCasesService(payload);
    yield put(listCustomerCasesSuccessAction(list.data.data));
  } catch (error) {
    yield put(listCustomerCasesFailedAction(error));
  }
}
export function* getCustomerCase({ payload }) {
  try {
    const edit = yield getCustomerCaseService(payload);
    yield put(getCustomerCaseSuccessAction(edit.data.data));
  } catch (error) {
    yield put(getCustomerCaseFailedAction(error));
  }
}
export function* getCustomerCasesSummary() {
  try {
    const summary = yield getCasesSummaryService();
    yield put(getCasesSummarySuccessAction(summary.data.data));
  } catch (error) {
    yield put(getCasesSummaryFailedAction(error));
    notification['error']({
      message: 'Failed to Get Customer Case Status',
    });
  }
}
export function* updateCustomerCase({
  payload: { submitUrl, caseData, case_uuid },
}) {
  try {
    const edit = yield updateCustomerCaseService(submitUrl, caseData);
    yield put(updateCustomerCaseSuccessAction(edit.data.data));
    yield call(getCustomerCase, { payload: case_uuid });
    notification['success']({
      message: 'Customer Case Updated Successfully',
      description: 'Customer Case Updated Successfully.',
    });
  } catch (error) {
    yield put(updateCustomerCaseFailedAction(error));
    notification['error']({
      message: 'Failed to Update Customer Case',
      description: 'Failed to Update Customer Case.',
    });
  }
}
export function* getCustomerCaseHistory({ payload }) {
  try {
    const edit = yield getCustomerCaseHistoryService(payload);
    yield put(getCustomerCaseHistorySuccessAction(edit.data.data));
  } catch (error) {
    yield put(getCustomerCaseHistoryFailedAction(error));
  }
}

export function* onListRequest() {
  yield takeLatest(types.LIST_CUSTOMER_CASES, listCustomerCases);
}
export function* onGetCaseRequest() {
  yield takeLatest(types.GET_CUSTOMER_CASE, getCustomerCase);
}
export function* onUpdateCaseRequest() {
  yield takeLatest(types.UPDATE_CUSTOMER_CASE, updateCustomerCase);
}
export function* onGetCustomerCasesSummaryRequest() {
  yield takeLatest(types.CUSTOMER_CASES_SUMMARY, getCustomerCasesSummary);
}
export function* onGetCaseHistoryRequest() {
  yield takeLatest(types.GET_CUSTOMER_CASE_HISTORY, getCustomerCaseHistory);
}

export function* customerCasesSaga() {
  yield all([
    onListRequest(),
    onGetCaseRequest(),
    onUpdateCaseRequest(),
    onGetCustomerCasesSummaryRequest(),
    onGetCaseHistoryRequest()
  ]);
}
