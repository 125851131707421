export const GET_KYC_OVERVIEW = 'GET_KYC_OVERVIEW';
export const GET_KYC_OVERVIEW_SUCCESS = 'GET_KYC_OVERVIEW_SUCCESS';
export const GET_KYC_OVERVIEW_FAILURE = 'GET_KYC_OVERVIEW_FAILURE';

export const LIST_KYC = 'LIST_KYC';
export const LIST_KYC_SUCCESS = 'LIST_KYC_SUCCESS';
export const LIST_KYC_FAILURE = 'LIST_KYC_FAILURE';

export const GET_KYC = 'GET_KYC';
export const GET_KYC_SUCCESS = 'GET_KYC_SUCCESS';
export const GET_KYC_FAILURE = 'GET_KYC_FAILURE';

export const GET_KYC_DOCUMENT = 'GET_KYC_DOCUMENT';
export const GET_KYC_DOCUMENT_SUCCESS = 'GET_KYC_DOCUMENT_SUCCESS';
export const GET_KYC_DOCUMENT_FAILURE = 'GET_KYC_DOCUMENT_FAILURE';
