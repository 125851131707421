import React from 'react';
import { Tabs } from 'antd';
import P2PTransactionTable from './p2p/p2p-transaction-table';
import RemittanceTable from './remittance/remittance-table';
import TopUpTable from './topup/topup-table';
import AllTransactionsTable from './all-transaction-table';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';

function TransactionList() {
  const { TabPane } = Tabs;
  const tabs = [
    {
      key: 0,
      tab: 'All Transactions',
      content: <AllTransactionsTable />,
    },
    {
      key: 1,
      tab: 'Top-Up Transactions',
      content: <TopUpTable />,
    },
    {
      key: 2,
      tab: 'P2P Transactions',
      content: <P2PTransactionTable />,
    },
    {
      key: 3,
      tab: 'Remittance Transactions',
      content: <RemittanceTable />,
    },
  ];
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Wallet',
      url: '/',
      last: false,
    },
    {
      home: true,
      title: 'Transactions',
      url: '/',
      last: false,
    },
    { home: false, title: 'All Transactions', url: '/', last: true },
  ];
  return (
    <>
      <SectionTitle
        title="All Transactions"
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="transaction-tabs">
        <Tabs type="card">
          {tabs.map((tab) => (
            <TabPane tab={tab.tab} key={tab.key}>
              <div className="transaction-tabs-content">{tab.content}</div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
}

export default TransactionList;
