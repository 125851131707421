import { formatCurrency } from '../../utils/numbers';
import React from 'react';
import '../../utils/numbers';
import { useTranslation } from 'react-i18next';
import { AiFillDollarCircle } from 'react-icons/ai';
import { lastMonthYearFormat, thisMonthYearFormat } from '../../utils/dateFormat';

export function EarningsOverviewWidget1({ earnings_overview }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-2 rounded-full border mr-4 bg-opacity-25 bg-green-100 border-green-100 text-green-anak">
            <AiFillDollarCircle size={28} />
          </div>
          <div className="flex flex-col">
            <div className="text-md font-normal mb-1">
            Total Earnings
            </div>
            <div className="text-2xl order-last font-extrabold leading-none">
              SGD{' '}
              <span className="text-green-anak">
                {' '}
                {formatCurrency(earnings_overview?.total_earnings || 0)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="font-medium">
          <div className="text-md font-normal mb-1">
            {t('dashboard.earnings_overview.from_fx')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(earnings_overview?.total_fx || 0)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md font-normal mb-1">
            {t('dashboard.earnings_overview.from_fee')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(
                earnings_overview?.total_fee || 0
              )}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function EarningsOverviewWidget2({ earnings_overview }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-2 rounded-full border mr-4 bg-opacity-25 bg-green-100 border-green-100 text-green-anak">
            <AiFillDollarCircle size={28} />
          </div>
          <div className="flex flex-col">
            <div className="text-md font-normal mb-1">
            Earnings This Month ({thisMonthYearFormat(new Date())})
            </div>
            <div className="text-2xl order-last font-extrabold leading-none">
              SGD{' '}
              <span className="text-green-anak">
                {' '}
                {formatCurrency(earnings_overview?.this_month_earnings || 0)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="font-medium">
          <div className="text-md font-normal mb-1">
            {t('dashboard.earnings_overview.from_fx_this_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(earnings_overview?.this_month_fx || 0)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md font-normal mb-1">
            {t('dashboard.earnings_overview.from_fee_this_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(
                earnings_overview?.this_month_fee || 0
              )}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function EarningsOverviewWidget3({ earnings_overview }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-2 rounded-full border mr-4 bg-opacity-25 bg-green-100 border-green-100 text-green-anak">
            <AiFillDollarCircle size={28} />
          </div>
          <div className="flex flex-col">
            <div className="text-md font-normal mb-1">
            Earnings Last Month ({lastMonthYearFormat(new Date())})
            </div>
            <div className="text-2xl order-last font-extrabold leading-none">
              SGD{' '}
              <span className="text-green-anak">
                {' '}
                {formatCurrency(earnings_overview?.last_month_earnings || 0)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="font-medium">
          <div className="text-md font-normal mb-1">
            {t('dashboard.earnings_overview.from_fx_last_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(earnings_overview?.last_month_fx || 0)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md font-normal mb-1">
            {t('dashboard.earnings_overview.from_fee_last_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="">
              {' '}
              {formatCurrency(
                earnings_overview?.last_month_fee || 0
              )}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}