import * as types from '../actionTypes/wallet';

export const listWalletAction = (payload) => {
  return { type: types.LIST_WALLET, payload };
};
export const listWalletSuccessAction = (payload) => {
  return { type: types.LIST_WALLET_SUCCESS, payload };
};
export const listWalletFailureAction = (payload) => {
  return { type: types.LIST_WALLET_FAILURE, payload };
};

export const getWalletSummaryAction = (payload) => {
  return { type: types.GET_WALLET_SUMMARY, payload };
};
export const getWalletSummarySuccessAction = (payload) => {
  return { type: types.GET_WALLET_SUMMARY_SUCCESS, payload };
};
export const getWalletSummaryFailureAction = (payload) => {
  return { type: types.GET_WALLET_SUMMARY_FAILURE, payload };
};

export const getWalletAction = (payload) => {
  return { type: types.GET_WALLET, payload };
};
export const getWalletSuccessAction = (payload) => {
  return { type: types.GET_WALLET_SUCCESS, payload };
};
export const getWalletFailureAction = (payload) => {
  return { type: types.GET_WALLET_FAILURE, payload };
};
