import * as types from '../actionTypes/downloadActionTypes';

export const downloadRequestAction = (downloadParams) => {
	return {
		type: types.DOWNLOAD_REQUEST,
		payload: downloadParams,
	};
}

export const downloadSuccessAction = (url) => {
	return {
		type: types.DOWNLOAD_SUCCESS,
		payload: url,
	};
}

export const downloadFailAction = (err) => {
	return {
		type: types.DOWNLOAD_FAIL,
		payload: err,
	};
}