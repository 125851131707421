import axiosClient from './config';

const IGW_SERVICE_UUID = process.env.REACT_APP_IGW_SERVICE_UUID
const IGW_ORGANIZATION_UUID = process.env.REACT_APP_IGW_ORGANIZATION_UUID

export const listIGWServicesService = async ({
  page,
  search,
  ordering,
  status,
  page_size,
  created_at_after,
  created_at_before,
}) => {

  return await axiosClient.get(
    `/organisations/${IGW_ORGANIZATION_UUID}/services/${IGW_SERVICE_UUID}/?page=${page}&search=${search}&status=${status}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};



export const getIGWDetailsListService = async ({ subscriber_id }) => {
  return await axiosClient.get(
    `/organisations/${IGW_ORGANIZATION_UUID}/services/${IGW_SERVICE_UUID}/subscriber/${subscriber_id}/`
  );
};

export const getIGWDetailsService = async ({ subscriber_id, form_uuid }) => {
  return await axiosClient.get(
    `/organisations/${IGW_ORGANIZATION_UUID}/services/${IGW_SERVICE_UUID}/subscriber/${subscriber_id}/form/${form_uuid}/`
  );
};

export const editIGWDetailsService = async ( initialTableParams , details) => {
  return await axiosClient.patch(`organisations/${IGW_ORGANIZATION_UUID}/services/${IGW_SERVICE_UUID}/subscriber/${initialTableParams.subscriber_id}/form/${initialTableParams.form_uuid}/`, details);
};