import * as types from '../actionTypes/customers';

export const listCustomersAction = (tableParams) => {
  return {
    type: types.LIST_CUSTOMERS,
    payload: tableParams,
  };
};
export const listCustomersSuccessAction = (customers) => {
  return {
    type: types.LIST_CUSTOMERS_SUCCESS,
    payload: customers,
  };
};
export const listCustomersFailedAction = (error) => {
  return {
    type: types.LIST_CUSTOMERS_FAILED,
    payload: error,
  };
};

export const viewCustomerAction = (customerId) => {
  return {
    type: types.VIEW_CUSTOMER,
    payload: customerId,
  };
};
export const viewCustomerSuccessAction = (currentCustomer) => {
  return {
    type: types.VIEW_CUSTOMER_SUCCESS,
    payload: currentCustomer,
  };
};
export const viewCustomerFailAction = (error) => {
  return {
    type: types.VIEW_CUSTOMER_FAILED,
    payload: error,
  };
};

export const editCustomerAction = (customerId, updatedFormValue) => {
  return {
    type: types.EDIT_CUSTOMER,
    payload: { customerId, updatedFormValue },
  };
};
export const editCustomerSuccessAction = (currentCustomer) => {
  return {
    type: types.EDIT_CUSTOMER_SUCCESS,
    payload: currentCustomer,
  };
};
export const editCustomerFailAction = (error) => {
  return {
    type: types.EDIT_CUSTOMER_FAILED,
    payload: error,
  };
};

export const updateCustomerPinAction = (customerId, pin) => {
  return {
    type: types.UPDATE_CUSTOMER_PIN,
    payload: {
      customerId,
      pin,
    },
  };
};
export const updateCustomerPinSuccessAction = (currentCustomer) => {
  return {
    type: types.UPDATE_CUSTOMER_PIN_SUCCESS,
    payload: currentCustomer,
  };
};
export const updateCustomerPinFailAction = (error) => {
  return {
    type: types.UPDATE_CUSTOMER_PIN_FAILED,
    payload: error,
  };
};

export const listCustomerTransactionAction = (payload) => {
  return {
    type: types.LIST_CUSTOMER_TRANSACTION,
    payload,
  };
};
export const listCustomerTransactionSuccessAction = (transactions) => {
  return {
    type: types.LIST_CUSTOMER_TRANSACTION_SUCCESS,
    payload: transactions,
  };
};
export const listCustomerTransactionFailedAction = (error) => {
  return {
    type: types.LIST_CUSTOMER_TRANSACTION_FAILED,
    payload: error,
  };
};
