import React from 'react';
import { useRouteMatch } from 'react-router';
import SectionTitle from '../../components/dashboard/section-title';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumbs';
import { BiPlusCircle } from 'react-icons/bi';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import OrgMessageTable from './org-message-table';

function OrgMessage() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  let title = t('org_message.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    { home: false, title: 'Org Message', url: '/', last: true },
  ];

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex lg:flex-row-reverse mb-6">
        <Link to={`${url}/new`}>
          <Button
            className="flex justify-center items-center"
            type="success"
            size="large"
            icon={<BiPlusCircle className="mr-2 w-6 h-6" />}
          >
            {t('org_message.create.button_text')}
          </Button>
        </Link>
      </div>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <OrgMessageTable />
      </Card>
    </>
  );
}

export default OrgMessage;
