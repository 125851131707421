import React from 'react';
import { useSelector } from 'react-redux';
import { CaseHistoryDetailsSkeleton } from '../../components/skeleton/WidgetSkeleton';
import { getCaseStatus } from '../../utils/get-case-status';
import { utcToLocalDateTime } from '../../utils/dateFormat';

function CaseHistoryDetails({ caseID }) {

  const { currentCaseHistory, currentCaseHistoryLoading } = useSelector(
    (state) => state.customerCases
  );

  const IDTypeNRIC = () => {
    return (
      <>
        <div className="col-span-12 sm:col-span-12 rounded-lg">
          <div className="grid grid-cols-12 gap-4">
            <div className="flex w-full items-center col-span-12">
              <div className="text-md text-white font-bold bg-blue-anak rounded-lg px-4 py-2 mr-3">STEP 2</div>
              <div className="text-lg font-bold">ID Type - NRIC</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 p-1 md:px-4 py-2 mb-6 rounded-lg bg-white border">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">KYC Document Details</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">ID Type</div>
                <div className="w-full">{currentCaseHistory?.document?.type}</div>
              </div>
            </div>
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">User Submission</div>
              <div className="py-3 border-b border-grey-10">
                <div className="w-full mb-1 font-bold">Working Industry</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.work_industry || 'Not Available'}</div>
              </div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">Working Profession</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.work_profession || 'Not Available'}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const IDTypeWorkPass = () => {
    return (
      <>
        <div className="col-span-12 sm:col-span-12 rounded-lg">
          <div className="grid grid-cols-12 gap-4">
            <div className="flex w-full items-center col-span-12">
              <div className="text-md text-white font-bold bg-blue-anak rounded-lg px-4 py-2 mr-3">STEP 2</div>
              <div className="text-lg font-bold">ID Type - Work Pass</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 p-1 md:px-4 py-2 mb-6 rounded-lg bg-white border">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">KYC Document Details</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">ID Type</div>
                <div className="w-full">{currentCaseHistory?.document?.type}</div>
              </div>
              <div className="text-lg font-bold mt-6">Work Info Validation</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">Is any of the prohibited industries selected</div>
                <div className="w-full">{(currentCaseHistory?.document?.response_data?.request_decision === true ? 'Yes' : 'No') || 'Not Available'}</div>
              </div>
            </div>
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">User Pass Expiry Date</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">Expiry/ Validity date</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.expiry_date || 'Not Available'}</div>
              </div>
            </div>
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">Work Info</div>
              <div className="py-3 border-b border-grey-10">
                <div className="w-full mb-1 font-bold">Working Profession</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.work_profession || 'Not Available'}</div>
              </div>
              <div className="py-3 border-b border-grey-10">
                <div className="w-full mb-1 font-bold">Working Industry</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.work_industry || 'Not Available'}</div>
              </div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">Company Name</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.company_name || 'Not Available'}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const IDTypeDependentPass = () => {
    return (
      <>
        <div className="col-span-12 sm:col-span-12 rounded-lg">
          <div className="grid grid-cols-12 gap-4">
            <div className="flex w-full items-center col-span-12">
              <div className="text-md text-white font-bold bg-blue-anak rounded-lg px-4 py-2 mr-3">STEP 2</div>
              <div className="text-lg font-bold">ID Type - Dependent Pass</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 p-1 md:px-4 py-2  mb-6 rounded-lg bg-white border border">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">KYC Document Details</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">ID Type</div>
                <div className="w-full">{currentCaseHistory?.document?.type}</div>
              </div>
            </div>
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">User Pass Expiry Date</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">Expiry/ Validity date</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.expiry_date || 'Not Available'}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const IDTypeLongTermVisitPass = () => {
    return (
      <>
        <div className="col-span-12 sm:col-span-12 rounded-lg">
          <div className="grid grid-cols-12 gap-4">
            <div className="flex w-full items-center col-span-12">
              <div className="text-md text-white font-bold bg-blue-anak rounded-lg px-4 py-2 mr-3">STEP 2</div>
              <div className="text-lg font-bold">ID Type - Long Term Visit Pass</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 p-1 md:px-4 py-2 mb-6 rounded-lg bg-white border">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">KYC Document Details</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">ID Type</div>
                <div className="w-full">{currentCaseHistory?.document?.type}</div>
              </div>
            </div>
            <div className="w-full p-2 col-span-12 md:col-span-4">
              <div className="text-lg font-bold">User Pass Expiry Date</div>
              <div className="py-3">
                <div className="w-full mb-1 font-bold">Expiry/ Validity date</div>
                <div className="w-full">{currentCaseHistory?.document?.response_data?.expiry_date || 'Not Available'}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const getDocumentsIDType = (IDType) => {
    switch (IDType) {
      case "NRIC":
        return <IDTypeNRIC />
      case "Workpass":
        return <IDTypeWorkPass />
      case "Dependant Pass":
        return <IDTypeDependentPass />
      case "Long Term Visit Pass":
        return <IDTypeLongTermVisitPass />
      default:
        break;
    }
  }

  return (
    <>
      {currentCaseHistoryLoading ? (
        <div className="text-center w-full">
          <CaseHistoryDetailsSkeleton />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-6 md:grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-12 px-1 md:px-4 py-2 rounded-lg border border-grey-10 bg-blue-anaklite">
              <div className="grid grid-cols-12 gap-4">
                <div className="w-full px-2 col-span-12 md:col-span-4 border-r border-grey-400">
                  <div className="w-full mb-1 text-md font-bold">Submitted By</div>
                  <div className="w-full text-lg font-bold">{currentCaseHistory?.handled_by || '-'}</div>
                </div>
                <div className="w-full px-2 col-span-12 md:col-span-4 border-r border-grey-400">
                  <div className="w-full mb-1 text-md font-bold">Submitted On</div>
                  <div className="w-full text-lg font-bold">{utcToLocalDateTime(currentCaseHistory?.updated_at) || '-' }</div>
                </div>
                <div className="w-full px-2 col-span-12 md:col-span-4">
                  <div className="w-full mb-1 text-md font-bold">Case ID</div>
                  <div className="w-full text-lg font-bold">{currentCaseHistory?.case_id || '-'}</div>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 mt-4 rounded-lg">
              <div className="grid grid-cols-12 gap-4">
                <div className="flex w-full items-center col-span-12">
                  <div className="text-md text-white font-bold bg-blue-anak rounded-lg px-4 py-2 mr-3">STEP 1</div>
                  <div className="text-lg font-bold">Address Detail</div>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 p-1 md:px-4 py-2 mb-4 rounded-lg bg-white border">
              <div className="grid grid-cols-12 gap-4">
                <div className="w-full p-2 col-span-12 md:col-span-4">
                  <div className="text-lg font-bold mb-2">Address Info</div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">Address</div>
                    <div className="w-full">{currentCaseHistory?.address?.submitted_address?.address || 'Not Available'}</div>
                  </div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">City</div>
                    <div className="w-full">{currentCaseHistory?.address?.submitted_address?.city || 'Not Available'}</div>
                  </div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">Zipcode</div>
                    <div className="w-full">{currentCaseHistory?.address?.submitted_address?.zipcode || 'Not Available'}</div>
                  </div>
                  <div className="py-3">
                    <div className="w-full mb-1 font-bold">Country</div>
                    <div className="w-full">{currentCaseHistory?.address?.submitted_address?.country || 'Not Available'}</div>
                  </div>
                </div>
                <div className="w-full p-2 col-span-12 md:col-span-4">
                  <div className="text-lg font-bold mb-2">Extracted from Document</div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">Date of Birth</div>
                    <div className="w-full">{currentCaseHistory?.address?.document_extracted_info?.date_of_birth || 'Not Available'}</div>
                  </div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">Gender</div>
                    <div className="w-full">{currentCaseHistory?.address?.document_extracted_info?.gender || 'Not Available'}</div>
                  </div>
                  <div className="py-3">
                    <div className="w-full mb-1 font-bold">Nationality</div>
                    <div className="w-full">{currentCaseHistory?.address?.document_extracted_info?.nationality || 'Not Available'}</div>
                  </div>
                </div>
                <div className="w-full p-2 col-span-12 md:col-span-4">
                  <div className="text-lg font-bold mb-2">User Submission</div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">Address</div>
                    <div className="w-full">{currentCaseHistory?.address?.user_submitted_address?.address || 'Not Available'}</div>
                  </div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">City</div>
                    <div className="w-full">{currentCaseHistory?.address?.user_submitted_address?.city || 'Not Available'}</div>
                  </div>
                  <div className="py-3 border-b border-grey-10">
                    <div className="w-full mb-1 font-bold">Zip Code</div>
                    <div className="w-full">{currentCaseHistory?.address?.user_submitted_address?.zipcode || 'Not Available'}</div>
                  </div>
                  <div className="py-3">
                    <div className="w-full mb-1 font-bold">Country</div>
                    <div className="w-full">{currentCaseHistory?.address?.user_submitted_address?.country || 'Not Available'}</div>
                  </div>
                </div>
              </div>
            </div>
            {getDocumentsIDType(currentCaseHistory?.document?.type)}
            <div className="col-span-12 sm:col-span-12 rounded-lg">
              <div className="grid grid-cols-12 gap-4">
                <div className="w-full items-center col-span-12">
                  <div className="text-lg font-bold mb-2 col-span-4">{getCaseStatus(currentCaseHistory?.status) || '-'}</div>
                  <div className="py-3">
                    <div className="w-full mb-2 font-bold">Note</div>
                    <div className="w-full">{currentCaseHistory?.comments || '-'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CaseHistoryDetails;
