import axiosClient from './config';

export const listSystemUsersService = async ({
  page,
  role,
  search,
  ordering,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/users/?page=${page}&role=${role}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};

export const createSystemUserService = async (name, email, role) => {
  return await axiosClient.post('/users/', {
    name,
    email,
    role,
  });
};

export const editSystemUserService = async (values, userId) => {
  return await axiosClient.patch(`/users/${userId}/`, values);
};

export const viewSystemUserService = async (userId) => {
  return await axiosClient.get(`/users/${userId}/`);
};

export const changeSystemUserStatusService = async (status, uuid) => {
  return await axiosClient.patch(`/users/${uuid}/status/`, {
    status,
  });
};
export const resetSystemUserPasswordService = async (email) => {
  return await axiosClient.post(`reset-user-password/`, {
    email,
  });
};
