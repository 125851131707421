import axiosClient from './config';

export const listOrganisationsService = async ({
  page,
  search,
  ordering,
  page_size,
  created_at_after,
  created_at_before,
  is_active,
}) => {
  return await axiosClient.get(
    `/organisations/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}&is_active=${is_active}`
  );
};
export const getOrganisationService = async (organization_uuid) => {
  return await axiosClient.get(`/organisations/${organization_uuid}/`);
};
export const getOrganisationSubscriberService = async ({
  organization_uuid,
  page,
  search,
  ordering,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/organisations/${organization_uuid}/subscribers/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};
export const getOrganisationUsersService = async (organization_uuid) => {
  return await axiosClient.get(
    `/organisation-users/?organization__uuid=${organization_uuid}`
  );
};
export const createOrganisationService = async (details) => {
  return await axiosClient.post('/organisations/', details);
};
export const editOrganisationService = async (uuid, details) => {
  return await axiosClient.patch(`/organisations/${uuid}/`, details);
};
export const deleteOrganisationService = async (organization_uuid) => {
  return await axiosClient.delete(`/organisations/${organization_uuid}/`);
};
