export const LIST_WALLET = 'LIST_WALLET';
export const LIST_WALLET_SUCCESS = 'LIST_WALLET_SUCCESS';
export const LIST_WALLET_FAILURE = 'LIST_WALLET_FAILURE';

export const GET_WALLET_SUMMARY = 'GET_WALLET_SUMMARY';
export const GET_WALLET_SUMMARY_SUCCESS = 'GET_WALLET_SUMMARY_SUCCESS';
export const GET_WALLET_SUMMARY_FAILURE = 'GET_WALLET_SUMMARY_FAILURE';

export const GET_WALLET = 'GET_WALLET';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAILURE = 'GET_WALLET_FAILURE';
