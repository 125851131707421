import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { Card, Modal, Form, Input, Button, Select, DatePicker, TimePicker, Skeleton, Space, message, Spin, } from 'antd';
import { BiFile } from 'react-icons/bi';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';
import { listOrganisationAction, viewOrganisationAction, viewOrganisationSubscriberAction } from '../../store/actions/organisationActions';
import { createOrgMessageAction } from '../../store/actions/orgMessageActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useDebounce from '../../hooks/useDebounce';
import { isEmpty } from '../../utils/generic';
import { fileUploadHandler, imageUploadHandler } from '../../utils/upload';
import { resetFileUploadAction, resetImageUploadAction } from '../../store/actions/uploadAction';
import QuillEditor from '../../components/quill-editor';
import { listCustomersAction } from '../../store/actions/customerActions';

function CreateNewMessage() {
  const { t } = useTranslation();
  let title = t('org_message.create.title');
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = moment.tz(zone).format('Z');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('org_message.section_title'),
      url: '/organisations/messages',
      last: false,
    },
    { home: false, title: 'Create New', url: '/', last: true },
  ];
  const { Option } = Select;
  const [createMessageForm] = Form.useForm();
  const [searchSubscribers, setSearchSubscribers] = useState('');
  const debouncedSearchValue = useDebounce(searchSubscribers, 500);
  const [searchOrg, setSearchOrg] = useState('');
  const debouncedOrgSearchValue = useDebounce(searchOrg, 500);
  const [page, setPage] = useState(1);

  let orgSubscribersInitialValue = {
    organization_uuid: '',
    page: page,
    search: '',
    ordering: '',
    page_size: 100,
    created_at_after: '',
    created_at_before: '',
  };
  let usersInitialValue = {
    page: 1,
    search: "",
    ordering: "",
    page_size: 50,
    created_at_after: "",
    created_at_before: ""
  }
  let listOrgInitialValue = {
    page: 1,
    search: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const [scheduleMessageModal, setScheduleMessageModal] = useState(false);
  const [createMessageFormData, setCreateMessageFormData] = useState({
    images: [],
    videos: [],
    files: [],
    recipient: [],
  });
  const [selectedOrganisationUUID, setSelectedOrganisationUUID] = useState(null);
  const [buttonClicked, setButtonClicked] = useState('');
  const dispatch = useDispatch();
  const { upload, organisation, customers } = useSelector(
    (state) => ({
      upload: state.upload,
      organisation: state.organisation,
      customers: state.customers
    }),
    shallowEqual
  );

  const { uploadFile, uploadFileLoading, uploadImage, uploadImageLoading } = upload;
  const { orgList, orgListLoading, orgSubscribers, orgSubscribersLoading, currentOrg, currentOrgLoading } = organisation;
  const { customersList, customersListLoading } = customers

  let recipients = createMessageFormData?.recipient;

  const validateMessageForm = () => {
    let title = createMessageFormData.title;
    let body = createMessageFormData.body;
    let organisation = createMessageFormData.organisation;
    let recipient = createMessageFormData.recipient;
    if (
      title === '' ||
      body === '' ||
      organisation === '' ||
      recipient.length === 0
    ) {
      message.error('Please provide all required fields');
      setScheduleMessageModal(false);
      return false;
    } else {
      return true;
    }
  };

  const sendMessageFormHandler = ({ body }) => {
    const isValidated = validateMessageForm();
    const messageFormData = {
      ...createMessageFormData,
      body,
      files: isEmpty(uploadFile) ? [] : [uploadFile],
      images: isEmpty(uploadImage) ? [] : [uploadImage],
      recipient: recipients,
    };
    if (isValidated && buttonClicked === 'send') {
      dispatch(
        createOrgMessageAction({
          ...messageFormData,
          status: 1,
        })
      );
    }
    if (isValidated && buttonClicked === 'draft') {
      dispatch(
        createOrgMessageAction({
          ...messageFormData,
          status: 2,
        })
      );
    }
  };

  const scheduleMessageFormHandler = ({ date, time }) => {
    const validated = validateMessageForm();
    const schedule_datetime = `${moment(date._d).format('YYYY-MM-DD')} ${moment(
      time._d
    ).format('HH:mm:ss')}.00${timezone}`;
    if (validated) {
      setScheduleMessageModal(false);
      dispatch(
        createOrgMessageAction({
          ...createMessageFormData,
          status: 3,
          recipient: recipients,
          schedule_datetime,
          files: isEmpty(uploadFile) ? [] : [uploadFile],
          images: isEmpty(uploadImage) ? [] : [uploadImage],
        })
      );
    }
  };

  const listOrgSearchHandler = (value) => {
    setSearchOrg(value);
  };

  const listSubscriberSearchHandler = (value) => {
    setSearchSubscribers(value);
  };

  useEffect(() => {
    dispatch(resetImageUploadAction());
    dispatch(resetFileUploadAction());
    if (debouncedSearchValue !== '' && selectedOrganisationUUID) {
      const timer = setTimeout(() => {
        dispatch(listCustomersAction({
          ...usersInitialValue,
          search: debouncedSearchValue,
        }))
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else if (selectedOrganisationUUID) {
      dispatch(listCustomersAction({
        ...usersInitialValue,
        search: debouncedSearchValue,
      }))
    }
    if (debouncedOrgSearchValue !== '') {
      const timer = setTimeout(() => {
        dispatch(
          listOrganisationAction({
            ...listOrgInitialValue,
            search: debouncedOrgSearchValue,
          })
        );
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else {
      dispatch(listOrganisationAction(listOrgInitialValue));
    }
  }, [debouncedSearchValue, debouncedOrgSearchValue, selectedOrganisationUUID]); // eslint-disable-line

  const onScrollSubscribersList = () => {
    if (!isEmpty(orgSubscribers?.next)) {
      setPage(page + 1)
      console.log("page", page, orgSubscribers.next)
    }
  }

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
        <div className="w-full lg:w-2/3">
          <Card>
            <Form
              name="createMessage"
              autoComplete="off"
              layout="vertical"
              onFinish={sendMessageFormHandler}
              form={createMessageForm}
            >
              <Form.Item
                className="mb-6"
                name="organization"
                label={t('org_message.create.org_label')}
                rules={[
                  {
                    required: true,
                    message: 'Please select organisation!',
                  },
                ]}
              >
                <Select
                  size="large"
                  labelInValue
                  filterOption={false}
                  showSearch
                  onSearch={listOrgSearchHandler}
                  placeholder={t('org_message.create.org_placeholder')}
                  onChange={({ value }) => {
                    setSelectedOrganisationUUID(value);
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      organization: value,
                    });
                    dispatch(viewOrganisationAction(value));
                    dispatch(
                      viewOrganisationSubscriberAction({
                        ...orgSubscribersInitialValue,
                        organization_uuid: value,
                      })
                    );
                  }}
                >
                  {orgListLoading ? (
                    <Option disabled value="spin">
                      <Spin size="small" />
                    </Option>
                  ) : (
                    orgList?.results?.map((item) => (
                      <Option key={item?.uuid} value={item?.uuid}>
                        {item?.name}
                      </Option>
                    ))
                  )}
                  {isEmpty(orgList?.results) && (
                    <Option disabled value="none">
                      No organisation found
                    </Option>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                className="mb-6"
                name="recipient"
                label={t('org_message.create.recipients_label')}
                rules={[
                  {
                    required: true,
                    message: 'Please select recipient!',
                  },
                ]}
              >
                <Select
                  size="large"
                  labelInValue
                  filterOption={false}
                  showSearch
                  onSearch={listSubscriberSearchHandler}
                  placeholder={t('org_message.create.recipients_placeholder')}
                  mode="multiple"
                  maxTagCount={4}
                  defaultActiveFirstOption
                  disabled={selectedOrganisationUUID === null}
                  // onSelect={({ value }) => console.log('onSelect', value)}
                  onChange={(values) => {
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      recipient: values?.map((item) => item?.value)
                    });
                  }}
                  onDeselect={({ value }) => {
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      recipient: recipients?.filter((item) => item !== value)
                    });
                  }}
                  onPopupScroll={() => {
                    console.log("paginato")
                    onScrollSubscribersList()
                  }}
                >
                  {orgSubscribersLoading || customersListLoading ? (
                    <Option>Loading...</Option>
                  ) : (
                    <>
                      <Option
                        disabled={
                          recipients?.length >= 1 &&
                            !recipients?.includes(
                              'all_users' ||
                              typeof createMessageFormData?.recipient
                                ?.all[0] === 'number'
                            )
                            ? true
                            : false
                        }
                        value="all_users"
                      >
                        {recipients?.length > 0 &&
                          !recipients?.includes('all_users') ? (
                          <del>All Users</del>
                        ) : (
                          'All Users'
                        )}
                      </Option>
                      <Option
                        disabled={
                          recipients?.length >= 1 &&
                            !recipients?.includes(
                              'all_subscribers' ||
                              typeof createMessageFormData?.recipient === 'number'
                            )
                            ? true
                            : false
                        }
                        value="all_subscribers"
                      >
                        {recipients?.length > 0 &&
                          !recipients?.includes('all_subscribers') ? (
                          <del>All Subscribers</del>
                        ) : (
                          'All Subscribers'
                        )}
                      </Option>
                      {(isEmpty(orgSubscribers?.results) && isEmpty(debouncedSearchValue))  ? (
                        <Option disabled value="none">
                          No subscriber found
                        </Option>
                      ) :
                        isEmpty(debouncedSearchValue) ?
                          orgSubscribers?.results?.map((item) => (
                            <Option
                              disabled={
                                (recipients?.length === 1 &&
                                  recipients?.includes('all_subscribers')) || recipients?.includes('all_users')
                              }
                              key={item?.subscriber_id}
                              value={item?.subscriber_id}
                            >
                              {item?.name} (+{item?.mobile_country}{" "}{item?.mobile})
                            </Option>
                          )) :
                          customersList?.results?.map((item) => (
                            <Option
                              disabled={
                                (recipients?.length === 1 &&
                                  recipients?.includes('all_subscribers')) || recipients?.includes('all_users')
                              }
                              key={item?.id}
                              value={item?.id}
                            >
                              {item?.name} (+{item?.mobile_country}{" "}{item?.mobile})
                            </Option>
                          ))
                      }
                    </>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                className="mb-6"
                name="title"
                label={t('org_message.create.msg_title_label')}
                rules={[
                  {
                    required: true,
                    message: 'Please provide message title!',
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]*[^=+@<>-]*$/),
                    message: "This field does not accept =, +, -, @, <, >"
                  }
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('org_message.create.msg_title_placeholder')}
                  // value={createMessageFormData.title}
                  onChange={(e) =>
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                className="mb-6"
                name="body"
                label="Message Content"
                tooltip="Max 1000 characters"
                rules={[
                  {
                    required: true,
                    message: 'Please write message body',
                  },
                ]}
              >
                <QuillEditor onChange={(body) => {
                  setCreateMessageFormData({
                    ...createMessageFormData,
                    body: body,
                  })
                }} />
              </Form.Item>

              <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                <div className="w-full lg:w-1/2">
                  <Form.Item
                    className="mb-6 file-uploader"
                    name="images"
                    tooltip="Image Resolution has to be less than 800*450px. Image file size has to be less than 1MB"
                    label={t('org_message.create.image_label')}
                  >
                    <Input
                      size="large"
                      placeholder={t('org_message.create.image_placeholder')}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={imageUploadHandler}
                    />
                    {uploadImageLoading && 'Uploading...'}
                    {uploadImage && (
                      <img
                        src={uploadImage}
                        alt=""
                        className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="w-full lg:w-1/2">
                  <Form.Item
                    className="mb-6 file-uploader"
                    name="files"
                    label="Add File"
                    tooltip="File size has to be less than 20MB"
                  >
                    <Input
                      size="large"
                      placeholder={t('org_message.create.image_placeholder')}
                      type="file"
                      accept="application/pdf"
                      onChange={fileUploadHandler}
                    />
                    {uploadFileLoading && 'Uploading...'}
                    {uploadFile && (
                      <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                        <BiFile className="text-blue-link text-lg" />
                        <a
                          className="text-sm font-bold text-blue-link pl-2 truncate"
                          href={uploadFile ? uploadFile : '#'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          File Attachment
                        </a>
                      </div>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                <div className="w-full lg:w-1/2">
                  <Form.Item
                    className="mb-6"
                    name="videos"
                    label="Video URL"
                    rules={[
                      {
                        type: 'url',
                        message: 'This field must be a valid url.',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t('org_message.create.video_placeholder')}
                      onChange={(e) =>
                        setCreateMessageFormData({
                          ...createMessageFormData,
                          videos: isEmpty(e.target.value)
                            ? []
                            : [e.target.value],
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                <div className="w-full lg:w-1/2">
                  <Form.Item
                      className="mb-6"
                      name="cta_text"
                      label="CTA Text"
                  >
                    <Input
                        size="large"
                        placeholder="CTA Text"
                        type="text"
                        onChange={(e) =>
                            setCreateMessageFormData({
                              ...createMessageFormData,
                              cta_text: e.target.value,
                            })
                        }
                    />
                  </Form.Item>
                </div>
                <div className="w-full lg:w-1/2">
                  <Form.Item
                      className="mb-6"
                      name="cta_link"
                      label="CTA Link"
                  >
                    <Input
                        size="large"
                        placeholder="CTA Link"
                        type="text"
                        onChange={(e) =>
                            setCreateMessageFormData({
                              ...createMessageFormData,
                              cta_link: e.target.value,
                            })
                        }
                    />
                  </Form.Item>
                </div>
              </div>

              <Form.Item className="mb-3">
                <div className="flex flex-col lg:flex-row lg:justify-between lg:space-x-8 text-center mt-4">
                  <Button
                    type="success"
                    className="w-full lg:w-1/3 mb-4 lg:mb-0"
                    size="large"
                    onClick={() => setButtonClicked('send')}
                    htmlType="submit"
                  >
                    {t('org_message.create.send_text')}
                  </Button>
                  <Button
                    type="warning"
                    className="w-full lg:w-1/3 mb-4 lg:mb-0"
                    size="large"
                    onClick={() => setButtonClicked('draft')}
                    htmlType="submit"
                  >
                    {t('org_message.create.draft_text')}
                  </Button>
                  <Button
                    type="primary"
                    className="w-full lg:w-1/3 mb-4 lg:mb-0"
                    size="large"
                    htmlType="submit"
                    onClick={() => setScheduleMessageModal(true)}
                  >
                    {t('org_message.create.schedule_text')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div className="flex justify-center w-full lg:w-1/3">
          <div className="mockup-phone">
            <div className="message">
              {currentOrgLoading ? (
                <Space>
                  <Skeleton.Image className="mr-2 mb-1" />
                  <Skeleton.Input
                    style={{ width: 200 }}
                    active={currentOrgLoading}
                  />
                </Space>
              ) : (
                <div className="message-header">
                  <div className="flex items-center mb-2">
                    {selectedOrganisationUUID ? (
                      <div className="logo">
                        <img src={currentOrg?.logo} alt="logo" />
                      </div>
                    ) : (
                      <Skeleton.Image className="mr-2 mb-1" />
                    )}

                    <div className="message-sender">
                      <div className="font-bold text-base mb-2 leading-none">
                        {selectedOrganisationUUID
                          ? currentOrg?.name
                          : 'Organisation Name'}
                      </div>
                      <div className="text-xs text-grey-600">
                        {createMessageFormData?.organization
                          ? new Date().toLocaleString() + ''
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="message-header-title text-base font-bold leading-5 border-b border-grey-300 pb-4 mb-4">
                    {createMessageFormData?.title
                      ? createMessageFormData?.title
                      : 'Message Title'}
                  </div>
                </div>
              )}
              <div className="message-content">
                <div
                  className="pb-4"
                  dangerouslySetInnerHTML={{
                    __html: `${createMessageFormData?.body
                      ? createMessageFormData?.body
                      : 'Message Body'
                      }`,
                  }}
                ></div>
                {uploadImage && (
                  <img
                    src={uploadImage}
                    alt=""
                    className="w-full h-auto p-2 mt-3 border border-grey-300 mx-auto rounded"
                  />
                )}
              </div>
              <div className="message-footer px-4 pb-5">
                {!isEmpty(createMessageFormData?.videos[0]) && (
                  <ReactPlayer
                    className="youtube-embed"
                    width="100%"
                    height="auto"
                    url={createMessageFormData?.videos[0]}
                    config={{ youtube: { playerVars: { showinfo: 1 } } }}
                  />
                )}
                {uploadFile && (
                  <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                    <BiFile className="text-blue-link text-lg" />
                    <a
                      className="text-sm font-bold text-blue-link pl-2"
                      href={uploadFile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File Attachment
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={scheduleMessageModal}
        onOk={() => setScheduleMessageModal(false)}
        onCancel={() => setScheduleMessageModal(!scheduleMessageModal)}
        footer={false}
        centered
      >
        <div className="py-4">
          <div className="text-xl font-bold mb-4 text-center">
            Schedule Message
          </div>
          <Form
            name="createMessage"
            autoComplete="off"
            layout="vertical"
            onFinish={scheduleMessageFormHandler}
          >
            <Form.Item
              className="mb-6"
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}
            >
              <DatePicker
                className="w-full"
                size="large"
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  current && current < moment().startOf('day')
                }
              />
            </Form.Item>
            <Form.Item
              className="mb-8"
              name="time"
              label={`Time (Timezone: ${zone}/${timezone})`}
              format="HH:mm:ss"
              rules={[
                {
                  required: true,
                  message: 'Please select time!',
                },
              ]}
            >
              <TimePicker
                className="w-full"
                size="large"
                disabledTime={(current) =>
                  current && current < moment().startOf('minute')
                }
              />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-between">
                <Button
                  size="large"
                  type="success"
                  className="w-64 mr-4"
                  htmlType="submit"
                  onClick={() => setButtonClicked('schedule')}
                >
                  Schedule Message
                </Button>
                <Button
                  size="large"
                  onClick={() => setScheduleMessageModal(false)}
                  className="w-64"
                >
                  Close
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default CreateNewMessage;
