import { formatCurrency } from '../../utils/numbers';
import React from 'react';
import { BiWallet } from 'react-icons/bi';
import '../../utils/numbers';
import { useTranslation } from 'react-i18next';

export default function WalletSummary({ data }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100">
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-3 rounded-full bg-blue-100 text-blue-500 border border-blue-100 mr-4 bg-opacity-25">
            <BiWallet size={24} />
          </div>
          <div className="flex flex-col">
            <div className="text-md font-medium mb-1">Total Wallet Balance</div>
            <div className="text-2xl order-last font-extrabold leading-none">
              SGD{' '}
              <span className="text-green-anak">
                {' '}
                {formatCurrency(data?.total_wallet_balance)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="font-medium">
          <div className="text-md font-medium mb-1">
            {t('dashboard.wallet_details.transaction_today')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="text-green-anak">
              {' '}
              {formatCurrency(data?.today_transactions)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md font-medium mb-1">
            {t('dashboard.wallet_details.transaction_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="text-green-anak">
              {' '}
              {formatCurrency(data?.this_month_transactions)}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
