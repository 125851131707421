import * as types from '../actionTypes/wallet';

const initialState = {
  walletList: null,
  walletListLoading: false,
  walletListError: null,
  walletSummary: null,
  walletSummaryLoading: false,
  walletSummaryError: null,
  currentWallet: null,
  currentWalletLoading: false,
  currentWalletError: null,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_WALLET:
      return {
        ...state,
        walletListLoading: true,
      };
    case types.LIST_WALLET_SUCCESS:
      return {
        ...state,
        walletList: action.payload,
        walletListLoading: false,
      };
    case types.LIST_WALLET_FAILURE:
      return {
        ...state,
        walletListLoading: false,
        walletListError: action.payload,
      };
    case types.GET_WALLET_SUMMARY:
      return {
        ...state,
        walletSummaryLoading: true,
      };
    case types.GET_WALLET_SUMMARY_SUCCESS:
      return {
        ...state,
        walletSummary: action.payload,
        walletSummaryLoading: false,
      };
    case types.GET_WALLET_SUMMARY_FAILURE:
      return {
        ...state,
        walletSummaryLoading: false,
        walletSummaryError: action.payload,
      };
    case types.GET_WALLET:
      return {
        ...state,
        currentWalletLoading: true,
      };
    case types.GET_WALLET_SUCCESS:
      return {
        ...state,
        currentWallet: action.payload,
        currentWalletLoading: false,
      };
    case types.GET_WALLET_FAILURE:
      return {
        ...state,
        currentWalletLoading: false,
        currentWalletError: action.payload,
      };
    default:
      return state;
  }
};

export default walletReducer;
