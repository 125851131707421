import * as types from '../actionTypes/system-users';

// SYSTEM USER TABLE LISTING
export const listSystemUsersAction = (tableParams) => {
  return {
    type: types.LIST_SYSTEM_USERS,
    payload: tableParams,
  };
};

export const listSystemUsersSuccessAction = (users) => {
  return {
    type: types.LIST_SYSTEM_USERS_SUCCESS,
    payload: users,
  };
};

export const listSystemUsersFailedAction = (error) => {
  return {
    type: types.LIST_SYSTEM_USERS_FAILED,
    payload: error,
  };
};

// CREATE SYSTEM USERS
export const createSystemUserAction = (values) => {
  return {
    type: types.CREATE_SYSTEM_USER,
    payload: values,
  };
};
export const createSystemUserSuccessAction = (user) => {
  return {
    type: types.CREATE_SYSTEM_USER_SUCCESS,
    payload: user,
  };
};
export const createSystemUserFailAction = (error) => {
  return {
    type: types.CREATE_SYSTEM_USER_FAIL,
    payload: error,
  };
};

// VIEW SYSTEM USER
export const viewSystemUserAction = (userId) => {
  return {
    type: types.VIEW_SYSTEM_USER,
    payload: userId,
  };
};
export const viewSystemUserSuccessAction = (currentUser) => {
  return {
    type: types.VIEW_SYSTEM_USER_SUCCESS,
    payload: currentUser,
  };
};
export const viewSystemUserFailAction = (error) => {
  return {
    type: types.VIEW_SYSTEM_USER_FAIL,
    payload: error,
  };
};

// EDIT SYSTEM USERS
export const editSystemUserAction = (updatedFormValue, userId) => {
  return {
    type: types.EDIT_SYSTEM_USER,
    payload: { updatedFormValue, userId },
  };
};
export const editSystemUserSuccessAction = (updatedUser) => {
  return {
    type: types.EDIT_SYSTEM_USER_SUCCESS,
    payload: updatedUser,
  };
};
export const editSystemUserFailAction = (error) => {
  return {
    type: types.EDIT_SYSTEM_USER_FAIL,
    payload: error,
  };
};

// UPDATE USER STATUS
export const changeSystemUserStatusAction = (userStatus, uuid) => {
  return {
    type: types.CHANGE_USER_STATUS,
    payload: { userStatus, uuid },
  };
};
export const changeSystemUserStatusSuccessAction = (user) => {
  return {
    type: types.CHANGE_USER_STATUS_SUCCESS,
    payload: user,
  };
};
export const changeSystemUserStatusFailAction = (error) => {
  return {
    type: types.CHANGE_USER_STATUS_FAIL,
    payload: error,
  };
};

//RESET USER PASSWORD
export const resetSystemUserPasswordAction = (email) => {
  return {
    type: types.RESET_USER_PASSWORD,
    payload: email,
  };
};
export const resetSystemUserPasswordSuccessAction = () => {
  return {
    type: types.RESET_USER_PASSWORD_SUCCESS,
    // payload: user,
  };
};
export const resetSystemUserPasswordFailAction = (error) => {
  return {
    type: types.RESET_USER_PASSWORD_FAIL,
    payload: error,
  };
};
