export const nationality_choices = [
    "Indonesian",
    "Malaysian",
    "Singaporean",
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
]
export const ethnicity_choices = [
    "Javanese",
    "Sundanese",
    "Malay",
    "Batak",
    "Madurese",
    "Betawi",
    "Minangkabau",
    "Buginese",
    "Bantenese",
    "Banjarese",
    "Balinese",
    "Acehnese",
    "Dayak",
    "Sasak",
    "Chinese Indonesian",
    "Makassarese",
    "Cirebonese",
    "Lampungese",
    "Palembangese",
    "Gorontaloan",
    "Minahasan",
    "Nias",
    "Indonesians",
    "Tamil Indonesians",
    "Native Indonesians",
    "Boyanese",
    "Gayonese",
    "Rampasasa",
    "Orang Kuala",
    "Alor tribe",
    "Naga",
    "Bukitan",
    "Ekari",
    "Kangeanese Sa'ban",
    "Bonerate",
    "Baduy",
    "Merau Malays",
    "Lamaholot",
    "Torajan",
    "Asmat",
    "Ambonese",
]
export const martial_choices = [
    "Married",
    "Widowed",
    "Separated",
    "Divorced",
    "Singled",
]
export const religion_choices = [
    "Islam",
    "Protestantism",
    "Catholicism",
    "Buddhism",
    "Hinduism",
    "Free Thinker",
]
export const gender_choices = [
    "Male",
    "Female",
]
export const hobbies_choices = [
    "Cooking",
    "Dance",
    "Gardening",
    "Shopping",
    "Photography",
    "Learning",
    "Painting",
    "Acting",
    "Drawing",
    "Sports",
    "Foreign Language - English",
    "Foreign Language - Japanese",
    "Foreign Language - Chinese",
    "Foreign Language - Korean",
]
export const experience_choices = [
    "Domestic Helper",
    "Construction",
    "Manufacturing",
    "Plantation",
    "Service - Restaurant, Tourism",
    "Shipping - Crew",
    "Healthcare - Nurse",
    "Logistics - Driver, Mover",
]
export const language_choices = [
    "Indonesia",
    "English",
    "Korean",
    "Japanese",
    "Traditional Chinese",
    "Simplified Chinese",
    "Arabic",
    "Cantonese",
    "Thai",
    "Turkish",
    "Others",
]
export const education_choices = [
    "Early Childhood",
    "Primary",
    "Secondary",
    "Junior School",
    "High School",
    "Vocation School",
    "University",
]
export const upskilling_interest_choices = [
    "Banking and Finance",
    "Digital IT",
    "Services",
    "Manufacturing",
    "Shipping",
    "Domestic care",
    "Healthcare",
]
export const local_working_experience = [
    "0 years",
    "1-2 years",
    "3-5 years",
    "Above 5 years",
]
export const overseas_working_experience = [
    "0 years",
    "1-2 years",
    "3-5 years",
    "Above 5 years",
]
export const driving_licence_choices = [
    "SIM A",
    "SIM B1",
    "SIM B2",
    "SIM C",
    "Others",
]
export const job_interest_choices = [
    "Domestic Helper",
    "Construction",
    "Manufacturing",
    "Plantation",
    "Service - Restaurant, Tourism",
    "Shipping - Crew",
    "Healthcare - Nurse",
    "Logistics - Driver, Mover",
]
export const yes_or_no = [
    "Yes",
    "No"
]