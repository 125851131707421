import { isEmpty } from '../utils/generic';
import axiosClient from './config';

export const downloadService = async ({
	url,
	requestParams,
	startDate,
	endDate,
}) => {
	let transctionCategory = requestParams?.category
	if(isEmpty(transctionCategory))
		return await axiosClient.get(`${url}/download/?created_at_after=${startDate}&created_at_before=${endDate}`);
	else
		return await axiosClient.get(`${url}/download/?category=${transctionCategory}&created_at_after=${startDate}&created_at_before=${endDate}`);
};