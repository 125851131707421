import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listCustomersAction } from '../../store/actions/customerActions';
import { Link } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import {getWalletStatus} from "../../utils/get-wallet-status";

function CustomersTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [];
  const csvExportHeader = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Mobile Number', key: 'mobile' },
    { label: 'Registered On', key: 'created_at' },
    { label: 'Updated At', key: 'updated_at' },
  ];
  const { customersList, customersListLoading, customersListError } =
    useSelector((state) => state.customers);
  const { Column } = Table;

  const ExpandedRow = ({ record }) => {
    return (
        <div style={{textAlign: 'left'}}>
          <div>
            <strong>Account ID</strong>
            <p>{record.wallex_accountId}</p>
          </div>
          <div className="mt-4">
            <strong>User ID</strong>
            <p>{record.wallex_userId}</p>
          </div>
        </div>
    )
  }

  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listCustomersAction}
      data={customersList}
      loading={customersListLoading}
      error={customersListError}
      csvExportHeader={csvExportHeader}
      downloadUrl="customers"
      expandable={
        {
          expandedRowRender: record => <ExpandedRow record={record} />,
          rowExpandable: record => record.wallex_accountId !== null
        }
      }
    >
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Mobile Number"
        dataIndex="mobile"
        key="mobile"
        align="left"
        render={(text, record) => (`+${record.mobile_country} ${record.mobile}`)}
        width={"18%"}
      />
      <Column
          title="Wallet Status"
          dataIndex="wallex_status"
          key="wallex_status"
          render={(text, record) => getWalletStatus(record?.wallex_status)}
      />
      <Column
        title="Registered On"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="Updated At"
        dataIndex="updated_at"
        key="updated_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.updated_at)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/customers/list/${record.id}`}>View Details</Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default CustomersTable;
