import * as types from '../actionTypes/services-igw';

const initialState = {
  listIGWServices: null,
  listIGWServicesLoading: false,
  listIGWServicesError: null,
  igwDetailsList: null,
  igwDetailsListLoading: false,
  igwDetailsListError: null,
  currentIGWService: null,
  currentIGWServiceLoading: false,
  currentIGWServiceError: null,
  updatedIGWDetails: null,
  updatedIGWDetailsLoading: false,
  updatedIGWDetailsError: null
};

const igwServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_IGW_SERVICES:
      return {
        ...state,
        listIGWServicesLoading: true,
      };
    case types.LIST_IGW_SERVICES_SUCCESS:
      return {
        ...state,
        listIGWServices: action.payload,
        listIGWServicesLoading: false,
      };
    case types.LIST_IGW_SERVICES_FAILED:
      return {
        ...state,
        listIGWServicesLoading: false,
        listIGWServicesError: action.payload,
      };
    case types.GET_IGW_DETAILS_LIST:
      return {
        ...state,
        igwDetailsListLoading: true,
      };
    case types.GET_IGW_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        igwDetailsList: action.payload,
        igwDetailsListLoading: false,
      };
    case types.GET_IGW_DETAILS_LIST_FAILED:
      return {
        ...state,
        igwDetailsListLoading: false,
        igwDetailsListError: action.payload,
      };
    case types.GET_IGW_DETAILS:
      return {
        ...state,
        currentIGWServiceLoading: true,
      };
    case types.GET_IGW_DETAILS_SUCCESS:
      return {
        ...state,
        currentIGWService: action.payload,
        currentIGWServiceLoading: false,
      };
    case types.GET_IGW_DETAILS_FAILED:
      return {
        ...state,
        currentIGWServiceLoading: false,
        currentIGWServiceError: action.payload,
      };
    case types.EDIT_IGW_DETAILS:
      return {
        ...state,
        updatedIGWDetailsLoading: true,
      };
    case types.EDIT_IGW_DETAILS_SUCCESS:
      return {
        ...state,
        updatedIGWDetails: action.payload,
        updatedIGWDetailsLoading: false,
      };
    case types.EDIT_IGW_DETAILS_FAIL:
      return {
        ...state,
        updatedIGWDetailsLoading: false,
        updatedIGWDetailsError: action.payload,
      };
    default:
      return state;
  }
};

export default igwServiceReducer;
