import React, { useState } from 'react';
import { Drawer, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionAction } from '../../../store/actions/transactionActions';
import { utcToLocalDateTime } from '../../../utils/dateFormat';
import { camelCaseString } from '../../../utils/string-helpers';
import { TopUpSkeleton } from '../../../components/skeleton/WidgetSkeleton'

const TopUpTransactionDetails = ({ transactionId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = useState(false);
  const showDetails = () => {
    setOpenDetails(true);
    dispatch(getTransactionAction(transactionId));
  };
  const { getTransaction, getTransactionLoading, getTransactionError } =
    useSelector((state) => state.transaction);
  const hideDetails = () => {
    setOpenDetails(false);
  };
  return (
    <>
      <div
        className="text-blue-link font-bold cursor-pointer"
        onClick={showDetails}
      >
        View Details
      </div>

      <Drawer
        title="Top Up Details"
        onClose={hideDetails}
        visible={openDetails}
      >
        {getTransactionError ? (
          <div>Failed to get transaction</div>
        ) : getTransactionLoading ? (
          <div className="grid grid-cols-1 justify-items-center text-center">
            <TopUpSkeleton />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-y-3 justify-items-center text-center pb-6">
              <Avatar>
                {getTransaction?.recipient_user?.name?.[0] || 'U'}
              </Avatar>
              <div className="">
                <div className="text-gray-600 mb-1">Receiver</div>
                <div className="text-base font-bold">
                  {getTransaction?.recipient_user?.name}
                </div>
                <div className="text-base font-bold">
                  +{getTransaction?.recipient_user.mobile_country} {getTransaction?.recipient_user?.mobile}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.amount')}
                </div>
                <div className="text-3xl font-bold flex">
                  SGD
                  <span
                    className={`text-${getTransaction?.status === 1
                      ? 'green'
                      : getTransaction?.status === 2
                        ? 'orange'
                        : 'red'
                      }-anak flex items-center mx-2`}
                  >
                    {getTransaction?.amount}
                    {/* <BsFillArrowDownLeftSquareFill size={18} className="ml-2" /> */}
                  </span>
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.date_time')}
                </div>
                <div className="text-base font-bold">
                  {utcToLocalDateTime(getTransaction?.created_at)}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">
                  {t('p2p_transactions.details_modal.status')}
                </div>
                <div className="text-base font-bold">
                  {camelCaseString(getTransaction?.topup?.status)}
                </div>
              </div>
              <div className="">
                <div className="text-gray-600 mb-1">Reference No</div>
                <div className="text-base font-bold">
                  {getTransaction?.topup?.reference_no}
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default TopUpTransactionDetails;
