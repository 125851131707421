import axiosClient from './config';

export const listCustomerCasesService = async ({
  page,
  search,
  ordering,
  status,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/customer-case/?page=${page}&search=${search}&status=${status}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};
export const getCustomerCaseService = async (case_uuid) => {
  return await axiosClient.get(`/customer-case/${case_uuid}/`);
};
export const getCasesSummaryService = async () => {
  return await axiosClient.get(`/customer-case/summary/`);
};
export const updateCustomerCaseService = async (submitUrl, caseData) => {
  return await axiosClient.post(`/${submitUrl}`, caseData);
};
export const getCustomerCaseHistoryService = async (case_uuid) => {
  return await axiosClient.get(`/customer-case/${case_uuid}/history/`);
};