import * as types from '../actionTypes/kyc-details';

export const getKycOverviewAction = () => {
  return {
    type: types.GET_KYC_OVERVIEW,
  }
};

export const getKycOverviewSuccessAction = (stats) => {
  return {
    type: types.GET_KYC_OVERVIEW_SUCCESS,
    payload: stats,
  }
}

export const getKycOverviewFailedAction = (error) => {
  return {
    type: types.GET_KYC_OVERVIEW_FAILURE,
  }
}

export const listKYCAction = (tableParams) => {
  return {
    type: types.LIST_KYC,
    payload: tableParams,
  };
};
export const listKYCSuccessAction = (kyc) => {
  return {
    type: types.LIST_KYC_SUCCESS,
    payload: kyc,
  };
};
export const listKYCFailedAction = (error) => {
  return {
    type: types.LIST_KYC_FAILURE,
    payload: error,
  };
};

export const getKYCAction = (kycId) => {
  return {
    type: types.GET_KYC,
    payload: kycId,
  };
};
export const getKYCSuccessAction = (kyc) => {
  return {
    type: types.GET_KYC_SUCCESS,
    payload: kyc,
  };
};
export const getKYCFailedAction = (error) => {
  return {
    type: types.GET_KYC_FAILURE,
    payload: error,
  };
};

export const getKYCDocumentAction = (customer_id, kyc_attempt_id) => {
  return {
    type: types.GET_KYC_DOCUMENT,
    payload: { customer_id, kyc_attempt_id },
  };
};
export const getKYCDocumentSuccessAction = (details) => {
  return {
    type: types.GET_KYC_DOCUMENT_SUCCESS,
    payload: details,
  };
};
export const getKYCDocumentFailedAction = (error) => {
  return {
    type: types.GET_KYC_DOCUMENT_FAILURE,
    payload: error,
  };
};
