import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import BoxedShadow from '../../components/widget/boxed-shadow';
import { Form, Input, Button, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  editOrganisationAction,
  viewOrganisationAction,
} from '../../store/actions/organisationActions';
import { listOrgCategoryAction } from '../../store/actions/orgCategoryActions';
import { useParams } from 'react-router-dom';
import { isEmpty } from '../../utils/generic';
import { imageUploadHandler } from '../../utils/upload';
import useDebounce from '../../hooks/useDebounce';
import { resetImageUploadAction } from '../../store/actions/uploadAction';
import QuillEditor from '../../components/quill-editor';

function EditOrganisation() {
  const { organization_uuid } = useParams();
  const [currentOrgCategoryUUID, setCurrentOrgCategoryUUID] = useState(null);
  const [searchCurrentOrgCategory, setSearchCurrentOrgCategory] = useState('');
  const debouncedOrgCategorySearchValue = useDebounce(
    searchCurrentOrgCategory,
    500
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { upload, orgCategory, organisation } = useSelector(
    (state) => ({
      upload: state.upload,
      orgCategory: state.orgCategory,
      organisation: state.organisation,
    }),
    shallowEqual
  );
  const { uploadImage } = upload;
  const { orgCategoriesList, orgCategoriesListLoading } = orgCategory;
  const { currentOrg, currentOrgLoading } = organisation;
  const [isBahasaInputActive, setIsBahasaInputActive] = useState(
    !isEmpty(currentOrg?.description?._id)
  );
  let title = t('add_organisation.title_edit');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'Organisations',
      url: '/organisations/list',
      last: false,
    },
    { title, url: '/', last: true },
  ];
  const editOrgInitialValue = {
    name: currentOrg?.name,
    category: currentOrg?.category,
    description_en: currentOrg?.description?._en,
    description_id: currentOrg?.description?._id,
    category_uuid: currentOrg?.category_uuid,
    logo: currentOrg?.logo,
    email: currentOrg?.email,
    phone: currentOrg?.phone,
    website: currentOrg?.website,
  };
  const listCategoryInitialValue = {
    page: 1,
    search: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const { Option } = Select;
  const listCategorySearchHandler = (value) => {
    setSearchCurrentOrgCategory(value);
  };
  const editOrgFormHandler = ({
    email,
    name,
    phone,
    website,
    description_en,
    description_id,
  }) => {
    const updatedFormValue = {
      category: currentOrgCategoryUUID || currentOrg?.category_uuid,
      logo: uploadImage || currentOrg?.logo,
      email,
      name,
      phone,
      website,
      description: {
        _en: description_en || '',
        _id: description_id || '',
      },
    };
    dispatch(editOrganisationAction(organization_uuid, updatedFormValue));
  };

  useEffect(() => {
    dispatch(resetImageUploadAction());
    if (isEmpty(organisation.currentOrg)) {
      dispatch(viewOrganisationAction(organization_uuid));
    }
    if (debouncedOrgCategorySearchValue !== '') {
      const timer = setTimeout(() => {
        dispatch(
          listOrgCategoryAction({
            ...listCategoryInitialValue,
            search: debouncedOrgCategorySearchValue,
          })
        );
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else {
      dispatch(listOrgCategoryAction(listCategoryInitialValue));
    }
  }, [debouncedOrgCategorySearchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      {currentOrgLoading ? (
        <div className="flex justify-center">
          <Spin />
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
          <BoxedShadow className="w-full lg:w-2/3">
            <Form
              name="createOrganisation"
              autoComplete="off"
              layout="vertical"
              // form={editOrganisationForm}
              onFinish={editOrgFormHandler}
              scrollToFirstError
              initialValues={editOrgInitialValue}
            >
              <div className="flex flex-col ">
                <div className="">
                  <Form.Item
                    name="name"
                    label={t('add_organisation.name_label')}
                    className="mb-6"
                    rules={[
                      {
                        required: true,
                        message: 'Please input organisation name!',
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z0-9]*[^=+@<>-]*$/),
                        message: "This field does not accept =, +, -, @, <, >"
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t('add_organisation.name_placeholder')}
                    />
                  </Form.Item>
                </div>

                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                    <Form.Item
                      className="mb-6"
                      name="category"
                      label={t('add_organisation.category_label')}
                      rules={[
                        {
                          required: true,
                          message: 'Please input organisation category!',
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        // labelInValue
                        filterOption={false}
                        showSearch
                        onSearch={listCategorySearchHandler}
                        placeholder={t('add_organisation.category_placeholder')}
                        onChange={(value) => {
                          setCurrentOrgCategoryUUID(value);
                          dispatch(
                            listOrgCategoryAction(listCategoryInitialValue)
                          );
                        }}
                      >
                        {orgCategoriesListLoading ? (
                          <Option disabled value="spin">
                            <Spin size="small" />
                          </Option>
                        ) : (
                          orgCategoriesList?.results?.map((item) => (
                            <Option key={item?.uuid} value={item?.uuid}>
                              {item?.name}
                            </Option>
                          ))
                        )}
                        {isEmpty(orgCategoriesList?.results) && (
                          <Option disabled value="none">
                            No organisation found
                          </Option>
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6 file-uploader"
                      name="logo"
                      label={`${t('add_organisation.logo_label')}`}
                      tooltip="Image should be less than 1MB"
                      required
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please upload organisation logo!',
                    //   },
                    // ]}
                    >
                      <Input
                        size="large"
                        placeholder={t('add_organisation.logo_placeholder')}
                        type="file"
                        accept="image/*"
                        onChange={imageUploadHandler}
                      />
                      {(currentOrg?.logo || uploadImage) && (
                        <img
                          src={uploadImage || currentOrg?.logo}
                          alt=""
                          className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <Form.Item
                    className="mb-6 relative"
                    tooltip="Max 3000 characters"
                    name="description_en"
                    label="Description (English)"
                    rules={[
                      {
                        required: true,
                        message: 'Please input description!',
                      },
                    ]}
                  >
                    <QuillEditor value={currentOrg?.description?._en} />
                  </Form.Item>
                </div>
                {isBahasaInputActive && (
                  <div>
                    <Form.Item
                      className="mb-6 relative"
                      tooltip="Max 3000 characters"
                      name="description_id"
                      label="Description (Bahasa)"
                      rules={[
                        {
                          required: true,
                          message: 'Please input description!',
                        },
                      ]}
                    >
                      <QuillEditor value={currentOrg?.description?._id} />
                    </Form.Item>
                  </div>
                )}
                {isBahasaInputActive ? (
                  <div className="mb-6 text-right">
                    <Button
                      type="error"
                      className="px-6 rounded"
                      onClick={() => setIsBahasaInputActive(false)}
                    >
                      Remove Bahasa Description
                    </Button>
                  </div>
                ) : (
                  <div className="mb-6 text-right">
                    <Button
                      type="primary"
                      className="px-6 rounded"
                      onClick={() => setIsBahasaInputActive(true)}
                    >
                      Add Bahasa Description
                    </Button>
                  </div>
                )}

                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6"
                      name="email"
                      label={t('add_organisation.email_label')}
                      rules={[
                        { required: true, message: 'Please input email!' },
                        {
                          type: 'email',
                          message: 'Please input valid email id!',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t('add_organisation.email_placeholder')}
                      />
                    </Form.Item>
                  </div>

                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6"
                      name="phone"
                      label={t('add_organisation.phone_label')}
                      rules={[
                        {
                          required: true,
                          message: 'Please input organisation phone!',
                        },
                        {
                          pattern: /^[+]?[0-9 ]{8,15}$/,
                          message: 'Please input valid Mobile Number!',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t('add_organisation.phone_placeholder')}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6"
                      name="website"
                      label={t('add_organisation.url_label')}
                      rules={[
                        {
                          required: true,
                          message: 'Please input organisation website!',
                        },
                        {
                          type: 'url',
                          message: 'This field must be a valid url.',
                        },
                      ]}
                      extra="Note: Please ensure the url ends with a slash “/”, e.g. https://www.example.com/"
                    >
                      <Input
                        size="large"
                        placeholder={t('add_organisation.url_placeholder')}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
                <div className="flex mt-6">
                  <Button
                    type="success"
                    size="large"
                    className="mr-4 w-full lg:w-64 mb-4 lg:mb-0"
                    htmlType="submit"
                  >
                    {t('add_organisation.confirm_text')}
                  </Button>
                  {/* <Button size="large" className="w-full lg:w-64">Cancel</Button> */}
                </div>
              </div>
            </Form>
          </BoxedShadow>
        </div>
      )}
    </>
  );
}

export default EditOrganisation;
