import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { DefaultTabs } from '../../components/tabs';
import TabDescription from '../../components/tabs/tab-description';
import TabContact from '../../components/tabs/tab-contact';
import { Button, Drawer, Radio, Modal, Skeleton, Tooltip } from 'antd';
import { BiCheckCircle, BiPlusCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteOrganisationAction,
  editOrganisationAction,
  viewOrganisationAction,
} from '../../store/actions/organisationActions';
import OrgMessageTable from '../org-message/org-message-table';
import OrgUsersTable from '../org-users/org-user-table';
import OrgSubscribersTable from './org-subscriber-table';
import {
  OrgDetailsSkeleton,
  TabSkeleton,
} from '../../components/skeleton/WidgetSkeleton';
import OrgWelcomeMessage from '../organisation/org-welcome-message';

function OrgDetails() {
  const { t } = useTranslation();
  const { organization_uuid } = useParams();
  const dispatch = useDispatch();
  const { currentOrg, currentOrgLoading } = useSelector(
    (state) => state.organisation
  );
  const { url } = useRouteMatch();
  useEffect(() => {
    dispatch(viewOrganisationAction(organization_uuid));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  let title = currentOrg?.name || t('org_details.section_title');
  let categoryBreadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'Category',
      url: '/organisations/categories',
      last: false,
    },
    {
      home: false,
      title: currentOrg?.category || 'Org Category',
      url: organization_uuid
        ? `/organisations/categories/${currentOrg?.category_uuid}`
        : '/',
      last: false,
    },
    { home: false, title: currentOrg?.name || title, url: '', last: true },
  ];
  let organisationBreadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'Organisations',
      url: '/organisations/list',
      last: false,
    },
    { home: false, title: 'Details', url: '', last: true },
  ];
  const tabs = [
    {
      index: 0,
      title: t('org_details.tab_description'),
      active: true,
      content: (
        <TabDescription
          body={currentOrgLoading ? 'Loading..' : currentOrg?.description}
        />
      ),
    },
    {
      index: 1,
      title: 'Org Admins',
      active: false,
      content: <OrgUsersTable org_uuid={organization_uuid} />,
    },
    {
      index: 2,
      title: t('org_details.tab_message'),
      active: false,
      content: (
        <div>
          <div className="flex lg:flex-row-reverse mb-6">
            <Link to={`${url}/new`}>
              <Button
                className="flex justify-center items-center"
                type="success"
                size="large"
                icon={<BiPlusCircle className="mr-2 w-6 h-6" />}
              >
                {t('org_message.create.button_text')}
              </Button>
            </Link>
          </div>
          <OrgMessageTable org_uuid={organization_uuid} />
        </div>
      ),
    },
    {
      index: 3,
      title: t('org_details.tab_contact'),
      active: false,
      content: (
        <TabContact
          email={currentOrg?.email}
          phone={currentOrg?.phone}
          website={currentOrg?.website}
        />
      ),
    },
    {
      index: 4,
      title: 'Org Subscribers',
      active: false,
      content: <OrgSubscribersTable />,
    },
    {
      index: 5,
      title: 'Welcome Message',
      active: false,
      content: <OrgWelcomeMessage />,
    },
  ];
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [orgStatus, setOrgStatus] = useState(currentOrg?.is_active);
  const [isOrgStatusDrawerVisible, setIsOrgStatusDrawerVisible] =
    useState(false);

  const deleteOrgHandler = () => {
    dispatch(deleteOrganisationAction(organization_uuid));
    setIsDeleteModalVisible(false);
  };
  const orgStatusDrawerHandler = () => {
    setIsOrgStatusDrawerVisible(true);
    setOrgStatus(currentOrg?.is_active);
  };
  const changeOrgStatusHandler = () => {
    dispatch(
      editOrganisationAction(organization_uuid, { is_active: orgStatus })
    );
  };
  return (
    <>
      <SectionTitle
        title={title}
        right={
          <Breadcrumb
            items={
              url.includes('/categories/')
                ? categoryBreadcrumbsItem
                : organisationBreadcrumbsItem
            }
          />
        }
      />
      <div className="w-full flex flex-col lg:flex-row lg:space-x-4">
        {currentOrgLoading ? (
          <>
            <OrgDetailsSkeleton />
          </>
        ) : (
          <>
            <div className="w-full lg:w-1/3 bg-white min-h-48 border border-grey-200 rounded-sm p-4 flex justify-center items-center">
              {currentOrgLoading ? (
                <Skeleton.Image className="mr-2 mb-1" />
              ) : (
                <img src={currentOrg?.logo} alt="" />
              )}
            </div>
            <div className="flex flex-col">
              <div className="text-md">Organisation Name</div>
              {currentOrgLoading ? (
                <Skeleton.Input
                  style={{ width: 200 }}
                  active={currentOrgLoading}
                />
              ) : (
                <div className="font-bold text-xl pr-4 my-2">
                  {currentOrg?.name}
                </div>
              )}
              <div className="my-2">
                {/* <div className="flex items-center align-middle"> */}
                <div className="text-md mr-2">{t('org_details.status')}</div>

                {/* </div> */}
                <div className="flex items-center text-lg font-bold">
                  <Tooltip
                    placement="top"
                    title="Click to change organisation status."
                  >
                    {currentOrgLoading ? (
                      <Skeleton.Input style={{ width: 200 }} />
                    ) : currentOrg?.is_active ? (
                      <div className="flex">
                        <span
                          className="text-blue-link underline cursor-pointer"
                          onClick={orgStatusDrawerHandler}
                        >
                          Active{' '}
                        </span>
                        <span className="ml-2">
                          <BiCheckCircle
                            size={24}
                            className="text-green-anak"
                          />
                        </span>
                      </div>
                    ) : (
                      <span
                        className="text-grey-700 underline cursor-pointer"
                        onClick={() => setIsOrgStatusDrawerVisible(true)}
                      >
                        Inactive{' '}
                      </span>
                    )}
                  </Tooltip>
                </div>
              </div>
              <div className="my-2">
                <div className="text-md">{t('org_details.category')}</div>
                {currentOrgLoading ? (
                  <Skeleton.Input
                    style={{ width: 200 }}
                    active={currentOrgLoading}
                  />
                ) : (
                  <div className="flex items-center text-lg font-bold">
                    {currentOrg?.category}
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:flex-row my-4">
                <Link to={`/organisations/list/${organization_uuid}/edit`}>
                  <Button
                    type="success"
                    size="large"
                    className="mr-0 w-full mb-4 lg:mb-0 lg:mr-4"
                  >
                    {t('org_details.edit_modal.button_text')}
                  </Button>
                </Link>
                <Button
                  type="danger"
                  size="large"
                  className="w-full lg:w-auto lg:ml-4"
                  onClick={() => {
                    setIsDeleteModalVisible(!isDeleteModalVisible);
                  }}
                >
                  {t('org_details.delete_modal.button_text')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>

      {currentOrgLoading ? (
        <>
          <div className="my-6">
            <TabSkeleton />
          </div>
        </>
      ) : (
        <>
          <div className="my-6">
            <DefaultTabs tabs={tabs} />
          </div>
        </>
      )}
      {/* ORG STATUS DRAWER */}
      {!currentOrgLoading && (
        <Drawer
          title="Change Organisation Status"
          placement="right"
          onClose={() => {
            setIsOrgStatusDrawerVisible(false);
            setOrgStatus(currentOrg?.is_active);
          }}
          visible={isOrgStatusDrawerVisible}
          headerStyle={{
            fontWeight: 'bold',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Radio.Group
            className="flex flex-col"
            onChange={(e) => {
              console.log('Radio.Group', e.target.value);
              const value = e.target.value === 'active' ? true : false;
              setOrgStatus(value);
            }}
            value={orgStatus ? 'active' : 'inactive'}
          >
            <Radio className="mb-6 font-bold" value="active">
              Active
            </Radio>
            <Radio className="mb-6 font-bold" value="inactive">
              Inactive
            </Radio>
          </Radio.Group>
          <div className="flex justify-between mt-2">
            <Button
              size="large"
              onClick={changeOrgStatusHandler}
              type="success"
              className="w-64 mr-4"
              block
            >
              Save
            </Button>
            <Button
              size="large"
              onClick={() => {
                setIsOrgStatusDrawerVisible(false);
                setOrgStatus(currentOrg?.is_active);
              }}
              className="w-64"
            >
              Cancel
            </Button>
          </div>
        </Drawer>
      )}
      <Modal
        visible={isDeleteModalVisible}
        onOk={() => {
          setIsDeleteModalVisible(!isDeleteModalVisible);
        }}
        onCancel={() => {
          setIsDeleteModalVisible(false);
        }}
        centered
        footer={null}
        closable={false}
      >
        <div className="px-8 py-4 text-center">
          <div className="text-center mb-6">
            <div className="text-lg font-bold">
              {t('org_details.delete_modal.title')}
            </div>
            <div className="text-md">
              {t('org_details.delete_modal.description')}
            </div>
          </div>
          <div className="flex justify-between">
            <Button
              size="large"
              onClick={deleteOrgHandler}
              type="danger"
              className="w-64 mr-4"
            >
              {t('org_details.delete_modal.confirm_text')}
            </Button>
            <Button
              size="large"
              onClick={() => {
                setIsDeleteModalVisible(!isDeleteModalVisible);
              }}
              className="w-64"
            >
              {t('org_details.delete_modal.cancel_text')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OrgDetails;
