import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Card, Button } from 'antd';
import TableUniversal from '../../components/table';
import { Link, useParams } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { getIGWDetailsListAction } from '../../store/actions/igwServiceActions';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { BiUser } from 'react-icons/bi';
import history from "../../utils/history";

function IMWServicesDetailsTable() {

	let title = 'IGW Details';
	let breadcrumbsItem = [
		{
			home: true,
			title: 'Customer Panel',
			url: '/',
			last: false,
		},
		{
			home: false,
			title: 'IGW',
			url: '/customers/igw/',
			last: false,
		},
		{ home: false, title, url: '/', last: true },
	];

	const { subscriber_id } = useParams();


	const initialTableParams = {
		page: 1,
		search: '',
		status: '',
		ordering: '-created_at',
		page_size: 10,
		created_at_after: '',
		created_at_before: '',
		subscriber_id: subscriber_id,
	};

	const menuFilter = [];

	const csvExportHeader = [
		{ label: 'Name', key: 'name' },
		{ label: 'Email ID', key: 'email' },
		{ label: 'Mobile Number', key: 'mobile' },
		{ label: 'Submitted On', key: 'created_at' },
		{ label: 'Last Updated On', key: 'reviewed_at' },
	];
	const { igwDetailsList, igwDetailsListLoading, igwDetailsListError } =
		useSelector((state) => state.igwService);
	console.log(igwDetailsList)

	const tableData = {
		results: igwDetailsList,
	};

	const { Column } = Table;

	return (
		<>
			<SectionTitle
				title={
					<div className="flex items-center">
						{title}
					</div>
				}
				right={<Breadcrumb items={breadcrumbsItem} />}
			/>
			<div className="grid grid-cols-12 mb-5 row-auto w-full rounded-lg bg-white p-5">
				<div className="col-span-12 sm:col-span-8 pt-0 rounded-lg flex md:flex-row flex-col md:items-center items-start">
					<div className="px-5 py-1 text-lg font-bold rounded-full">
						{igwDetailsList?.results[0]?.name}
					</div>
				</div>
				<div className="col-span-12 sm:col-span-4 rounded-lg flex flex-col lg:flex-row items-center justify-end">
					<div className="flex flex-row">
						<Button
							className="ml-1 flex items-center"
							type="success"
							size="default"
							onClick={history.goBack}
							icon={<BiUser className="mr-2" size={16} />}
						>
							Back to Profile
						</Button>
					</div>
				</div>
			</div>
			<Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>

				<TableUniversal
					initialTableParams={initialTableParams}
					menuFilter={menuFilter}
					menuFilterBy="status"
					dispatchAction={getIGWDetailsListAction}
					data={tableData.results}
					loading={igwDetailsListLoading}
					error={igwDetailsListError}
					csvExportHeader={csvExportHeader}
				// downloadUrl="/organisations/43220d84-c0ee-47c5-8dbc-7fb828ec326f/services/17eee0eb-18b9-4fe0-bdd3-fcc63fc5b9b9/"
				>
					<Column
						title="Submitted On"
						dataIndex="created_at"
						key="created_at"
						render={(text, record) => utcToLocalDateTime(record?.created_at)}
						sorter={true}
						align="left"
						defaultSortOrder='descend'
					/>
					<Column
						title="Last Updated On"
						dataIndex="reviewd_at"
						key="reviewd_at"
						align='left'
						render={(text, record) => utcToLocalDateTime(record?.reviewed_at) || '-'}
					// sorter={true}
					/>
					<Column
						title="Updated By"
						dataIndex="reviewed_by"
						key="reviewed_by"
						align="left"
						// sorter={true}
						render={(text, record) => record?.reviewed_by || '-'}
					/>
					<Column
						className="text-sm px-2 py-3"
						title="Actions"
						dataIndex="action"
						key="action"
						fixed="right"
						render={(text, record) => (
							<Link to={
								`/customers/igw/subscriber/${record.subscriber_id}/form/${record.uuid}/`
							}>
								View Details
							</Link>
						)}
						width={120}
					/>
				</TableUniversal>
			</Card>
		</>

	);
}
export default IMWServicesDetailsTable;
