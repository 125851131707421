import React from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomersTable from './customers-table';

function OrgMessage() {
  const { t } = useTranslation();
  let title = t('customer_list.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Customer Panel',
      url: '/',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <CustomersTable />
      </Card>
    </>
  );
}

export default OrgMessage;
