import * as types from '../actionTypes/transactions';

export const listTransactions = (payload) => {
  return { type: types.LIST_TRANSACTIONS, payload };
};
export const listTransactionsSuccess = (payload) => {
  return { type: types.LIST_TRANSACTIONS_SUCCESS, payload };
};
export const listTransactionsFailure = (payload) => {
  return { type: types.LIST_TRANSACTIONS_FAILURE, payload };
};
export const listP2PTransactionsAction = (tableParams) => {
  return {
    type: types.LIST_P2P_TRANSACTIONS,
    payload: tableParams,
  };
};
export const listP2PTransactionsSuccessAction = (transactions) => {
  return {
    type: types.LIST_P2P_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};
export const listP2PTransactionsFailedAction = (error) => {
  return {
    type: types.LIST_P2P_TRANSACTIONS_FAILURE,
    payload: error,
  };
};
export const listRemittanceAction = (tableParams) => {
  return {
    type: types.LIST_REMITTANCE_TRANSACTIONS,
    payload: tableParams,
  };
};
export const listRemittanceSuccessAction = (transactions) => {
  return {
    type: types.LIST_REMITTANCE_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};
export const listRemittanceFailedAction = (error) => {
  return {
    type: types.LIST_REMITTANCE_TRANSACTIONS_FAILURE,
    payload: error,
  };
};
export const listTopUpTransactions = (tableParams) => {
  return {
    type: types.LIST_TOP_UP_TRANSACTIONS,
    payload: tableParams,
  };
};
export const listTopUpTransactionsSuccess = (transactions) => {
  return {
    type: types.LIST_TOP_UP_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};
export const listTopUpTransactionsFailure = (error) => {
  return {
    type: types.LIST_TOP_UP_TRANSACTIONS_FAILURE,
    payload: error,
  };
};
export const getTransactionAction = (transactionId) => {
  return {
    type: types.GET_TRANSACTION,
    payload: transactionId,
  };
};
export const getTransactionSuccessAction = (transaction) => {
  return {
    type: types.GET_TRANSACTION_SUCCESS,
    payload: transaction,
  };
};
export const getTransactionFailedAction = (error) => {
  return {
    type: types.GET_TRANSACTION_FAILURE,
    payload: error,
  };
};
export const getTransactionOverviewAction = (params) => {
  return {
    type: types.TRANSACTION_OVERVIEW,
    payload: params,
  };
};
export const getTransactionOverviewSuccessAction = (transactions) => {
  return {
    type: types.TRANSACTION_OVERVIEW_SUCCESS,
    payload: transactions,
  };
};
export const getTransactionOverviewFailedAction = (error) => {
  return {
    type: types.TRANSACTION_OVERVIEW_FAILURE,
    payload: error,
  };
};
