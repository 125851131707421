import { put, all, takeLatest } from 'redux-saga/effects';
import { listIGWServicesService, getIGWDetailsService, getIGWDetailsListService, editIGWDetailsService } from '../services/igwServicesService';
import {
  listIGWServicesFailedAction,
  listIGWServicesSuccessAction,
  getIGWDetailsListSuccessAction,
  getIGWDetailsListFailedAction,
  getIGWDetailsSuccessAction,
  getIGWDetailsFailedAction,
  editIGWDetailsSuccessAction,
  editIGWDetailsFailAction
} from '../store/actions/igwServiceActions';
import * as types from '../store/actionTypes/services-igw';
import history from '../utils/history';
import { notification } from 'antd';

export function* listIGWServices({ payload }) {
  try {
    const list = yield listIGWServicesService(payload);
    yield put(listIGWServicesSuccessAction(list.data.data));
  } catch (error) {
    yield put(listIGWServicesFailedAction(error));
  }
}

export function* getIGWDetailsList({ payload }) {
  try {
    const list = yield getIGWDetailsListService(payload);
    yield put(getIGWDetailsListSuccessAction(list.data.data));
  } catch (error) {
    yield put(getIGWDetailsListFailedAction(error));
  }
}

export function* getIGWDetails({ payload }) {
  try {
    const list = yield getIGWDetailsService(payload);
    yield put(getIGWDetailsSuccessAction(list.data.data));
  } catch (error) {
    yield put(getIGWDetailsFailedAction(error));
  }
}

//edit igw details
export function* editIGWDetails({
  payload: { formValue, initialTableParams },
}) {
  try {
    const edit = yield editIGWDetailsService(initialTableParams, formValue);
    yield put(editIGWDetailsSuccessAction(edit));
    notification['success']({
      message: `IGW details updated successfully.`,
      description: 'Success description',
    });
    history.push(`/customers/igw/subscriber/${initialTableParams.subscriber_id}/form/${initialTableParams.form_uuid}/`)
  } catch (error) {
    yield put(editIGWDetailsFailAction(error));
    notification['error']({
      message: 'Failed to update IGW details.',
      description: 'Please retry or contact administration.',
    });
  }
}

//Watcher
export function* onListIGWServicesRequest() {
  yield takeLatest(types.LIST_IGW_SERVICES, listIGWServices);
}

export function* onGetIGWDetailsListRequest() {
  yield takeLatest(types.GET_IGW_DETAILS_LIST, getIGWDetailsList);
}

export function* onGetIGWDetailsRequest() {
  yield takeLatest(types.GET_IGW_DETAILS, getIGWDetails);
}

export function* onEditIGWDetailsRequest() {
  yield takeLatest(types.EDIT_IGW_DETAILS, editIGWDetails);
}

export function* igwSaga() {
  yield all([onListIGWServicesRequest(), onGetIGWDetailsListRequest(), onGetIGWDetailsRequest(), onEditIGWDetailsRequest()]);
}
