import * as types from '../actionTypes/system-users';

const initialState = {
  usersList: null,
  userListLoading: false,
  userListError: null,
  createUserLoading: false,
  createUserError: null,
  editUserLoading: false,
  editUserSuccess: false,
  editUserError: null,
  currentUserLoading: false,
  currentUserSuccess: false,
  currentUserError: null,
  currentUser: null,
  changeUserStatusLoading: false,
  changeUserStatusError: null,
  resetPasswordLoading: false,
  resetPasswordError: null,
};

const systemUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_SYSTEM_USERS:
      return {
        ...state,
        userListLoading: true,
      };
    case types.LIST_SYSTEM_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        userListLoading: false,
      };
    case types.LIST_SYSTEM_USERS_FAILED:
      return {
        ...state,
        userListLoading: false,
        userListError: action.payload,
      };
    case types.CREATE_SYSTEM_USER:
      return {
        ...state,
        createUserLoading: true,
      };
    case types.CREATE_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        // usersList: [...state.usersList, action.payload],
      };

    case types.CREATE_SYSTEM_USER_FAIL:
      return {
        ...state,
        createUserLoading: false,
        createUserError: action.payload.message,
      };
    case types.EDIT_SYSTEM_USER:
      return {
        ...state,
        editUserLoading: true,
        editUserSuccess: false,
        editUserError: null,
      };
    case types.EDIT_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: true,
        editUserError: null,
        currentUser: action.payload,
      };
    case types.EDIT_SYSTEM_USER_FAIL:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: false,
        editUserError: action.payload,
      };
    case types.VIEW_SYSTEM_USER:
      return {
        ...state,
        currentUserLoading: true,
        currentUserSuccess: false,
        currentUserError: null,
        currentUser: null,
      };
    case types.VIEW_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        currentUserLoading: false,
        currentUserSuccess: true,
        currentUserError: null,
        currentUser: action.payload,
      };
    case types.VIEW_SYSTEM_USER_FAIL:
      return {
        ...state,
        currentUserLoading: false,
        currentUserSuccess: false,
        currentUserError: action.payload,
        currentUser: null,
      };
    case types.CHANGE_USER_STATUS:
      return {
        ...state,
        changeUserStatusLoading: true,
        changeUserStatusError: null,
      };
    case types.CHANGE_USER_STATUS_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        changeUserStatusLoading: false,
        changeUserStatusError: null,
      };
    case types.CHANGE_USER_STATUS_FAIL:
      return {
        ...state,
        changeUserStatusLoading: false,
        changeUserStatusError: action.payload,
      };
    case types.RESET_USER_PASSWORD:
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordError: null,
      };
    case types.RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: null,
      };
    case types.RESET_USER_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.payload,
      };
    default:
      return state;
  }
};

export default systemUsersReducer;
