import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { orgCategorySaga } from './orgCategorySaga';
import { systemUsersSaga } from './systemUserSaga';
import { organisationSaga } from './organisationSaga';
import { orgMessageSaga } from './orgMessageSaga';
import { imageUploadSaga } from './uploadSaga';
import { orgUsersSaga } from './orgUserSaga';
import { customerSaga } from './customerSaga';
import { userProfile } from './userProfileSaga';
import { kycDetailsSaga } from './kycSaga';
import { transactionsSaga } from './transactionsSaga';
import { walletSaga } from './walletSaga';
import { dashboardSaga } from './dashboardSaga';
import { rolesPermissionSaga } from './rolesPermissionSaga';
import { customerCasesSaga } from './customerCasesSaga';
import { igwSaga } from './igwSaga';
import { downloadSaga } from './downloadSaga';

function* rootSaga() {
  yield all([
    authSaga(),
    dashboardSaga(),
    systemUsersSaga(),
    userProfile(),
    orgCategorySaga(),
    organisationSaga(),
    orgMessageSaga(),
    imageUploadSaga(),
    orgUsersSaga(),
    customerSaga(),
    kycDetailsSaga(),
    transactionsSaga(),
    walletSaga(),
    rolesPermissionSaga(),
    customerCasesSaga(),
    igwSaga(),
    downloadSaga(),
  ]);
}

export default rootSaga;
