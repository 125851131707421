import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listOrganisationAction } from '../../store/actions/organisationActions';
import { Link } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { BiArrowFromBottom, BiCheck } from 'react-icons/bi';

function OrgCategoryTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [
    {
      key: true,
      value: 'Active',
    },
    {
      key: false,
      value: 'Inactive',
    },
  ];
  const { orgList, orgListLoading, orgListError } = useSelector(
    (state) => state.organisation
  );
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="is_active"
      dispatchAction={listOrganisationAction}
      data={orgList}
      loading={orgListLoading}
      error={orgListError}
    >
      <Column
        title="Organisation Name"
        dataIndex="name"
        key="name"
        sorter={(a, b) => a.name.toString().localeCompare(b.name)}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Organisation Logo"
        dataIndex="logo"
        key="logo"
        align="left"
        render={(url) => <img src={url} alt="Logo" className="h-8" />}
      />
      <Column
        title="Created At"
        dataIndex="created_at"
        key="created_at"
        sorter={(a, b) => a.created_at?.localeCompare(b.created_at)}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
        defaultSortOrder="descend"
      />
      <Column
        title="Category"
        dataIndex="category"
        key="category"
        sorter={(a, b) => a.category?.localeCompare(b.category)}
        render={(text, record) => record?.category}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) =>
          record?.is_active ? (
            <div className="flex items-center">
              <div className="p-1 rounded-full bg-green-anak">
                <BiCheck className="h-4 w-4 text-white " />
              </div>
              <div className="ml-2 text-blue-anak">Active</div>
            </div>
          ) : (
            <div className="flex items-center">
              <div className="p-1 rounded-full bg-grey-anak">
                <BiArrowFromBottom className="h-4 w-4 text-white" />
              </div>
              <div className="ml-2 text-grey-anak">Inactive</div>
            </div>
          )
        }
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/organisations/list/${record.uuid}`}>View Details</Link>
        )}
        width={140}
      />
    </TableUniversal>
  );
}
export default OrgCategoryTable;
