import { put, all, takeLatest } from 'redux-saga/effects';
import * as types from '../store/actionTypes/upload';
import { message } from 'antd';
import {
  uploadImageService,
  uploadFileService,
  uploadImageServiceIdentifier
} from '../services/uploadService';
import {
  uploadImageSuccessAction,
  uploadImageFailAction,
  uploadFileSuccessAction,
  uploadFileFailAction,
  uploadImageIdentifierSuccessAction,
  uploadImageIdentifierFailAction
} from '../store/actions/uploadAction';

//Worker
export function* uploadImage({ payload: formData }) {
  try {
    const file = yield uploadImageService(formData);
    yield put(uploadImageSuccessAction(file.data.data.url));
    message.success({ content: 'Image uploaded successfully!', duration: 2 });
  } catch (error) {
    yield put(uploadImageFailAction(error));
    message.warning({ content: 'Image upload failed!', duration: 2 });
  }
}
export function* uploadImageIdentifier({ payload: {formData, identifier} }) {
  try {
    const file = yield uploadImageServiceIdentifier(formData);
    yield put(uploadImageIdentifierSuccessAction(file.data.data.url));
    message.success({ content: 'Image uploaded successfully!', duration: 2 });
  } catch (error) {
    yield put(uploadImageIdentifierFailAction(error));
    message.warning({ content: 'Image upload failed!', duration: 2 });
  }
}
export function* uploadFile({ payload: formData }) {
  try {
    const file = yield uploadFileService(formData);
    yield put(uploadFileSuccessAction(file.data.data.url));
    message.success({ content: 'File uploaded successfully!', duration: 2 });
  } catch (error) {
    yield put(uploadFileFailAction(error));
    message.warning({ content: 'File upload failed!', duration: 2 });
  }
}

//Watcher
export function* onUploadImageRequest() {
  yield takeLatest(types.IMAGE_UPLOAD, uploadImage);
}
export function* onUploadImageIdentifierRequest() {
  yield takeLatest(types.IDENTIFIER_IMAGE_UPLOAD, uploadImageIdentifier);
}
export function* onUploadFileRequest() {
  yield takeLatest(types.FILE_UPLOAD, uploadFile);
}

export function* imageUploadSaga() {
  yield all([onUploadImageRequest(), onUploadFileRequest(), onUploadImageIdentifierRequest()]);
}
