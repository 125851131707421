import { formatCurrency } from '../../utils/numbers';
import React from 'react';
import { BiWallet } from 'react-icons/bi';
import '../../utils/numbers';
import { useTranslation } from 'react-i18next';
import WidgetTitle from '../../components/profile-widgets/widget-title';

export default function CustomerWalletDetails({ walletDetails }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 rounded-lg">
      <WidgetTitle title={t('customer_details.wallet.title')} />
      <div className="pb-2 pt-4 mb-3 border-b">
        <div className="text-base">{t('customer_details.wallet.balance')}</div>
        {walletDetails?.balance ? (
          <div className="text-3xl font-bold">
            SGD{' '}
            <span className="text-green-A700">
              {formatCurrency(walletDetails.balance)}
            </span>
          </div>
        ) : (
          0
        )}
      </div>
      <div className="flex flex-col">
        <div className="flex justify items-center font-bold border-b border-grey-200 pb-6">
          <div className="p-3 rounded-full bg-blue-100 text-blue-500 border border-blue-100 mr-4 bg-opacity-25">
            <BiWallet size={24} />
          </div>
          <div className="flex flex-col">
            <div className="text-md font-medium mb-1">
              Total Wallet Transactions
            </div>
            {walletDetails?.total_wallet_transactions ? (
              <div className="text-2xl order-last font-extrabold leading-none">
                SGD{' '}
                <span className="text-green-anak">
                  {' '}
                  {formatCurrency(walletDetails.total_wallet_transactions)}{' '}
                </span>
              </div>
            ) : (
              0
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-around mt-6 space-y-4 lg:space-y-0">
        <div className="font-medium">
          <div className="text-md font-medium mb-1">
            {t('dashboard.wallet_details.transaction_today')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="text-green-anak">
              {formatCurrency(walletDetails?.todays_transactions)}{' '}
            </span>
          </div>
        </div>
        <div className="border border-r border-grey-200"></div>
        <div className="">
          <div className="text-md font-medium mb-1">
            {t('dashboard.wallet_details.transaction_month')}
          </div>
          <div className="text-xl order-last font-bold leading-none">
            SGD{' '}
            <span className="text-green-anak">
              {' '}
              {formatCurrency(walletDetails?.this_month_transactions)}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
