import { Carousel, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { getKYCDocumentAction } from '../../store/actions/kycActions';
import { getKycStatus } from '../../utils/get-kyc-status';
import { dateFormat } from '../../utils/dateFormat';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { KYCDetails } from '../../components/skeleton/WidgetSkeleton';
import { isEmpty } from '../../utils/generic';

function DocumentDetails({ caseID }) {
  const { customer_id, kyc_attempt_id } = useParams();
  const [documentModal, setDocumentModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let title = t('kyc_details.document_title');
  let breadcrumbsItem = [
    {
      home: false,
      title: t('kyc_details.section_title'),
      url: '/customers/kyc',
      last: false,
    },
    {
      home: false,
      title: t('kyc_details.user_detail_title'),
      url: `/customers/kyc/${customer_id}`,
      last: false,
    },
    { title, url: '/', last: true },
  ];
  const { currentDocument, currentDocumentLoading } = useSelector(
    (state) => state.kyc
  );
  let basicInfo = currentDocument?.workflow_data?.extraction?.data;
  let screening = currentDocument?.workflow_data?.watchlistScreening;
  const details = {
    first_name: basicInfo?.firstName,
    last_name: basicInfo?.lastName,
    dateOfBirth: basicInfo?.dateOfBirth,
    documentNumber: basicInfo?.documentNumber,
    address1: basicInfo?.address?.line1,
    address2: basicInfo?.address?.line2,
    address3: basicInfo?.address?.line3,
    postal_code: basicInfo?.address?.postalCode,
    city: basicInfo?.address?.city,
    country: basicInfo?.address?.country,
    issuingCountry: basicInfo?.issuingCountry,
    documentType: basicInfo?.type,
    documentSubType: basicInfo?.subType,
    id_front: currentDocument?.documents.filter(
      (doc) => doc.classifier === 'FRONT'
    )[0]?.url,
    id_back: currentDocument?.documents.filter(
      (doc) => doc.classifier === 'BACK'
    )[0]?.url,
    selfie: currentDocument?.documents.filter(
      (doc) => doc.category === 'SELFIE'
    )[0]?.url,
  };
  useEffect(() => {
    dispatch(getKYCDocumentAction(customer_id, kyc_attempt_id || caseID));
  }, []); // eslint-disable-line

  return (
    <>
      {isEmpty(caseID) && (
        <SectionTitle
          title={title}
          right={<Breadcrumb items={breadcrumbsItem} />}
        />
      )}
      {currentDocumentLoading ? (
        <div className="text-center w-full">
          <KYCDetails />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-6 md:grid-cols-12 gap-4">
            <div className="p-4 rounded-lg bg-white border border-grey-10 col-span-5">
              <div className="flex justify-between items-center mb-4">
                <div className="text-lg font-bold">Details</div>
                {getKycStatus(currentDocument?.status)}
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">First Name:</div>
                <div className="w-2/3">{details?.first_name || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Last Name:</div>
                <div className="w-2/3">{details?.last_name || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Date of Birth:</div>
                <div className="w-2/3">{details?.dateOfBirth || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Document Number:</div>
                <div className="w-2/3">{details?.documentNumber || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Address Line 1:</div>
                <div className="w-2/3">{details?.address1 || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Address Line 2:</div>
                <div className="w-2/3">{details?.address2 || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Address Line 3:</div>
                <div className="w-2/3">{details?.address3 || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Postal Code:</div>
                <div className="w-2/3">{details?.postal_code || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">City:</div>
                <div className="w-2/3">{details?.city || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Issuing Country:</div>
                <div className="w-2/3">{details?.issuingCountry || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Document Type:</div>
                <div className="w-2/3">{details?.documentType || '-'}</div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/3">Document Sub Type:</div>
                <div className="w-2/3">{details?.documentSubType || '-'}</div>
              </div>
            </div>
            <div className="p-4 rounded-lg bg-white border border-grey-10 col-span-5 sm:col-span-3">
              <div className="text-lg font-bold mb-4">Usability</div>
              {currentDocument?.workflow_data?.usability?.map((data, j) => (
                <div key={j} className="flex w-full mb-4">
                  <div className="w-1/2">{data.credentials[0].category}</div>
                  <div className="w-1/2">{data.decision || '-'}</div>
                </div>
              ))}
              <div className="text-lg font-bold mb-4">Data Check</div>
              <div className="flex w-full mb-4">
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.dataChecks?.credentials[0]
                    ?.category || '-'}
                </div>
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.dataChecks?.decision || '-'}
                </div>
              </div>
              <div className="text-lg font-bold mb-4">Image Check</div>
              <div className="flex w-full mb-4">
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.imageChecks?.credentials[0]
                    ?.category || '-'}
                </div>
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.imageChecks?.decision || '-'}
                </div>
              </div>
            </div>
            <div className="p-4 rounded-lg bg-white border border-grey-10 col-span-5 sm:col-span-4">
              <div className="mb-4">
                <div className="text-md mb-2 font-bold">ID FRONT</div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open(`${details?.id_front}`)}
                  // onClick={() => setDocumentModal(true)}
                >
                  <img src={details?.id_front} alt="" />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-md mb-2 font-bold">ID BACK</div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open(`${details?.id_back}`)}
                  // onClick={() => setDocumentModal(true)}
                >
                  <img src={details?.id_back} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-6 md:grid-cols-12 gap-4">
            <div className="sm:col-span-4 p-4 mt-6 rounded-lg bg-white border border-grey-10 col-span-12">
              <div className="text-lg font-bold mb-4">Facemap</div>
              <div className="flex w-full mb-4">
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.liveness?.credentials[0]
                    ?.category || '-'}
                </div>
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.liveness?.decision || '-'}
                </div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.liveness?.credentials[1]
                    ?.category || '-'}
                </div>
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.liveness?.decision || '-'}
                </div>
              </div>
              <div className="text-lg font-bold mb-4">Similarity</div>
              <div className="flex w-full mb-4">
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.similarity?.credentials[0]
                    ?.category || '-'}
                </div>
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.similarity?.decision || '-'}
                </div>
              </div>
              <div className="flex w-full mb-4">
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.similarity?.credentials[1]
                    ?.category || '-'}
                </div>
                <div className="w-1/2">
                  {currentDocument?.workflow_data?.similarity?.decision || '-'}
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-8 p-4 mt-6 rounded-lg bg-white border border-grey-10">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-2">
                  <div className="text-lg font-bold mb-4 col-span-4">
                    Liveness
                  </div>
                  <div className="flex w-full mb-4 ">
                    <div className="w-1/2">
                      {currentDocument?.workflow_data?.liveness?.credentials[0]
                        ?.category || '-'}
                    </div>
                    <div className="w-1/2">
                      {currentDocument?.workflow_data?.liveness?.decision ||
                        '-'}
                    </div>
                  </div>
                  <div className="flex w-full mb-4">
                    <div className="w-1/2">
                      {currentDocument?.workflow_data?.liveness?.credentials[1]
                        ?.category || '-'}
                    </div>
                    <div className="w-1/2">
                      {currentDocument?.workflow_data?.liveness?.decision ||
                        '-'}
                    </div>
                  </div>
                </div>
                <div className="w-full mb-4 col-span-2">
                  <div className="text-md font-bold mb-4 col-span-4">
                    FACE SELFIE
                  </div>
                  <div className="mb-4">
                    <div
                      className="cursor-pointer"
                      onClick={() => window.open(`${details?.selfie}`)}
                      // onClick={() => setDocumentModal(true)}
                    >
                      <img className="h-48" src={details?.selfie} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 p-4 rounded-lg bg-white border border-grey-10">
              <div className="text-lg font-bold mb-4">Screening</div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-2">
                  {screening?.credentials[0]?.category}
                </div>
                <div className="col-span-10">{screening?.decision || '-'}</div>
                <div className="col-span-2">Search Date</div>
                <div className="col-span-10">
                  {dateFormat(screening?.data?.searchDate) || '-'}
                </div>
                <div className="col-span-2">Search ID</div>
                <div
                  className="col-span-10  cursor-pointer"
                  onClick={() => copyToClipboard(screening?.data?.searchId)}
                >
                  {screening?.data?.searchId || '-'}
                </div>
                <div className="col-span-2">Search Reference</div>
                <div
                  className="col-span-10 cursor-pointer"
                  onClick={() =>
                    copyToClipboard(screening?.data?.searchReference)
                  }
                >
                  {screening?.data?.searchReference || '-'}
                </div>
                <div className="col-span-2">Search URL</div>
                <div className="col-span-10">
                  <div
                    className="cursor-pointer underline text-blue-anak"
                    onClick={() =>
                      window.open(`${screening?.data?.searchResultUrl}`)
                    }
                  >
                    Click to Open
                  </div>
                </div>
                <div className="col-span-2">Search Results</div>
                <div className="col-span-10">
                  {screening?.data?.searchResults || '-'}
                </div>
                <div className="col-span-2">Search Status</div>
                <div className="col-span-10">
                  {screening?.data?.searchStatus || '-'}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        open={documentModal}
        title="Documents"
        footer={null}
        closable
        onCancel={() => setDocumentModal(!documentModal)}
        onOk={() => setDocumentModal(!documentModal)}
      >
        <Carousel
          dots={true}
          arrows={true}
          slidesToShow={1}
          slidesToScroll={1}
          infinite={true}
        >
          {currentDocument?.documents.map((item, i) => (
            <div key={i} className="flex justify-center items-center">
              <Link to={item.url} target="_blank">
                <img
                  style={{
                    maxHeight: '300px',
                    margin: '0 auto',
                  }}
                  src={item.url}
                  alt=""
                />
              </Link>
            </div>
          ))}
        </Carousel>
      </Modal>
    </>
  );
}

export default DocumentDetails;
