import * as types from '../actionTypes/downloadActionTypes';

const initialState = {
	downloadRequest: null,
	downloadRequestLoading: false,
	downloadRequestError: null,
}

const download = (state = initialState, action) => {
	switch (action.type) {
		case types.DOWNLOAD_REQUEST:
			return {
				...state,
				downloadRequestLoading: true,
			};
		case types.DOWNLOAD_SUCCESS:
			return {
				...state,
				downloadRequest: action.payload,
				downloadRequestLoading: false,
			};
		case types.DOWNLOAD_FAIL:
			return {
				...state,
				downloadRequestLoading: false,
				downloadRequestError: action.payload,
			};
		default:
			return state;
	}
}

export default download;