import React, { useEffect } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { Card, Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewOrgMessageAction } from '../../store/actions/orgMessageActions';
import getMessageStatus from '../../components/status/message';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { BiFile } from 'react-icons/bi';
import { isEmpty } from '../../utils/generic';
import ReactPlayer from 'react-player/youtube';

function MessageDetails() {
  const { t } = useTranslation();
  const { message_uuid } = useParams();
  const dispatch = useDispatch();
  const { currentMessage, currentMessageLoading } = useSelector(
    (state) => state.orgMessages
  );
  useEffect(() => {
    dispatch(viewOrgMessageAction(message_uuid));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  let title = t('org_message.details.title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('org_message.section_title'),
      url: '/organisations/messages',
      last: false,
    },
    { home: false, title: 'Details', url: '/', last: true },
  ];
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col lg:flex-row items-start space-x-0 lg:space-x-4">
        <div className="w-full lg:w-2/3">
          <Card>
            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">
                {t('org_message.details.name_label')}
              </div>
              {currentMessageLoading ? (
                <Skeleton.Input style={{ width: 200 }} active />
              ) : (
                <div className="text-lg font-bold">
                  {currentMessage?.organization}
                </div>
              )}
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">
                Recipients
              </div>
              {currentMessageLoading ? (
                <Skeleton.Input style={{ width: 200 }} active />
              ) : (
                <div className="text-md font-bold">
                <div className='flex flex-row'>
                  {currentMessage?.recipient && currentMessage.recipient.map(item=>{
                    if(currentMessage?.recipient.length === 1){
                    return <>{item.name}</> 
                    }else
                    return <>{item.name}, </> 
                  })}
                  </div>
                </div>
              )}
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <div className="text-md mb-1 text-grey-700">
                  {currentMessage?.status === 1 ? t('org_message.details.date_label') : "Scheduled Date & Time" }
                </div>
                <div className="text-lg font-bold">
                  {!isEmpty(currentMessage?.scheduled_at) ? utcToLocalDateTime(currentMessage?.scheduled_at) : utcToLocalDateTime(currentMessage?.created_at)}
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="text-md mb-1 text-grey-700">
                  {t('org_message.details.language_label')}
                </div>
                <div className="text-lg font-bold">English</div>
              </div>
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <div className="text-md mb-1 text-grey-700">
                  {t('org_message.details.status_label')}
                </div>
                {currentMessageLoading ? (
                  <Skeleton.Input style={{ width: 200 }} active />
                ) : (
                  getMessageStatus(currentMessage?.status)
                )}
              </div>
              <div className="w-full lg:w-1/2">
                <div className="text-md mb-1 text-grey-700">
                  {t('org_message.details.views_label')}
                </div>
                {currentMessageLoading ? (
                  <Skeleton.Input style={{ width: 200 }} active />
                ) : (
                  <div className="text-lg font-bold">
                    {currentMessage?.views}
                  </div>
                )}
              </div>
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">
                {t('org_message.details.heading_label')}
              </div>
              {currentMessageLoading ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <div className="text-lg font-bold">{currentMessage?.title}</div>
              )}
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">
                {t('org_message.details.content_label')}
              </div>
              {currentMessageLoading ? (
                <Skeleton active paragraph={{ rows: 6 }} />
              ) : (
                <div
                  className="text-md font-bold pb-4"
                  dangerouslySetInnerHTML={{
                    __html: `${
                      currentMessage?.body
                        ? currentMessage?.body
                        : 'Message Body'
                    }`,
                  }}
                ></div>
              )}
              {currentMessageLoading ? (
                <Space>
                  <Skeleton.Image className="mr-2 mb-2" />
                  <Skeleton.Image className="mr-2 mb-2" />
                </Space>
              ) : (
                <>
                  {!isEmpty(currentMessage?.images[0]) && (
                    <img
                      src={currentMessage?.images[0]}
                      alt=""
                      className="w-1/2 mb-4 h-auto p-2 mt-3 border border-grey-300 rounded"
                    />
                  )}
                  {!isEmpty(currentMessage?.videos[0]) && (
                    <div className="w-1/2">
                      <ReactPlayer
                        className="youtube-embed"
                        width="100%"
                        height="auto"
                        url={currentMessage?.videos[0]}
                        config={{ youtube: { playerVars: { showinfo: 1 } } }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">File Added</div>
              <div className="text-md font-bold">
                <div className="w-1/2">
                  {currentMessageLoading ? (
                    <Space>
                      <Skeleton.Image className="mr-2 mb-1" />
                    </Space>
                  ) : (
                    <div className="message-footer">
                      {!isEmpty(currentMessage?.files[0]) ? (
                        <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                          <BiFile className="text-blue-link text-lg" />
                          <a
                            className="text-sm font-bold text-blue-link pl-2"
                            href={`${currentMessage?.files[0]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            File Attachment
                          </a>
                        </div>
                      ) : (
                        'No File Attachment'
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="w-full lg:w-1/3 flex justify-center">
          <div className="mockup-phone">
            <div className="message">
              {currentMessageLoading ? (
                <Space>
                  <Skeleton.Image className="mr-2 mb-1" />
                  <Skeleton.Input
                    style={{ width: 200 }}
                    active={currentMessageLoading}
                  />
                </Space>
              ) : (
                <div className="message-header">
                  <div className="flex items-center mb-4">
                    {currentMessage?.org_logo ? (
                      <div className="logo">
                        <img src={currentMessage?.org_logo} alt="logo" />
                      </div>
                    ) : (
                      <Skeleton.Image className="mr-2 mb-1" />
                    )}
                    <div className="message-sender">
                      {currentMessageLoading ? (
                        <Skeleton.Input style={{ width: 100 }} active />
                      ) : (
                        <div className="text-lg font-bold">
                          {currentMessage?.organization}
                        </div>
                      )}
                      <div className="text-xs text-grey-600">
                        {new Date().toLocaleString() + ''}
                      </div>
                    </div>
                  </div>
                  {currentMessageLoading ? (
                    <Skeleton active paragraph={{ rows: 2 }} />
                  ) : (
                    <div className="message-header-title text-base font-bold leading-5 border-b border-grey-300 pb-4 mb-4">
                      {currentMessage?.title}
                    </div>
                  )}
                </div>
              )}
              {currentMessageLoading ? (
                <Skeleton active paragraph={{ rows: 6 }} />
              ) : (
                <>
                  <div className="message-content">
                    <div
                      className="pb-4"
                      dangerouslySetInnerHTML={{
                        __html: `${
                          currentMessageLoading
                            ? 'Message Body'
                            : currentMessage?.body
                        }`,
                      }}
                    ></div>
                    {!isEmpty(currentMessage?.images[0]) && (
                      <img
                        src={currentMessage?.images[0]}
                        alt=""
                        className="w-full h-auto p-2 mt-3 border border-grey-300 mx-auto rounded"
                      />
                    )}
                  </div>
                  <div className="message-footer px-4 pb-5">
                    {!isEmpty(currentMessage?.videos[0]) && (
                      <ReactPlayer
                        className="youtube-embed"
                        width="100%"
                        height="auto"
                        url={currentMessage?.videos[0]}
                        config={{ youtube: { playerVars: { showinfo: 1 } } }}
                      />
                    )}
                    {!isEmpty(currentMessage?.files[0]) && (
                      <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                        <BiFile className="text-blue-link text-lg" />
                        <a
                          className="text-sm font-bold text-blue-link pl-2"
                          href={`${currentMessage?.files[0]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          File Attachment
                        </a>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageDetails;
