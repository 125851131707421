import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../../components/table';
import { listRemittanceAction } from '../../../store/actions/transactionActions';
import { getTransactionStatus } from '../../../utils/get-transaction-status';
import RemittanceTransactionDetails from './remittance-details';
import { utcToLocalDateTime } from '../../../utils/dateFormat';
import { formatCurrency, formatCurrencyToDecimal3 } from '../../../utils/numbers';
// import { getTransactionCategory } from '../../../utils/get-transaction-category';

function RemittanceTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
  };
  const menuFilter = [
    {
      key: 1,
      value: 'Success',
    },
    {
      key: 2,
      value: 'Pending',
    },
    {
      key: 3,
      value: 'Failed',
    },
  ];
  const csvExportHeader = [
    { label: 'ID', key: 'id' },
    { label: 'Sender', key: 'sender_user.name' },
    { label: 'Receiver', key: 'recipient_user.name' },
    { label: 'Amount', key: 'amount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Transaction Type', key: 'category' },
    { label: 'Date & Time', key: 'created_at' },
    { label: 'Status', key: 'status' },
  ];
  const requestParams = {
    category: 3
  }
  const { remittanceList, remittanceListLoading, remittanceListError } =
    useSelector((state) => state.transaction);
  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listRemittanceAction}
      data={remittanceList}
      loading={remittanceListLoading}
      error={remittanceListError}
      csvExportHeader={csvExportHeader}
      downloadUrl="transaction"
      requestParams= {requestParams}
    >
      <Column
        title="Sender"
        dataIndex="sender_user"
        key="sender_user"
        align="left"
        render={(text, record) => record?.sender_user?.name}
      />
      <Column
        title="Receiver"
        dataIndex="recipient_user"
        key="recipient_user"
        align="left"
        // render={(text, record) => record?.recipient_user?.name}
        render={(text, record) => 'To Bank'}
      />
      <Column
        title="Amount"
        dataIndex="amount"
        key="amount"
        align="left"
        sorter={true}
        render={(text, record) => `${formatCurrency(record?.amount)} ${record?.currency}`}
      />
       <Column
        title="FX Fee"
        dataIndex="fx_fee"
        key="fx_fee"
        render={(text, record) => formatCurrencyToDecimal3(record?.fx_fee) || '-' }
      />ƒ
      <Column
        title="Transfer Fee"
        dataIndex="transfer_fee"
        key="transfer_fee"
        render={(text, record) => formatCurrency(record?.fee) || '-' }
      />
      {/* <Column
        title="Transaction Type"
        dataIndex="category"
        key="category"
        render={(text, record) => getTransactionCategory(record?.category)}
      /> */}
      <Column
        title="Date & Time"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getTransactionStatus(record?.status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <RemittanceTransactionDetails transactionId={record?.id} />
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default RemittanceTable;
