import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Loader from '../../components/loader';
import LeftSidebar from '../../components/left-sidebar';
import Navbar from '../../components/navbar';
import Hotkeys from '../../components/hotkeys';
import Backdrop from '../../components/backdrop';
import { NotificationContainer } from 'react-notifications';
import '../../css/layouts/layout-1.css';

const Layout = ({ children }) => {
  const { config, palettes, auth } = useSelector(
    (state) => ({
      config: state.config,
      palettes: state.palettes,
      auth: state.auth,
    }),
    shallowEqual
  );
  const { layout, collapsed } = { ...config };
  let { background, navbar, logo, leftSidebar, rightSidebar } = {
    ...palettes,
  };
  let key = `${[
    layout,
    collapsed,
    background,
    navbar,
    logo,
    leftSidebar,
    rightSidebar,
  ].join('-')}`;

  return (
    <div
      data-layout={layout}
      data-collapsed={collapsed}
      data-background={background}
      data-navbar={navbar}
      data-logo={logo}
      data-left-sidebar={leftSidebar}
      data-right-sidebar={rightSidebar}
    >
      <Loader />
      <Backdrop />
      <NotificationContainer />
      <Hotkeys key={key} />
      <div className="wrapper">
        {/* {auth.currentUser && <LeftSidebar authUser={auth.currentUser} />} */}
        {auth.currentUser && <LeftSidebar authUser={auth.currentUser} />}
        <div className="main w-full bg-grey-50 text-grey-anak dark:bg-grey-900 dark:text-white">
          {auth.currentUser && <Navbar />}
          <div className="min-h-screen w-full p-4 lg:px-6 lg:pb-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
