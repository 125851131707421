import * as types from '../actionTypes/org-users';

// ORG USERS TABLE LISTING
export const listOrgUsersAction = (tableParams) => {
  return {
    type: types.LIST_ORG_USERS,
    payload: tableParams,
  };
};

export const listOrgUsersSuccessAction = (users) => {
  return {
    type: types.LIST_ORG_USERS_SUCCESS,
    payload: users,
  };
};

export const listOrgUsersFailedAction = (error) => {
  return {
    type: types.LIST_ORG_USERS_FAILED,
    payload: error,
  };
};

// CREATE ORG USER
export const createOrgUserAction = (values) => {
  return {
    type: types.CREATE_ORG_USER,
    payload: values,
  };
};
export const createOrgUserSuccessAction = (user) => {
  return {
    type: types.CREATE_ORG_USER_SUCCESS,
    payload: user,
  };
};
export const createOrgUserFailAction = (error) => {
  return {
    type: types.CREATE_ORG_USER_FAIL,
    payload: error,
  };
};

// VIEW ORG USER
export const viewOrgUserAction = (userId) => {
  return {
    type: types.VIEW_ORG_USER,
    payload: userId,
  };
};
export const viewOrgUserSuccessAction = (currentUser) => {
  return {
    type: types.VIEW_ORG_USER_SUCCESS,
    payload: currentUser,
  };
};
export const viewOrgUserFailAction = (error) => {
  return {
    type: types.VIEW_ORG_USER_FAIL,
    payload: error,
  };
};

// EDIT ORG USER
export const editOrgUserAction = ({ name, role }, userId) => {
  return {
    type: types.EDIT_ORG_USER,
    payload: { name, role, userId },
  };
};
export const editOrgUserSuccessAction = (updatedUser) => {
  return {
    type: types.EDIT_ORG_USER_SUCCESS,
    payload: updatedUser,
  };
};
export const editOrgUserFailAction = (error) => {
  return {
    type: types.EDIT_ORG_USER_FAIL,
    payload: error,
  };
};

// UPDATE ORG USER STATUS
export const changeOrgUserStatusAction = (userStatus, uuid) => {
  return {
    type: types.CHANGE_ORG_USER_STATUS,
    payload: { userStatus, uuid },
  };
};
export const changeOrgUserStatusSuccessAction = (user) => {
  return {
    type: types.CHANGE_ORG_USER_STATUS_SUCCESS,
    payload: user,
  };
};
export const changeOrgUserStatusFailAction = (error) => {
  return {
    type: types.CHANGE_ORG_USER_STATUS_FAIL,
    payload: error,
  };
};

// RESET ORG USER PASSWORD
export const resetOrgUserPasswordAction = (profile_uuid, password) => {
  return {
    type: types.UPDATE_ORG_USER_PASSWORD,
    payload: { profile_uuid, password },
  };
};
export const resetOrgUserPasswordSuccessAction = () => {
  return {
    type: types.UPDATE_ORG_USER_PASSWORD_SUCCESS,
  };
};
export const resetOrgUserPasswordFailAction = (error) => {
  return {
    type: types.UPDATE_ORG_USER_PASSWORD_FAIL,
    payload: error,
  };
};
