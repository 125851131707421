import * as types from '../actionTypes/transactions';

const initialState = {
  transactions: null,
  transactionsLoading: false,
  transactionsError: null,
  transactionOverview: null,
  transactionOverviewLoading: false,
  transactionOverviewError: null,
  p2pList: null,
  p2pListLoading: false,
  p2pListError: null,
  remittanceList: null,
  remittanceListLoading: false,
  remittanceListError: null,
  topUpList: null,
  topUpListLoading: false,
  topUpListError: null,
  getTransaction: null,
  getTransactionLoading: false,
  getTransactionError: null,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_TRANSACTIONS:
      return {
        ...state,
        transactionsLoading: true,
        transactionsError: null,
      };
    case types.LIST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        transactionsLoading: false,
        transactionsError: null,
      };
    case types.LIST_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactionsLoading: false,
        transactionsError: action.payload,
      };
    case types.TRANSACTION_OVERVIEW:
      return {
        ...state,
        transactionOverviewLoading: true,
        transactionOverviewError: null,
      };
    case types.TRANSACTION_OVERVIEW_SUCCESS:
      return {
        ...state,
        transactionOverview: action.payload,
        transactionOverviewLoading: false,
        transactionOverviewError: null,
      };
    case types.TRANSACTION_OVERVIEW_FAILURE:
      return {
        ...state,
        transactionOverviewLoading: false,
        transactionOverviewError: action.payload,
      };
    case types.LIST_P2P_TRANSACTIONS:
      return {
        ...state,
        p2pListLoading: true,
      };
    case types.LIST_P2P_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        p2pList: action.payload,
        p2pListLoading: false,
      };
    case types.LIST_P2P_TRANSACTIONS_FAILURE:
      return {
        ...state,
        p2pListLoading: false,
        p2pListError: action.payload,
      };
    case types.LIST_REMITTANCE_TRANSACTIONS:
      return {
        ...state,
        remittanceListLoading: true,
      };
    case types.LIST_REMITTANCE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        remittanceList: action.payload,
        remittanceListLoading: false,
      };
    case types.LIST_REMITTANCE_TRANSACTIONS_FAILURE:
      return {
        ...state,
        remittanceListLoading: false,
        remittanceListError: action.payload,
      };
    case types.LIST_TOP_UP_TRANSACTIONS:
      return {
        ...state,
        topUpListLoading: true,
      };
    case types.LIST_TOP_UP_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        topUpList: action.payload,
        topUpListLoading: false,
      };
    case types.LIST_TOP_UP_TRANSACTIONS_FAILURE:
      return {
        ...state,
        topUpListLoading: false,
        topUpListError: action.payload,
      };
    case types.GET_TRANSACTION:
      return {
        ...state,
        getTransactionLoading: true,
      };
    case types.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        getTransaction: action.payload,
        getTransactionLoading: false,
      };
    case types.GET_TRANSACTION_FAILURE:
      return {
        ...state,
        getTransactionLoading: false,
        getTransactionError: action.payload,
      };
    default:
      return state;
  }
};

export default transactionReducer;
