import { put, all, takeLatest } from 'redux-saga/effects';
import * as types from '../store/actionTypes/wallet';
import {
  listWalletSuccessAction,
  listWalletFailureAction,
  getWalletSummarySuccessAction,
  getWalletSummaryFailureAction,
  getWalletSuccessAction,
  getWalletFailureAction,
} from '../store/actions/walletActions';
import {
  listWalletService,
  getWalletService,
  getWalletSummaryService,
} from '../services/walletService';

export function* listWallet({ payload }) {
  try {
    const list = yield listWalletService(payload);
    yield put(listWalletSuccessAction(list.data.data));
  } catch (error) {
    yield put(listWalletFailureAction(error));
  }
}
export function* getWalletSummary() {
  try {
    const summary = yield getWalletSummaryService();
    yield put(getWalletSummarySuccessAction(summary.data.data));
  } catch (error) {
    yield put(getWalletFailureAction(error));
  }
}
export function* getWallet(payload) {
  try {
    const view = yield getWalletService(payload);
    yield put(getWalletSuccessAction(view.data.data));
  } catch (error) {
    yield put(getWalletSummaryFailureAction(error));
  }
}

export function* onListRequest() {
  yield takeLatest(types.LIST_WALLET, listWallet);
}
export function* onGetWalletSummary() {
  yield takeLatest(types.GET_WALLET_SUMMARY, getWalletSummary);
}
export function* onViewRequest() {
  yield takeLatest(types.GET_WALLET, getWallet);
}
export function* walletSaga() {
  yield all([onListRequest(), onGetWalletSummary(), onViewRequest()]);
}
