import React from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/numbers';
import { Table, Tooltip } from 'antd';
import TableUniversal from '../../components/table';
import { listWalletAction } from '../../store/actions/walletActions';
import { getWalletStatus } from '../../utils/get-wallet-status';
import { Link } from 'react-router-dom';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { BiInfoCircle } from 'react-icons/bi';

function WalletDetailsTable() {
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    active_wallet_status: '',
  };
  const menuFilter = [
    {
      key: 'processed',
      value: 'Processed',
    },
    {
      key: 'pending',
      value: 'Pending',
    },
    {
      key: 'rejected',
      value: 'Rejected',
    },
    {
      key: 'suspended',
      value: 'Suspended',
    },
    {
      key: 'blocked',
      value: 'Blocked',
    },
  ];

  const statusFilter = [
    {
      key: 'true',
      value: 'Active Users',
    },
    {
      key: 'false',
      value: 'Inactive Users',
    }
  ]
  const csvExportHeader = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'User Status', key: 'active_wallet_user' },
    { label: 'Balance', key: 'balance' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Wallet Status', key: 'wallet_status' },
  ];
  const { walletList, walletListLoading, walletListError } = useSelector(
    (state) => state.wallet
  );
  const { Column } = Table;

  const ExpandedRow = ({ record }) => {
    return (
        <div style={{textAlign: 'left'}}>
          <div>
            <strong>Account ID</strong>
            <p>{record.wallex_account_id}</p>
          </div>
          <div className="mt-4">
            <strong>User ID</strong>
            <p>{record.wallex_user_id}</p>
          </div>
        </div>
    )
  }

  return (
      <TableUniversal
          initialTableParams={initialTableParams}
          menuFilter={menuFilter}
          menuFilterBy="status"
          dispatchAction={listWalletAction}
          data={walletList}
          loading={walletListLoading}
      error={walletListError}
      csvExportHeader={csvExportHeader}
      downloadUrl="wallet"
      activeStatusFilter={statusFilter}
      activeStatusFilterBy="active_wallet_status"
      expandable={{
        expandedRowRender: record => <ExpandedRow record={record} />,
      }}
    >
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
          title="Account Holder Name"
          dataIndex="account_holder_name"
          key="account_holder_name"
          align="left"
          render={(text, record) => record?.account_holder_name}
      />
      <Column
          title="Account Number"
          dataIndex="account_number"
          key="account_number"
          align="left"
          render={(text, record) => record?.account_number}
      />
      <Column
          title="Routing Code"
          dataIndex="routing_code_value"
          key="routing_code_value"
          align="left"
          render={(text, record) => record?.routing_code_value}
      />
      <Column
        title={<p className="flex justify-between">User Status<Tooltip placement="bottom" title="Active = At least 1 transaction per month"><BiInfoCircle size={16} /></Tooltip></p>}
        dataIndex="active_wallet_status"
        key="active_wallet_status"
        render={(text, record) =>
          record?.active_wallet_user ? (
            <div className="text-sm font-bold text-green-anak">Active</div>
          ) : (
            <div className="text-sm font-bold text-orange-anak">Inactive</div>
          )
        }
      />
      <Column
        title="Wallet Balance"
        dataIndex="balance"
        key="balance"
        sorter={true}
        align="left"
        render={(text, record) => formatCurrency(record?.balance)}
      />
      <Column
        title="Created At"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        render={(text, record) => utcToLocalDateTime(record?.created_at)}
      />
      <Column
        title="Wallet Status"
        dataIndex="active_wallet_status"
        key="active_wallet_status"
        render={(text, record) => getWalletStatus(record?.wallet_status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/wallet-details/${record?.user}`}>View Details</Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default WalletDetailsTable;
