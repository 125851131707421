import React from 'react';

const Widget1 = ({ title, description, right = null, className = '' }) => {
  return (
    <div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100">
      <div className="text-xs font-medium truncate">{title}</div>
      <div className="flex items-center justify-between">
        <div className={`text-3xl font-bold ${className}`}>{description}</div>
        {right && <div>{right}</div>}
      </div>
    </div>
  );
};
export default Widget1;
