import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as types from '../store/actionTypes/org-users';
import {
  listOrgUsersSuccessAction,
  listOrgUsersFailedAction,
  createOrgUserSuccessAction,
  createOrgUserFailAction,
  viewOrgUserSuccessAction,
  viewOrgUserFailAction,
  editOrgUserSuccessAction,
  editOrgUserFailAction,
  changeOrgUserStatusSuccessAction,
  changeOrgUserStatusFailAction,
  resetOrgUserPasswordSuccessAction,
  resetOrgUserPasswordFailAction,
} from '../store/actions/orgUsersAction';
import {
  listOrgUsersService,
  createOrgUserService,
  editOrgUserService,
  viewOrgUserService,
  changeOrgUserStatusService,
  updateOrgUserPasswordService,
} from '../services/orgUserService';
import { isEmpty } from '../utils/generic';
import { notification } from 'antd';

//Worker
export function* listOrgUsers({ payload }) {
  try {
    const list = yield listOrgUsersService(payload);
    yield put(listOrgUsersSuccessAction(list.data.data));
  } catch (error) {
    yield put(listOrgUsersFailedAction(error));
  }
}

export function* createOrgUser({ payload }) {
  try {
    const create = yield createOrgUserService(payload);
    if (isEmpty(create)) {
      yield put(createOrgUserFailAction(create.data));
      notification['error']({
        message: 'Something went wrong!',
        description: 'Please retry or contact administration.',
      });
    } else if (create.status_code === 400) {
      yield put(createOrgUserFailAction(create.data));
      notification['error']({
        message: 'Email already exists!',
        description:
          'This email already exists. Please enter unique email address.',
      });
    } else if (create.status === 200) {
      yield put(createOrgUserSuccessAction());
      notification['success']({
        message: 'Org user added successfully.',
        description:
          'Org user added successfully and an invite email has sent.',
      });
      yield call(listOrgUsers, {
        payload: {
          page: 1,
          search: '',
          ordering: '',
          page_size: 10,
          created_at_after: '',
          created_at_before: '',
          is_active: '',
        },
      });
    } else {
      yield put(createOrgUserFailAction(create.status));
    }
  } catch (error) {
    yield put(createOrgUserFailAction(error));
    notification['error']({
      message: 'Something went wrong!',
      description: 'Please retry or contact administration.',
    });
  }
}

export function* editOrgUser({ payload: { name, role, userId } }) {
  try {
    const edit = yield editOrgUserService(name, role, userId);
    yield put(editOrgUserSuccessAction(edit.data.data));
    notification['success']({
      message: 'User profile updated successfully.',
      description: 'User profile updated successfully',
    });
  } catch (error) {
    yield put(editOrgUserFailAction(error));
  }
}

export function* viewOrgUser({ payload: userId }) {
  try {
    const list = yield viewOrgUserService(userId);
    yield put(viewOrgUserSuccessAction(list.data.data));
  } catch (error) {
    yield put(viewOrgUserFailAction(error));
  }
}

export function* changeOrgUserStatus({
  payload: { userStatus: status, uuid },
}) {
  try {
    const change = yield changeOrgUserStatusService(status, uuid);
    yield put(changeOrgUserStatusSuccessAction(change.data.data));
    notification['success']({
      message: 'User status updated successfully.',
      description: 'User status updated successfully',
    });
  } catch (error) {
    yield put(changeOrgUserStatusFailAction(error));
    notification['error']({
      message: 'Failed to update user status!',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* updateOrgUserPassword({
  payload: { profile_uuid, password },
}) {
  try {
    const update = yield updateOrgUserPasswordService(profile_uuid, password);
    yield put(resetOrgUserPasswordSuccessAction(update.data.data));
    notification['success']({
      message: 'User password updated successfully.',
      description: 'User password updated successfully',
    });
  } catch (error) {
    yield put(resetOrgUserPasswordFailAction(error));
    notification['error']({
      message: 'Failed to update user password!',
      description: 'Please retry or contact administration.',
    });
  }
}
//Watcher
export function* onListRequest() {
  yield takeLatest(types.LIST_ORG_USERS, listOrgUsers);
}

export function* onCreateOrgUser() {
  yield takeLatest(types.CREATE_ORG_USER, createOrgUser);
}

export function* onEditOrgUser() {
  yield takeLatest(types.EDIT_ORG_USER, editOrgUser);
}

export function* onViewOrgUser() {
  yield takeLatest(types.VIEW_ORG_USER, viewOrgUser);
}
export function* onChangeOrgUserStatus() {
  yield takeLatest(types.CHANGE_ORG_USER_STATUS, changeOrgUserStatus);
}
export function* onUpdateOrgUserPassword() {
  yield takeLatest(types.UPDATE_ORG_USER_PASSWORD, updateOrgUserPassword);
}

export function* orgUsersSaga() {
  yield all([
    onListRequest(),
    onCreateOrgUser(),
    onEditOrgUser(),
    onViewOrgUser(),
    onChangeOrgUserStatus(),
    onUpdateOrgUserPassword(),
  ]);
}
